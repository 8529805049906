import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { toDeci, toPerc } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';
import { riskMeasuresRatios, riskMeasuresPerc, riskMeasuresDeci2 } from 'helpers/meta';

[
  'CAGR',
  'Standard Deviation',
  'Beta',
  'CVaR 95%',
  'Maximum Drawdown',
  'Downside Deviation',
  'Skew',
  'Kurtosis',
  'Sharpe Ratio',
  'Sortino Ratio',
  'MAR Ratio (RoMaD)',
  'Omega Ratio',
  'Correlation',
];

export const metricOptions = [
  {
    label: 'CAGR and Std Dev',
    value: { keys: ['CAGR', 'Standard Deviation'], type: 'percentage' },
  },
  {
    label: 'Beta and Correlation',
    value: { keys: ['Beta', 'Correlation'], type: 'decimal' },
  },
  {
    label: 'CVaR and Max DD',
    value: { keys: ['CVaR 95%', 'Maximum Drawdown'], type: 'percentage' },
  },
  {
    label: 'Std Dev and Downside Dev',
    value: { keys: ['Standard Deviation', 'Downside Deviation'], type: 'percentage' },
  },
  {
    label: 'Skew and Kurtosis',
    value: { keys: ['Skew', 'Kurtosis'], type: 'decimal' },
  },
  {
    label: 'Sharpe and Sortino',
    value: { keys: ['Sharpe Ratio', 'Sortino Ratio'], type: 'decimal' },
  },
  {
    label: 'MAR and Omega',
    value: { keys: ['MAR Ratio (RoMaD)', 'Omega Ratio'], type: 'decimal' },
  },
  {
    label: 'Ratios (Sharpe, Sortino, Omega)',
    value: { keys: ['Sharpe Ratio', 'Sortino Ratio', 'Omega Ratio'], type: 'decimal' },
  },
];

function RiskMetrics({ data: { data, table_dates }, params, options = {}, notify, session }) {
  const [holdingOptions, setHoldingOptions] = useState([]);
  const [selectedHoldingOption, setSelectedHoldingOption] = useState(null);
  const [selectedMetricOption, setSelectedMetricOption] = useState(metricOptions[0]);

  useEffect(() => {
    if (!data) return;

    const _holdings = data.index.filter((val) => ![params.port1Name, params.benchmark].includes(val));
    const _holdingOptions = optionizeAll(_holdings);
    setHoldingOptions(_holdingOptions);

    if (_holdingOptions.length > 0) {
      setSelectedHoldingOption(_holdingOptions[0]);
    }
  }, [data]);

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Risk Metrics
          <TimePeriod dates={table_dates} />
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="risk-performance-risk-metrics"
            data={data}
            indexName=""
            indexWidth="250px"
            useSort={true}
            rowDecorator={(rowTitle, rowInd, rowTitles) =>
              rowTitle === params.benchmark ? { style: { backgroundColor: 'rgb(251,198,88,0.5)' } } : undefined
            }
            cellFormatter={(value, row, col) => {
              return riskMeasuresDeci2.includes(col)
                ? toDeci(value, { decorate: true, absolute: true })
                : toPerc(value, { decorate: true, absolute: true });
            }}
            title={['Risk Metrics', formatDates(table_dates)]}
            {...options.riskMetricTable}
          />
        </CardBody>
      </Card>

      <div className="tw-grid tw-grid-cols-6 tw-gap-4">
        <div className="tw-col-start-2 tw-col-span-4">
          <div className="tw-flex">
            <div className="tw-mr-1">
              <div className="tw-text-left tw-font-bold">SELECT RISK METRICS</div>
              <Select
                className="react-select tw-w-[380px] tw-inline-block"
                value={selectedMetricOption}
                onChange={setSelectedMetricOption}
                options={metricOptions}
              />
            </div>
            {holdingOptions.length > 1 && (
              <div>
                <div className="tw-text-left tw-font-bold">SELECT HOLDING</div>
                <Select
                  className="react-select tw-w-[380px] tw-inline-block"
                  value={selectedHoldingOption}
                  onChange={setSelectedHoldingOption}
                  options={holdingOptions}
                />
              </div>
            )}
          </div>
          {selectedMetricOption && (
            <>
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  Risk Charts
                </CardHeader>
                <CardBody>
                  {selectedHoldingOption ? (
                    <TightChart
                      title="Risk Charts"
                      data={data}
                      rows={[params.port1Name, params.benchmark, selectedHoldingOption.value]}
                      cols={selectedMetricOption.value.keys}
                      dataType={selectedMetricOption.value.type}
                      base="cols"
                    />
                  ) : (
                    <TightChart
                      title="Risk Charts"
                      data={data}
                      rows={[params.port1Name, params.benchmark]}
                      cols={selectedMetricOption.value.keys}
                      dataType={selectedMetricOption.value.type}
                      base="cols"
                    />
                  )}
                </CardBody>
              </Card>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default RiskMetrics;
