import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody, TabPane, Row, Col, Table } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { toDeci, toDeciTable, toPercTable, formatDate } from 'helpers/formatter';
import { getTightRowByKey, getTightColIndexByKey, colors } from 'helpers/chart';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';
import { removeEmptyCols } from 'helpers/data-frame';

function Correlation({ data: { data, table_dates }, params, notify, session }) {
  const [filteredDF, setFilteredDF] = useState(null);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [selectedSubjectOption, setSelectedSubjectOption] = useState(null);
  const [assetOptions, setAssetOptions] = useState([]);
  const [selectedAssetOption, setSelectedAssetOption] = useState(null);

  useEffect(() => {
    if (!data) return;

    const _filteredDF = removeEmptyCols(data, ['15Y', '20Y']);
    setFilteredDF(_filteredDF);

    const _assetOptions = optionizeAll(_filteredDF.index);
    setAssetOptions(_assetOptions);

    if (_assetOptions.length > 0) {
      setSelectedAssetOption(_assetOptions[0]);
    }

    let _subjectOptions = optionizeAll(_filteredDF.columns);
    // exclude the subjects with no data in rows
    _subjectOptions = _subjectOptions.filter((opt) => {
      const colIndex = getTightColIndexByKey(_filteredDF, opt.value);
      return _filteredDF.index.some((row) => {
        const rowData = getTightRowByKey(_filteredDF, row);
        return rowData[colIndex];
      });
    });

    setSubjectOptions(_subjectOptions);

    if (_subjectOptions.length > 0) {
      setSelectedSubjectOption(_subjectOptions[0]);
    }
  }, [data]);

  if (!filteredDF) return null;

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Asset Correlations
          <div className="tw-text-lg">{params.product}</div>
          <TimePeriod dates={table_dates} />
        </CardHeader>
        <CardBody>
          <TightTable
            data={filteredDF}
            indexWidth="350px"
            cellFormatter={toDeciTable}
            suffix="risk-performance-asset-correlation"
            heatmap
            useSort
            title={['Asset Correlations', params.product, formatDates(table_dates)]}
          />
        </CardBody>
      </Card>
      <div className="tw-grid tw-grid-cols-6 tw-gap-4">
        <div className="tw-col-span-6 xl:tw-col-start-2 xl:tw-col-span-4">
          <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
          <Select
            className="react-select tw-w-[200px]"
            value={selectedSubjectOption}
            onChange={setSelectedSubjectOption}
            options={subjectOptions}
          />
          {selectedSubjectOption && (
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                <div>Asset Correlations</div>
                <div className="tw-text-lg">{params.product}</div>
                <div className="tw-text-lg">Time Period - {selectedSubjectOption.label}</div>
              </CardHeader>
              <CardBody>
                <TightChart
                  title="Asset Correlations"
                  data={filteredDF}
                  cols={[selectedSubjectOption.value]}
                  flip={false}
                  omitEmpty={true}
                  wraplabelX={true}
                  options={{
                    scales: {
                      x: {
                        ticks: {
                          font: {
                            size: 11,
                          },
                        },
                      },
                    },
                  }}
                />
              </CardBody>
            </Card>
          )}
        </div>
      </div>
      <div className="tw-grid tw-grid-cols-6 tw-gap-4">
        <div className="tw-col-span-6 xl:tw-col-start-2 xl:tw-col-span-4">
          <div className="tw-text-left tw-font-bold">SELECT ASSET CLASS</div>
          <Select
            className="react-select mw-400px"
            value={selectedAssetOption}
            onChange={setSelectedAssetOption}
            options={assetOptions}
          />
          {selectedAssetOption && (
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                <div>Asset Class: {selectedAssetOption.label}</div>
                <div className="tw-text-lg">{params.product}</div>
              </CardHeader>
              <CardBody>
                <TightChart
                  title={`Asset Class: ${selectedAssetOption.label}`}
                  data={filteredDF}
                  cols={[selectedAssetOption.value]}
                  flip={true}
                  wraplabelX={true}
                />
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </>
  );
}

export default Correlation;
