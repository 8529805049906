import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import Select from 'react-select';
import { toDeci, toDeciTable, toPercTable, formatDate } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';

function CreditSpreads({ data, session }) {
  const [periodOptions, setPeriodOptions, selectedPeriodOption, setSelectedPeriodOption] = useArrayState();

  useEffect(() => {
    const _periods = _.keys(data);
    const _periodOptions = optionizeAll(_periods, { labelFormatter: _.upperFirst });
    setPeriodOptions(_periodOptions);
  }, [data]);

  if (!data || !selectedPeriodOption) return null;

  const periodData = data[selectedPeriodOption.value];

  return (
    <>
      <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
      <Select
        className="react-select tw-w-[200px]"
        value={selectedPeriodOption}
        onChange={setSelectedPeriodOption}
        options={periodOptions}
      />
      {selectedPeriodOption && (
        <>
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Exposure: Credit Spreads
              <TimePeriod dates={periodData.table_dates} prefix="Time Period:" />
            </CardHeader>
            <CardBody>
              <TightTable
                suffix="risk-performance-credit-spreads"
                data={periodData.data}
                indexName=""
                indexWidth="250px"
                cellFormatter={(value, row, col, rowInd, colInd, flip) => {
                  const label = flip ? col : row;
                  return _.isBoolean(value)
                    ? value
                    : ['R2'].includes(label)
                    ? toPercTable(value, { precision: 0 })
                    : toDeciTable(value);
                }}
                title={['Exposure: Credit Spreads', formatDates(periodData.table_dates)]}
              />
            </CardBody>
          </Card>

          <div className="tw-grid tw-grid-cols-6 tw-gap-4">
            <div className="tw-col-start-2 tw-col-span-4">
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  Exposure: Credit Spreads
                </CardHeader>
                <CardBody>
                  <TightChart
                    title="Exposure: Credit Spreads"
                    data={periodData.data}
                    cols={['Beta', 'R2']}
                    flip={true}
                    wraplabelX={true}
                    dataType="decimal"
                  />
                </CardBody>
              </Card>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CreditSpreads;
