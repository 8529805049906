import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { toPerc, formatDate } from 'helpers/formatter';
import { formatDates } from 'components/TimePeriod';
import AssetHeader from 'views/AssetHeader';

function AssetInfo({
  asset,
  database,
  data,
  valueMap = {
    name: 'asset_name',
    description: 'fund_description',
    benchmark: 'assumptions. .Benchmark',
    geography: 'assumptions. .Geography (CAPM & FFM)',
    startDate: 'info_df. .Start Date',
    endDate: 'info_df. .End Date',
    cumulative: 'info_df. .Cumulative Return',
    cagr: 'info_df. .CAGR',
    deviation: 'info_df. .Standard Deviation',
    impactDescription: 'Impact Description',
  },
  showMap = {
    name: true,
    description: true,
    benchmark: true,
    geography: true,
    impactDescription: false,
    timePeriod: true,
    returns: true,
  },
  geographyTitle = 'CAPM and Factors',
  children,
}) {
  const getValue = (key) => _.get(data, key, '');
  const values = _.mapValues(valueMap, getValue);

  const tableData = {};
  let boxData = null;

  if (showMap.description) {
    tableData['Fund Description'] = values.description;
  }

  if (showMap.benchmark) {
    tableData['Benchmark'] = values.benchmark;
  }

  if (showMap.geography && values.geography) {
    tableData[geographyTitle] = values.geography;
  }

  if (showMap.impactDescription) {
    tableData['Portfolio Description'] = values.impactDescription;
  }

  if (showMap.timePeriod) {
    tableData['Time Period'] = formatDates([values.startDate, values.endDate]);
  }

  if (showMap.returns) {
    boxData = {
      'Cumulative Return': values.cumulative,
      CAGR: values.cagr,
      'Standard Deviation': values.deviation,
    };
  }

  return (
    <AssetHeader asset={asset} database={database} tableData={tableData} boxData={boxData}>
      {children}
    </AssetHeader>
  );
}

export default AssetInfo;
