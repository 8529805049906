import { instance } from './axios';

const testUser = { id: 'id', firstName: 'demo@apfinlabs.com', lastName: '', image: null };

export async function getSession() {
  try {
    const data = await instance.get('/cognito/session').then((res) => res.data);
    return data;
  } catch {
    return null;
  }
}

export async function updateConsent() {
  try {
    const data = await instance.get('/cognito/consent').then((res) => res.data);
    return data;
  } catch {
    return null;
  }
}
