import React, { useState, useEffect, useMemo } from 'react';
import { Chart } from 'react-chartjs-2';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import _ from 'lodash';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import Select from 'react-select';
import { getClientPortfolioOptions, getClientPortfolio } from 'services/analysis';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import BubbleChart from 'components/BubbleChart';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { toDeci, toPerc, isEmptyTightData, removeEmptyRows } from 'helpers/formatter';
import { optionize, optionizeAll } from 'helpers/select';
import { colors } from 'helpers/chart';

const multiply100 = (v) => parseFloat(v) * 100;
const createChartLabel =
  (suffix = '') =>
  (item, data) =>
    `${item.dataset.label}: ${toDeci(item.raw)}${suffix}`;

const chartLabel = createChartLabel();
const chartLabelPerc = createChartLabel('%');

const SpinnerWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const riskMeasuresRatios = ['Sharpe Ratio', 'Sortino Ratio', 'Omega Ratio'];
const riskMeasuresPerc = ['Cumulative Return', 'CAGR', 'Standard Deviation', 'CVaR 95%', 'Maximum Drawdown'];
const riskMeasuresDeci = ['Beta', 'Skew', 'Kurtosis', ...riskMeasuresRatios, 'Correlation'];
const riskMeasuresAll = [...riskMeasuresPerc, ...riskMeasuresDeci];

function ClientPortfolio({ notify }) {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [option, setOption] = useState(null);
  const [result, setResult] = useState(null);
  const [assets, setAssets] = useState();
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [durations, setDurations] = useState();
  const [selectedDuration, setSelectedDuration] = useState();
  const [selectedMeasure, setSelectedMeasure] = useState(optionize(riskMeasuresAll[0]));

  useEffect(() => {
    const fetchOptions = async () => {
      const [data, err] = await getClientPortfolioOptions();
      if (err) {
        notify.danger({ message: <div>{err}</div> });
      } else {
        setOptions(optionizeAll(data));
        if (!option) setOption(optionize(data[0]));
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    const fetchResult = async () => {
      if (!option) return;

      setLoading(true);
      const [data, err] = await getClientPortfolio({ group: option.value });
      if (err) {
        notify.danger({ message: <div>{err}</div> });
      } else {
        data.risk_summary = _.omitBy(data.risk_summary, isEmptyTightData);
        data.risk_summary = _.mapValues(data.risk_summary, removeEmptyRows);
        setResult(data);
        const durs = _.keys(data.risk_summary);
        const assetList = data.risk_summary[durs[0]].index;
        setDurations(optionizeAll(durs, { labelFormatter: (yrs) => yrs.replace('yr', 'Y') }));
        if (durs.length > 0) setSelectedDuration(optionize(durs[0], durs[0].replace('yr', 'Y')));
        setAssets(optionizeAll(assetList, { useIndex: true }));
        if (assetList.length > 1) setSelectedAssets([optionize(0, assetList[0]), optionize(1, assetList[1])]);
      }
      setLoading(false);
    };

    fetchResult();
  }, [option]);

  const riskReturnChart = useMemo(() => {
    if (!result || !selectedDuration) return {};

    const target = result.risk_summary[selectedDuration.value];
    const rowTitles = target.index;
    const colTitles = target.columns;

    const indCG = colTitles.indexOf('CAGR');
    const indSD = colTitles.indexOf('Standard Deviation');

    return {
      data: {
        datasets: rowTitles.map((title, ind) => {
          return {
            label: title,
            data: [
              {
                x: target.data[ind][indSD] * 100,
                y: target.data[ind][indCG] * 100,
              },
            ],
            pointRadius: 13,
            pointHoverRadius: 5,
            backgroundColor: colors[ind % colors.length],
          };
        }),
      },
      options: {
        plugins: {
          legend: { display: rowTitles.length < 5 },
          tooltip: {
            enabled: true,
            callbacks: {
              label: (item) => `${item.dataset.label}: ${toDeci(item.raw.y)}%, ${toDeci(item.raw.x)}%`,
            },
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Annualized Standard Deviation of Returns',
            },
            ticks: { callback: (val) => `${val}%` },
            color: 'black',
            beginAtZero: true,
          },
          y: {
            title: {
              display: true,
              text: 'Annualized Return',
            },
            ticks: { callback: (val) => `${toDeci(val, { precision: 1 })}%`, color: 'black' },
            beginAtZero: true,
          },
        },
      },
    };
  }, [result, selectedDuration]);

  if (loading || !result) {
    return (
      <SpinnerWrapper>
        <Spinner>Loading...</Spinner>
      </SpinnerWrapper>
    );
  }

  return (
    <>
      <Select className="react-select mw-400px" name="group" value={option} onChange={setOption} options={options} />
      <br />
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Returns
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="returns"
            data={result.return_summary_lt}
            indexName="Name"
            useSort={true}
            cellFormatter={(value, row, col) =>
              ['Currency', 'Inception Date'].includes(col) ? value : toPerc(value, { decorate: true, absolute: true })
            }
            title="Returns"
          />
        </CardBody>
      </Card>

      {durations && durations.length > 0 && (
        <>
          <Select
            className="react-select mw-200px"
            name="duration"
            value={selectedDuration}
            onChange={setSelectedDuration}
            options={durations}
          />
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Risk Summary
              <div className="small">Time Period ({selectedDuration.value.replace('yr', ' Year')})</div>
            </CardHeader>
            <CardBody>
              <TightTable
                suffix="risk-summary"
                data={result.risk_summary[selectedDuration.value]}
                indexName="Name"
                useSort={true}
                cellFormatter={(value, row, col) =>
                  ['Cumulative Return', 'CAGR', 'Standard Deviation', 'CVaR 95%', 'Maximum Drawdown'].includes(col)
                    ? toPerc(value, { decorate: true, absolute: true })
                    : toDeci(value, { decorate: true, absolute: true })
                }
                title={['Risk Summary', `Time Period (${selectedDuration.value.replace('yr', ' Year')})`]}
              />
            </CardBody>
          </Card>

          <Row>
            <Col xl="8" className="offset-xl-2">
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  Risk and Return
                </CardHeader>
                <CardBody>
                  <BubbleChart
                    data={riskReturnChart.data}
                    options={riskReturnChart.options}
                    height={400}
                    width={826}
                    title="Risk and Return"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl="6">
              {assets && assets.length > 0 && (
                <Select
                  className="react-select w-full"
                  name="asset"
                  placeholder="Please select two assets..."
                  isMulti={true}
                  isOptionDisabled={() => selectedAssets.length >= 2}
                  value={selectedAssets}
                  onChange={setSelectedAssets}
                  options={assets}
                />
              )}
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  Risk Charts
                </CardHeader>
                <CardBody>
                  {selectedAssets.length > 1 ? (
                    <TightChart
                      title="Risk Charts"
                      data={result.risk_summary[selectedDuration.value]}
                      rows={selectedAssets.map((v) => v.label)}
                      cols={riskMeasuresRatios}
                      options={{
                        plugins: {
                          tooltip: {
                            callbacks: {
                              label: (item, data) => `${item.dataset.label}: ${toDeci(item.raw)}`,
                            },
                          },
                        },
                      }}
                    />
                  ) : (
                    <div className="text-center">Please select two assets...</div>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col xl="6">
              <Select
                className="react-select mw-300px"
                name="measure"
                value={selectedMeasure}
                onChange={setSelectedMeasure}
                options={optionizeAll(riskMeasuresAll, false)}
              />
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  Risk Charts
                </CardHeader>
                <CardBody>
                  <TightChart
                    title="Risk Charts"
                    data={result.risk_summary[selectedDuration.value]}
                    cols={[selectedMeasure.value]}
                    wraplabelX={true}
                    valueFormatter={riskMeasuresPerc.includes(selectedMeasure.value) ? multiply100 : undefined}
                    options={{
                      plugins: {
                        tooltip: {
                          callbacks: {
                            label: riskMeasuresPerc.includes(selectedMeasure.value) ? chartLabelPerc : chartLabel,
                          },
                        },
                        legend: {
                          display: false,
                        },
                      },
                      scales: {
                        y: {
                          ticks: {
                            callback: (val) =>
                              `${toDeci(val, {
                                absolute: true,
                                suffix: riskMeasuresPerc.includes(selectedMeasure.value) ? '%' : '',
                              })}`,
                            color: ({ tick }) => (tick.value < 0 ? 'red' : 'black'),
                          },
                        },
                        x: {
                          ticks: {
                            font: {
                              size: 10,
                            },
                          },
                        },
                      },
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default ClientPortfolio;
