import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { Card, CardHeader, CardBody, Label, TabPane, Row, Col } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { getTightRowByKey, getTightColIndexByKey, colors } from 'helpers/chart';
import { toDeci, toDeciTable, toPercTable, tablePresets } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';

function CorrelationMatrix({ data, params, notify, session }) {
  const [periodOptions, setPeriodOptions, selectedPeriodOption, setSelectedPeriodOption] = useArrayState();
  const [assetOptions, setAssetOptions, selectedAssetOption, setSelectedAssetOption] = useArrayState();

  useEffect(() => {
    if (!data) return;

    const _periods = _.keys(data);
    const _periodOptions = optionizeAll(_periods, { labelFormatter: _.upperFirst });
    setPeriodOptions(_periodOptions);
    if (_periodOptions.length > 0) setSelectedPeriodOption(_periodOptions[0]);
  }, [data]);

  useEffect(() => {
    if (!selectedPeriodOption) return;

    const periodData = data[selectedPeriodOption.value].data;

    // exclude the subjects with no data in rows
    const validCols = periodData.columns.filter((col) => {
      const colIndex = getTightColIndexByKey(periodData, col);
      return periodData.index.some((row) => {
        const rowData = getTightRowByKey(periodData, row);
        return rowData[colIndex];
      });
    });

    setAssetOptions(optionizeAll(validCols));
  }, [selectedPeriodOption]);

  if (!selectedPeriodOption || !selectedAssetOption) return null;

  const periodData = data[selectedPeriodOption.value];

  return (
    <>
      <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
      <Select
        className="react-select mw-200px"
        name="duration"
        value={selectedPeriodOption}
        onChange={setSelectedPeriodOption}
        options={periodOptions}
      />

      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Cross Correlations
          <TimePeriod dates={periodData.table_dates} />
        </CardHeader>
        <CardBody>
          <TightTable
            data={periodData.data}
            indexWidth="350px"
            cellFormatter={toDeciTable}
            suffix="risk-performance-cross-correlation"
            heatmap
            title={['Cross Correlations', formatDates(periodData.table_dates)]}
          />
        </CardBody>
      </Card>
      <div className="tw-grid tw-grid-cols-6 tw-gap-4">
        <div className="tw-col-start-2 tw-col-span-4">
          <div className="tw-text-left tw-font-bold">SELECT</div>
          <Select
            className="react-select mw-400px"
            value={selectedAssetOption}
            onChange={setSelectedAssetOption}
            options={assetOptions}
          />
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              {selectedAssetOption.label}
              <TimePeriod dates={periodData.table_dates} />
            </CardHeader>
            <CardBody>
              <TightChart
                title={selectedAssetOption.label}
                data={periodData.data}
                cols={[selectedAssetOption.value]}
                rows={(rows) => rows.filter((row) => row !== selectedAssetOption.value)}
                flip={true}
                wraplabelX={true}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default CorrelationMatrix;
