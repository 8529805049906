import React, { useState, useEffect, useMemo } from 'react';
import { Chart } from 'react-chartjs-2';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import _ from 'lodash';
import { Card, CardHeader, CardBody, TabPane, Row, Col } from 'reactstrap';
import Select from 'react-select';
import { getMyflareAlternativeFundsOptions, getMyflareAlternativeFunds } from 'services/analysis';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import ScatterChart from 'components/ScatterChart';
import PageTabs from 'components/PageTabs';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import {
  toDeci,
  toPerc,
  toDeciTable,
  toPercTable,
  isEmptyTightData,
  removeEmptyRows,
  formatDate,
} from 'helpers/formatter';
import { optionize, optionizeAll } from 'helpers/select';
import { colors } from 'helpers/chart';
import { riskRatioGroups, riskMeasuresPerc, riskMeasuresAll } from 'helpers/meta';
import CalendarYearReturns from './alternatives/CalendarYearReturns';
import ShortTermReturns from './alternatives/ShortTermReturns';

const tabOptions1 = [
  { value: 'description', label: 'Description' },
  { value: 'risk-performance', label: 'Risk & Performance' },
];

const tabOptions2 = [
  { value: 'detail', label: 'Detail' },
  { value: 'category', label: 'Category' },
  { value: 'terms', label: 'Terms' },
];

const tabOptions3 = [
  { value: 'short-term-returns', label: 'Short-Term Returns' },
  { value: 'returns', label: 'Long-Term Returns' },
  { value: 'calendar-year-returns', label: 'Calendar Year Returns' },
  { value: 'risk-metrics', label: 'Risk Metrics' },
];

const SpinnerWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

function AlternativeDashboard({ notify }) {
  const [loading, setLoading] = useState(false);
  const [selectedTab1, setSelectedTab1] = useState(tabOptions1[0]);
  const [selectedTab2, setSelectedTab2] = useState(tabOptions2[0]);
  const [result, setResult] = useState(null);
  const [assets, setAssets] = useState();
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [periodOptions, setPeriodOptions, selectedPeriodOption, setSelectedPeriodOption] = useArrayState();
  const [lvl1Options, setLvl1Options, selectedLvl1Option, setSelectedLvl1Option] = useArrayState({
    initialItems: [
      { value: 'Strategy', label: 'Strategy' },
      { value: 'Fund Manager', label: 'Fund Manager' },
    ],
    initialItem: { value: 'Strategy', label: 'Strategy' },
  });
  const [offeringType, setOfferingType] = useState();
  const [strategyOptions, setStrategyOptions, selectedStrategyOption, setSelectedStrategyOption] = useArrayState();
  const [managerOptions, setManagerOptions, selectedManagerOption, setSelectedManagerOption] = useArrayState();
  const [offeringOptions, setOfferingOptions, selectedOfferingOption, setSelectedOfferingOption] = useArrayState();
  const [fundOptions, setFundOptions, selectedFundOption, setSelectedFundOption] = useArrayState();
  const [selectedRiskGroup, setSelectedRiskGroup] = useState(riskRatioGroups[0]);

  useEffect(() => {
    const fetchOptions = async () => {
      const [data, err] = await getMyflareAlternativeFundsOptions();
      if (err) {
        notify.danger({ message: <div>{err}</div> });
      } else {
        setStrategyOptions(optionizeAll(data['Strategy']));
        setManagerOptions(optionizeAll(data['Fund Manager']));
        setOfferingType(data['Offering Type']);
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    const fetchResult = async () => {
      if (!selectedLvl1Option || !selectedStrategyOption || !selectedManagerOption) return;

      setLoading(true);

      const value1 =
        selectedLvl1Option.value === 'Strategy' ? selectedStrategyOption.value : selectedManagerOption.value;
      const offerings = offeringType[selectedLvl1Option.value][value1];

      // Refresh offering type options only
      if (!_.isEqual(optionizeAll(offerings), offeringOptions)) {
        const _offeringOptions = optionizeAll(offerings);
        setOfferingOptions(_offeringOptions);
        setSelectedOfferingOption(_offeringOptions[0]);
        return;
      }

      const [data, err] = await getMyflareAlternativeFunds({
        filter_choice_1: selectedLvl1Option.value,
        filter_value_1: value1,
        filter_value_2: selectedOfferingOption.value,
      });

      if (err) {
        notify.danger({ message: <div>{err}</div> });
      } else {
        data.risk_summary = _.omitBy(data.risk_summary, isEmptyTightData);
        data.risk_summary = _.mapValues(data.risk_summary, removeEmptyRows);
        setResult(data);

        const _periods = _.keys(data.risk_summary);
        const _periodOptions = optionizeAll(_periods, {
          labelFormatter: (yrs) => _.upperFirst(yrs.replace('yr', 'Y')),
        });
        setPeriodOptions(_periodOptions);
        setFundOptions(optionizeAll(data.calendar_year_returns.index));
      }
      setLoading(false);
    };

    fetchResult();
  }, [selectedLvl1Option, selectedStrategyOption, selectedManagerOption, selectedOfferingOption]);

  const riskReturnChart = useMemo(() => {
    if (!result || !selectedPeriodOption) return {};

    const target = result.risk_summary[selectedPeriodOption.value];
    const rowTitles = target.index;
    const colTitles = target.columns;

    const indCG = colTitles.indexOf('CAGR');
    const indSD = colTitles.indexOf('Standard Deviation');

    return {
      data: {
        datasets: rowTitles.map((title, ind) => {
          return {
            label: title,
            data: [
              {
                x: target.data[ind][indSD] * 100,
                y: target.data[ind][indCG] * 100,
              },
            ],
            pointRadius: 13,
            pointHoverRadius: 5,
            backgroundColor: colors[ind % colors.length],
          };
        }),
      },
      options: {
        plugins: {
          legend: { display: rowTitles.length < 5 },
          tooltip: {
            enabled: true,
            callbacks: {
              label: (item) => `${item.dataset.label}: ${toDeci(item.raw.y)}%, ${toDeci(item.raw.x)}%`,
            },
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Standard Deviation of Returns',
            },
            ticks: { callback: (val) => `${val}%` },
            color: 'black',
            beginAtZero: true,
          },
          y: {
            title: {
              display: true,
              text: 'Annualized Return',
            },
            ticks: {
              callback: (val) => toDeci(val, { precision: 2, suffix: '%', absolute: true }),
              color: ({ tick }) => (tick.value < 0 ? 'red' : 'black'),
            },
            beginAtZero: true,
          },
        },
      },
    };
  }, [result, selectedPeriodOption]);

  useEffect(() => {
    if (!result || !selectedPeriodOption) return;

    const assetList = result.risk_summary[selectedPeriodOption.value].index;
    setAssets(optionizeAll(assetList, { useIndex: true }));
    if (assetList.length > 1) setSelectedAsset(optionize(0, assetList[0]));
  }, [result, selectedPeriodOption]);

  if (loading || !result) {
    return (
      <SpinnerWrapper>
        <Spinner>Loading...</Spinner>
      </SpinnerWrapper>
    );
  }

  const headerComponent = (
    <div className="tw-grid tw-gap-2 tw-grid-flow-col tw-justify-items-stretch tw-mb-2">
      <div>
        <div className="tw-text-left tw-font-bold">SELECT</div>
        <Select
          className="react-select"
          name="lvl1"
          value={selectedLvl1Option}
          onChange={setSelectedLvl1Option}
          options={lvl1Options}
        />
      </div>
      {selectedLvl1Option.value === 'Strategy' && (
        <div>
          <div className="tw-text-left tw-font-bold">Strategy</div>
          <Select
            className="react-select"
            name="strategy"
            value={selectedStrategyOption}
            onChange={setSelectedStrategyOption}
            options={strategyOptions}
          />
        </div>
      )}
      {selectedLvl1Option.value === 'Fund Manager' && (
        <div>
          <div className="tw-text-left tw-font-bold">Fund Manager</div>
          <Select
            className="react-select"
            name="manager"
            value={selectedManagerOption}
            onChange={setSelectedManagerOption}
            options={managerOptions}
          />
        </div>
      )}
      <div>
        <div className="tw-text-left tw-font-bold">Offering Type</div>
        <Select
          className="react-select"
          name="offering"
          value={selectedOfferingOption}
          onChange={setSelectedOfferingOption}
          options={offeringOptions}
        />
      </div>
    </div>
  );

  return (
    <>
      <PageTabs
        options={tabOptions1.map((option) => ({ ...option, default: option.value === selectedTab1.value }))}
        onTabSelect={(newValue) => {
          setSelectedTab1(newValue);
          setSelectedTab2(newValue.value === tabOptions1[0].value ? tabOptions2[0] : tabOptions3[0]);
        }}
      >
        <TabPane tabId="description" role="tabpanel">
          <PageTabs
            options={tabOptions2.map((option) => ({ ...option, default: option.value === selectedTab2.value }))}
            colorIndex={0}
            onTabSelect={setSelectedTab2}
            HeaderComponent={headerComponent}
          >
            <TabPane tabId="detail" role="tabpanel">
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  Detail
                </CardHeader>
                <CardBody>
                  <TightTable
                    title="Detail"
                    suffix="detail"
                    data={result.detail_table}
                    indexName="Fund"
                    useSort={true}
                  />
                </CardBody>
              </Card>
            </TabPane>
            <TabPane tabId="category" role="tabpanel">
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  Category
                </CardHeader>
                <CardBody>
                  <TightTable
                    title="Category"
                    suffix="category"
                    data={result.category_table}
                    indexName="Fund"
                    useSort={true}
                  />
                </CardBody>
              </Card>
            </TabPane>
            <TabPane tabId="terms" role="tabpanel">
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  Key Terms and Providers
                </CardHeader>
                <CardBody>
                  <TightTable
                    suffix="key-terms-providers"
                    data={result.terms_table}
                    indexName="Fund"
                    useSort={true}
                    cellFormatter={(value, row, col) =>
                      ['Management Fee', 'Performance Fee'].includes(col)
                        ? toPerc(value, { decorate: true, absolute: true })
                        : value
                    }
                    title="Key Terms and Providers"
                  />
                </CardBody>
              </Card>
            </TabPane>
          </PageTabs>
        </TabPane>
        <TabPane tabId="risk-performance" role="tabpanel">
          <PageTabs
            options={tabOptions3.map((option) => ({ ...option, default: option.value === selectedTab2.value }))}
            colorIndex={0}
            onTabSelect={setSelectedTab2}
            HeaderComponent={headerComponent}
          >
            <TabPane tabId="short-term-returns" role="tabpanel">
              <ShortTermReturns data={result.short_term_returns} asof={result.summary_asof_date} />
            </TabPane>
            <TabPane tabId="returns" role="tabpanel">
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  Returns
                  <div className="small">As of: {formatDate(result.summary_asof_date)}</div>
                </CardHeader>
                <CardBody>
                  <TightTable
                    suffix="returns"
                    data={result.return_summary_lt}
                    indexName="Fund"
                    useSort={true}
                    cellFormatter={(value, row, col) =>
                      ['Currency', 'Inception Date'].includes(col)
                        ? value
                        : toPerc(value, { decorate: true, absolute: true })
                    }
                    title={['Returns', `As of: ${formatDate(result.summary_asof_date)}`]}
                  />
                </CardBody>
              </Card>
              {assets && assets.length > 0 && (
                <Row>
                  <Col xl="8" className="offset-xl-2">
                    <div className="tw-text-left tw-font-bold">SELECT FUND</div>
                    <Select
                      className="react-select mw-400px"
                      name="asset"
                      placeholder="Please select an asset..."
                      value={selectedAsset}
                      onChange={setSelectedAsset}
                      options={assets}
                    />
                    {selectedAsset && (
                      <Card>
                        <CardHeader tag="h4" className="mt-0 text-center">
                          {selectedAsset.label}
                        </CardHeader>
                        <CardBody>
                          <TightChart
                            title={selectedAsset.label}
                            data={result.return_summary_lt}
                            rows={[selectedAsset.label]}
                            base="cols"
                            cols={(cols) => cols.filter((col) => !['Currency', 'Inception Date'].includes(col))}
                            dataType="percentage"
                            omitEmpty={false}
                          />
                        </CardBody>
                      </Card>
                    )}
                  </Col>
                </Row>
              )}
            </TabPane>
            <TabPane tabId="calendar-year-returns" role="tabpanel">
              <CalendarYearReturns data={result.calendar_year_returns} asof={result.summary_asof_date} />
            </TabPane>
            <TabPane tabId="risk-metrics" role="tabpanel">
              {periodOptions && periodOptions.length > 0 && (
                <>
                  <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
                  <Select
                    className="react-select mw-200px"
                    name="duration"
                    value={selectedPeriodOption}
                    onChange={setSelectedPeriodOption}
                    options={periodOptions}
                  />
                  <Card>
                    <CardHeader tag="h4" className="mt-0 text-center">
                      Risk Metrics
                      <div className="small">Time Period ({selectedPeriodOption.value.replace('yr', ' Year')})</div>
                      <div className="small">As of: {formatDate(result.summary_asof_date)}</div>
                    </CardHeader>
                    <CardBody>
                      <TightTable
                        suffix="risk-summary"
                        data={result.risk_summary[selectedPeriodOption.value]}
                        indexName="Fund"
                        useSort={true}
                        omitEmptyRow={true}
                        cellFormatter={(value, row, col) =>
                          ['Cumulative Return', 'CAGR', 'Standard Deviation', 'CVaR 95%', 'Maximum Drawdown'].includes(
                            col,
                          )
                            ? toPerc(value, { decorate: true, absolute: true })
                            : toDeci(value, { decorate: true, absolute: true })
                        }
                        title={[
                          'Risk Metrics',
                          `Time Period (${selectedPeriodOption.value.replace('yr', ' Year')})`,
                          `As of: ${formatDate(result.summary_asof_date)}`,
                        ]}
                      />
                    </CardBody>
                  </Card>

                  <Row>
                    <Col xl="8" className="offset-xl-2">
                      <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
                      <Select
                        className="react-select mw-200px"
                        name="duration"
                        value={selectedPeriodOption}
                        onChange={setSelectedPeriodOption}
                        options={periodOptions}
                      />
                      <Card>
                        <CardHeader tag="h4" className="mt-0 text-center">
                          Risk and Return
                        </CardHeader>
                        <CardBody>
                          <ScatterChart
                            data={riskReturnChart.data}
                            options={riskReturnChart.options}
                            height={400}
                            width={826}
                            title="Risk and Return"
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  {assets && assets.length > 0 && (
                    <Row>
                      <Col xl="8" className="offset-xl-2">
                        <div className="d-flex">
                          <div style={{ width: '400px', marginRight: '10px' }}>
                            <div className="tw-text-left tw-font-bold">SELECT FUND</div>
                            <Select
                              className="react-select tw-inline-block tw-w-[400px] mr-2"
                              name="asset"
                              placeholder="Please select an asset..."
                              value={selectedAsset}
                              onChange={setSelectedAsset}
                              options={assets}
                            />
                          </div>
                          <div style={{ width: '290px' }}>
                            <div className="tw-text-left tw-font-bold">SELECT RISK METRICS</div>
                            <Select
                              className="react-select tw-inline-block tw-w-[300px]"
                              name="asset"
                              placeholder="Please select an risk ratio group..."
                              value={selectedRiskGroup}
                              onChange={setSelectedRiskGroup}
                              options={riskRatioGroups}
                            />
                          </div>
                        </div>
                        {selectedAsset && selectedRiskGroup && (
                          <Card>
                            <CardHeader tag="h4" className="mt-0 text-center">
                              {selectedAsset.label}
                            </CardHeader>
                            <CardBody>
                              <TightChart
                                title={selectedAsset.label}
                                data={result.risk_summary[selectedPeriodOption.value]}
                                rows={[selectedAsset.label]}
                                cols={selectedRiskGroup.value.keys}
                                base="cols"
                                dataType={selectedRiskGroup.value.type}
                              />
                            </CardBody>
                          </Card>
                        )}
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </TabPane>
          </PageTabs>
        </TabPane>
      </PageTabs>
    </>
  );
}

export default AlternativeDashboard;
