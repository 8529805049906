import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { Card, CardHeader, CardBody, TabPane, Row, Col } from 'reactstrap';
import PageTabs from 'components/PageTabs';
import { FixedCenterLoader } from 'components/Loaders';
import { parseSearchParams } from 'helpers/location';
import SingleAssetOverview from './Overview';
import Returns from './Returns';
import SingleAssetVar from './Var';
import SingleAssetDrawdownAnalysis from './DrawdownAnalysis';
import SingleAssetRiskBudget from './RiskBudget';
import SingleAssetBenchmarkComparison from './BenchmarkComparison';
import SingleAssetFamaFrench from './FamaFrench';
import SingleAssetFactorAnalysis from './FactorAnalysis';
import SingleAssetRollingReturn from './RollingReturn';
import SingleAssetImpactAnalysis from './ImpactAnalysis';

function InvestmentComparison({ notify, session }) {
  const location = useLocation();
  const [params, setParams] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const params = parseSearchParams(location.search, [
      'database',
      'product',
      'benchmark',
      'geography',
      'riskbucket',
      'correlation',
      'significance',
      'sdate',
      'edate',
    ]);
    setParams(params);
  }, [location]);

  if (!params) {
    return <FixedCenterLoader />;
  }

  return (
    <>
      <PageTabs
        options={[
          { value: 'overview', label: 'Overview', permission: 'aa-overview' },
          { value: 'calendar-returns', label: 'Returns', permission: 'aa-returns' },
          { value: 'var', label: 'Value-at-Risk', permission: 'aa-var' },
          { value: 'drawdown', label: 'Drawdown', permission: 'aa-drawdown' },
          { value: 'risk-budget', label: 'Risk Contribution', permission: 'aa-risk-contribution' },
          {
            value: 'benchmark-comparison',
            label: 'Benchmark Comparison',
            permission: 'aa-benchmark',
          },
          { value: 'fama-french', label: 'Fama French', permission: 'aa-fama-french' },
          { value: 'factor-analysis', label: 'Factor Exposures', permission: 'aa-factor-exposures' },
          { value: 'rolling-return', label: 'Rolling Returns', permission: 'aa-rolling-returns' },
          { value: 'impact', label: 'Portfolio Impact', permission: 'aa-portfolio-impact' },
        ]}
        session={session}
        round={false}
      >
        <TabPane tabId="overview" role="tabpanel">
          <SingleAssetOverview params={params} notify={notify} session={session} />
        </TabPane>
        <TabPane tabId="calendar-returns" role="tabpanel">
          <Returns params={params} notify={notify} session={session} />
        </TabPane>
        <TabPane tabId="var" role="tabpanel">
          <SingleAssetVar params={params} notify={notify} session={session} />
        </TabPane>
        <TabPane tabId="drawdown" role="tabpanel">
          <SingleAssetDrawdownAnalysis params={params} notify={notify} session={session} />
        </TabPane>
        <TabPane tabId="risk-budget" role="tabpanel">
          <SingleAssetRiskBudget params={params} notify={notify} session={session} />
        </TabPane>
        <TabPane tabId="benchmark-comparison" role="tabpanel">
          <SingleAssetBenchmarkComparison params={params} notify={notify} session={session} />
        </TabPane>
        <TabPane tabId="fama-french" role="tabpanel">
          <SingleAssetFamaFrench params={params} notify={notify} session={session} />
        </TabPane>
        <TabPane tabId="factor-analysis" role="tabpanel">
          <SingleAssetFactorAnalysis params={params} notify={notify} session={session} />
        </TabPane>
        <TabPane tabId="rolling-return" role="tabpanel">
          <SingleAssetRollingReturn params={params} notify={notify} session={session} />
        </TabPane>
        <TabPane tabId="impact" role="tabpanel">
          <SingleAssetImpactAnalysis params={params} notify={notify} session={session} />
        </TabPane>
      </PageTabs>
    </>
  );
}

export default InvestmentComparison;
