import React, { useState, useImperativeHandle, forwardRef } from 'react';
import _ from 'lodash';
import { Modal, Button } from 'reactstrap';

// treat this modal as a singleton service
let _callback = _.noop;

function GeneralConfirmationModal({}, ref) {
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  useImperativeHandle(ref, () => ({
    open: ({ title = 'Confirmation', content = 'Are you sure to proceed?', callback = _.noop }) => {
      setShowModal(true);
      setTitle(title);
      setContent(content);
      _callback = callback;
    },
  }));

  const handleClose = () => {
    setShowModal(false);
    _callback(null);
  };

  const handleSubmit = () => {
    setShowModal(false);
    _callback('confirmed');
  };

  return (
    <Modal isOpen={showModal} toggle={handleClose} size="md" className="tw-mt-2 tw-pt-0">
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
      </div>
      <div className="modal-body">
        <span>{content}</span>
      </div>
      <div className="modal-footer tw-justify-center">
        <Button
          className="btn-round"
          color="info"
          outline={true}
          data-dismiss="modal"
          type="button"
          onClick={handleClose}
        >
          Cancel
        </Button>

        <Button className="btn-round" color="info" data-dismiss="modal" type="button" onClick={handleSubmit}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
}

export default forwardRef(GeneralConfirmationModal);
