import React from 'react';

export function AutoColumns({ children }) {
  let _children = [];
  if (typeof children === 'object') {
    if (Array.isArray(children)) {
      _children = children;
    } else {
      _children.push(children);
    }
  }

  if (_children.length === 1) {
    return (
      <div className="tw-grid tw-grid-cols-12">
        <div className="tw-col-span-12 xl:tw-col-start-3 xl:tw-col-span-8">{_children}</div>
      </div>
    );
  }

  return (
    <div className="tw-grid tw-grid-cols-12 tw-gap-4">
      <div className="tw-col-span-12 xl:tw-col-span-6">{_children[0]}</div>
      <div className="tw-col-span-12 xl:tw-col-span-6">{_children[1]}</div>
    </div>
  );
}
