import _ from 'lodash';
import { write, utils } from 'xlsx';
import { saveAs } from 'file-saver';

const hasAny = (v) => {
  if (_.isString(v) || _.isNil(v)) return !!v;
  return true;
};

const generateData = (dict1, dict2, cellFormatter, indexName = '', flip) => {
  let { columns, data, index } = dict1;

  const showIndex = index.some(hasAny);
  const showHeaders = columns.some(hasAny);

  if (!showIndex) index = data.map(() => '');
  if (!showHeaders) columns = data[0].map(() => '');

  const rows = [];
  if (showHeaders) {
    const row = [];
    if (showIndex) row.push(indexName);
    row.push(...columns);
    rows.push(row);
  }

  _.each(index, (rowTitle, rowInd) => {
    let empty = true;

    const row = _.map(columns, (colTitle, colInd) => {
      const value = data[rowInd][colInd];
      if (empty) empty = _.isNil(value);

      let processed = cellFormatter(value, rowTitle, colTitle, rowInd, colInd, flip);
      if (_.isObject(processed)) processed = processed.value;

      return processed;
    });

    if (showIndex) row.unshift(rowTitle);

    if (true) {
      if (!empty) rows.push(row);
    } else {
      rows.push(row);
    }
  });

  return rows;
};

const generateDataUrl = (data) => `data:text/csv;charset=utf-8,${data.map((v) => v.join(';')).join('\n')}`;

const downloadFile = (dataUrl, filename) => {
  const encodedUri = encodeURI(dataUrl);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', filename);
  document.body.appendChild(link);

  link.click();
};

export const downloadTable = (dict1, dict2, cellFormatter, indexName, flip, suffix, title) => {
  const data = generateData(dict1, dict2, (v) => v, indexName, flip);

  const workbook = utils.book_new();
  const worksheet = utils.aoa_to_sheet([..._.compact(_.castArray(title)).map(_.castArray), ...data]);
  utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const xlsxOutput = write(workbook, { bookType: 'xlsx', type: 'array' });
  saveAs(new Blob([xlsxOutput], { type: 'application/octet-stream' }), `${suffix}.xlsx`);
};
