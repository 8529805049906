import _ from 'lodash';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getTightRowByKey, getTightColIndexByKey, colors as defaultColors } from 'helpers/chart';
import { toDeci, toPerc, formatCurrency } from 'helpers/formatter';
import { flipDF } from 'helpers/data-frame';
import { customCanvasBackgroundColor } from './_chart';

const splitBySpace = (value) => _.split(value, ' ');

const defaultOptions = {
  indexAxis: 'x',
  plugins: {
    legend: {
      display: true,
    },
    tooltip: {
      enabled: true,
    },
    // autocolors: {
    //   enabled: true,
    //   mode: 'dataset'
    // },
  },
  scales: {
    x: {
      ticks: {
        autoSkip: false,
        maxRotation: 0,
        minRotation: 0,
        color: 'black',
      },
      beginAtZero: true,
    },
  },
};

const tickCallbacks = {
  text: (val) => val,
  percentage: (val) => `${toPerc(val, { absolute: true, suffix: '%' })}`,
  'whole-percentage': (val) => `${toPerc(val, { precision: 0, absolute: true, suffix: '%' })}`,
  currency: (val) => formatCurrency(val, { precision: 2 }),
  'whole-currency': (val) => formatCurrency(val, { precision: 0 }),
  decimal: (val) => `${toDeci(val, { absolute: true })}`,
};

const labelCallbacks = {
  text: (item) => item.raw,
  percentage: (item, data) => `${item.dataset.label}: ${toPerc(item.raw, { suffix: '%' })}`,
  'whole-percentage': (item, data) =>
    `${item.dataset.label}: ${toPerc(item.raw, { precision: 0, absolute: true, suffix: '%' })}`,
  currency: (item, data) => `${item.dataset.label}: ${formatCurrency(item.raw, { precision: 2 })}`,
  'whole-currency': (item, data) => `${item.dataset.label}: ${formatCurrency(item.raw, { precision: 0 })}`,
  decimal: (item, data) => `${item.dataset.label}: ${toDeci(item.raw)}`,
};

const datalabelFormatters = {
  text: (val) => val,
  percentage: (value, context) => `${toPerc(value, { suffix: '%' })}`,
  'whole-percentage': (value, context) => `${toPerc(value, { precision: 0, absolute: true, suffix: '%' })}`,
  currency: (value, context) => formatCurrency(value, { precision: 2 }),
  'whole-currency': (value, context) => formatCurrency(value, { precision: 0 }),
  decimal: (value, context) => `${toDeci(value)}`,
};

const intersectionSort = (base, target) => {
  return base.filter((v) => target.includes(v)).sort((a, b) => target.indexOf(a) - target.indexOf(b));
};

function getBarChartProps({
  data: tightDict = {},
  rows,
  cols,
  base = 'rows',
  omitEmpty = false,
  wraplabelX = false,
  valueFormatter = (v) => v,
  labelFormatter = (v) => v,
  dataType = 'decimal',
  dataLabels = true,
  flip = false,
  alwaysShowScale = false,
  options = {},
  colors = defaultColors,
  width,
  height,
}) {
  const dataFrame = flip ? flipDF(tightDict) : tightDict;
  const flippedDF = flip ? tightDict : flipDF(tightDict);
  if (_.isFunction(rows)) rows = rows(dataFrame.index, dataFrame);
  if (_.isFunction(cols)) cols = cols(flippedDF.index, flippedDF);
  rows = rows ? intersectionSort(dataFrame.index, rows) : dataFrame.index;
  cols = cols ? intersectionSort(dataFrame.columns, cols) : dataFrame.columns;

  const _options = _.cloneDeep(defaultOptions);
  const indexAxis = options.indexAxis || _options.indexAxis;

  const tickCallback = tickCallbacks[dataType];

  _.set(_options, `scales.${indexAxis === 'x' ? 'y' : 'x'}.ticks`, {
    callback: tickCallback,
    color: ({ tick }) => (tick.value < 0 ? 'red' : 'black'),
  });

  _.set(_options, `plugins.tooltip.callbacks.label`, labelCallbacks[dataType]);

  if (dataLabels) {
    _.set(_options, `plugins.datalabels`, {
      color: 'white',
      font: {
        weight: 'bold',
      },
    });

    _.set(_options, `plugins.datalabels.formatter`, datalabelFormatters[dataType]);
  }

  if (base === 'rows') {
    if (rows.length === 1) {
      _.set(_options, 'plugins.legend.position', 'bottom');
      _.set(_options, 'scales.x.display', alwaysShowScale);
    }
    if (cols.length === 1) _.set(_options, 'plugins.legend.display', false);
  } else {
    if (cols.length === 1) {
      _.set(_options, 'plugins.legend.position', 'bottom');
      _.set(_options, 'scales.x.display', alwaysShowScale);
    }
    if (rows.length === 1) _.set(_options, 'plugins.legend.display', false);
  }

  const chart = { options: _.merge({}, _options, options) };

  if (base === 'rows') {
    const labels = rows.map(labelFormatter);
    chart.data = {
      labels: wraplabelX ? labels.map(splitBySpace) : labels,
      datasets: cols.map((col, colInd) => {
        const colIndex = getTightColIndexByKey(dataFrame, col);
        return {
          label: col,
          backgroundColor: cols.length === 1 ? colors : colors[colInd],
          data: rows.map((row, rowInd) => {
            const d = getTightRowByKey(dataFrame, row);
            return valueFormatter(d[colIndex], row, col, rowInd, colInd);
          }),
        };
      }),
    };
  } else {
    if (omitEmpty)
      cols = cols.filter((col) => {
        const colIndex = getTightColIndexByKey(dataFrame, col);
        return rows.some((row) => {
          const rowData = getTightRowByKey(dataFrame, row);
          return rowData[colIndex];
        });
      });

    const labels = cols.map(labelFormatter);
    chart.data = {
      labels: wraplabelX ? labels.map(splitBySpace) : labels,
      datasets: rows.map((row, rowInd) => {
        const rowData = getTightRowByKey(dataFrame, row);
        return {
          label: row,
          backgroundColor: rows.length === 1 ? colors : colors[rowInd],
          data: cols.map((col, colInd) => {
            const colIndex = getTightColIndexByKey(dataFrame, col);
            return valueFormatter(rowData[colIndex], row, col, rowInd, colInd);
          }),
        };
      }),
    };
  }

  const plugins = dataLabels ? [ChartDataLabels] : [];
  plugins.push(customCanvasBackgroundColor);

  return {
    type: 'bar',
    data: chart.data,
    plugins,
    options: chart.options,
    width,
    height,
  };
}

export default getBarChartProps;
