import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { Card, CardHeader, CardBody, Label, TabPane, Row, Col } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { getTightColIndexByKey } from 'helpers/chart';
import { toDeci, toDeciTable, toPercTable, tablePresets } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';

function MarketStress({ data, params, notify, session }) {
  const [factorOptions, setFactorOptions, selectedFactorOption, setSelectedFactorOption] = useArrayState();

  useEffect(() => {
    if (!data) return;

    const assetIndex = getTightColIndexByKey(data, params.product);
    const benchIndex = getTightColIndexByKey(data, params.benchmark);

    const facts = data.index.filter((v, i) => {
      const assetValue = data.data[i][assetIndex];
      const benchValue = data.data[i][benchIndex];

      return assetValue || benchValue;
    });

    setFactorOptions(optionizeAll(facts));
  }, [data]);

  if (!selectedFactorOption) return;

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Market Stress
          <div className="small">Total Returns</div>
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="market-stress"
            data={data}
            indexName="Event"
            cellFormatter={(value, row, col) => (isNaN(Number(value)) ? value : toPercTable(value))}
            compact
            title={['Market Stress', 'Total Returns']}
          />
        </CardBody>
      </Card>

      <Row>
        <Col xl="8" className="offset-xl-2">
          <div className="tw-text-left tw-font-bold">SELECT</div>
          <Select
            className="react-select mw-400px"
            name="factors"
            value={selectedFactorOption}
            onChange={setSelectedFactorOption}
            options={factorOptions}
          />
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              {selectedFactorOption.label}
            </CardHeader>
            <CardBody>
              <TightChart
                data={data}
                cols={[params.product, params.benchmark]}
                rows={[selectedFactorOption.value]}
                dataType="percentage"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default MarketStress;
