import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { Card, CardHeader, CardBody, Label, TabPane, Row, Col } from 'reactstrap';
import Select from 'react-select';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { useArrayState } from 'components/CustomHooks';
import { optionize, optionizeAll } from 'helpers/select';
import { getTightColIndexByKey } from 'helpers/chart';
import { toDeciTable, toPercTable, formatDate } from 'helpers/formatter';

function FactorLens({ data, params, notify, session }) {
  const [periodOptions, setPeriodOptions, selectedPeriodOption, setSelectedPeriodOption] = useArrayState();

  useEffect(() => {
    const _periodOptions = optionizeAll(data.asset_df.columns, { labelFormatter: _.upperFirst });
    setPeriodOptions(_periodOptions);
  }, [data]);

  const comparisonDf = useMemo(() => {
    if (!data || !selectedPeriodOption) return null;

    const { asset_df, bench_df } = data;
    const colIndex = getTightColIndexByKey(asset_df, selectedPeriodOption.value);

    return {
      index: asset_df.index,
      columns: bench_df ? [params.product, params.benchmark] : [params.product],
      data: asset_df.index.map((rowName, i) => {
        const item = [asset_df.data[i][colIndex]];
        if (bench_df) item.push(bench_df.data[i][colIndex]);
        return item;
      }),
    };
  }, [data, selectedPeriodOption]);

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Exposure: {params.single_factor_choice}
          <TimePeriod dates={data.date_range} prefix="Time Period:" />
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="single-factor"
            data={data.asset_df}
            rowHeader={{ text: params.product, style: { color: 'black', backgroundColor: '#C6E0B4' } }}
            indexName="Factor"
            cellFormatter={(value, row, col) =>
              ['Beta', 'Correlation', 't-stat'].includes(row)
                ? toDeciTable(value)
                : row === 'R2'
                ? toPercTable(value)
                : value
            }
            compact
            title={[`Exposure: ${params.single_factor_choice}`, formatDates(data.date_range)]}
          />
        </CardBody>
      </Card>

      <Row>
        <Col xl="8" className="offset-xl-2">
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Exposure: {params.single_factor_choice}
            </CardHeader>
            <CardBody>
              <TightChart
                title={`Exposure: ${params.single_factor_choice}`}
                data={data.asset_df}
                rows={['Beta', 'R2']}
                base="cols"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      {selectedPeriodOption && (
        <>
          <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
          <Select
            className="react-select mw-200px"
            name="selectedPeriodOption"
            value={selectedPeriodOption}
            onChange={setSelectedPeriodOption}
            options={periodOptions}
          />
        </>
      )}

      {comparisonDf && (
        <Row>
          <Col xl="6">
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                {params.single_factor_choice}
              </CardHeader>
              <CardBody>
                <TightTable
                  suffix="factor-analysis"
                  data={comparisonDf}
                  indexWidth="180px"
                  indexName=""
                  cellFormatter={(value, row, col) =>
                    ['Beta', 'Correlation', 't-stat'].includes(row)
                      ? toDeciTable(value)
                      : row === 'R2'
                      ? toPercTable(value)
                      : value
                  }
                  compact
                  title={params.single_factor_choice}
                />
              </CardBody>
            </Card>
          </Col>
          <Col xl="6">
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                {params.single_factor_choice}
              </CardHeader>
              <CardBody>
                <TightChart title={params.single_factor_choice} data={comparisonDf} rows={['Beta', 'R2']} base="cols" />
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}

export default FactorLens;
