import _ from 'lodash';

export const parseSearchParams = (searchstring = {}, keys = []) => {
  const query = new URLSearchParams(searchstring);
  const result = {};

  keys.forEach((v) => {
    if (_.isString(v)) {
      result[v] = query.get(v);
    } else {
      result[v.key] = v.multi ? query.getAll(v.key) : query.get(v.key);
    }
  });

  return result;
};
