import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { toDeci, toPerc } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';
import { riskMeasuresRatios, riskMeasuresPerc, riskMeasuresDeci2 } from 'helpers/meta';

export const metricOptions = [
  {
    label: 'CAGR and Std Dev',
    value: { keys: ['CAGR', 'Standard Deviation'], type: 'percentage' },
  },
  {
    label: 'Beta and Correlation',
    value: { keys: ['Beta', 'Correlation'], type: 'decimal' },
  },
  {
    label: 'CVaR and Max DD',
    value: { keys: ['CVaR 95%', 'Maximum Drawdown'], type: 'percentage' },
  },
  {
    label: 'Std Dev and Downside Dev',
    value: { keys: ['Standard Deviation', 'Downside Deviation'], type: 'percentage' },
  },
  {
    label: 'Skew and Kurtosis',
    value: { keys: ['Skew', 'Kurtosis'], type: 'decimal' },
  },
  {
    label: 'Sharpe and Sortino',
    value: { keys: ['Sharpe Ratio', 'Sortino Ratio'], type: 'decimal' },
  },
  {
    label: 'MAR and Omega',
    value: { keys: ['MAR Ratio (RoMaD)', 'Omega Ratio'], type: 'decimal' },
  },
  {
    label: 'Ratios (Sharpe, Sortino, Omega)',
    value: { keys: ['Sharpe Ratio', 'Sortino Ratio', 'Omega Ratio'], type: 'decimal' },
  },
];

function RiskMetrics({ data: { data, table_dates }, params, options = {}, notify, session }) {
  const [measureOptions, setMeasureOptions] = useState([]);
  const [selectedMeasureOption, setSelectedMeasureOption] = useState(null);
  const [assetOptions, setAssetOptions] = useState([]);
  const [selectedAssetOption, setSelectedAssetOption] = useState(null);
  const [selectedMetricOption, setSelectedMetricOption] = useState(metricOptions[0]);

  useEffect(() => {
    if (!data) return;

    const _measureOptions = optionizeAll(data.columns);
    setMeasureOptions(_measureOptions);

    if (_measureOptions.length > 0) {
      setSelectedMeasureOption(_measureOptions[0]);
    }

    const _assetOptions = optionizeAll(data.index.filter((v, i) => v !== params.benchmark));
    setAssetOptions(_assetOptions);

    if (_assetOptions.length > 0) {
      setSelectedAssetOption(_assetOptions[0]);
    }
  }, [data]);

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Risk Metrics
          <TimePeriod dates={table_dates} />
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="risk-performance-risk-metrics"
            data={data}
            indexName=""
            indexWidth="250px"
            useSort={true}
            rowDecorator={(rowTitle, rowInd, rowTitles) =>
              rowTitle === params.benchmark ? { style: { backgroundColor: 'rgb(251,198,88,0.5)' } } : undefined
            }
            cellFormatter={(value, row, col) => {
              return riskMeasuresDeci2.includes(col)
                ? toDeci(value, { decorate: true, absolute: true })
                : toPerc(value, { decorate: true, absolute: true });
            }}
            title={['Risk Metrics', formatDates(table_dates)]}
            {...options.riskMetricTable}
          />
        </CardBody>
      </Card>

      <div className="tw-grid tw-grid-cols-6 tw-gap-4">
        <div className="tw-col-start-2 tw-col-span-4">
          <div className="tw-text-left tw-font-bold">SELECT METRIC</div>
          <Select
            className="react-select mw-400px"
            value={selectedMeasureOption}
            onChange={setSelectedMeasureOption}
            options={measureOptions}
          />
          {selectedMeasureOption && (
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                {selectedMeasureOption.label}
              </CardHeader>
              <CardBody>
                <TightChart
                  title={selectedMeasureOption.label}
                  data={data}
                  cols={[selectedMeasureOption.value]}
                  flip={false}
                  wraplabelX={true}
                  dataType={riskMeasuresDeci2.includes(selectedMeasureOption.value) ? 'decimal' : 'percentage'}
                />
              </CardBody>
            </Card>
          )}
        </div>
      </div>

      <div className="tw-grid tw-grid-cols-6 tw-gap-4">
        <div className="tw-col-start-2 tw-col-span-4">
          <div className="tw-flex">
            <div className="tw-min-w-[400px] tw-mr-1">
              <div className="tw-text-left tw-font-bold">
                {params.tags && params.tags.includes('portfolio-compare') ? 'SELECT PORTFOLIO' : 'SELECT ASSET'}
              </div>
              <Select
                className="react-select mw-400px"
                value={selectedAssetOption}
                onChange={setSelectedAssetOption}
                options={assetOptions}
              />
            </div>
            <div className="tw-min-w-[400px]">
              <div className="tw-text-left tw-font-bold">SELECT RISK METRICS</div>
              <Select
                className="react-select tw-w-[400px] tw-inline-block"
                value={selectedMetricOption}
                onChange={setSelectedMetricOption}
                options={metricOptions}
              />
            </div>
          </div>

          {selectedAssetOption && selectedMetricOption && (
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                Risk Charts
              </CardHeader>
              <CardBody>
                <TightChart
                  title="Risk Charts"
                  data={data}
                  rows={[selectedAssetOption.value, params.benchmark]}
                  cols={selectedMetricOption.value.keys}
                  dataType={selectedMetricOption.value.type}
                />
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </>
  );
}

export default RiskMetrics;
