import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody, TabPane, Row, Col, Table } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { toDeci, toDeciTable, toPercTable, formatDate } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';

function FamaFrench({ data, params, notify, session }) {
  const [modelOptions, setModelOptions] = useState([]);
  <div className="tw-text-left tw-font-bold">SELECT RISK EXPOSURE</div>;
  const [selectedModeloption, setSelectedModeloption] = useState(null);

  useEffect(() => {
    if (!data) return;

    const _modelOptions = optionizeAll(_.keys(data));
    setModelOptions(_modelOptions);

    if (_modelOptions.length > 0) {
      setSelectedModeloption(_modelOptions[0]);
    }
  }, [data]);

  if (!selectedModeloption) return;

  const modelData = data[selectedModeloption.value];

  return (
    <>
      <div className="tw-grid tw-grid-cols-64">
        <div className="tw-col-span-6">
          <div className="tw-text-left tw-font-bold">SELECT FAMA FRENCH GEOGRAPHY</div>
          <Select
            className="react-select tw-w-[300px]"
            value={selectedModeloption}
            onChange={setSelectedModeloption}
            options={modelOptions}
          />
        </div>
      </div>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Fama French Analysis
          <div className="tw-text-lg">{params.product}</div>
          <div className="tw-text-lg">{selectedModeloption.label}</div>
          <TimePeriod dates={data[selectedModeloption.value]['table_dates']} />
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="risk-performance-fama-french"
            data={modelData['regression_results']}
            indexName=""
            indexWidth="250px"
            flip={true}
            hideIndexTable={true}
            cellFormatter={(value, row, col) =>
              ['Alpha'].includes(col)
                ? toPercTable(value)
                : ['R2'].includes(col)
                ? toPercTable(value, { precision: 0 })
                : toDeciTable(value)
            }
            title={[
              'Fama French Analysis',
              params.product,
              selectedModeloption.label,
              formatDates(data[selectedModeloption.value]['table_dates']),
            ]}
          />
        </CardBody>
      </Card>
      <div className="tw-grid tw-grid-cols-12 tw-gap-4">
        <div className="tw-col-span-5">
          <Card>
            <CardBody>
              <Table bordered striped>
                <tbody>
                  <tr>
                    <td></td>
                    <td className="tw-font-semibold">Significance</td>
                  </tr>
                  {modelData['significance results'].index.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{item}</td>
                        <td>{modelData['significance results'].data[i][0] ? 'Yes' : 'No'}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </div>
        <div className="tw-col-span-7">
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              {params.product}
            </CardHeader>
            <CardBody>
              <TightChart
                title={params.product}
                data={modelData['regression_results']}
                rows={['MKT', 'SMB', 'HML', 'MOM']}
                options={{
                  indexAxis: 'y',
                }}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default FamaFrench;
