import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import _ from 'lodash';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Table,
  Row,
  Col,
  Spinner,
  UncontrolledTooltip,
  TabPane,
  CardFooter,
} from 'reactstrap';
import PageTabs from 'components/PageTabs';
import { AutoColumns } from 'components/Columns';
import { FixedCenterLoader } from 'components/Loaders';
import TightChart from 'components/TightChart';
import LineChart from 'components/LineChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import ReactDatetime from 'react-datetime';
import { removeEmptyCols } from 'helpers/data-frame';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { listProducts, getDiscovery, toolboxCorrelation, getQuickViewData } from 'services/analysis';
import { optionizeValue, optionizeAll, getValue, getDate, getYearMonth, monthDiff } from 'helpers/select';
import { confidenceLevelToInterval, tablePresets, toDeciTable, formatCurrency } from 'helpers/formatter';
import TightTable from 'components/TightTable';
import { colors } from 'helpers/chart';
import { Descriptions, Notes } from '../others';

function LongTermReturns({ database, product, converter, setName }) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);

  const fetchData = async () => {
    setLoading(true);

    const [data, err] = await getQuickViewData({
      database,
      product,
      converter,
      page_choice: 'long_term_returns',
    });

    if (data) {
      data.long_term_returns = removeEmptyCols(data.long_term_returns, ['15Y', '20Y']);
      setResult(data);
      setName(data.formal_name);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!database || !product) return;

    fetchData();
  }, [database, product, converter]);

  if (loading) {
    return <FixedCenterLoader />;
  }

  if (!result) {
    return null;
  }

  console.log('LongTermReturns', result);

  return (
    <>
      <Descriptions content={result.descriptions} />
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          <div>{product}</div>
          <div className="tw-text-lg">Currency: {result.currency}</div>
          <TimePeriod dates={result.table_dates} prefix="Time Period: " />
        </CardHeader>
        <CardBody>
          <TightTable
            data={result.long_term_returns}
            indexWidth="200px"
            suffix="quickview-long-term-returns"
            dataType="percentage"
            hideIndexTable
          />
        </CardBody>
      </Card>

      <AutoColumns>
        <Card>
          <CardHeader tag="h4" className="mt-0 text-center">
            {product}
          </CardHeader>
          <CardBody>
            <TightChart
              title={product}
              data={result.long_term_returns}
              base="cols"
              dataType="percentage"
              cols={(cols) =>
                cols.filter((col, ind) => {
                  if (['As of Date'].includes(col)) {
                    return false;
                  }

                  if (['15Y', '20Y'].includes(col)) {
                    const ind = result.long_term_returns.columns.findIndex((_col) => _col === col);
                    return !!result.long_term_returns.data[0][ind];
                  }

                  return true;
                })
              }
            />
          </CardBody>
        </Card>
      </AutoColumns>
      <Notes content={result.notes} />
    </>
  );
}

export default LongTermReturns;
