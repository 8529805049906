import store from 'store2';
import _ from 'lodash';
import {
  getInvestmentComparison,
  getInvestmentComparisonHeader,
  getInvestmentComparisonWithCustomData,
} from 'services/analysis';

const CUSTOM_DATA = 'custom-data';

export async function fetchData(params) {
  if (!params) return;

  params.product = _.castArray(params.product);
  params.database = _.castArray(params.database);

  if (_.isBoolean(params.custom) ? params.custom : params.custom === 'true') {
    const customData = store.get(CUSTOM_DATA);

    const [res, err] = await getInvestmentComparisonWithCustomData({ ...customData, ...params });

    if (err) {
      return { success: false, error: err };
    } else {
      return { success: true, info: res[0], riskPerf: res[1], detail: res[2] };
    }
  } else {
    const [infoRes, dataRes] = await Promise.all([
      getInvestmentComparisonHeader(params),
      getInvestmentComparison(params),
    ]);
    const [info, infoErr] = infoRes;
    const [data, dataErr] = dataRes;

    if (infoErr || dataErr) {
      return { success: false, error: infoErr || dataErr };
    } else {
      return { success: true, info, riskPerf: data[1], detail: data[0] };
    }
  }
}
