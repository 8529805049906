import React, { useState } from 'react';
import { Modal, Button, Label, FormGroup, Input } from 'reactstrap';

function PortfolioDeleteConfirmModal({ isOpen, onClose }) {
  const handleSubmit = async () => {
    await onClose('confirmed');
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} size="md" className="tw-mt-2 tw-pt-0">
      <div className="modal-header">
        <h5 className="modal-title">Save Portfolio</h5>
      </div>
      <div className="modal-body">
        <span>Are you sure to delete this portfolio?</span>
      </div>
      <div className="modal-footer tw-justify-center">
        <Button className="btn-round" color="info" outline={true} data-dismiss="modal" type="button" onClick={onClose}>
          Cancel
        </Button>

        <Button className="btn-round" color="info" data-dismiss="modal" type="button" onClick={handleSubmit}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
}

export default PortfolioDeleteConfirmModal;
