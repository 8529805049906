import React, { useRef, useLayoutEffect, createRef } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';

import PieChart from 'components/PieChart';

function TopPieCharts({ params }) {
  const pieCardHeadRefs = useRef([1, 2, 3].map(() => createRef()));

  const syncHeaderHeights = () => {
    if (pieCardHeadRefs.current.every((v) => !v)) return;
    const maxHeight = _.max(_.map(pieCardHeadRefs.current, (ref) => (ref.current && ref.current.clientHeight) || 0));
    if (maxHeight === 0) return;

    for (let x = 0; x < pieCardHeadRefs.current.length; x++) {
      if (pieCardHeadRefs.current[x].current) pieCardHeadRefs.current[x].current.style.height = `${maxHeight}px`;
    }
  };

  useLayoutEffect(() => {
    syncHeaderHeights();
  }, [pieCardHeadRefs.current]);

  if (!params) return null;

  let chartStart = 1;
  let chartCount = 0;
  if (params.portFunds3.length > 0) {
    chartStart = 3;
    chartCount += 1;
  }
  if (params.portFunds2.length > 0) {
    chartStart = 2;
    chartCount += 1;
  }
  if (params.portFunds1.length > 0) {
    chartStart = 1;
    chartCount += 1;
  }

  return (
    <div className="tw-grid tw-grid-cols-1 tw-content-start tw-items-stretch tw-gap-6 md:tw-grid-cols-6">
      {params.portFunds1.length > 0 && (
        <div
          className={classnames('md:tw-col-span-2', {
            'md:tw-col-start-2': chartStart === 1 && chartCount === 2,
            'md:tw-col-start-3': chartStart === 1 && chartCount === 1,
          })}
        >
          <Card>
            <CardHeader tag="h4" className="tw-mt-0 tw-text-center tw-text-lg">
              <div ref={pieCardHeadRefs.current[0]}>{params.port1Name}</div>
              <div className="tw-text-sm">Holdings</div>
            </CardHeader>
            <CardBody>
              <PieChart
                title={params.port1Name}
                labels={params.portFunds1}
                values={params.portValues1}
                containerStyle={{ maxWidth: '400px', maxHeight: '400px' }}
                options={{
                  plugins: {
                    legend: { display: false },
                  },
                }}
              />
            </CardBody>
          </Card>
        </div>
      )}
      {params.portFunds2.length > 0 && (
        <div
          className={classnames('md:tw-col-span-2', {
            'md:tw-col-start-2': chartStart === 2 && chartCount === 2,
            'md:tw-col-start-3': chartStart === 2 && chartCount === 1,
          })}
        >
          <Card>
            <CardHeader tag="h4" className="tw-mt-0 tw-text-center tw-text-lg">
              <div ref={pieCardHeadRefs.current[1]}>{params.port2Name}</div>
              <div className="tw-text-sm">Holdings</div>
            </CardHeader>
            <CardBody>
              <PieChart
                title={params.port2Name}
                labels={params.portFunds2}
                values={params.portValues2}
                containerStyle={{ maxWidth: '400px', maxHeight: '400px' }}
                options={{
                  plugins: {
                    legend: { display: false },
                  },
                }}
              />
            </CardBody>
          </Card>
        </div>
      )}
      {params.portFunds3.length > 0 && (
        <div
          className={classnames('md:tw-col-span-2', {
            'md:tw-col-start-2': chartStart === 3 && chartCount === 2,
            'md:tw-col-start-3': chartStart === 3 && chartCount === 1,
          })}
        >
          <Card>
            <CardHeader tag="h4" className="tw-mt-0 tw-text-center tw-text-lg">
              <div ref={pieCardHeadRefs.current[2]}>{params.port3Name}</div>
              <div className="tw-text-sm">Holdings</div>
            </CardHeader>
            <CardBody>
              <PieChart
                title={params.port3Name}
                labels={params.portFunds3}
                values={params.portValues3}
                containerStyle={{ maxWidth: '400px', maxHeight: '400px' }}
                options={{
                  plugins: {
                    legend: { display: false },
                  },
                }}
              />
            </CardBody>
          </Card>
        </div>
      )}
    </div>
  );
}

export default TopPieCharts;
