import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';
import { toDeci, toDeciTable, toPercTable, tablePresets, dataTypePresets, getDataType } from 'helpers/formatter';

const ratios = ['Beta', 'Alpha', 'R2', 'Correlation', "Jensen's Alpha", 'Treynor Ratio'];

function MarketRisk({ data: { data, table_dates }, params, options = {}, notify, session }) {
  const [ratioOptions, setRatioOptions] = useState(optionizeAll(ratios));
  const [selectedRatioOption, setSelectedRatioOption] = useState(optionizeValue(ratios[0]));

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Market Risk
          <TimePeriod dates={table_dates} />
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="risk-performance-market-risk"
            data={data}
            indexName=""
            indexWidth="250px"
            cellFormatter={(value, row, col) => {
              const formatter = tablePresets[row];
              if (formatter) return formatter(value);

              return toPercTable(value);
            }}
            title={['Market Risk', formatDates(table_dates)]}
            {...options.marketRiskTable}
          />
        </CardBody>
      </Card>

      <div className="tw-grid tw-grid-cols-64">
        <div className="tw-col-span-6">
          <div className="tw-text-left tw-font-bold">SELECT METRIC</div>
          <Select
            className="react-select mw-400px"
            value={selectedRatioOption}
            onChange={setSelectedRatioOption}
            options={ratioOptions}
          />
        </div>
      </div>

      <div className="tw-grid tw-grid-cols-6 tw-gap-4">
        <div className="tw-col-span-3">
          {selectedRatioOption && (
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                {selectedRatioOption.label}
              </CardHeader>
              <CardBody>
                <TightChart
                  title={selectedRatioOption.label}
                  data={data}
                  cols={[selectedRatioOption.value]}
                  flip={true}
                  wraplabelX={true}
                  dataType={getDataType(selectedRatioOption.label)}
                />
              </CardBody>
            </Card>
          )}
        </div>
        <div className="tw-col-span-3">
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Historical Risk
            </CardHeader>
            <CardBody>
              <TightChart
                title="Historical Risk"
                data={data}
                cols={['Unsystematic Risk', 'Systematic Risk']}
                flip={true}
                dataType="percentage"
                options={{
                  indexAxis: 'y',
                  scales: {
                    x: {
                      stacked: true,
                    },
                    y: {
                      stacked: true,
                    },
                  },
                }}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default MarketRisk;
