import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import _ from 'lodash';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import Select from 'react-select';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import AssetInfo from './AssetInfo';
import { optionize, optionizeAll } from 'helpers/select';
import { parseSearchParams } from 'helpers/location';
import { getAdvancedAnalyticsReturns } from 'services/analysis';

const SpinnerWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const reverseRows = (df) => {
  const index = df.index.slice().reverse();
  const data = df.data.slice().reverse();

  return {
    ...df,
    index,
    data,
  };
};

const abbrMonth = (month) => (month.startsWith('Full') ? 'Full' : month.substring(0, 3));

function SingleAssetCalendar({ params, notify, session }) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [durations, setDurations] = useState();
  const [selectedDuration, setSelectedDuration] = useState();

  const fetchResult = async () => {
    if (!params) return;

    setLoading(true);
    const [data, err] = await getAdvancedAnalyticsReturns(params);
    if (err) {
      notify.danger({ message: <div>{err}</div> });
    } else {
      data.cy_table.columns = data.cy_table.columns.map(abbrMonth);
      setResult(data);
      const _durations = data.cy_table.index;
      setDurations(optionizeAll(_durations, { useIndex: true }));
      if (_durations.length > 0) setSelectedDuration(optionize(0, _durations[0]));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (params) fetchResult();
  }, [params]);

  if (loading || !result) {
    return (
      <SpinnerWrapper>
        <Spinner>Loading...</Spinner>
      </SpinnerWrapper>
    );
  }

  return (
    <>
      <AssetInfo
        asset={params.product}
        database={params.database}
        data={result}
        valueMap={{
          name: 'info_dict.fund_name',
          description: 'info_dict.fund_desc',
          benchmark: 'info_dict.benchmark',
          geography: 'info_dict.geography',
          startDate: 'info_dict.time_period.0',
          endDate: 'info_dict.time_period.1',
          cumulative: 'info_dict.stats_df..Cumulative Return',
          cagr: 'info_dict.stats_df..CAGR',
          deviation: 'info_dict.stats_df..Standard Deviation',
        }}
      />

      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Monthly Returns
          <div className="tw-text-lg">{params.product}</div>
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="monthly-returns"
            data={result.cy_table}
            indexName="Year"
            dataType="percentage"
            indexWidth="100px"
            compact
            useSort
            title={['Monthly Returns', params.product]}
          />
        </CardBody>
      </Card>
      {durations && durations.length > 0 && (
        <>
          <div className="tw-grid tw-grid-cols-6 tw-gap-4">
            <div className="tw-col-span-6 xl:tw-col-span-4 xl:tw-col-start-2">
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  Calendar Year Returns
                  <div className="tw-text-lg">{params.product}</div>
                </CardHeader>
                <CardBody>
                  <TightChart
                    title="Calendar Year Returns"
                    data={reverseRows(result.cy_table)}
                    base="rows"
                    cols={['Full']}
                    dataType="percentage"
                  />
                </CardBody>
              </Card>
            </div>
          </div>

          <div className="tw-grid tw-grid-cols-6 tw-gap-4">
            <div className="tw-col-span-6 xl:tw-col-span-4 xl:tw-col-start-2">
              <div className="tw-text-left tw-font-bold">SELECT YEAR</div>
              <Select
                className="react-select mw-200px"
                name="duration"
                value={selectedDuration}
                onChange={setSelectedDuration}
                options={durations}
              />
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  Monthly Returns
                  <div className="tw-text-lg">{selectedDuration.label}</div>
                  <div className="tw-text-lg">{params.product}</div>
                </CardHeader>
                <CardBody>
                  <TightChart
                    title="Monthly Returns"
                    data={result.cy_table}
                    base="cols"
                    rows={[result.cy_table.index[selectedDuration.value]]}
                    dataType="percentage"
                  />
                </CardBody>
              </Card>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default SingleAssetCalendar;
