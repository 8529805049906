import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Select from 'react-select';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Table,
  Row,
  Col,
  Spinner,
  UncontrolledTooltip,
  TabPane,
  CardFooter,
} from 'reactstrap';
import { useArrayState } from 'components/CustomHooks';
import { FixedCenterLoader } from 'components/Loaders';
import {
  listProducts,
  getDiscovery,
  getQuickViewDiscovery,
  getAdvancedAnalyticsFactorExposuresOptions,
} from 'services/analysis';
import { optionizeValue, optionizeAll, getValue, getDate, getYearMonth, monthDiff } from 'helpers/select';
import { SAVED_PORTFOLIOS } from './constants';

const defaultCurrencyOptions = [
  { value: 'Default', label: 'No' },
  { value: 'CAD', label: 'CAD (Convert USD)' },
  { value: 'USD', label: 'USD (Convert CAD)' },
];

const defaultPeriodOptions = [
  { value: '12', label: '12 months' },
  { value: '18', label: '18 months' },
  { value: '24', label: '24 months' },
  { value: '30', label: '30 months' },
  { value: '36', label: '36 months' },
];

const emptyOption = { label: 'No Benchmark', value: '' };

function AssetSelect({
  onSelect,
  enableBenchmark = false,
  enableFactorGroups = false,
  enableFactor = false,
  enablePeriod = false,
  portfolioOnly = false,
}) {
  const [dataLoading, setDataLoading] = useState(false);
  const [asset, setAsset] = useState({});
  const [factorGroupOptions, setFactorGroupOptions, selectedFactorGroupOption, setSelectedFactorGroupOption] =
    useArrayState({ initialItems: [] });
  const [benchOptions, setBenchOptions, selectedBenchOption, setSelectedBenchOption] = useArrayState({
    initialItems: [emptyOption],
  });
  const [factorOptions, setFactorOptions, selectedFactorOption, setSelectedFactorOption] = useArrayState({
    initialItems: [],
  });
  const [allAssetOptions, setAllAssetOptions] = useState(null);
  const [databaseOptions, setDatabaseOptions, selectedDatabaseOption, setSelectedDatabaseOption] = useArrayState({
    initialItems: [],
  });
  const [assetOptions, setAssetOptions, selectedAssetOption, setSelectedAssetOption] = useArrayState({
    initialItems: [],
  });
  const [currencyOptions, setCurrencyOptions, selectedCurrencyOption, setSelectedCurrencyOption] = useArrayState({
    initialItems: defaultCurrencyOptions,
  });
  const [periodOptions, setPeriodOptions, selectedPeriodOption, setSelectedPeriodOption] = useArrayState({
    initialItems: defaultPeriodOptions,
  });

  const fetchData = async () => {
    setDataLoading(true);

    const [assetRes, benchRes, factorRes] = await Promise.all([
      getQuickViewDiscovery(),
      getDiscovery(),
      getAdvancedAnalyticsFactorExposuresOptions(),
    ]);

    const [asset] = assetRes;
    if (!asset) {
      setAsset({});
    } else {
      setAsset(asset);
      if (portfolioOnly) {
        setDatabaseOptions(optionizeAll([SAVED_PORTFOLIOS]));
      } else {
        setDatabaseOptions(optionizeAll(_.keys(asset)));
      }

      const _allAssetOptions = {};
      _.each(asset, (val, key) => {
        if (val) _allAssetOptions[key] = optionizeAll(val);
      });

      setAllAssetOptions(_allAssetOptions);
    }

    const [bench] = benchRes;
    if (bench) {
      const benchmarks = bench.benchmark_choice.map(optionizeValue);
      const factorGroups = bench.risk_budget_choice.map(optionizeValue);
      setBenchOptions([emptyOption, ...benchmarks]);
      setSelectedBenchOption(emptyOption);
      setFactorGroupOptions(factorGroups);
      setSelectedFactorGroupOption(factorGroups[0]);
    } else {
      setBenchOptions([emptyOption]);
      setSelectedBenchOption(emptyOption);
      setFactorGroupOptions([]);
      setSelectedFactorGroupOption(null);
    }

    const [factor] = factorRes;
    if (factor) {
      const factorOptions = factor.factors.map(optionizeValue);
      setFactorOptions(factorOptions);
      setSelectedFactorOption(factorOptions[0]);
    } else {
      setFactorOptions([]);
      setSelectedFactorOption(null);
    }

    setDataLoading(false);
  };

  useEffect(() => {
    if (!asset || !selectedDatabaseOption || !allAssetOptions) return;

    const _assetOptions = allAssetOptions[selectedDatabaseOption.value];
    setAssetOptions(_assetOptions);
    if (_assetOptions.length > 0) setSelectedAssetOption(_assetOptions[0]);
  }, [asset, selectedDatabaseOption]);

  useEffect(() => {
    fetchData();
  }, []);

  if (dataLoading || !selectedDatabaseOption || !selectedAssetOption) {
    return <FixedCenterLoader />;
  }

  return (
    <Card className="tw-max-w-3xl tw-mx-auto">
      <CardBody>
        <div className="tw-text-left tw-font-bold">SELECT</div>
        <Select
          className="react-select tw-mb-2"
          name="selectedDatabaseOption"
          value={selectedDatabaseOption}
          onChange={setSelectedDatabaseOption}
          options={databaseOptions}
        />

        {asset[selectedDatabaseOption.value].includes(selectedAssetOption.value) && (
          <>
            <div className="tw-text-left tw-font-bold">SELECT</div>
            <Select
              className="react-select tw-mb-2"
              name="selectedAssetOption"
              value={selectedAssetOption}
              onChange={setSelectedAssetOption}
              options={assetOptions}
            />
          </>
        )}
        {selectedDatabaseOption?.value === SAVED_PORTFOLIOS && (
          <>
            <div className="tw-text-left tw-font-bold">Convert Currency</div>
            <Select
              className="react-select tw-mb-2"
              name="selectedCurrencyOption"
              value={selectedCurrencyOption}
              onChange={setSelectedCurrencyOption}
              options={currencyOptions}
            />
          </>
        )}

        {enableFactorGroups && (
          <>
            <div className="tw-text-left tw-font-bold">SELECT Risk Model</div>
            {selectedFactorGroupOption && (
              <Select
                className="react-select tw-mb-2"
                name="benchmark"
                value={selectedFactorGroupOption}
                onChange={setSelectedFactorGroupOption}
                options={factorGroupOptions}
              />
            )}
          </>
        )}

        {enableFactor && (
          <>
            <div className="tw-text-left tw-font-bold">Factor Exposure</div>
            {selectedFactorOption && (
              <Select
                className="react-select tw-mb-2"
                name="selectedFactorOption"
                value={selectedFactorOption}
                onChange={setSelectedFactorOption}
                options={factorOptions}
              />
            )}
          </>
        )}

        {enableBenchmark && (
          <>
            <div className="tw-text-left tw-font-bold">SELECT Benchmark</div>
            {selectedBenchOption && (
              <Select
                className="react-select tw-mb-2"
                name="selectedBenchOption"
                value={selectedBenchOption}
                onChange={setSelectedBenchOption}
                options={benchOptions}
              />
            )}
          </>
        )}

        {enablePeriod && (
          <>
            <div className="tw-text-left tw-font-bold">Rolling Period</div>
            {selectedPeriodOption && (
              <Select
                className="react-select tw-mb-2"
                name="selectedPeriodOption"
                value={selectedPeriodOption}
                onChange={setSelectedPeriodOption}
                options={periodOptions}
              />
            )}
          </>
        )}

        <Button
          className="btn-round"
          color="info"
          onClick={() => {
            onSelect({
              database: selectedDatabaseOption.value,
              product: selectedAssetOption.value,
              converter:
                selectedDatabaseOption.value === SAVED_PORTFOLIOS
                  ? selectedCurrencyOption.value
                  : defaultCurrencyOptions[0].value,
              benchmark: enableBenchmark ? selectedBenchOption.value : '',
              factorGroup: enableFactorGroups ? selectedFactorGroupOption.value : '',
              factor: enableFactor ? selectedFactorOption.value : '',
              period: enablePeriod ? selectedPeriodOption.value : '',
            });
          }}
        >
          Analyze
        </Button>
      </CardBody>
    </Card>
  );
}

export default AssetSelect;
