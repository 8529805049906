import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';
import { removeEmptyCols } from 'helpers/data-frame';

function ValueAtRisk({ data: { data, table_dates }, notify, params, session }) {
  const [filteredDF, setFilteredDF] = useState(null);
  const [periodOptions, setPeriodOptions, selectedPeriodOption, setSelectedPeriodOption] = useArrayState();

  useEffect(() => {
    if (!data) return;

    const _filteredDF = removeEmptyCols(data, ['15Y', '20Y']);

    const _filteredDF2 = removeEmptyCols(data);
    const _periods = _filteredDF2.columns;
    const _periodOptions = optionizeAll(_periods, { labelFormatter: _.upperFirst });
    setPeriodOptions(_periodOptions);

    setFilteredDF(_filteredDF);
  }, [data]);

  if (!filteredDF) return null;

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Value at Risk
          <div className="tw-text-lg">{params.product}</div>
          <div className="tw-text-sm">(95% Confidence)</div>
          <TimePeriod dates={table_dates} />
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="risk-performance-var"
            data={filteredDF}
            indexName=""
            indexWidth="250px"
            dataType="percentage"
            title={['Value at Risk', params.product, '(95% Confidence)', formatDates(table_dates)]}
          />
        </CardBody>
      </Card>

      <div className="tw-grid tw-grid-cols-6 tw-gap-4">
        <div className="tw-col-start-2 tw-col-span-4">
          <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
          <Select
            className="react-select tw-w-[200px]"
            value={selectedPeriodOption}
            onChange={setSelectedPeriodOption}
            options={periodOptions}
          />
          {selectedPeriodOption && (
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                <div>Value at Risk</div>
                <div className="tw-text-lg">{params.product}</div>
                <div className="tw-text-lg">Time Period - {selectedPeriodOption.label}</div>
              </CardHeader>
              <CardBody>
                <TightChart
                  title="Value at Risk"
                  data={filteredDF}
                  cols={[selectedPeriodOption.value]}
                  flip={false}
                  wraplabelX={true}
                  dataType="percentage"
                />
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </>
  );
}

export default ValueAtRisk;
