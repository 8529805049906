import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody, TabPane, Row, Col, Table } from 'reactstrap';
import PieChart from 'components/PieChart';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';
import { toDeci, toPerc, toPercTable, toDeciTable, tablePresets, getDataType, formatCurrency } from 'helpers/formatter';

const includeBenchmarkOptions = [
  { label: 'Include Benchmark', value: true },
  { label: 'Exclude Benchmark', value: false },
];

function RiskBudget({ data, params, notify, session }) {
  const [includeBenchmarkOption, setIncludeBenchmarkOption] = useState(includeBenchmarkOptions[0]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [periodOptions, setPeriodOptions, selectedPeriodOption, setSelectedPeriodOption] = useArrayState();

  useEffect(() => {
    const _periods = _.keys(data);
    const _periodOptions = optionizeAll(_periods, { labelFormatter: _.upperFirst });
    setPeriodOptions(_periodOptions);
  }, [data]);

  if (!data || !selectedPeriodOption) return null;
  const periodData = data[selectedPeriodOption.value];

  return (
    <>
      <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
      <Select
        className="react-select tw-w-[200px]"
        value={selectedPeriodOption}
        onChange={setSelectedPeriodOption}
        options={periodOptions}
      />

      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Risk Budget
          <TimePeriod dates={periodData.table_dates} prefix="Time Period:" />
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="risk-lab-risk-budget"
            data={periodData.data}
            indexName=""
            indexWidth="250px"
            dataType="percentage"
            useSort={true}
            rowDecorator={(rowTitle, rowInd, rowTitles) =>
              rowTitle === params.benchmark ? { style: { backgroundColor: 'rgb(251,198,88,0.5)' } } : undefined
            }
            title={['Risk Budget', formatDates(periodData.table_dates)]}
          />
        </CardBody>
      </Card>

      <AutoColumns>
        <>
          {params.benchmark && (
            <div className="tw-text-left">
              <Select
                className="react-select tw-w-[200px] tw-inline-block"
                value={includeBenchmarkOption}
                onChange={setIncludeBenchmarkOption}
                options={includeBenchmarkOptions}
              />
            </div>
          )}

          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Risk Contribution
              <TimePeriod dates={periodData.table_dates} prefix="Time Period:" />
            </CardHeader>
            <CardBody key={includeBenchmarkOption.label}>
              <TightChart
                title="Risk Contribution"
                data={periodData.data}
                options={{ indexAxis: 'y' }}
                base="cols"
                cols={(cols) => cols.filter((cols) => !['R2'].includes(cols))}
                rows={(rows) => (includeBenchmarkOption.value ? rows : rows.filter((row) => row !== params.benchmark))}
                dataType="percentage"
              />
            </CardBody>
          </Card>
        </>
        <>
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              {params.product}
              <TimePeriod dates={periodData.table_dates} prefix="Time Period:" />
            </CardHeader>
            <CardBody>
              <PieChart
                title={params.product}
                data={periodData.data}
                base="cols"
                field={params.product}
                dataType="percentage"
                containerStyle={{ maxWidth: '500px', maxHeight: '500px' }}
                filter={(labels) => labels.filter((label) => !['Total Risk', 'R2'].includes(label))}
              />
            </CardBody>
          </Card>
        </>
      </AutoColumns>
    </>
  );
}

export default RiskBudget;
