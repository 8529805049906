import React, { memo, useMemo } from 'react';
import _ from 'lodash';
import { Chart } from 'react-chartjs-2';
import getPieChartProps from './_pie-chart';
import ChartDownload from './ChartDownload';

const defaultContainerStyle = {
  width: '100%',
  height: '100%',
  margin: 'auto',
};

function PieChart(props) {
  const { containerStyle, title } = props;
  const chart = getPieChartProps(props);

  return (
    <>
      <ChartDownload chartFn={() => getPieChartProps(props)} filename="download.png" title={title} />
      <div style={_.merge({}, defaultContainerStyle, containerStyle)}>
        <Chart {...chart} />
      </div>
    </>
  );
}

export default memo(PieChart);
