import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { Card, CardHeader, CardBody, CardFooter, CardTitle, Row, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import ReactTable from 'components/ReactTable/ReactTable';
import { formatDate } from 'helpers/formatter';
import { getAllResources, updateUserGroups } from 'services/resource';

const processData = (data) => {
  return data.map((row, i) => {
    const attributes = _.get(row, 'Attributes');
    const entry = {
      username: _.get(row, 'Username'),
      name: attributes.find((v) => v.Name === 'name').Value,
      email: attributes.find((v) => v.Name === 'email').Value,
      emailVerified: attributes.find((v) => v.Name === 'email_verified').Value,
      groups: _.get(row, 'Groups', []),
      createdAt: formatDate(_.get(row, 'UserCreateDate')),
      _createdAt: _.get(row, 'UserCreateDate'),
    };
    return entry;
  });
};

const getSelectStyles = {
  menu: (baseStyles) => {
    return {
      ...baseStyles,
      position: 'static',
    };
  },
};

const DEFAULT_ROLE = { label: 'None', value: 'no-role' };
const DEFAULT_AFFILIATION = { label: 'None', value: 'no-affiliation' };

function Users({ notify, state }) {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [affiliations, setAffiliations] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const [data, err] = await getAllResources(state);
    if (!data) {
      setUsers([]);
      setRoles([]);
      setAffiliations([]);
    } else {
      setUsers(processData(data.users));
      setRoles(data.roles);
      setAffiliations(data.affiliations);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [state]);

  const updateUser = async (username, roleKey, affiliationKey) => {
    await updateUserGroups(username, roleKey, affiliationKey);

    const type = roleKey ? 'Role' : 'Affiliation';
    notify.success({ message: <div>{`${type} updated successfully`}</div> });
    // setUsers(
    //   users.map((user) => {
    //     if (user.username === username) return { ...user, groups: [roleKey, affiliationKey] };
    //     return user;
    //   }),
    // );
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4" className="tw-mb-0">
          Users
        </CardTitle>
      </CardHeader>
      <CardBody>
        {loading ? (
          <div className="tw-text-center mb-5">
            <Spinner>Loading...</Spinner>
          </div>
        ) : (
          <ReactTable
            data={users}
            initialPage={page}
            navigatePage={setPage}
            columns={[
              { Header: 'Name', accessor: 'name', disableSortBy: false, disableFilters: false },
              {
                Header: 'Email',
                accessor: 'email',
                disableSortBy: false,
                disableFilters: false,
                Cell: ({ value: initialValue, row, column }) => {
                  const { emailVerified } = row.original;
                  return (
                    <>
                      <span>{initialValue}</span>
                      {emailVerified === 'true' && (
                        <FontAwesomeIcon className="tw-ml-1" icon={faCheckCircle} color="green" />
                      )}
                    </>
                  );
                },
              },
              {
                Header: 'Created At',
                accessor: 'createdAt',
                disableSortBy: false,
                disableFilters: false,
                sortType: (rowA, rowB, columnId) => {
                  return rowA.original._createdAt.localeCompare(rowB.original._createdAt);
                },
              },
              {
                Header: 'Affiliation',
                disableSortBy: true,
                disableFilters: true,
                Cell: createRoleAffiliationCell({ updateUser, roles, affiliations, type: 'affiliation' }),
              },
              {
                Header: 'Role',
                disableSortBy: true,
                disableFilters: true,
                Cell: createRoleAffiliationCell({ updateUser, roles, affiliations, type: 'role' }),
              },
            ]}
            // onRowSelect={handleRowSelect}
            // You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
            className="-striped -highlight primary-pagination"
          />
        )}
      </CardBody>
    </Card>
  );
}

export default Users;

function createRoleAffiliationCell({ updateUser, roles, affiliations, type, notify }) {
  return function RoleAffiliationCell({ value: initialValue, row, column }) {
    const [loading, setLoading] = useState();
    const [role, setRole] = useState();
    const [affiliation, setAffiliation] = useState();
    const { username, groups } = row.original;

    useEffect(() => {
      const _role = roles.find((v) => groups.includes(v.value)) || DEFAULT_ROLE;
      const _affiliation = affiliations.find((v) => groups.includes(v.value)) || DEFAULT_AFFILIATION;
      setRole(_role);
      setAffiliation(_affiliation);
    }, row.original);

    const handleChange = async (value) => {
      setLoading(true);

      if (type === 'role') {
        await updateUser(username, value.value, '');
        setRole(value);
      } else {
        await updateUser(username, '', value.value);
        setAffiliation(value);
      }

      setLoading(false);
    };

    if (!role || !affiliation) return null;

    if (loading)
      return (
        <div className="tw-text-center">
          <Spinner animation="border" variant="primary" size="sm" />
        </div>
      );

    const options = type === 'role' ? roles : affiliations;
    const value = type === 'role' ? role : affiliation;

    return (
      <Select
        className="react-select"
        value={value}
        onChange={handleChange}
        options={options}
        styles={getSelectStyles}
        position
      />
    );
  };
}
