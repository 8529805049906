import _ from 'lodash';

export const removeEmptyCols = (df, tcols = []) => {
  const tmp = df.data.reduce((r, a) => (a.forEach((v, i) => (r[i] = r[i] || v)), r), []);

  let columns = [];
  let data = [];

  if (tcols.length === 0) {
    columns = df.columns.filter((_, i) => tmp[i]);
    data = df.data.map((a) => a.filter((_, i) => tmp[i]));
  } else {
    const inds = tcols.map((tcol) => df.columns.findIndex((col) => col === tcol));
    columns = df.columns.filter((_, i) => !inds.includes(i) || tmp[i]);
    data = df.data.map((a) => a.filter((_, i) => !inds.includes(i) || tmp[i]));
  }

  const index = df.index.concat();

  return { index, columns, data };
};

export const removeEmptyRows = (df, trows = []) => {
  let index = [];
  let data = [];

  if (trows.length === 0) {
    _.forEach(df.data, (row, i) => {
      if (row.some((r) => !!r)) {
        data.push(row);
        index.push(df.index[i]);
      }
    });
  } else {
    _.forEach(df.data, (row, i) => {
      if (!trows.includes(df.index[i]) || row.some((r) => !!r)) {
        data.push(row);
        index.push(df.index[i]);
      }
    });
  }

  const columns = df.columns.concat();

  return { index, columns, data };
};

export const flipDF = (df) => {
  if (!df) return null;

  const matrix = df.data.concat();
  const rows = matrix.length,
    cols = matrix[0].length;
  const data = [];
  for (let j = 0; j < cols; j++) {
    data[j] = Array(rows);
  }
  for (let i = 0; i < rows; i++) {
    for (let j = 0; j < cols; j++) {
      data[j][i] = matrix[i][j];
    }
  }

  const index = df.columns.concat();
  const columns = df.index.concat();
  return { index, columns, data };
};

export const filterDF = (df, rows, cols) => {
  if (!df) return null;
  if (!rows && !cols) return { index: df.index.concat(), columns: df.columns.concat(), data: df.data.concat() };

  if (!rows) rows = df.index;
  if (!cols) cols = df.columns;
  if (_.isFunction(rows)) rows = rows(df.index);
  if (_.isFunction(cols)) cols = cols(df.columns);

  const index = [];
  const columns = [];
  const data = [];

  _.each(rows, (row, i) => {
    const ind = df.index.indexOf(row);
    if (ind > -1) {
      index.push(row);
      data.push([]);
    }
  });

  _.each(cols, (col, i) => {
    const colInd = df.columns.indexOf(col);
    if (colInd > -1) {
      columns.push(col);
      _.each(data, (d, j) => {
        const rowInd = df.index.indexOf(index[j]);
        data[j].push(df.data[rowInd][colInd]);
      });
    }
  });

  return { index, columns, data };
};

export const sortDF = (df, rows, cols) => {
  if (!df) return null;

  if (!rows) rows = df.index;
  if (!cols) cols = df.columns;
  if (_.isFunction(rows)) rows = rows(df.index);
  if (_.isFunction(cols)) cols = cols(df.columns);

  const index = [];
  const columns = [];
  const data = [];

  _.each(rows, (row, i) => {
    const ind = df.index.indexOf(row);
    if (ind > -1) {
      index.push(row);
      data.push([]);
    }
  });

  _.each(cols, (col, i) => {
    const colInd = df.columns.indexOf(col);
    if (colInd > -1) {
      columns.push(col);
      _.each(data, (d, j) => {
        const rowInd = df.index.indexOf(index[j]);
        data[j].push(df.data[rowInd][colInd]);
      });
    }
  });

  return { index, columns, data };
};

export function sortDataFrameByIndex(dataFrame, ascending = true) {
  // Get the index and data arrays
  let index = dataFrame.index;
  let data = dataFrame.data;

  // Create an array of objects that combines the index and data arrays
  let combinedData = index.map((val, i) => ({ index: val, data: data[i] }));

  // Sort the combinedData array by the index property
  combinedData.sort((a, b) => {
    if (a.index < b.index) {
      return ascending ? -1 : 1;
    } else if (a.index > b.index) {
      return ascending ? 1 : -1;
    } else {
      return 0;
    }
  });

  // Update the index and data arrays with the sorted values
  dataFrame.index = combinedData.map((val) => val.index);
  dataFrame.data = combinedData.map((val) => val.data);

  // Return the sorted data frame
  return dataFrame;
}
