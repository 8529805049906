import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import _ from 'lodash';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Table,
  Row,
  Col,
  Spinner,
  UncontrolledTooltip,
  TabPane,
  CardFooter,
} from 'reactstrap';
import PageTabs from 'components/PageTabs';
import { FixedCenterLoader } from 'components/Loaders';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import DownloadSimpleTable from 'components/DownloadSimpleTable';
import ReactDatetime from 'react-datetime';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { getRiskLabPortfolioRisk } from 'services/analysis';
import { optionizeValue, optionizeAll, getValue, getDate, getYearMonth, monthDiff } from 'helpers/select';
import { confidenceLevelToInterval } from 'helpers/formatter';
import { toDeci, toPerc, toPercTable, toDeciTable, tablePresets, getDataType, formatCurrency } from 'helpers/formatter';
import PieChart from 'components/PieChart';
import TightTable from 'components/TightTable';
import { Descriptions, Notes } from '../others';
import AssetSelect from '../AssetSelect';
import Holdings from './portfolio-risk/Holdings';
import RiskBudget from './portfolio-risk/RiskBudget';
import SingleAssetHeader from 'views/SingleAssetHeader';

function RiskLabPortfolioRisk({ session }) {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({ portfolio: '', budget_model: '', converter: '' });
  const [result, setResult] = useState(null);

  const fetchData = async () => {
    setLoading(true);

    const [data, err] = await getRiskLabPortfolioRisk(options);

    setResult(data);
    setLoading(false);
  };

  useEffect(() => {
    if (!options.portfolio) return;
    fetchData();
  }, [options]);

  console.log('result', result);

  let main = null;
  if (loading) {
    main = <FixedCenterLoader />;
  } else if (result) {
    const portfolioHoldingData = _.reduce(
      result.data,
      (ret, val, key) => {
        ret[key] = val.portfolio_holding;
        return ret;
      },
      {},
    );

    const majorAssetsData = _.reduce(
      result.data,
      (ret, val, key) => {
        ret[key] = val.major_assets;
        return ret;
      },
      {},
    );

    main = (
      <>
        <SingleAssetHeader data={result.header} />
        <Descriptions content={result.description} />

        <PageTabs
          options={[
            { value: 'holdings', label: 'Holdings' },
            { value: 'risk-budget', label: 'Risk Budget' },
          ]}
          colorIndex={0}
        >
          <TabPane tabId="holdings" role="tabpanel">
            <Holdings data={portfolioHoldingData} params={options} />
          </TabPane>

          <TabPane tabId="risk-budget" role="tabpanel">
            <RiskBudget data={majorAssetsData} params={options} />
          </TabPane>
        </PageTabs>

        <Notes content={result.notes} />
      </>
    );
  }

  return (
    <div className="tw-grid tw-grid-cols-12 tw-gap-4">
      <div className="tw-col-span-12 xl:tw-col-span-3">
        <AssetSelect
          session={session}
          onSelect={({ product, database, factorGroup, converter }) => {
            setOptions({ portfolio: product, budget_model: factorGroup, converter });
          }}
          enableFactorGroups
          portfolioOnly
        />
      </div>
      <div className="tw-col-span-12 xl:tw-col-span-9">{main}</div>
    </div>
  );
}

export default RiskLabPortfolioRisk;
