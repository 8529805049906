import Color from 'color';
import _ from 'lodash';

export const heatmapColor = (value) => {
  if (isNaN(value)) return '';
  const color = Color.hsl(`hsl(${value}, 100%, 50%)`);
  const rgb = color.rgb().string();
  return rgb;
};

export const distinctColor = (rgb) => {
  const color = Color.rgb(rgb);
  return color.isDark() ? 'rgb(255,255,255)' : 'rgb(0,0,0)';
};

export const getHeatmap = (values) => {
  const min = _.min(values);
  const max = _.max(values);
  const range = max - min;

  const perc = (val) => {
    const a = val - min;
    const b = (a / range) * 120;
    return parseFloat(Math.abs(120 - b).toFixed(4));
  };

  const result = _.reduce(
    values,
    (ret, val) => {
      ret[val] = heatmapColor(perc(val));
      return ret;
    },
    {},
  );

  return result;
};
