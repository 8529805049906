import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import {
  toPerc,
  toDeci,
  toDeciTable,
  toPercTable,
  isEmptyTightData,
  removeEmptyRows,
  formatDate,
} from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';

function CalendarYearReturns({ data, asof, notify, session }) {
  const [assetOptions, setAssetOptions, selectedAssetOption, setSelectedAssetOption] = useArrayState();

  useEffect(() => {
    if (!data) return;

    const _assets = data.index;
    const _assetsOptions = optionizeAll(_assets);
    setAssetOptions(_assetsOptions);
  }, [data]);

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Calendar Year Returns
          <div className="small">As of: {formatDate(asof)}</div>
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="risk-performance-calendar-year-returns"
            data={data}
            cols={(cols) => _.orderBy(cols, null, 'asc')}
            indexName="Fund"
            useSort
            cellFormatter={(value, row, col) => toPerc(value, { decorate: true, absolute: true })}
            title="Calendar Year Returns"
          />
        </CardBody>
      </Card>

      <div className="tw-grid tw-grid-cols-6 tw-gap-4">
        <div className="tw-col-start-2 tw-col-span-4">
          <div className="tw-text-left tw-font-bold">SELECT FUND</div>
          <Select
            className="react-select mw-400px"
            value={selectedAssetOption}
            onChange={setSelectedAssetOption}
            options={assetOptions}
          />
          {selectedAssetOption && (
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                Calendar Year Returns
                <div className="tw-text-lg">{selectedAssetOption.label}</div>
              </CardHeader>
              <CardBody>
                <TightChart
                  title="Calendar Year Returns"
                  data={data}
                  rows={(rows) => _.orderBy(rows, null, 'asc')}
                  cols={[selectedAssetOption.value]}
                  wraplabelX
                  omitEmpty
                  flip
                  dataType="percentage"
                />
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </>
  );
}

export default CalendarYearReturns;
