import React, { useState, useEffect, useMemo } from 'react';
import { Chart } from 'react-chartjs-2';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import BubbleChart from 'components/BubbleChart';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { toDeci, toPerc, toDeciTable, tablePresets } from 'helpers/formatter';
import { colors } from 'helpers/chart';
import { removeEmptyCols } from 'helpers/data-frame';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';

function Summary({ data, params, notify, session }) {
  const [returnDF, setReturnDF] = useState(null);
  const [assetOptions, setAssetOptions] = useState([]);
  const [selectedAssetOption, setSelectedAssetOption] = useState(null);

  useEffect(() => {
    if (!data) return;

    const _returnDF = removeEmptyCols(data.returns, ['15Y', '20Y']);
    const returnAssetKeys = _returnDF.index;
    setReturnDF(_returnDF);

    const _assetOptions = optionizeAll(returnAssetKeys.filter((v, i) => i < returnAssetKeys.length - 1));
    setAssetOptions(_assetOptions);

    if (_assetOptions.length > 0) {
      setSelectedAssetOption(_assetOptions[0]);
    }
  }, [data]);

  const riskReturnChart = useMemo(() => {
    if (!data) return {};

    const target = data.performance;
    const rowTitles = target.index;
    const colTitles = target.columns;

    const indCG = rowTitles.indexOf('CAGR');
    const indSD = rowTitles.indexOf('Standard Deviation');

    return {
      data: {
        datasets: colTitles.map((title, ind) => {
          return {
            label: title,
            data: [
              {
                x: target.data[indSD][ind] * 100,
                y: target.data[indCG][ind] * 100,
              },
            ],
            pointRadius: 13,
            pointHoverRadius: 5,
            backgroundColor: colors[ind % colors.length],
          };
        }),
      },
      options: {
        plugins: {
          legend: { display: colTitles.length < 5 },
          tooltip: {
            enabled: true,
            callbacks: {
              label: (item) => `${item.dataset.label}: ${toDeci(item.raw.y)}%, ${toDeci(item.raw.x)}%`,
            },
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Standard Deviation',
            },
            ticks: { callback: (val) => `${val}%` },
            color: 'black',
            beginAtZero: true,
          },
          y: {
            title: {
              display: true,
              text: 'Annualized Return',
            },
            ticks: { callback: (val) => `${toDeci(val, { precision: 1 })}%`, color: 'black' },
            beginAtZero: true,
          },
        },
      },
    };
  }, [data]);

  if (!returnDF) return null;

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Returns
          <TimePeriod dates={data.table_dates} />
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="summary_returns"
            data={returnDF}
            indexName=""
            useSort={true}
            rowDecorator={(rowTitle, rowInd, rowTitles) =>
              rowTitle === params.benchmark ? { style: { backgroundColor: 'rgb(251,198,88,0.5)' } } : undefined
            }
            cellFormatter={(value, row, col) => toPerc(value, { decorate: true, absolute: true })}
            title={['Returns', formatDates(data.table_dates)]}
          />
        </CardBody>
      </Card>

      <AutoColumns>
        <>
          <div className="tw-text-left tw-font-bold">SELECT ASSET</div>
          <Select
            className="react-select mw-400px"
            value={selectedAssetOption}
            onChange={setSelectedAssetOption}
            options={assetOptions}
          />
          {selectedAssetOption && (
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                Returns vs Benchmark
              </CardHeader>
              <CardBody>
                <TightChart
                  title="Returns vs Benchmark"
                  cols={[selectedAssetOption.value, params.benchmark]}
                  // rows={(rows) =>
                  //   rows.filter((row) => {
                  //     const num = parseInt(row);
                  //     console.log(row, num)
                  //     if (isNaN(num)) {
                  //       return false;
                  //     }

                  //     return num < 20;
                  //   })
                  // }
                  data={returnDF}
                  flip={true}
                  dataType="percentage"
                />
              </CardBody>
            </Card>
          )}
        </>
      </AutoColumns>

      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Performance
          <TimePeriod dates={data.table_dates} />
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="summary_performance"
            data={data.performance}
            indexName=""
            cellFormatter={(value, row, col) => {
              const formatter = tablePresets[row];
              if (formatter) return formatter(value);

              return toDeciTable(value);
            }}
            title={['Performance', formatDates(data.table_dates)]}
          />
        </CardBody>
      </Card>

      <div className="tw-grid tw-grid-cols-6 tw-gap-4">
        <Card className="tw-col-start-2 tw-col-span-4">
          <CardHeader tag="h4" className="mt-0 text-center">
            Risk and Return
          </CardHeader>
          <CardBody>
            <BubbleChart
              data={riskReturnChart.data}
              options={riskReturnChart.options}
              height={400}
              width={826}
              title="Risk and Return"
            />
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default Summary;
