import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { toDeci, toPerc } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';

function Returns({ data, params, notify, session }) {
  const [yearDF, setYearDF] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);
  const [selectedYearOption, setselectedYearOption] = useState(null);
  const [monthOptions, setMonthOptions] = useState([]);
  const [selectedMonthOption, setselectedMonthOption] = useState(null);
  const [assetOptions, setAssetOptions] = useState([]);
  const [selectedAssetOption, setSelectedAssetOption] = useState(null);

  useEffect(() => {
    if (!data) return;

    const yearsASC = _.orderBy(_.keys(data.monthly_returns), null, 'asc');
    const yearsDESC = _.orderBy(yearsASC, null, 'desc');
    const yearOptions = optionizeAll(yearsDESC);
    setYearOptions(yearOptions);

    if (yearOptions.length > 0) {
      setselectedYearOption(yearOptions[0]);
      const months = data.monthly_returns[yearsASC[0]].index;
      const monthOptions = optionizeAll(months);

      const assets = data.monthly_returns[yearsASC[0]].columns;
      const _assetOptions = optionizeAll(assets.filter((v, i) => i < assets.length - 1));
      setAssetOptions(_assetOptions);

      if (_assetOptions.length > 0) {
        setSelectedAssetOption(_assetOptions[0]);
      }

      setMonthOptions(monthOptions);
      setselectedMonthOption(monthOptions[0]);

      const _yearDF = _.reduce(
        data.monthly_returns,
        (ret, val) => {
          const row = _.map(val.data, (v) => v[0]);
          ret.data.push(row);
          return ret;
        },
        {
          index: yearsASC,
          columns: data.monthly_returns[yearsASC[0]].index,
          data: [],
        },
      );

      setYearDF(_yearDF);
    }
  }, [data]);

  if (!yearDF) return null;

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Calendar Year Returns
          <div className="tw-text-lg">{params.product}</div>
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="risk-performance-returns"
            data={yearDF}
            useSort
            initialSortBy={0}
            initialSortOrder="desc"
            indexName=""
            indexWidth="150px"
            dataType="percentage"
            title={['Calendar Year Returns', params.product]}
          />
        </CardBody>
      </Card>

      <AutoColumns>
        <Card>
          <CardHeader tag="h4" className="mt-0 text-center">
            Calendar Year Returns
            <div className="tw-text-lg">{params.product}</div>
          </CardHeader>
          <CardBody>
            <TightChart title="Calendar Year Returns" data={yearDF} cols={['Year']} dataType="percentage" />
          </CardBody>
        </Card>
      </AutoColumns>

      <AutoColumns>
        <>
          <div className="tw-text-left tw-font-bold">SELECT YEAR</div>
          <Select
            className="react-select tw-w-[200px]"
            value={selectedYearOption}
            onChange={setselectedYearOption}
            options={yearOptions}
          />
          {selectedYearOption && (
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                Monthly Returns
                <div className="tw-text-lg">{selectedYearOption.label}</div>
                <div className="tw-text-lg">{params.product}</div>
              </CardHeader>
              <CardBody>
                <TightChart
                  title="Monthly Returns"
                  data={data.monthly_returns[selectedYearOption.value]}
                  wraplabelX={true}
                  dataType="percentage"
                />
              </CardBody>
            </Card>
          )}
        </>
      </AutoColumns>
    </>
  );
}

export default Returns;
