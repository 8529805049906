import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { Card, CardHeader, CardBody, Label, TabPane, Row, Col } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { getTightColIndexByKey } from 'helpers/chart';
import { toDeci, toDeciTable, toPercTable, tablePresets } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';

const formatReturnPeriod = (value) => {
  const dates = value.split(' - ');
  if (dates.length < 2) return value;
  return dates.map((date) => date.substring(0, 7)).join(' - ');
};

function MarketStress({ data, params, notify, session }) {
  const [rowOptions, setRowOptions, selectedRowOption, setSelectedRowOption] = useArrayState();
  const [colOptions, setColOptions, selectedColOption, setSelectedColOption] = useArrayState();

  useEffect(() => {
    if (!data) return;

    data.index = data.index.filter(
      (row) =>
        ![
          'Correlation',
          'Beta',
          'Beta T-Stat',
          'Beta Significance',
          'Alpha',
          'Alpha T-Stat',
          'Alpha Significance',
          'R2',
        ].includes(row),
    );

    const analysisData = data.data;
    const index = data.index.filter((row) => !row.endsWith('Period'));
    const columns = data.columns.filter((col, i) =>
      index.some((row, j) => analysisData[j][i] && analysisData[j][i] !== '-'),
    );
    setRowOptions(optionizeAll(index));
    setColOptions(optionizeAll(columns));
  }, [data]);

  if (!selectedRowOption || !selectedColOption) return;

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Rolling Returns Analysis
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="rolling-returns-analysis"
            data={data}
            indexName="Rolling Period"
            indexWidth="250px"
            cellFormatter={(value, row, col) => {
              if (
                [
                  '% Positive',
                  '% Negative',
                  'Average Return',
                  'Median Return',
                  'Standard Deviation',
                  'Average Positive Return',
                  'Average Negative Return',
                  'Highest Return',
                  'Lowest Return',
                  'Alpha',
                  'Alpha T-Stat',
                  'Alpha Significance',
                ].includes(row)
              )
                return toPercTable(value);

              if (['R2'].includes(row)) return toPercTable(value, { precision: 0 });
              if (['Correlation', 'Beta', 'Beta T-Stat', 'Beta Significance'].includes(row)) return toDeciTable(value);
              if (['Highest Return Time Period', 'Lowest Return Time Period'].includes(row))
                return formatReturnPeriod(value);

              return value;
            }}
            title={['Rolling Returns Analysis']}
          />
        </CardBody>
      </Card>

      <Row>
        <Col xl="8" className="offset-xl-2">
          <div className="tw-text-left tw-font-bold">SELECT</div>
          <Select
            className="react-select mw-300px"
            name="rowName"
            value={selectedRowOption}
            onChange={setSelectedRowOption}
            options={rowOptions}
          />
          <Card>
            <CardHeader>&nbsp;</CardHeader>
            <CardBody>
              <TightChart
                data={data}
                rows={[selectedRowOption.value]}
                base="cols"
                dataType={
                  [
                    '% Positive',
                    '% Negative',
                    'Average Return',
                    'Median Return',
                    'Standard Deviation',
                    'Average Positive Return',
                    'Average Negative Return',
                    'Highest Return',
                    'Lowest Return',
                  ].includes(selectedRowOption.value)
                    ? 'percentage'
                    : 'text'
                }
                omitEmpty
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <div className="tw-text-left tw-font-bold">SELECT ROLLING PERIOD</div>
      <Select
        className="react-select mw-200px"
        name="colName"
        value={selectedColOption}
        onChange={setSelectedColOption}
        options={colOptions}
      />
      <Row>
        <Col xl="6">
          <Card>
            <CardHeader>&nbsp;</CardHeader>
            <CardBody>
              <TightChart
                data={data}
                rows={['Average Return', 'Average Positive Return', 'Average Negative Return']}
                cols={[selectedColOption.value]}
                omitEmpty
                dataType="percentage"
              />
            </CardBody>
          </Card>
        </Col>
        <Col xl="6">
          <Card>
            <CardHeader>&nbsp;</CardHeader>
            <CardBody>
              <TightChart
                data={data}
                rows={['Lowest Return', 'Highest Return']}
                cols={[selectedColOption.value]}
                omitEmpty
                dataType="percentage"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default MarketStress;
