import React, { useContext, useState, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { downloadTable } from 'helpers/table-csv';
import { AdminContext } from 'layouts/Admin';

function DownloadSimpleTable({ data, suffix, title }) {
  const context = useContext(AdminContext);

  if (!context?.session?.permissions['export-table-data']) return null;

  return (
    <div className="tw-text-right -tw-mt-9 tw-mb-2">
      <button
        type="button"
        className="tw-px-2 tw-py-1 tw-text-gray-900 tw-bg-white tw-border tw-border-gray-200 tw-rounded-lg hover:tw-bg-gray-100 hover:tw-text-blue-700"
        onClick={() => downloadTable(data, data, (v) => v, '', false, suffix, title)}
      >
        <ArrowDownTrayIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
      </button>
    </div>
  );
}

export default DownloadSimpleTable;
