import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import classnames from 'classnames';
import _ from 'lodash';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Table,
  Row,
  Col,
  Spinner,
  UncontrolledTooltip,
  TabPane,
} from 'reactstrap';
import { FixedCenterLoader } from 'components/Loaders';
import Select from 'react-select';
import { allowZeroHundred } from 'helpers/number';
import {
  listProducts,
  getDiscovery,
  getQuickViewDiscovery,
  getAdvancedAnalyticsFactorExposuresOptions,
  getAdvancedAnalyticsPortfolioImpactOptions,
} from 'services/analysis';
import { optionizeValue, optionizeAll, getValue, getDate, getYearMonth, monthDiff } from 'helpers/select';
import { useArrayState } from 'components/CustomHooks';
import { SAVED_PORTFOLIOS } from './constants';

const selectOptions = {
  valueContainer: () => ({
    paddingLeft: 10,
    display: 'flex',
    alignItems: 'center',
  }),
  menu: () => ({
    zIndex: 200,
    position: 'absolute',
    backgroundColor: '#fff',
  }),
};

export const defaultCurrencyOptions = [
  { value: 'Default', label: 'No' },
  { value: 'CAD', label: 'CAD (Convert USD)' },
  { value: 'USD', label: 'USD (Convert CAD)' },
];

export const defaultPeriodOptions = [
  { value: '12', label: '12 months' },
  { value: '18', label: '18 months' },
  { value: '24', label: '24 months' },
  { value: '30', label: '30 months' },
  { value: '36', label: '36 months' },
];

export const defaultMeasureOptions = [
  { value: 'cagr', label: 'Return' },
  { value: 'st_dev', label: 'Standard Deviation' },
];

export const defaultRatioOptions = [
  { value: 'sharpe', label: 'Sharpe Ratio' },
  { value: 'sortino', label: 'Sortino Ratio' },
];

export const defaultVarMetricOptions = [
  { value: 'HVaR', label: 'HVaR' },
  { value: 'CVaR', label: 'CVaR' },
  { value: 'AVaR', label: 'AVaR' },
];

export const defaultConfidenceOptions = [
  { value: 0.9, label: '90%' },
  { value: 0.95, label: '95%' },
  { value: 0.99, label: '99%' },
];

export const defaultChartOptions = [
  { value: 'rolling_beta', label: 'Beta' },
  { value: 'rolling_correl', label: 'Correlation' },
];

export const defaultMetricOptions = [
  { value: 'beta', label: 'Beta', format: 'decimal' },
  { value: 'alpha', label: 'Alpha', format: 'percentage' },
  { value: 'up_capture', label: 'Up Capture', format: 'decimal' },
  { value: 'down_capture', label: 'Down Capture', format: 'decimal' },
  { value: 'information_ratio', label: 'Information Ratio', format: 'decimal' },
  { value: 'tracking_error', label: 'Tracking Error', format: 'percentage' },
  { value: 'correlation', label: 'Correlation', format: 'decimal' },
];

const emptyBenchmarkOption = { label: 'No Benchmark', value: '' };
const emptyFactorOption = { label: 'No Factor', value: '' };

function MultipleAssetSelect({
  onSelect,
  enableFactor = false,
  enableBenchmark = false,
  enableMeasure = false,
  enableRatio = false,
  enableVarMetric = false,
  enableConfidence = false,
  enableChart = false,
  enablePeriod = false,
  enablePortfolioImpact = false,
  factorOrBenchmark = false,
  benchmarkRequired = false,
  minItems = 2,
  maxItems = 5,
  itemStep = 5,
  displayMax = false,
  title = 'SELECT ASSETS',
  includePortfolio = false,
  portfolioOnly = false,
  onAllowed = () => true,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const defaultNulls = Array(displayMax ? maxItems : minItems).fill(null);

  const [assetsMeta, setAssetsMeta] = useState({});
  const [databaseOptions, setDatabaseOptions] = useState([]);
  const [assets, setAssets] = useState(defaultNulls.concat());
  const [databases, setDatabases] = useState(defaultNulls.concat());
  const [dataLoading, setDataLoading] = useState(false);
  const [allocation, setAllocation] = useState(10);
  const [benchmarkOptions, setBenchmarkOptions, selectedBenchmarkOption, setSelectedBenchmarkOption] = useArrayState({
    initialItems: benchmarkRequired ? [] : [emptyBenchmarkOption],
  });
  const [factorOptions, setFactorOptions, selectedFactorOption, setSelectedFactorOption] = useArrayState({
    initialItems: [emptyFactorOption],
  });
  const [periodOptions, setPeriodOptions, selectedPeriodOption, setSelectedPeriodOption] = useArrayState({
    initialItems: defaultPeriodOptions,
  });
  const [measureOptions, setMeasureOptions, selectedMeasureOption, setSelectedMeasureOption] = useArrayState({
    initialItems: defaultMeasureOptions,
  });
  const [ratioOptions, setRatioOptions, selectedRatioOption, setSelectedRatioOption] = useArrayState({
    initialItems: defaultRatioOptions,
  });
  const [varMetricOptions, setVarMetricOptions, selectedVarMetricOption, setSelectedVarMetricOption] = useArrayState({
    initialItems: defaultVarMetricOptions,
  });
  const [portfolioOptions, setPortfolioOptions, selectedPortfolioOption, setSelectedPortfolioOption] = useArrayState({
    initialItems: [],
  });
  const [confidenceOptions, setConfidenceOptions, selectedConfidenceOption, setSelectedConfidenceOption] =
    useArrayState({
      initialItems: defaultConfidenceOptions,
    });
  const [chartOptions, setChartOptions, selectedChartOption, setSelectedChartOption] = useArrayState({
    initialItems: defaultChartOptions,
  });
  const [currencyOptions, setCurrencyOptions, selectedCurrencyOption, setSelectedCurrencyOption] = useArrayState({
    initialItems: defaultCurrencyOptions,
  });

  const fetchData = async () => {
    setDataLoading(true);

    const [productRes, benchRes, factorRes, portfolioRes] = await Promise.all([
      getQuickViewDiscovery(),
      getDiscovery(),
      getAdvancedAnalyticsFactorExposuresOptions(),
      getAdvancedAnalyticsPortfolioImpactOptions(),
    ]);

    let [product] = productRes;
    if (product) {
      if (includePortfolio) {
        if (portfolioOnly) product = { [SAVED_PORTFOLIOS]: product[SAVED_PORTFOLIOS] };
      } else {
        delete product[SAVED_PORTFOLIOS];
      }

      const keys = _.keys(product);
      setAssetsMeta(product);
      setDatabaseOptions(optionizeAll(keys));
    }

    const [bench] = benchRes;
    if (bench) {
      const benchmarks = bench.benchmark_choice.map(optionizeValue);
      const factorGroups = bench.risk_budget_choice.map(optionizeValue);
      setBenchmarkOptions(benchmarkRequired ? benchmarks : [emptyBenchmarkOption, ...benchmarks]);
    }

    const [factor] = factorRes;
    if (factor) {
      const factorOptions = factor.factors.map(optionizeValue);
      setFactorOptions([emptyFactorOption, ...factorOptions]);
    }

    const [portfolio] = portfolioRes;
    if (portfolio) {
      const portfolioOptions = portfolio.map(optionizeValue);
      setPortfolioOptions(portfolioOptions);
    }

    setDataLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const allowed = useMemo(() => {
    const compactAssets = _.compact(assets);
    if (compactAssets.length < minItems) return false;

    for (let x = 0; x < assets.length; x++) {
      const asset = assets[x];
      const database = databases[x];
      if (asset && !database) return false;
      if (!asset && database) return false;
    }

    if (enableFactor && enableBenchmark && factorOrBenchmark) {
      if (selectedBenchmarkOption.value === '' && selectedFactorOption.value === '') return false;
    }

    return onAllowed({
      assets: _.compact(assets),
      databases: _.compact(databases),
      converter: databases[0] === SAVED_PORTFOLIOS ? selectedCurrencyOption.value : defaultCurrencyOptions[0].value,
      factor: selectedFactorOption.value,
      benchmark: selectedBenchmarkOption.value,
      measure: selectedMeasureOption.value,
      ratio: selectedRatioOption.value,
      varMetric: selectedVarMetricOption.value,
      confidence: selectedConfidenceOption.value,
      chart: selectedChartOption.value,
      period: selectedPeriodOption.value,
      portfolioChoice: selectedPortfolioOption?.value,
      allocation,
    });
  }, [databases, assets, selectedBenchmarkOption, selectedFactorOption, onAllowed]);

  if (dataLoading || _.isEmpty(assetsMeta)) {
    return <FixedCenterLoader />;
  }

  return (
    <>
      <Card>
        <CardBody>
          <Table hover striped bordered className="tw-table-auto">
            <thead>
              <tr>
                <th>{title}</th>
              </tr>
            </thead>
            <tbody>
              {assets.map((asset, i) => {
                const databaseAssetOptions = optionizeAll((databases[i] && assetsMeta[databases[i]]) || []);

                return (
                  <tr key={`${asset}-${i}`}>
                    <td>
                      <Select
                        className="react-select"
                        name="databases"
                        placeholder="Select Database..."
                        value={databaseOptions.find((option) => option.value === databases[i])}
                        onChange={(option) => {
                          databases[i] = option.value;
                          assets[i] = null;
                          setDatabases(databases.concat());
                          setAssets(assets.concat());
                        }}
                        options={databaseOptions}
                        styles={selectOptions}
                      />
                      <Select
                        className="react-select"
                        name="assets"
                        placeholder="Select Asset..."
                        value={databaseAssetOptions.find((option) => option.value === asset)}
                        onChange={(option) => {
                          assets[i] = option.value;
                          setAssets(assets.concat());
                        }}
                        // exclude the asset already taken
                        options={databaseAssetOptions.filter((option) => {
                          if (option.value === option) return true;
                          if (assets.includes(option.value)) return false;
                          return true;
                        })}
                        styles={selectOptions}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          {minItems !== maxItems && (
            <>
              <Button
                className="btn-round"
                color="info"
                disabled={databases.length >= maxItems}
                onClick={() => {
                  const remaining = maxItems - databases.length;
                  const nulls = Array(remaining > itemStep ? itemStep : remaining).fill(null);
                  setDatabases(databases.concat(...nulls.concat()));
                  setAssets(assets.concat(...nulls.concat()));
                }}
              >
                Add Asset{itemStep > 1 && 's'}
              </Button>
              <Button
                className="btn-round"
                color="danger"
                disabled={databases.length <= minItems}
                onClick={() => {
                  const num = databases.length - itemStep > minItems ? databases.length - itemStep : minItems;
                  setDatabases(databases.slice(0, num));
                  setAssets(assets.slice(0, num));
                }}
              >
                Remove Asset{itemStep > 1 && 's'}
              </Button>
              <Button
                className="btn-round"
                color="warning"
                onClick={() => {
                  setDatabases(defaultNulls.concat());
                  setAssets(defaultNulls.concat());
                }}
              >
                Clear
              </Button>
            </>
          )}
        </CardBody>
      </Card>

      {(enableFactor ||
        enableBenchmark ||
        enableMeasure ||
        enableRatio ||
        enableVarMetric ||
        enableConfidence ||
        enablePeriod ||
        enableChart ||
        enablePortfolioImpact) && (
        <Card>
          <CardBody className="mt-3">
            {databases.length === 1 && databases[0] === SAVED_PORTFOLIOS && (
              <Form className="form-horizontal">
                <Label>SELECT Convert Currency</Label>
                <FormGroup>
                  <Select
                    className="react-select"
                    name="selectedCurrencyOption"
                    value={selectedCurrencyOption}
                    onChange={setSelectedCurrencyOption}
                    options={currencyOptions}
                  />
                </FormGroup>
              </Form>
            )}

            {enableBenchmark && selectedBenchmarkOption && (
              <Form className="form-horizontal">
                <Label>SELECT Benchmark</Label>
                <FormGroup>
                  <Select
                    className="react-select"
                    name="selectedBenchmarkOption"
                    value={selectedBenchmarkOption}
                    onChange={(option) => {
                      setSelectedBenchmarkOption(option);
                      if (factorOrBenchmark) {
                        setSelectedFactorOption(emptyFactorOption);
                      }
                    }}
                    options={benchmarkOptions}
                  />
                </FormGroup>
              </Form>
            )}

            {enableMeasure && selectedMeasureOption && (
              <Form className="form-horizontal">
                <Label>SELECT</Label>
                <FormGroup>
                  <Select
                    className="react-select"
                    name="selectedMeasureOption"
                    value={selectedMeasureOption}
                    onChange={setSelectedMeasureOption}
                    options={measureOptions}
                  />
                </FormGroup>
              </Form>
            )}

            {enableRatio && selectedRatioOption && (
              <Form className="form-horizontal">
                <Label>SELECT Ratio</Label>
                <FormGroup>
                  <Select
                    className="react-select"
                    name="selectedRatioOption"
                    value={selectedRatioOption}
                    onChange={setSelectedRatioOption}
                    options={ratioOptions}
                  />
                </FormGroup>
              </Form>
            )}

            {enableVarMetric && selectedVarMetricOption && (
              <Form className="form-horizontal">
                <Label>SELECT VaR Metric</Label>
                <FormGroup>
                  <Select
                    className="react-select"
                    name="selectedVarMetricOption"
                    value={selectedVarMetricOption}
                    onChange={setSelectedVarMetricOption}
                    options={varMetricOptions}
                  />
                </FormGroup>
              </Form>
            )}

            {enableConfidence && selectedConfidenceOption && (
              <Form className="form-horizontal">
                <Label>SELECT Confidence Interval</Label>
                <FormGroup>
                  <Select
                    className="react-select"
                    name="selectedConfidenceOption"
                    value={selectedConfidenceOption}
                    onChange={setSelectedConfidenceOption}
                    options={confidenceOptions}
                  />
                </FormGroup>
              </Form>
            )}

            {enableFactor && selectedFactorOption && (
              <Form className="form-horizontal">
                <Label>SELECT Factor</Label>
                <FormGroup>
                  <Select
                    className="react-select"
                    name="selectedFactorOption"
                    value={selectedFactorOption}
                    onChange={(option) => {
                      setSelectedFactorOption(option);
                      if (factorOrBenchmark && benchmarkOptions.length > 0) {
                        setSelectedBenchmarkOption(benchmarkOptions[0]);
                      }
                    }}
                    options={factorOptions}
                  />
                </FormGroup>
              </Form>
            )}

            {enablePortfolioImpact && selectedPortfolioOption && (
              <>
                <Form className="form-horizontal">
                  <Label>SELECT Portfolio</Label>
                  <FormGroup>
                    <Select
                      className="react-select"
                      name="selectedPortfolioOption"
                      value={selectedPortfolioOption}
                      onChange={setSelectedPortfolioOption}
                      options={portfolioOptions}
                    />
                  </FormGroup>
                </Form>

                <Form className="form-horizontal">
                  <Label>Subject Allocation (%)</Label>
                  <FormGroup>
                    <Input
                      type="number"
                      min="0"
                      max="100"
                      maxlength="3"
                      value={allocation}
                      onChange={(event) => {
                        setAllocation(allowZeroHundred(event.target.value));
                      }}
                    />
                  </FormGroup>
                </Form>
              </>
            )}

            {enableChart && selectedChartOption && (
              <Form className="form-horizontal">
                <Label>SELECT Chart</Label>
                <FormGroup>
                  <Select
                    className="react-select"
                    name="selectedChartOption"
                    value={selectedChartOption}
                    onChange={setSelectedChartOption}
                    options={chartOptions}
                  />
                </FormGroup>
              </Form>
            )}

            {enablePeriod && selectedPeriodOption && (
              <Form className="form-horizontal">
                <Label>SELECT Rolling Period</Label>
                <FormGroup>
                  <Select
                    className="react-select"
                    name="selectedPeriodOption"
                    value={selectedPeriodOption}
                    onChange={setSelectedPeriodOption}
                    options={periodOptions}
                  />
                </FormGroup>
              </Form>
            )}
          </CardBody>
        </Card>
      )}

      <div className="text-center">
        <Button
          className="btn-round"
          color="info"
          disabled={!allowed}
          onClick={() => {
            if (!allowed) return;
            onSelect({
              assets: _.compact(assets),
              databases: _.compact(databases),
              converter:
                databases[0] === SAVED_PORTFOLIOS ? selectedCurrencyOption.value : defaultCurrencyOptions[0].value,
              factor: selectedFactorOption.value,
              benchmark: selectedBenchmarkOption.value,
              measure: selectedMeasureOption.value,
              ratio: selectedRatioOption.value,
              varMetric: selectedVarMetricOption.value,
              confidence: selectedConfidenceOption.value,
              chart: selectedChartOption.value,
              period: selectedPeriodOption.value,
              portfolioChoice: selectedPortfolioOption?.value,
              allocation,
            });
          }}
        >
          Analyze
        </Button>
      </div>
    </>
  );
}

export default MultipleAssetSelect;
