import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import _ from 'lodash';
import { Card, CardHeader, CardBody, TabPane, Row, Col } from 'reactstrap';
import Select from 'react-select';
import { FixedCenterLoader } from 'components/Loaders';
import PageTabs from 'components/PageTabs';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { optionize, optionizeAll } from 'helpers/select';
import { toDeciTable, toPercTable, formatDate } from 'helpers/formatter';
import { removeEmptyCols, flipDF } from 'helpers/data-frame';
import { parseSearchParams } from 'helpers/location';
import { riskMeasuresPerc, riskRatioGroups } from 'helpers/meta';

function AdvancedDetail({ data, params, notify, session }) {
  const [measureOptions, setMeasureOptions, selectedMeasureOption, setSelectedMeasureOption] = useArrayState({
    initialItems: [],
  });

  useEffect(() => {
    const _regressionMeasures = data.index;
    setMeasureOptions(optionizeAll(_regressionMeasures, { useIndex: true }));
  }, [data]);

  if (!selectedMeasureOption) return null;

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          {params.product}
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="regression-benchmark"
            data={data}
            indexName=""
            dataType="decimal"
            cellFormatter={(value, row, col) =>
              ['Tracking Error', 'Alpha', 'R2'].includes(row) ? toPercTable(value) : toDeciTable(value)
            }
            compact
            title="Regression vs (Benchmark)"
          />
        </CardBody>
      </Card>
      <Row>
        <Col xl="8" className="offset-xl-2">
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              {params.product}
            </CardHeader>
            <CardBody>
              <TightChart
                data={data}
                base="cols"
                rows={['Up Market Capture Ratio', 'Down Market Capture Ratio']}
                dataType="decimal"
                labelFormatter={_.upperFirst}
                omitEmpty={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xl="8" className="offset-xl-2">
          <div className="tw-text-left tw-font-bold">SELECT</div>
          <Select
            className="react-select mw-300px"
            name="selectedMeasureOption"
            placeholder="Please select an risk measure..."
            value={selectedMeasureOption}
            onChange={setSelectedMeasureOption}
            options={measureOptions}
          />
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              {params.product}
              <div className="tw-text-lg">{selectedMeasureOption.label}</div>
            </CardHeader>
            <CardBody>
              <TightChart
                data={data}
                base="cols"
                rows={[selectedMeasureOption.label]}
                dataLabels={true}
                omitEmpty={true}
                labelFormatter={_.upperFirst}
                dataType={
                  ['Tracking Error', 'Alpha', 'R2'].includes(selectedMeasureOption.label) ? 'percentage' : 'decimal'
                }
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default AdvancedDetail;
