import React, { memo } from 'react';
import _ from 'lodash';
import { Chart } from 'react-chartjs-2';
import ChartDownload from './ChartDownload';
import { customCanvasBackgroundColor } from './_chart';

function LineChart(props) {
  const { title } = props;

  return (
    <>
      <ChartDownload
        chartFn={() => ({
          ..._.cloneDeep(props),
          type: 'line',
          plugins: [...(props.plugins || []), customCanvasBackgroundColor],
        })}
        filename="download.png"
        title={title}
      />
      <Chart {...props} type="line" />
    </>
  );
}

export default memo(LineChart);
