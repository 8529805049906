import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import _ from 'lodash';
import { Card, CardHeader, CardBody, TabPane, Row, Col } from 'reactstrap';
import Select from 'react-select';
import { FixedCenterLoader } from 'components/Loaders';
import PageTabs from 'components/PageTabs';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { optionize, optionizeAll } from 'helpers/select';
import { toDeci, toPerc, toDeciTable, toPercTable, tablePresets } from 'helpers/formatter';
import { removeEmptyCols, flipDF } from 'helpers/data-frame';
import { parseSearchParams } from 'helpers/location';
import LineChart from 'components/LineChart';
import { colors } from 'helpers/chart';
import { defaultMetricOptions, defaultPeriodOptions } from 'views/MultipleAssetSelect';
import { getPerformanceEngineBenchmarkRolling } from 'services/analysis';

const defaultMetric = 'beta';
const defaultWindow = '12';

function Charts({ data, params, notify, session }) {
  const [chart, setChart] = useState(data.rolling_metrics);
  const [table, setTable] = useState(data.table);

  const [metricOptions, setMetricOptions, selectedMetricOption, setSelectedMetricOption] = useArrayState({
    initialItems: defaultMetricOptions,
    initialItem: defaultMetricOptions.find((v) => v.value === defaultMetric),
  });
  const [periodOptions, setPeriodOptions, selectedPeriodOption, setSelectedPeriodOption] = useArrayState({
    initialItems: data.valid_windows.map((window) => ({ label: `${window} months`, value: String(window) })),
  });

  const fetchData = async () => {
    const [data, err] = await getPerformanceEngineBenchmarkRolling({
      ...params,
      metric: selectedMetricOption.value,
      window: selectedPeriodOption.value,
    });

    if (data) {
      setChart(data.chart.rolling_metrics);
      setTable(data.chart.table);
    } else {
      setChart(null);
      setTable(null);
    }
  };

  useEffect(() => {
    if (!selectedMetricOption || !selectedPeriodOption) return;
    if (selectedMetricOption.value === defaultMetric && selectedPeriodOption.value === defaultWindow) {
      setChart(data.rolling_metrics);
      setTable(data.table);
      return;
    }

    fetchData();
  }, [selectedMetricOption, selectedPeriodOption]);

  const metric = defaultMetricOptions.find(
    (opt) => opt.value === (selectedMetricOption ? selectedMetricOption.value : defaultMetric),
  );
  const formatter = metric.format === 'decimal' ? toDeci : toPerc;
  const formatterTable = metric.format === 'decimal' ? toDeciTable : toPercTable;

  const lineChart = useMemo(() => {
    return {
      data: {
        labels: chart.index.map((v) => {
          const date = new Date(v);
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const day = date.getDate();

          return `${_.padStart(month, 2, '0')}/${year}`;
        }),
        datasets: [
          {
            label: metric.label,
            borderColor: colors[0],
            backgroundColor: colors[5],
            pointRadius: 3,
            pointHoverRadius: 4,
            fill: false,
            borderWidth: 3,
            barPercentage: 1.6,
            tension: 0.4,
            data: chart.data.map((v) => v[0]),
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: (item, data) => `${item.dataset.label}: ${formatter(item.raw)}`,
            },
          },
        },
        scales: {
          x: {
            title: {
              display: false,
            },
            ticks: {
              maxRotation: 0,
              autoskip: true,
              autoSkipPadding: 20,
              color: 'black',
            },
            beginAtZero: false,
          },
          y: {
            title: {
              display: true,
            },
            // min: -1,
            // max: 1,
            ticks: {
              callback: (val) => {
                if (val < 0) {
                  const pos = Math.abs(val);
                  return `(${formatter(pos)})`;
                }

                return formatter(val);
              },
              color: ({ tick }) => (tick.value < 0 ? 'red' : 'black'),
            },
            beginAtZero: false,
          },
        },
      },
    };
  }, [chart]);

  const tableDf = useMemo(() => {
    if (!table) return null;

    return {
      columns: ['Current', 'Average', 'Min', 'Max'],
      index: [0],
      data: [[table.Current, table.Average, table.Min, table.Max]],
    };
  }, [table]);

  if (!chart || !table || !tableDf || !lineChart || !selectedPeriodOption) return null;

  return (
    <>
      <div className="tw-max-w-3xl tw-mx-auto">
        <div className="tw-grid tw-grid-cols-6 tw-gap-4">
          <div className="tw-col-span-3">
            <div className="tw-text-left tw-font-bold">SELECT METRIC</div>
            <Select
              className="react-select"
              value={selectedMetricOption}
              onChange={setSelectedMetricOption}
              options={metricOptions}
            />
          </div>
          <div className="tw-col-span-3">
            <div className="tw-text-left tw-font-bold">SELECT ROLLING TIME PERIOD</div>
            <Select
              className="react-select"
              value={selectedPeriodOption}
              onChange={setSelectedPeriodOption}
              options={periodOptions}
            />
          </div>
        </div>
        <Card>
          <CardHeader tag="h4" className="mt-0 text-center">
            <div className="tw-text-lg">{params.product}</div>
            <div className="tw-text-lg">{metric.label}</div>
            <div className="tw-text-lg">Rolling Period: {selectedPeriodOption.value} Months</div>
          </CardHeader>
          <CardBody>
            <LineChart
              data={lineChart.data}
              options={lineChart.options}
              height={400}
              width={826}
              title={[`Rolling ${selectedPeriodOption.value} Month Correlation`]}
            />
          </CardBody>
        </Card>
      </div>

      <Row>
        <Col xl="8" className="offset-xl-2">
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              <div className="tw-text-lg">{params.product}</div>
              <div className="tw-text-lg">{metric.label}</div>
              <div className="tw-text-lg">Rolling Period: {selectedPeriodOption.value} Months</div>
            </CardHeader>
            <CardBody>
              <TightTable suffix="rolling-correlation" data={tableDf} cellFormatter={formatterTable} hideIndexTable />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Charts;
