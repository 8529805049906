import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import _ from 'lodash';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Table,
  Row,
  Col,
  Spinner,
  UncontrolledTooltip,
  TabPane,
  CardFooter,
} from 'reactstrap';
import PageTabs from 'components/PageTabs';
import { FixedCenterLoader } from 'components/Loaders';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import DownloadSimpleTable from 'components/DownloadSimpleTable';
import ReactDatetime from 'react-datetime';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { listProducts, getDiscovery, toolboxCorrelation, getRiskLabCompare } from 'services/analysis';
import { optionizeValue, optionizeAll, getValue, getDate, getYearMonth, monthDiff } from 'helpers/select';
import { confidenceLevelToInterval } from 'helpers/formatter';
import { toDeci, toPerc, toPercTable, toDeciTable, tablePresets, getDataType, formatCurrency } from 'helpers/formatter';
import PieChart from 'components/PieChart';
import TightTable from 'components/TightTable';
import { Descriptions, Notes } from '../others';
import MultipleAssetSelect from '../MultipleAssetSelect';
import MarketRisk from './risk-compare/MarketRisk';
import InterestRateRisk from './risk-compare/InterestRateRisk';
import CreditSpreads from './risk-compare/CreditSpreads';

function RiskCompare({ session }) {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({ assets: [], databases: [], benchmark: null });
  const [result, setResult] = useState(null);

  const fetchData = async () => {
    setLoading(true);

    const [data, err] = await getRiskLabCompare({
      database: options.databases,
      product: options.assets,
      benchmark: options.benchmark,
    });

    if (data) {
      setResult(data);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (options.assets.length < 2) return;

    fetchData();
  }, [options]);

  let main = loading ? <FixedCenterLoader /> : null;
  if (result) {
    main = (
      <>
        <div className="tw-text-center">
          <h3 className="title tw-mb-2">Risk Comparison</h3>
        </div>

        <Card>
          <CardHeader tag="h4" className="mt-0 text-center">
            <div className="tw-text-sm">
              Benchmark: <span className="tw-font-semibold">{result.benchmark_display}</span>
            </div>
          </CardHeader>
          <CardBody>
            <TightTable
              title={[
                `Benchmark: ${result.benchmark_display}`,
                formatDates([result.header.start_date, result.header.end_date]),
              ]}
              suffix="info"
              indexWidth="230px"
              data={result.header.asset_info_header}
              indexName=""
            />
          </CardBody>
        </Card>

        <PageTabs
          options={[
            { value: 'market-risk', label: 'Market Risk' },
            { value: 'interest-rates', label: 'Interest Rates' },
            { value: 'credit-spreads', label: 'Credit Spreads' },
          ]}
          colorIndex={0}
        >
          <TabPane tabId="market-risk" role="tabpanel">
            <Descriptions content={result.descriptions.market_risk} />
            <MarketRisk data={result.market_risk} />
            <Notes content={result.notes.market_risk} />
          </TabPane>

          <TabPane tabId="interest-rates" role="tabpanel">
            <Descriptions content={result.descriptions.interest_rates} />
            <InterestRateRisk data={result.interest_rates} />
            <Notes content={result.notes.interest_rates} />
          </TabPane>

          <TabPane tabId="credit-spreads" role="tabpanel">
            <Descriptions content={result.descriptions.credit_spreads} />
            <CreditSpreads data={result.credit_spreads} />
            <Notes content={result.notes.credit_spreads} />
          </TabPane>
        </PageTabs>
      </>
    );
  }

  return (
    <div className="tw-grid tw-grid-cols-12 tw-gap-4">
      <div className="tw-col-span-12 xl:tw-col-span-3">
        <MultipleAssetSelect
          session={session}
          onSelect={({ assets, databases, benchmark }) => {
            setOptions({ assets, databases, benchmark });
          }}
          title="SELECT ASSETS (MIN 2, MAX 5)"
          minItems={2}
          maxItems={5}
          itemStep={1}
          displayMax
          enableBenchmark
        />
      </div>
      <div className="tw-col-span-12 xl:tw-col-span-9">{main}</div>
    </div>
  );
}

export default RiskCompare;
