import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import _ from 'lodash';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Table,
  Row,
  Col,
  Spinner,
  UncontrolledTooltip,
  TabPane,
  CardFooter,
} from 'reactstrap';
import PageTabs from 'components/PageTabs';
import { FixedCenterLoader } from 'components/Loaders';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import DownloadSimpleTable from 'components/DownloadSimpleTable';
import ReactDatetime from 'react-datetime';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { getPerformanceEnginePortfolioImpact } from 'services/analysis';
import { optionizeValue, optionizeAll, getValue, getDate, getYearMonth, monthDiff } from 'helpers/select';
import { confidenceLevelToInterval } from 'helpers/formatter';
import { toDeci, toPerc, toPercTable, toDeciTable, tablePresets, getDataType, formatCurrency } from 'helpers/formatter';
import PieChart from 'components/PieChart';
import TightTable from 'components/TightTable';
import { Descriptions, Notes } from '../others';
import AssetSelect from '../AssetSelect';
import Summary from './benchmark-analysis/Summary';
import RiskMetrics from './benchmark-analysis/RiskMetrics';
import AdvancedDetail from './benchmark-analysis/AdvancedDetail';
import Charts from './benchmark-analysis/Charts';
import MultipleAssetSelect from '../MultipleAssetSelect';
import PortfolioImpactBase from './portfolio-impact/PortfolioImpact';
import AssetHeader from 'views/AssetHeader';

function PortfolioImpact({ session }) {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({
    product: [],
    database: [],
    portfolio_choice: '',
    impact: 10,
  });
  const [result, setResult] = useState(null);

  const fetchData = async () => {
    setLoading(true);

    const [data, err] = await getPerformanceEnginePortfolioImpact(options);

    setResult(data);
    setLoading(false);
  };

  useEffect(() => {
    if (options.product.length === 0) return;
    fetchData();
  }, [options]);

  console.log('result', result);

  let main = null;
  if (loading) {
    main = <FixedCenterLoader />;
  } else if (result) {
    main = (
      <>
        <Descriptions content={result.page_description} />
        <Card>
          <CardBody className="tw-text-lg tw-text-center">
            <div>
              Original Portfolio:
              <span className="tw-italic tw-font-semibold tw-ml-1">{result.original_description}</span>
            </div>
            <div>
              New Portfolio:<span className="tw-italic tw-font-semibold tw-ml-1">{result.new_description}</span>
            </div>
          </CardBody>
        </Card>
        <PortfolioImpactBase data={result.impact_output} params={options} />
        <Notes content={result.page_notes} />
      </>
    );
  }

  return (
    <div className="tw-grid tw-grid-cols-12 tw-gap-4">
      <div className="tw-col-span-12 xl:tw-col-span-3">
        <MultipleAssetSelect
          session={session}
          onSelect={({ assets, databases, portfolioChoice, allocation }) => {
            setOptions({
              product: assets,
              database: databases,
              portfolio_choice: portfolioChoice,
              impact: allocation,
            });
          }}
          title="SELECT (1-3) ASSETS"
          minItems={1}
          maxItems={3}
          itemStep={1}
          enablePortfolioImpact
          onAllowed={({ portfolioChoice }) => !!portfolioChoice}
        />
      </div>
      <div className="tw-col-span-12 xl:tw-col-span-9">{main}</div>
    </div>
  );
}

export default PortfolioImpact;
