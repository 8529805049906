import store from 'store2';
import _ from 'lodash';
import {
  getInvestmentAnalysis,
  getInvestmentAnalysisHeader,
  getInvestmentAnalysisWithCustomData,
} from 'services/analysis';

const CUSTOM_DATA = 'custom-data';

export async function fetchData(params) {
  if (!params) return { success: false, error: 'not sufficient params' };

  if (_.isArray(params.product)) params.product = params.product[0];
  if (_.isArray(params.database)) params.database = params.database[0];

  if (_.isBoolean(params.custom) ? params.custom : params.custom === 'true') {
    const customData = store.get(CUSTOM_DATA);

    const [res, err] = await getInvestmentAnalysisWithCustomData({ ...customData, ...params });

    if (err) {
      return { success: false, error: err };
    } else {
      return { success: true, info: res[0], riskPerf: res[1], detail: res[2] };
    }
  } else {
    const [infoRes, dataRes] = await Promise.all([getInvestmentAnalysisHeader(params), getInvestmentAnalysis(params)]);
    const [info, infoErr] = infoRes;
    const [data, dataErr] = dataRes;

    if (infoErr || dataErr) {
      return { success: false, error: infoErr || dataErr };
    } else {
      return { success: true, info, riskPerf: data[0], detail: data[1] };
    }
  }
}
