import React, { useState } from 'react';
import { Modal, Button, Label, FormGroup, Input } from 'reactstrap';
import { updateConsent } from 'services/auth';

function ConsentModal({ isOpen, onClose }) {
  const [consent, setConsent] = useState(false);

  const handleSubmit = async () => {
    if (!consent) return;

    await updateConsent();
    await onClose();
  };

  return (
    <Modal isOpen={isOpen} size="lg" className="tw-mt-2 tw-pt-0">
      <div className="modal-header">
        <h5 className="modal-title" id="myModalLabel">
          DISCLAIMER
        </h5>
      </div>
      <div className="modal-body">
        <h6>IMPORTANT NOTICE GENERAL DISCLAIMER</h6>
        <p>
          You should read the below Terms of Use of FLARE™ (the “Terms of Use”) before proceeding, as they explain
          restrictions imposed by law and contract on your access to the information contained in this portal. It shall
          be your responsibility to be aware of and to observe all applicable laws and regulations of your own
          jurisdiction.
        </p>
        <h6>GENERAL DISCLAIMER FOR ALL USERS OF FLARE™</h6>
        <p>
          Users of the FLARE™, as well as those who have access to it, do so at their own initiative and are responsible
          for compliance with the Terms of Use as well as their respective applicable laws and regulations.
        </p>
      </div>
      <div className="modal-footer tw-justify-between tw-px-10">
        <FormGroup check>
          <Label check>
            <Input checked={consent} type="checkbox" onChange={(e) => setConsent(e.target.checked)} />
            <span className="tw-absolute tw-left-0 tw-top-0 form-check-sign" />
            <span className="tw-select-none">
              Click to accept the <b>Terms of Use</b> above.
            </span>
          </Label>
        </FormGroup>

        <Button
          className="btn-round"
          color="info"
          data-dismiss="modal"
          type="button"
          disabled={!consent}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
}

export default ConsentModal;
