export const allowZeroHundred = (value) => {
  let val = parseInt(value, 10);

  if (isNaN(val)) {
    val = 0;
  } else {
    val = Math.min(Math.max(val, 0), 100);
  }

  return String(val);
};
