import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { toDeci, toDeciTable, toPercTable, formatDate } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';

function RiskExposure({ data: { data, table_dates }, params, options = {}, notify, session }) {
  const [exposureOptions, setExposureOptions] = useState([]);
  const [selectedExposureOption, setSelectedExposureOption] = useState(null);
  const [ratioOptions, setRatioOptions] = useState([]);
  const [selectedRatioOption, setSelectedRatioOption] = useState(null);

  useEffect(() => {
    if (!data) return;

    const _exposureOptions = optionizeAll(_.keys(data));
    setExposureOptions(_exposureOptions);

    if (_exposureOptions.length > 0) {
      setSelectedExposureOption(_exposureOptions[0]);

      const _ratioOptions = optionizeAll(data[_exposureOptions[0].value].index);
      setRatioOptions(_ratioOptions);

      if (_ratioOptions.length > 0) {
        setSelectedRatioOption(_ratioOptions[0]);
      }
    }
  }, [data]);

  return (
    <>
      {selectedExposureOption && (
        <>
          <div className="tw-col-span-6">
            <div className="tw-text-left tw-font-bold">SELECT RISK EXPOSURE</div>
            <Select
              className="react-select tw-w-[400px]"
              value={selectedExposureOption}
              onChange={setSelectedExposureOption}
              options={exposureOptions}
            />
          </div>
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Exposure: {selectedExposureOption.label}
              <TimePeriod dates={table_dates[selectedExposureOption.value]} />
            </CardHeader>
            <CardBody>
              <TightTable
                suffix="risk-performance-risk-exposure"
                data={data[selectedExposureOption.value]}
                indexName=""
                indexWidth="250px"
                cellFormatter={(value, row, col, rowInd, colInd, flip) => {
                  const label = flip ? col : row;
                  return ['R2'].includes(label) ? toPercTable(value, { precision: 0 }) : toDeciTable(value);
                }}
                title={[
                  `Exposure: ${selectedExposureOption.label}`,
                  formatDates(table_dates[selectedExposureOption.value]),
                ]}
                {...options.riskExposureTable}
              />
            </CardBody>
          </Card>
          <div className="tw-grid tw-grid-cols-6 tw-gap-4">
            <div className="tw-col-start-2 tw-col-span-4">
              <div className="tw-flex">
                <div className="tw-mr-1">
                  <div className="tw-text-left tw-font-bold">SELECT METRIC</div>
                  <Select
                    className="react-select tw-w-[400px]"
                    value={selectedRatioOption}
                    onChange={setSelectedRatioOption}
                    options={ratioOptions}
                  />
                </div>
                <div>
                  <div className="tw-text-left tw-font-bold">SELECT RISK EXPOSURE</div>
                  <Select
                    className="react-select tw-w-[400px]"
                    value={selectedExposureOption}
                    onChange={setSelectedExposureOption}
                    options={exposureOptions}
                  />
                </div>
              </div>

              {selectedRatioOption && (
                <Card>
                  <CardHeader tag="h4" className="mt-0 text-center">
                    {selectedRatioOption.label}
                    <div className="tw-text-lg">Exposure: {selectedExposureOption.label}</div>
                  </CardHeader>
                  <CardBody>
                    <TightChart
                      title={selectedRatioOption.label}
                      data={data[selectedExposureOption.value]}
                      cols={[selectedRatioOption.value]}
                      flip={true}
                      wraplabelX={true}
                      dataType={selectedRatioOption.value === 'R2' ? 'whole-percentage' : 'decimal'}
                    />
                  </CardBody>
                </Card>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default RiskExposure;
