import React, { useState, useEffect, useMemo } from 'react';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import _ from 'lodash';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import Select from 'react-select';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import PieChart from 'components/PieChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import AssetInfo from './AssetInfo';
import { optionize, optionizeAll } from 'helpers/select';
import { toPerc, toPercTable, formatDate } from 'helpers/formatter';
import { removeEmptyCols, flipDF } from 'helpers/data-frame';
import { getAdvancedAnalyticsRiskContribution } from 'services/analysis';

const SpinnerWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const includeBenchmarkOptions = [
  { label: 'Include Benchmark', value: true },
  { label: 'Exclude Benchmark', value: false },
];

function SingleAssetRiskBudget({ params, notify, session }) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [flippedAsset, setFlippedAsset] = useState(null);
  const [flippedBench, setFlippedBench] = useState(null);
  const [compactAsset, setCompactAsset] = useState(null);
  const [durations, setDurations] = useState();
  const [selectedDuration, setSelectedDuration] = useState();
  const [includeBenchmarkOption, setIncludeBenchmarkOption] = useState(includeBenchmarkOptions[0]);

  const fetchResult = async () => {
    if (!params) return;

    setLoading(true);
    const [data, err] = await getAdvancedAnalyticsRiskContribution(params);
    if (err) {
      notify.danger({ message: <div>{err}</div> });
    } else {
      setResult(data);
      const _compactAsset = removeEmptyCols(data.asset);
      const _durations = _compactAsset.columns.filter((v) => v !== 'Since Inception');
      setCompactAsset(_compactAsset);
      setDurations(optionizeAll(_durations, { labelFormatter: _.upperFirst }));
      if (_durations.length > 0) setSelectedDuration(optionize(_durations[0], _.upperFirst(_durations[0])));
      setFlippedAsset(flipDF(data.asset));
      setFlippedBench(flipDF(data.bench));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (params) fetchResult();
  }, [params]);

  const comparisonTightDict = useMemo(() => {
    if (!result) return null;

    const ind = flippedAsset.index.findIndex((v) => v === selectedDuration.value);
    if (ind === -1) return null;

    const index = [result.info['Fund Name'], result.info.Benchmark];
    const columns = flippedAsset.columns.concat();
    const data = [flippedAsset.data[ind], flippedBench.data[ind]];

    return { index, columns, data };
  }, [flippedAsset, flippedBench, selectedDuration]);

  if (loading || !result || !comparisonTightDict) {
    return (
      <SpinnerWrapper>
        <Spinner>Loading...</Spinner>
      </SpinnerWrapper>
    );
  }

  return (
    <>
      <AssetInfo
        asset={params.product}
        database={params.database}
        data={result}
        valueMap={{
          name: 'info.Fund Name',
          description: 'info.Fund Description',
          benchmark: 'info.Benchmark',
          geography: 'info.geography',
          startDate: 'info.Dates.0',
          endDate: 'info.Dates.1',
          cumulative: 'info.cumul_return',
          cagr: 'info.cagr',
          deviation: 'info.std',
        }}
      />

      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Risk Contribution
          <TimePeriod
            dates={[_.get(result, 'info.Since Inception Date.0'), _.get(result, 'info.Since Inception Date.1')]}
          />
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="risk-budget"
            data={result.asset}
            cols={(cols) => cols.filter((col) => col !== 'Commonolized')}
            indexName=""
            dataType="percentage"
            indexFormatter={(value) =>
              ['Total Risk'].includes(value) ? { style: { backgroundColor: '#f08080' }, value } : value
            }
            cellFormatter={(value, row, col) =>
              ['Total Risk'].includes(row)
                ? { style: { backgroundColor: '#f08080' }, value: toPerc(value) }
                : toPercTable(value)
            }
            compact
            title={[
              'Risk Contribution',
              formatDates([_.get(result, 'info.Since Inception Date.0'), _.get(result, 'info.Since Inception Date.1')]),
            ]}
          />
        </CardBody>
      </Card>
      {durations && durations.length > 0 && (
        <>
          <Row>
            <Col xl="8" className="offset-xl-2">
              <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
              <Select
                className="react-select mw-200px"
                name="duration"
                value={selectedDuration}
                onChange={setSelectedDuration}
                options={durations}
              />
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  Risk Contribution
                </CardHeader>
                <CardBody>
                  <PieChart
                    title="Risk Contribution"
                    data={result.asset}
                    field={selectedDuration.value}
                    dataType="percentage"
                    containerStyle={{ maxWidth: '500px', maxHeight: '500px' }}
                    filter={(labels) => labels.filter((label) => !['Total Risk', 'R2'].includes(label))}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Risk Contribution
              <div className="small">Time Period ({selectedDuration.label.replace('Y', ' Year')})</div>
              {result.info.date_range[selectedDuration.value] && (
                <TimePeriod dates={result.info.date_range[selectedDuration.value]} />
              )}
            </CardHeader>
            <CardBody>
              <TightTable
                suffix="risk-budget-period"
                data={result.asset}
                data2={result.bench}
                indexName="Year"
                dataType="percentage"
                cols={[selectedDuration.value]}
                cols2={[selectedDuration.value]}
                rowHeader={{ text: result.info['Fund Name'], style: { color: 'black', backgroundColor: '#C6E0B4' } }}
                rowHeader2={{
                  text: result.info.Benchmark,
                  style: { color: 'black', backgroundColor: 'rgb(251,198,88,0.5)' },
                }}
                hideIndexTable
                compact
                flip
                title={[
                  'Risk Contribution',
                  `Time Period (${selectedDuration.label.replace('Y', ' Year')})`,
                  result.info.date_range[selectedDuration.value] &&
                    formatDates(result.info.date_range[selectedDuration.value]),
                ]}
              />
            </CardBody>
          </Card>
        </>
      )}
      <Row>
        <Col xl="8" className="offset-xl-2">
          <div className="tw-text-left tw-font-bold">SELECT</div>
          <Select
            className="react-select mw-200px"
            name="includeBenchmark"
            value={includeBenchmarkOption}
            onChange={setIncludeBenchmarkOption}
            options={includeBenchmarkOptions}
          />
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Risk Contribution
            </CardHeader>
            <CardBody>
              <TightChart
                title="Risk Contribution"
                data={comparisonTightDict}
                options={{ indexAxis: 'y' }}
                base="cols"
                cols={(cols) => cols.filter((cols) => !['R2'].includes(cols))}
                rows={
                  includeBenchmarkOption.value
                    ? [result.info['Fund Name'], result.info.Benchmark]
                    : [result.info['Fund Name']]
                }
                dataType="percentage"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default SingleAssetRiskBudget;
