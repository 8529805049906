import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { Card, CardHeader, CardBody, Label, TabPane, Row, Col } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import LineChart from 'components/LineChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { getTightColIndexByKey } from 'helpers/chart';
import { removeEmptyCols } from 'helpers/data-frame';
import { toDeci, toDeciTable, toPercTable, tablePresets } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';
import { drawdownMetrics } from 'helpers/meta';

function DrawdownAnalysis({ data, series, params, notify, session }) {
  const [drawdown_summary_df_a, drawdown_summary_df_b, table_dates, date_range_dict] = data;

  const [durationOptions, setDurationOptions, selectedDurationOption, setSelectedDurationOption] = useArrayState();
  const [metricOptions, setMetricOptions, selectedMetricOption, setSelectedMetricOption] = useArrayState({
    initialItems: drawdownMetrics,
  });

  useEffect(() => {
    if (!data) return;

    const compactAsset = removeEmptyCols(drawdown_summary_df_a);
    const _durations = compactAsset.columns;
    setDurationOptions(optionizeAll(_durations));
  }, [data]);

  const drawdownChart = useMemo(() => {
    if (!data) return null;

    const data1 = series[0];
    const data2 = series[1];

    const firstAssetStartDate = new Date(_.sortBy(_.keys(data1))[0]).getTime();

    // remove all benchmark values that starts before the asset start date
    _.each(_.sortBy(_.keys(data2)), (date) => {
      const d = new Date(date).getTime();
      if (d < firstAssetStartDate) delete data2[date];
    });

    _.each(data1, (val, key) => {
      if (_.isNil(data2[key])) data2[key] = -1;
    });

    _.each(data2, (val, key) => {
      if (_.isNil(data1[key])) data1[key] = -1;
    });

    const allDates = _.sortBy(_.keys(data1));

    const dataLabel1 = params.product;

    const getValues = (dataMap) =>
      allDates.map((date) => {
        return dataMap[date] === -1 ? undefined : dataMap[date] * 100;
      });

    const datasets = [
      {
        label: dataLabel1,
        data: getValues(data1),
        fill: false,
        backgroundColor: 'rgba(51,102,204,0.2)',
        borderColor: 'rgb(51,102,204)',
        borderWidth: 2,
        tension: 0,
        pointRadius: 3,
        pointHoverRadius: 4,
      },
    ];

    if (params.benchmark) {
      datasets.push({
        label: params.benchmark,
        data: getValues(data2),
        fill: false,
        backgroundColor: 'rgba(220,57,18,0.2)',
        borderColor: 'rgb(220,57,18)',
        borderWidth: 2,
        tension: 0,
        pointRadius: 3,
        pointHoverRadius: 4,
      });
    }

    const _data = {
      labels: allDates.map((v) => v.substring(0, 7)),
      datasets,
    };

    const options = {
      plugins: {
        legend: { display: true },
        tooltip: {
          enabled: true,
          callbacks: {
            label: (item) => {
              return `${item.label}: ${toDeci(item.raw)}%`;
            },
          },
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        x: {
          title: {
            display: false,
          },
          ticks: {
            autoSkip: true,
            maxRotation: 0,
            minRotation: 0,
            color: 'black',
          },
          beginAtZero: false,
        },
        y: {
          title: {
            display: true,
          },
          ticks: {
            callback: (val) => toDeci(val, { precision: 1, absolute: true, suffix: '%' }),
            color: ({ tick }) => (tick.value < 0 ? 'red' : 'black'),
          },
          beginAtZero: false,
        },
      },
    };

    return { data: _data, options };
  }, [data]);

  const comparisonDf1 = useMemo(() => {
    if (!data || !selectedDurationOption) return null;

    const data1 = drawdown_summary_df_a;
    const data2 = drawdown_summary_df_b;

    const colIndex = getTightColIndexByKey(data1, selectedDurationOption.value);

    return {
      index: data1.index,
      columns: params.benchmark ? [params.product, params.benchmark] : [params.product],
      data: data1.index.map((rowName, i) => {
        return [data1.data[i][colIndex], data2.data[i][colIndex]];
      }),
    };
  }, [data, selectedDurationOption]);

  if (!selectedMetricOption || !comparisonDf1) return;

  const dates = [
    _.get(date_range_dict, `${selectedMetricOption.value}.0`, _.get(table_dates, '0')),
    _.get(date_range_dict, `${selectedMetricOption.value}.1`, _.get(table_dates, '1')),
  ];

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Drawdown Analysis
          <TimePeriod dates={table_dates} />
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="drawdown-analysis"
            data={drawdown_summary_df_a}
            rowHeader={{ text: params.product, style: { color: 'black', backgroundColor: '#C6E0B4' } }}
            indexName="Time Interval"
            cellFormatter={(value, row, col) =>
              ['CAGR', 'Standard Deviation', 'Maximum Drawdown'].includes(row)
                ? toPercTable(value)
                : ['Calmar Ratio', 'MAR Ratio (RoMaD)', 'Sterling Ratio'].includes(row)
                ? toDeciTable(value)
                : value
            }
            compact
            title={['Drawdown Analysis', formatDates(table_dates)]}
          />
        </CardBody>
      </Card>

      <Row>
        <Col xl="8" className="offset-xl-2">
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Drawdowns History
            </CardHeader>
            <CardBody>
              <LineChart
                title="Drawdowns History"
                data={drawdownChart.data}
                options={drawdownChart.options}
                plugins={[]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xl="6">
          <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
          <Select
            className="react-select mw-200px"
            name="durations"
            value={selectedDurationOption}
            onChange={setSelectedDurationOption}
            options={durationOptions}
          />
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Drawdown Analysis
              <TimePeriod dates={dates} />
            </CardHeader>
            <CardBody>
              <TightTable
                suffix="drawdowns-analysis"
                data={comparisonDf1}
                indexName=""
                cellFormatter={(value, row, col) =>
                  ['CAGR', 'Standard Deviation', 'Maximum Drawdown'].includes(row)
                    ? toPercTable(value)
                    : ['Calmar Ratio', 'MAR Ratio (RoMaD)', 'Sterling Ratio'].includes(row)
                    ? toDeciTable(value)
                    : value
                }
                compact
                title={['Drawdown Analysis', formatDates(dates)]}
              />
            </CardBody>
          </Card>
        </Col>

        <Col xl="6">
          <div className="tw-text-left tw-font-bold">SELECT RISK METRICS</div>
          <Select
            className="react-select mw-300px"
            name="drawdownMetrics"
            value={selectedMetricOption}
            onChange={setSelectedMetricOption}
            options={metricOptions}
          />
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Drawdown Analysis
              <TimePeriod dates={dates} />
            </CardHeader>
            <CardBody>
              <TightChart
                title="Drawdown Analysis"
                data={comparisonDf1}
                rows={selectedMetricOption.value.keys}
                dataType={selectedMetricOption.value.type}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default DrawdownAnalysis;
