import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import { read, utils } from 'xlsx';
import assetTemplateFile from 'assets/files/single-asset-template.xlsx';
import multiAssetTemplateFile from 'assets/files/multi-asset-template.xlsx';
import { AdminContext } from 'layouts/Admin';

function AssetUpload({ onUploaded, tag }) {
  const context = useContext(AdminContext);
  if (!context?.session?.permissions['upload-data']) return null;

  const fileRef = React.useRef();

  const handleChange = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const maxSize = 20 * 1024 * 1024; // 20MB in bytes

    if (file.size > maxSize) {
      alert('File size exceeds 20MB. Please choose a smaller file.');
      fileRef.current.value = null;
      return;
    }

    const cont = await file.arrayBuffer();

    const wb = read(cont);
    const ws = wb.Sheets[wb.SheetNames[0]];
    var jsa = utils.sheet_to_json(ws, { header: 1 });

    const index = [];
    const columns = [];
    const data = [];

    for (let x = 0; x < jsa.length; x++) {
      const row = jsa[x];
      if (row.length === 0) break;

      if (x === 0) {
        for (let y = 0; y < row.length; y++) {
          if (y === 0) continue;
          columns.push(row[y]);
        }
      } else {
        index.push(row[0]);
        data.push(row.slice(1));
      }
    }

    fileRef.current.value = null;
    onUploaded({ index, columns, data });
  };

  const handleClick = () => {
    fileRef.current.click();
  };

  const handleRemove = () => {
    fileRef.current.value = null;
  };

  return (
    <div className="tw-w-full tw-text-right">
      <div className="fileinput tw-text-center tw-p-0 tw-m-0">
        <input type="file" accept=".xlsx,.csv" onChange={handleChange} ref={fileRef} />
        <Button className="btn" color="info" onClick={() => handleClick()}>
          <i className="fa fa-upload" aria-hidden="true"></i>
          Upload Assets
        </Button>
        <a
          className="hover:tw-no-underline tw-ml-1 tw-font-semibold tw-px-[16px] tw-py-[9px] tw-border tw-border-solid tw-border-[#191970] tw-text-[#191970] tw-bg-white hover:tw-text-[#191970] focus:tw-text-[#191970]"
          target="_blank"
          href={tag === 'ic' ? multiAssetTemplateFile : assetTemplateFile}
        >
          <i className="fa fa-download" aria-hidden="true"></i>
          Download Template
        </a>
      </div>
    </div>
  );
}

AssetUpload.propTypes = {};

export default AssetUpload;
