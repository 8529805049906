import { Fragment, useEffect, useState, createContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import classnames from 'classnames';

const defaultMenus = [
  {
    value: 'Investment Analysis',
    label: 'Investment Analysis',
    navigate: { pathname: '/analyzers/asset-discovery-ia', search: 'minass=true' },
    permission: 'ia',
  },
  {
    value: 'Investment Comparison',
    label: 'Investment Comparison',
    navigate: { pathname: '/analyzers/asset-discovery-ic', search: 'multi=true&minass=true' },
    permission: 'ic',
  },
  {
    value: 'Portfolio Analysis',
    label: 'Portfolio Analysis',
    permission: 'pa',
    subs: [
      {
        value: 'Build and Analyze',
        label: 'Build and Analyze',
        navigate: { pathname: '/build-portfolio', search: 'single=true' },
      },
      { value: 'Compare', label: 'Compare', navigate: { pathname: '/compare-portfolios' } },
    ],
  },
  // {
  //   value: 'Advanced Analytics',
  //   label: 'Advanced Analytics',
  //   navigate: { pathname: '/analyzers/asset-discovery-aa' },
  //   permission: 'aa',
  // },
];

const chartsMenus = [
  {
    value: 'Risk & Return',
    label: 'Risk & Return',
    navigate: { pathname: '/charts/risk-return' },
    permission: 'chart-risk-return',
  },
  {
    value: 'Risk Ratios',
    label: 'Risk Ratios',
    navigate: { pathname: '/charts/risk-ratios' },
    permission: 'chart-risk-ratios',
  },
  {
    value: 'Value at Risk',
    label: 'Value at Risk',
    navigate: { pathname: '/charts/var' },
    permission: 'chart-var',
  },
  {
    value: 'Beta & Correlation',
    label: 'Beta & Correlation',
    navigate: { pathname: '/charts/beta-correl' },
    permission: 'chart-beta-correl',
  },
];

const correlationMenus = [
  {
    value: 'Asset Correlations',
    label: 'Asset Correlations',
    navigate: { pathname: '/co/asset-correlations' },
    permission: 'correlation-rolling',
  },
  {
    value: 'Correlation Matrix',
    label: 'Correlation Matrix',
    navigate: { pathname: '/co/correlation-matrix' },
    permission: 'correlation-matrix',
  },
  {
    value: 'Rolling Correlation',
    label: 'Rolling Correlation',
    navigate: { pathname: '/co/rolling-correlation' },
    permission: 'correlation-assets',
  },
];

function AnalyzerMenus({ session }) {
  const location = useLocation();
  const navigate = useNavigate();

  const permissions = session?.permissions || {};
  if (!permissions['member']) return null;

  let allowedMenus = [];
  if (location.pathname === '/quickview') {
    allowedMenus = [];
  } else if (location.pathname.startsWith('/charts')) {
    allowedMenus = chartsMenus.filter((menu) => permissions[menu.permission]);
  } else if (location.pathname.startsWith('/co')) {
    allowedMenus = correlationMenus.filter((menu) => permissions[menu.permission]);
  } else {
    allowedMenus = defaultMenus.filter((menu) => permissions[menu.permission]);
  }

  return (
    <>
      {allowedMenus.map((menu) => {
        return menu.navigate ? (
          <button
            className="tw-text-[#191870] tw-border-0 tw-bg-transparent tw-font-bold tw-text-sm ml-2"
            type="button"
            onClick={() => {
              navigate(menu.navigate, { replace: false });
            }}
          >
            {menu.label}
          </button>
        ) : (
          <Menu as="span" className="tw-relative">
            <Menu.Button
              className="tw-text-[#191870] tw-border-0 tw-bg-transparent tw-font-bold tw-text-sm ml-2"
              type="button"
            >
              {menu.label}
            </Menu.Button>
            <Menu.Items className="tw-absolute tw-border-gray-300 tw-border-solid tw-border tw-right-0 tw-z-10 tw-mt-2 tw-w-48 tw-origin-top-right tw-rounded-md tw-bg-white tw-py-1 tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5">
              {menu.subs.map((sub) => {
                return (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        className="tw-border-0 tw-bg-transparent tw-text-sm ml-2 tw-block tw-p-2 hover:tw-underline"
                        onClick={() => {
                          navigate(sub.navigate, { replace: false });
                        }}
                      >
                        {sub.label}
                      </button>
                    )}
                  </Menu.Item>
                );
              })}
            </Menu.Items>
          </Menu>
        );
      })}
    </>
  );
}

export default AnalyzerMenus;
