import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import _ from 'lodash';
import { Card, CardHeader, CardBody, TabPane, Row, Col } from 'reactstrap';
import Select from 'react-select';
import PageTabs from 'components/PageTabs';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import AssetInfo from './AssetInfo';
import { optionize, optionizeAll } from 'helpers/select';
import { toDeciTable, toPercTable, formatDate } from 'helpers/formatter';
import { removeEmptyCols, flipDF } from 'helpers/data-frame';
import { parseSearchParams } from 'helpers/location';
import { riskMeasuresPerc, riskRatioGroups } from 'helpers/meta';
import { getAdvancedAnalyticsBenchmark } from 'services/analysis';

const SpinnerWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

function SingleAssetBenchmarkComparison({ params, notify, session }) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [durations, setDurations] = useState();
  const [selectedDuration, setSelectedDuration] = useState();
  const [flippedAsset, setFlippedAsset] = useState(null);
  const [flippedBench, setFlippedBench] = useState(null);
  const [selectedRiskGroup, setSelectedRiskGroup] = useState(riskRatioGroups[0]);
  const [riskMeasures, setRiskMeasures] = useState();
  const [selectedRiskMeasure, setSelectedRiskMeasure] = useState();
  const [regressionMeasures, setRegressionMeasures] = useState();
  const [selectedRegressionMeasure, setSelectedRegressionMeasure] = useState();

  const fetchResult = async () => {
    if (!params) return;

    setLoading(true);
    const [data, err] = await getAdvancedAnalyticsBenchmark(params);
    if (err) {
      notify.danger({ message: <div>{err}</div> });
    } else {
      setResult(data);
      const _compactAsset = removeEmptyCols(data.asset);
      const _durations = _compactAsset.columns;
      setDurations(optionizeAll(_durations, { labelFormatter: _.upperFirst }));
      if (_durations.length > 0) setSelectedDuration(optionize(_durations[0], _.upperFirst(_durations[0])));
      setFlippedAsset(flipDF(data.asset));
      setFlippedBench(flipDF(data.bench));

      const _riskMeasures = data.comp.index;
      setRiskMeasures(optionizeAll(_riskMeasures, { useIndex: true }));
      if (_riskMeasures.length > 0) setSelectedRiskMeasure(optionize(0, _riskMeasures[0]));

      const _regressionMeasures = data.reg.index;
      setRegressionMeasures(optionizeAll(_regressionMeasures, { useIndex: true }));
      if (_regressionMeasures.length > 0) setSelectedRegressionMeasure(optionize(0, _regressionMeasures[0]));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (params) fetchResult();
  }, [params]);

  const joinTightDict = useMemo(() => {
    if (!result) return null;

    const ind = result.asset.columns.findIndex((v) => v === selectedDuration.value);

    const index = result.asset.index.concat();
    const columns = [result.info['Fund Name'], result.info['Benchmark Name']];
    const data = index.map((row, i) => [result.asset.data[i][ind], result.bench.data[i][ind]]);

    return { index, columns, data };
  }, [result, selectedDuration]);

  if (loading || !result) {
    return (
      <SpinnerWrapper>
        <Spinner>Loading...</Spinner>
      </SpinnerWrapper>
    );
  }

  return (
    <>
      <AssetInfo
        asset={params.product}
        database={params.database}
        data={result}
        valueMap={{
          name: 'info.Fund Name',
          description: 'info.Fund Description',
          benchmark: 'info.Benchmark Name',
          geography: 'info.Geography Selection',
          startDate: 'info.Dates.0',
          endDate: 'info.Dates.1',
          cumulative: 'info.cumul_return',
          cagr: 'info.cagr',
          deviation: 'info.std',
        }}
      />

      <PageTabs
        options={[
          { value: 'comparison', label: 'Comparison', permission: 'aa-benchmark-comparison' },
          { value: 'regression', label: 'Regression', permission: 'aa-benchmark-regression' },
        ]}
        session={session}
      >
        <TabPane tabId="comparison" role="tabpanel">
          {durations && durations.length > 0 && (
            <>
              <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
              <Select
                className="react-select mw-200px"
                name="duration"
                value={selectedDuration}
                onChange={setSelectedDuration}
                options={durations}
              />
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  Benchmark Comparison
                  <div className="small">Time Period ({selectedDuration.label.replace('Y', ' Year')})</div>
                  <TimePeriod dates={result.info.date_range[selectedDuration.value]} />
                </CardHeader>
                <CardBody>
                  <TightTable
                    suffix="comparison-benchmark-comparison"
                    data={flippedAsset}
                    data2={flippedBench}
                    indexName="Year"
                    dataType="percentage"
                    rows={[selectedDuration.value]}
                    rows2={[selectedDuration.value]}
                    rowHeader={{
                      text: result.info['Fund Name'],
                      style: { color: 'black', backgroundColor: '#C6E0B4' },
                    }}
                    rowHeader2={{
                      text: result.info['Benchmark Name'],
                      style: { color: 'black', backgroundColor: 'rgb(251,198,88,0.5)' },
                    }}
                    cellFormatter={(value, row, col) =>
                      riskMeasuresPerc.includes(col) ? toPercTable(value) : toDeciTable(value)
                    }
                    hideIndexTable={true}
                    compact
                    title={[
                      'Benchmark Comparison',
                      `Time Period (${selectedDuration.label.replace('Y', ' Year')})`,
                      formatDates(result.info.date_range[selectedDuration.value]),
                    ]}
                  />
                </CardBody>
              </Card>
              <Row>
                <Col xl="8" className="offset-xl-2">
                  <div className="tw-text-left tw-font-bold">SELECT RISK METRICS</div>
                  <Select
                    className="react-select mw-300px"
                    name="asset"
                    placeholder="Please select an risk ratio group..."
                    value={selectedRiskGroup}
                    onChange={setSelectedRiskGroup}
                    options={riskRatioGroups}
                  />

                  <Card>
                    <CardBody>
                      <TightChart
                        data={joinTightDict}
                        // base="cols"
                        rows={selectedRiskGroup.value.keys.map((key) =>
                          key === 'Sortino Ratio' ? 'Sortino (MAR RF)' : key,
                        )}
                        dataType={selectedRiskGroup.value.type}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Comparison (vs Benchmark)
            </CardHeader>
            <CardBody>
              <TightTable
                suffix="comparison-benchmark"
                data={result.comp}
                indexName=""
                cellFormatter={(value, row, col) =>
                  ['Excess Return (Cumulative)', 'Excess Return (CAGR)'].includes(row)
                    ? toPercTable(value)
                    : toDeciTable(value)
                }
                compact
                title="Comparison (vs Benchmark)"
              />
            </CardBody>
          </Card>
          {riskMeasures && riskMeasures.length > 0 && (
            <Row>
              <Col xl="8" className="offset-xl-2">
                <div className="tw-text-left tw-font-bold">SELECT</div>
                <Select
                  className="react-select mw-300px"
                  name="riskMeasure"
                  placeholder="Please select an risk measure..."
                  value={selectedRiskMeasure}
                  onChange={setSelectedRiskMeasure}
                  options={riskMeasures}
                />

                <Card>
                  <CardBody>
                    <TightChart
                      data={result.comp}
                      rows={[selectedRiskMeasure.label]}
                      base="cols"
                      dataType={
                        ['Excess Return (Cumulative)', 'Excess Return (CAGR)'].includes(selectedRiskMeasure.label)
                          ? 'percentage'
                          : 'decimal'
                      }
                      labelFormatter={_.upperFirst}
                      omitEmpty={true}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </TabPane>
        <TabPane tabId="regression" role="tabpanel">
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Regression vs (Benchmark)
            </CardHeader>
            <CardBody>
              <TightTable
                suffix="regression-benchmark"
                data={result.reg}
                indexName=""
                dataType="decimal"
                cellFormatter={(value, row, col) =>
                  ['Tracking Error', 'Alpha', 'R2'].includes(row) ? toPercTable(value) : toDeciTable(value)
                }
                compact
                title="Regression vs (Benchmark)"
              />
            </CardBody>
          </Card>
          <Row>
            <Col xl="8" className="offset-xl-2">
              <Card>
                <CardBody>
                  <TightChart
                    data={result.reg}
                    base="cols"
                    rows={['Up Market Capture Ratio', 'Down Market Capture Ratio']}
                    dataType="decimal"
                    labelFormatter={_.upperFirst}
                    omitEmpty={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {regressionMeasures && regressionMeasures.length > 0 && (
            <Row>
              <Col xl="8" className="offset-xl-2">
                <div className="tw-text-left tw-font-bold">SELECT</div>
                <Select
                  className="react-select mw-300px"
                  name="riskMeasure"
                  placeholder="Please select an risk measure..."
                  value={selectedRegressionMeasure}
                  onChange={setSelectedRegressionMeasure}
                  options={regressionMeasures}
                />
                <Card>
                  <CardBody>
                    <TightChart
                      data={result.reg}
                      base="cols"
                      rows={[selectedRegressionMeasure.label]}
                      dataLabels={true}
                      omitEmpty={true}
                      labelFormatter={_.upperFirst}
                      dataType={
                        ['Tracking Error', 'Alpha', 'R2'].includes(selectedRegressionMeasure.label)
                          ? 'percentage'
                          : 'decimal'
                      }
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </TabPane>
      </PageTabs>
    </>
  );
}

export default SingleAssetBenchmarkComparison;
