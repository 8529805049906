import React, { useEffect, useState } from 'react';
import { Modal, Button, Label, FormGroup, Input } from 'reactstrap';
import { createClientPortfolio } from 'services/client-portfolio';
import {
  DEFAULT_PORTFOLIO_1,
  DEFAULT_PORTFOLIO_2,
  DEFAULT_PORTFOLIO_3,
} from '../../../views/portfolio-analysis/BuildPortfolio';

function PortfolioSaveModal({ isOpen, onClose, portfolios, data, confirm }) {
  const [name, setName] = useState('');

  useEffect(() => {
    setName('');
  }, [isOpen]);

  const handleSubmit = async () => {
    if ([DEFAULT_PORTFOLIO_1, DEFAULT_PORTFOLIO_2, DEFAULT_PORTFOLIO_3].includes(name)) {
      confirm({
        content: `"${name}" is reserved name. Please use another one.`,
      });

      return;
    }

    const nameTaken = portfolios.find((portfolio) => portfolio.name === name);
    if (nameTaken) {
      confirm({
        content: `This will overwrite the existing portfolio "${name}". Are you sure you would like to continue with this overwrite?`,
        callback: async (result) => {
          if (result !== 'confirmed') return;

          await createClientPortfolio(name, data);
          await onClose();
        },
      });
    } else {
      const [saved, err] = await createClientPortfolio(name, data);
      await onClose(saved);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={() => onClose()} size="md" className="tw-mt-2 tw-pt-0">
        <div className="modal-header">
          <h5 className="modal-title">Save Portfolio</h5>
        </div>
        <div className="modal-body">
          <FormGroup>
            <Input
              placeholder={`Name...`}
              type="text"
              value={name}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onChange={(e) => {
                setName(e.target.value);
              }}
              maxLength="50"
            />
          </FormGroup>
        </div>
        <div className="modal-footer tw-justify-center">
          <Button
            className="btn-round"
            color="info"
            data-dismiss="modal"
            type="button"
            disabled={!name}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default PortfolioSaveModal;
