import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import styled from 'styled-components';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Row,
  Col,
  Label,
  Input,
  Button,
  Spinner,
} from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { toDeci, toPerc, toDeciTable, toPercTable } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';
import { riskMeasuresDeci2 } from 'helpers/meta';
import { allowZeroHundred } from 'helpers/number';
import { getInvestmentAnalysisImpact, getInvestmentAnalysisImpactCustom } from 'services/analysis';

const StyledLabel = styled(Label)`
  font-size: 1rem !important;
`;

export const riskRatioGroups = [
  {
    label: 'CAGR and Std Dev',
    value: { keys: ['CAGR', 'Standard Deviation'], type: 'percentage' },
  },
  {
    label: 'Beta and Correlation',
    value: { keys: ['Beta', 'Correlation'], type: 'decimal' },
  },
  {
    label: 'CVaR and Max DD',
    value: { keys: ['CVaR 95%', 'Maximum Drawdown'], type: 'percentage' },
  },
  {
    label: 'Std Dev and Downside Dev',
    value: { keys: ['Standard Deviation', 'Downside Deviation'], type: 'percentage' },
  },
  {
    label: 'Skew and Kurtosis',
    value: { keys: ['Skew', 'Kurtosis'], type: 'decimal' },
  },
  {
    label: 'Sharpe and Sortino',
    value: { keys: ['Sharpe Ratio', 'Sortino Ratio'], type: 'decimal' },
  },
  {
    label: 'MAR and Omega',
    value: { keys: ['MAR Ratio (RoMaD)', 'Omega Ratio'], type: 'decimal' },
  },
  {
    label: 'Ratios (Sharpe, Sortino, Omega)',
    value: { keys: ['Sharpe Ratio', 'Sortino Ratio', 'Omega Ratio'], type: 'decimal' },
  },
];

function PortfolioImpact({ options, params, notify, session, custom, customData }) {
  const [loading, setLoading] = useState(false);
  const [selections, setSelections] = useState([]);
  const [selectedSelection, setSelectedSelection] = useState(null);
  const [allocation, setAllocation] = useState(10);
  const [result, setResult] = useState(null);
  const [selectedRiskGroup, setSelectedRiskGroup] = useState(riskRatioGroups[0]);

  useEffect(() => {
    if (!options) return;

    const _selections = optionizeAll(options);
    setSelections(_selections);

    if (_selections.length > 0) {
      setSelectedSelection(_selections[0]);
    }
  }, [options]);

  const fetchResult = async () => {
    if (!params) return;
    setLoading(true);

    let data = null;
    let err = null;

    const commonData = {
      sdate: params.sdate,
      edate: params.edate,
      impact: selectedSelection.value,
      subject_allocation: allocation,
    };

    if (custom) {
      [data, err] = await getInvestmentAnalysisImpactCustom({
        index: JSON.stringify(customData.index),
        columns: JSON.stringify(customData.columns),
        data: JSON.stringify(customData.data),
        ...commonData,
      });
    } else {
      [data, err] = await getInvestmentAnalysisImpact({
        product: params.product,
        database: params.database,
        ...commonData,
      });
    }

    if (err) {
      notify.danger({ message: <div>{err}</div> });
    } else {
      setResult(data);
    }
    setLoading(false);
  };

  return (
    <>
      <Card>
        <CardBody className="mt-3">
          <Form className="form-horizontal">
            <Row className="mb-1">
              <Col sm="3" className="text-right">
                <StyledLabel>Portfolio Selection</StyledLabel>
              </Col>
              <Col sm="7">
                <FormGroup className="mb-0">
                  {selections && (
                    <Select
                      className="react-select"
                      name="portfolio"
                      value={selectedSelection}
                      onChange={setSelectedSelection}
                      options={selections}
                    />
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col sm="3" className="text-right">
                <StyledLabel>Subject Allocation (%)</StyledLabel>
              </Col>
              <Col sm="7">
                <FormGroup className="mb-0">
                  <Input
                    type="number"
                    min="0"
                    max="100"
                    maxlength="3"
                    value={allocation}
                    onChange={(event) => {
                      setAllocation(allowZeroHundred(event.target.value));
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
        <CardFooter className="mt-0 text-center">
          <Button className="btn-round" color="info" onClick={fetchResult}>
            Analyze
          </Button>
        </CardFooter>
      </Card>
      {loading ? (
        <div className="tw-text-center tw-mb-5">
          <Spinner>Loading...</Spinner>
        </div>
      ) : (
        result && (
          <>
            <Card>
              <CardBody className="tw-text-lg">
                <div>
                  Original Portfolio:<span className="tw-italic tw-font-semibold tw-ml-1">{result.Description}</span>
                </div>
                <div>
                  New Portfolio:<span className="tw-italic tw-font-semibold tw-ml-1">{result.new_description}</span>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                Risk Metrics
                <div className="tw-text-lg">{params.product}</div>
                <TimePeriod dates={result.table_dates} />
              </CardHeader>
              <CardBody>
                <TightTable
                  suffix="risk-performance-impact"
                  data={result.data.data}
                  indexName=""
                  indexWidth="150px"
                  cellFormatter={(value, row, col) =>
                    riskMeasuresDeci2.includes(col) ? toDeciTable(value) : toPercTable(value)
                  }
                  rowDecorator={(rowTitle, rowInd, rowTitles) =>
                    rowTitle === 'New Portfolio'
                      ? { style: { backgroundColor: '#C6E0B4' } }
                      : rowTitle === 'Original Portfolio'
                      ? { style: { backgroundColor: 'rgb(251,198,88,0.5)' } }
                      : undefined
                  }
                  useSort
                  title={['Risk Metrics', params.product, formatDates(result.table_dates)]}
                />
              </CardBody>
            </Card>

            <div className="tw-grid tw-grid-cols-6 tw-gap-4">
              <div className="tw-col-start-2 tw-col-span-4">
                <div style={{ width: '290px' }}>
                  <div className="tw-text-left tw-font-bold">SELECT RISK METRICS</div>
                  <Select
                    className="react-select w-full"
                    name="riskGroup"
                    value={selectedRiskGroup}
                    onChange={setSelectedRiskGroup}
                    options={riskRatioGroups}
                  />
                </div>
                {selectedRiskGroup && (
                  <Card>
                    <CardHeader tag="h4" className="mt-0 text-center">
                      Risk Charts
                    </CardHeader>
                    <CardBody>
                      <TightChart
                        title="Risk Charts"
                        data={result.data.data}
                        cols={selectedRiskGroup.value.keys}
                        base="cols"
                        dataType={selectedRiskGroup.value.type}
                      />
                    </CardBody>
                  </Card>
                )}
              </div>
            </div>
          </>
        )
      )}
    </>
  );
}

export default PortfolioImpact;
