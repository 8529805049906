import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody, TabPane, Row, Col, Table } from 'reactstrap';
import PieChart from 'components/PieChart';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';

const includeBenchmarkOptions = [
  { label: 'Include Benchmark', value: true },
  { label: 'Exclude Benchmark', value: false },
];

function RiskContribution({ data: { data, table_dates }, params, notify, session }) {
  const [includeBenchmarkOption, setIncludeBenchmarkOption] = useState(includeBenchmarkOptions[0]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [selectedSubjectOption, setSelectedSubjectOption] = useState(null);

  useEffect(() => {
    if (!data) return;

    const _subjectOptions = optionizeAll(data.index.filter((v, i) => v !== params.benchmark));
    setSubjectOptions(_subjectOptions);

    if (_subjectOptions.length > 0) {
      setSelectedSubjectOption(_subjectOptions[0]);
    }
  }, [data]);

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Risk Contribution
          <TimePeriod dates={table_dates} />
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="risk-performance-risk-contribution"
            data={data}
            indexName=""
            indexWidth="250px"
            dataType="percentage"
            useSort={true}
            rowDecorator={(rowTitle, rowInd, rowTitles) =>
              rowTitle === params.benchmark ? { style: { backgroundColor: 'rgb(251,198,88,0.5)' } } : undefined
            }
            title={['Risk Contribution', formatDates(table_dates)]}
          />
        </CardBody>
      </Card>

      <AutoColumns>
        <>
          <div className="tw-text-left">
            <div className="tw-text-left tw-font-bold">
              {params.tags && params.tags.includes('portfolio-compare') ? 'SELECT PORTFOLIO' : 'SELECT ASSET'}
            </div>
            <Select
              className="react-select tw-w-[400px] tw-inline-block"
              value={selectedSubjectOption}
              onChange={setSelectedSubjectOption}
              options={subjectOptions}
            />
            <Select
              className="react-select tw-w-[200px] tw-inline-block"
              value={includeBenchmarkOption}
              onChange={setIncludeBenchmarkOption}
              options={includeBenchmarkOptions}
            />
          </div>
          {selectedSubjectOption && (
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                Risk Contribution
              </CardHeader>
              <CardBody key={includeBenchmarkOption.label}>
                <TightChart
                  title="Risk Contribution"
                  data={data}
                  options={{ indexAxis: 'y' }}
                  base="cols"
                  cols={(cols) => cols.filter((cols) => !['R2'].includes(cols))}
                  rows={
                    includeBenchmarkOption.value
                      ? [selectedSubjectOption.value, params.benchmark]
                      : [selectedSubjectOption.value]
                  }
                  dataType="percentage"
                />
              </CardBody>
            </Card>
          )}
        </>
        <>
          <div className="tw-text-left">
            <div className="tw-text-left tw-font-bold">
              {params.tags && params.tags.includes('portfolio-compare') ? 'SELECT PORTFOLIO' : 'SELECT ASSET'}
            </div>
            <Select
              className="react-select tw-w-[400px] tw-inline-block"
              value={selectedSubjectOption}
              onChange={setSelectedSubjectOption}
              options={subjectOptions}
            />
          </div>
          {selectedSubjectOption && (
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                {selectedSubjectOption.label}
              </CardHeader>
              <CardBody>
                <PieChart
                  title={selectedSubjectOption.label}
                  data={data}
                  base="cols"
                  field={selectedSubjectOption.value}
                  dataType="percentage"
                  containerStyle={{ maxWidth: '500px', maxHeight: '500px' }}
                  filter={(labels) => labels.filter((label) => !['Total Risk', 'R2'].includes(label))}
                />
              </CardBody>
            </Card>
          )}
        </>
      </AutoColumns>
    </>
  );
}

export default RiskContribution;
