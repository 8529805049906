import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import _ from 'lodash';
import { Card, CardHeader, CardBody, TabPane, Row, Col } from 'reactstrap';
import PageTabs from 'components/PageTabs';
import { getPortfolioAnalysisBuildAnalyze } from 'services/analysis';
import TightTable from 'components/TightTable';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { FixedCenterLoader } from 'components/Loaders';
import BackButton from 'views/portfolio-analysis/BackButton';
import DetailSummary from './Summary';
import RiskPerformanceReturns from './RiskPerformance/Returns';
import RiskPerformanceRiskMetrics from './RiskPerformance/RiskMetrics';
import RiskPerformanceValueAtRisk from './RiskPerformance/ValueAtRisk';
import RiskPerformanceDrawdown from './RiskPerformance/Drawdown';
import RiskPerformanceMarketRisk from './RiskPerformance/MarketRisk';
import RiskPerformanceInterestRateRisk from './RiskPerformance/InterestRateRisk';
import RiskPerformanceCreditRisk from './RiskPerformance/CreditRisk';
import RiskPerformanceRiskExposure from './RiskPerformance/RiskExposure';
import RiskPerformanceRiskContributionMajorAssets from './RiskPerformance/RiskContributionMajorAssets';
import RiskPerformanceRiskContributionHoldings from './RiskPerformance/RiskContributionHoldings';
import RiskPerformanceFamaFrench from './RiskPerformance/FamaFrench';
import RiskPerformanceCorrelation from './RiskPerformance/Correlation';
import TopPieCharts from 'views/portfolio-analysis/TopPieCharts';
import { prepareData, parseParams } from 'views/portfolio-analysis/helpers';
import { toDeci, toDeciTable, toPercTable, formatCurrency, tablePresets } from 'helpers/formatter';
import { flipDF, sortDataFrameByIndex } from 'helpers/data-frame';

function HeaderComponent({ content }) {
  if (!content) return;

  return (
    <Card>
      <CardHeader tag="h4" className="mt-0 tw-text-center">
        Description
      </CardHeader>
      <CardBody>
        <div className="tw-italic tw-text-sm tw-text-center">{content}</div>
      </CardBody>
    </Card>
  );
}

function FooterComponent({ content }) {
  if (!content) return;

  return (
    <Card>
      <CardHeader tag="h4" className="mt-0 tw-text-center">
        Notes
      </CardHeader>
      <CardBody>
        <div className="tw-italic tw-text-sm tw-text-center">{content}</div>
      </CardBody>
    </Card>
  );
}

function SingleAnalyze({ notify, session }) {
  const location = useLocation();
  const [params, setParams] = useState(null);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);

  const loadData = async () => {
    if (!params) return;

    setLoading(true);
    const [result, resultErr] = await getPortfolioAnalysisBuildAnalyze(prepareData(params));

    if (resultErr) {
      notify.danger({ message: <div>{resultErr}</div> });
    } else {
      _.each(['corr_output.asset correlation.data', 'corr_output.cross correlation.data'], (key) => {
        const df = _.get(result.data, key);
        if (df) _.set(result.data, key, flipDF(df));
      });

      _.set(
        result.data,
        'calendar_return_output.calendar_year_return',
        sortDataFrameByIndex(_.get(result.data, 'calendar_return_output.calendar_year_return')),
      );

      setResult(result);
    }

    setLoading(false);
  };

  useEffect(() => {
    const params = parseParams(location.search);
    setParams(params);
  }, [location]);

  useEffect(() => {
    if (params) loadData();
  }, [params]);

  const tableRowDecorator = useMemo(() => {
    return (rowTitle, rowInd, rowTitles) =>
      rowTitle === params.port1Name
        ? { style: { backgroundColor: '#C6E0B4' } }
        : rowTitle === params.benchmark
        ? { style: { backgroundColor: 'rgb(251,198,88,0.5)' } }
        : undefined;
  }, [params]);

  if (loading || !result) {
    return <FixedCenterLoader />;
  }

  return (
    <>
      <div>
        <BackButton />
      </div>

      <div className="tw-flex tw-justify-center tw-items-center">
        <div className="tw-mx-auto">
          <h3 className="title tw-mb-2">{result.pba_header.data.columns[0]}</h3>
        </div>
      </div>

      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          <div className="tw-text-sm">
            Currency: <span className="tw-font-semibold">{result.pba_header.conversion_note}</span>
          </div>
          <div className="tw-text-sm">
            Benchmark: <span className="tw-font-semibold">{result.pba_header.benchmark_name}</span>
          </div>
          <TimePeriod dates={result.pba_header.table_dates} prefix="Time Period:" />
          <div className="tw-text-sm">
            Rebalance Frequency: <span className="tw-font-semibold">{result.pba_header.rebalance_frequency}</span>
          </div>
        </CardHeader>
        <CardBody>
          <TightTable
            data={result.pba_header.data}
            indexWidth="350px"
            flip={true}
            cellFormatter={(value, row, col, rowInd, colInd, flip) => {
              const label = flip ? col : row;

              const formatter = tablePresets[label];
              if (formatter) return formatter(value);

              return toDeciTable(value);
            }}
            rowDecorator={(rowTitle, rowInd, rowTitles) =>
              rowTitle === params.benchmark ? { style: { backgroundColor: 'rgb(251,198,88,0.5)' } } : undefined
            }
            suffix="portfolio-analysis-single-analyze-top"
            title={[
              `Benchmark: ${result.pba_header.benchmark_name}`,
              formatDates(result.pba_header.table_dates),
              `Rebalance Frequency: ${result.pba_header.rebalance_frequency}`,
            ]}
          />
        </CardBody>
      </Card>

      <TopPieCharts params={params} />

      <PageTabs
        options={_.compact([
          result.data.summary && { value: 'summary', label: result.titles.summary },
          result.data.calendar_return_output && { value: 'returns', label: result.titles.calendar_return_output },
          result.data.risk_metrics_output && { value: 'risk-metrics', label: result.titles.risk_metrics_output },
          result.data.var_output && { value: 'value-at-risk', label: result.titles.var_output },
          result.data.drawdown_output && { value: 'drawdown', label: result.titles.drawdown_output },
          result.data.market_risk_output && { value: 'market-risk', label: result.titles.market_risk_output },
          result.data.ir_output && { value: 'interest-rate-risk', label: result.titles.ir_output },
          result.data.cr_output && { value: 'credit-risk', label: result.titles.cr_output },
          result.data.exposure_output && { value: 'risk-exposure', label: result.titles.exposure_output },
          result.data.risk_contribution_output && {
            value: 'risk-contribution',
            label: result.titles.risk_contribution_output,
          },
          result.data.ffm_output && { value: 'fama-french', label: result.titles.ffm_output },
          result.data.corr_output && { value: 'correlation', label: result.titles.corr_output },
        ])}
        colorIndex={0}
        round={false}
      >
        <TabPane tabId="summary" role="tabpanel">
          <HeaderComponent content={result.descriptions.summary} />
          <DetailSummary data={result.data.summary} params={params} notify={notify} session={session} />
          <FooterComponent content={result.notes.summary} />
        </TabPane>
        <TabPane tabId="returns" role="tabpanel">
          <HeaderComponent content={result.descriptions.calendar_return_output} />
          <RiskPerformanceReturns
            data={result.data.calendar_return_output}
            params={params}
            options={{
              calendarYearTable: {
                flip: true,
                useSort: true,
                rowDecorator: tableRowDecorator,
              },
              monthlyReturnTable: {
                rowDecorator: tableRowDecorator,
              },
            }}
            notify={notify}
            session={session}
          />
          <FooterComponent content={result.notes.calendar_return_output} />
        </TabPane>
        <TabPane tabId="risk-metrics" role="tabpanel">
          <HeaderComponent content={result.descriptions.risk_metrics_output} />
          <RiskPerformanceRiskMetrics
            data={result.data.risk_metrics_output}
            params={params}
            options={{
              riskMetricTable: {
                rowDecorator: tableRowDecorator,
              },
            }}
            notify={notify}
            session={session}
          />
          <FooterComponent content={result.notes.risk_metrics_output} />
        </TabPane>
        <TabPane tabId="value-at-risk" role="tabpanel">
          <HeaderComponent content={result.descriptions.var_output} />
          <RiskPerformanceValueAtRisk
            data={result.data.var_output}
            options={{
              valueAtRiskTable: {
                rowDecorator: tableRowDecorator,
              },
            }}
            params={params}
            notify={notify}
            session={session}
          />
          <FooterComponent content={result.notes.var_output} />
        </TabPane>
        <TabPane tabId="drawdown" role="tabpanel">
          <HeaderComponent content={result.descriptions.drawdown_output} />
          <RiskPerformanceDrawdown
            data={result.data.drawdown_output}
            params={params}
            options={{
              drawdownHistoryTable: {
                flip: true,
                rowDecorator: tableRowDecorator,
              },
            }}
            notify={notify}
            session={session}
          />
          <FooterComponent content={result.notes.drawdown_output} />
        </TabPane>
        <TabPane tabId="market-risk" role="tabpanel">
          <HeaderComponent content={result.descriptions.market_risk_output} />
          <RiskPerformanceMarketRisk
            data={result.data.market_risk_output}
            params={params}
            options={{
              marketRiskTable: {
                flip: true,
                rowDecorator: tableRowDecorator,
              },
            }}
            notify={notify}
            session={session}
          />
          <FooterComponent content={result.notes.market_risk_output} />
        </TabPane>
        <TabPane tabId="interest-rate-risk" role="tabpanel">
          <HeaderComponent content={result.descriptions.ir_output} />
          <RiskPerformanceInterestRateRisk
            data={result.data.ir_output}
            params={params}
            options={{
              interestRateTable: {
                flip: true,
                rowDecorator: tableRowDecorator,
              },
            }}
            notify={notify}
            session={session}
          />
          <FooterComponent content={result.notes.ir_output} />
        </TabPane>
        <TabPane tabId="credit-risk" role="tabpanel">
          <HeaderComponent content={result.descriptions.cr_output} />
          <RiskPerformanceCreditRisk
            data={result.data.cr_output}
            params={params}
            options={{
              creditSpreadTable: {
                flip: true,
                rowDecorator: tableRowDecorator,
              },
            }}
            notify={notify}
            session={session}
          />
          <FooterComponent content={result.notes.cr_output} />
        </TabPane>
        <TabPane tabId="risk-exposure" role="tabpanel">
          <HeaderComponent content={result.descriptions.exposure_output} />
          <RiskPerformanceRiskExposure
            data={result.data.exposure_output}
            params={params}
            options={{
              riskExposureTable: {
                flip: true,
                rowDecorator: tableRowDecorator,
              },
            }}
            notify={notify}
            session={session}
          />
          <FooterComponent content={result.notes.exposure_output} />
        </TabPane>

        {result.data.risk_contribution_output && (
          <TabPane tabId="risk-contribution" role="tabpanel">
            <PageTabs
              options={[
                { value: 'major-assets', label: 'Major Assets' },
                { value: 'holdings', label: 'Portfolio Holdings' },
              ]}
              colorIndex={1}
            >
              <TabPane tabId="major-assets" role="tabpanel">
                <HeaderComponent content={result.descriptions.risk_contribution_output} />
                <RiskPerformanceRiskContributionMajorAssets
                  data={result.data.risk_contribution_output.major_assets}
                  params={params}
                  options={{
                    riskContributionTable: {
                      rowDecorator: tableRowDecorator,
                    },
                  }}
                  notify={notify}
                  session={session}
                />
                <FooterComponent content={result.notes.risk_contribution_output} />
              </TabPane>
              <TabPane tabId="holdings" role="tabpanel">
                <HeaderComponent content={result.descriptions.risk_contribution_output} />
                <RiskPerformanceRiskContributionHoldings
                  data={result.data.risk_contribution_output.portfolio_holding}
                  params={params}
                  notify={notify}
                  session={session}
                />
                <FooterComponent content={result.notes.risk_contribution_output} />
              </TabPane>
            </PageTabs>
          </TabPane>
        )}

        <TabPane tabId="fama-french" role="tabpanel">
          <HeaderComponent content={result.descriptions.ffm_output} />
          <RiskPerformanceFamaFrench
            data={result.data.ffm_output}
            params={params}
            options={{
              analysisTable: {
                flip: true,
                rowDecorator: tableRowDecorator,
              },
            }}
            notify={notify}
            session={session}
          />
          <FooterComponent content={result.notes.ffm_output} />
        </TabPane>
        <TabPane tabId="correlation" role="tabpanel">
          <RiskPerformanceCorrelation
            data={result.data.corr_output}
            params={params}
            options={{
              assetCorrelationTable: {
                rowDecorator: tableRowDecorator,
              },
            }}
            notify={notify}
            session={session}
            header={<HeaderComponent content={result.descriptions.corr_output} />}
            footer={<FooterComponent content={result.notes.corr_output} />}
          />
        </TabPane>
      </PageTabs>
    </>
  );
}

export default SingleAnalyze;
