import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';

export function Descriptions({ content }) {
  if (!content) return;

  return (
    <Card>
      <CardHeader tag="h4" className="mt-0 tw-text-center">
        Description
      </CardHeader>
      <CardBody>
        <div className="tw-italic tw-text-sm tw-text-center">{content}</div>
      </CardBody>
    </Card>
  );
}

export function Notes({ content }) {
  if (!content) return;

  return (
    <Card>
      <CardHeader tag="h4" className="mt-0 tw-text-center">
        Notes
      </CardHeader>
      <CardBody>
        <div className="tw-italic tw-text-sm tw-text-center">{content}</div>
      </CardBody>
    </Card>
  );
}
