import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';

function BackButton() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Button
      className="tw-mb-0"
      color="default"
      onClick={() => {
        navigate(
          {
            pathname: location.search.includes('single=true') ? '/build-portfolio' : '/compare-portfolios',
            search: location.search,
          },
          { replace: false },
        );
      }}
    >
      Back
    </Button>
  );
}

export default BackButton;
