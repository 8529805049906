import { instance } from './axios';
import _ from 'lodash';

const getErrorMessage = (error) => _.get(error, 'response.data.message');

export async function listUsers() {
  try {
    const data = await instance.get('/cognito/users').then((res) => res.data);
    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function listAssignedUsers() {
  try {
    const data = await instance.get('/cognito/assigned-users').then((res) => res.data);
    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function listPendingUsers() {
  try {
    const data = await instance.get('/cognito/pending-users').then((res) => res.data);
    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function listGroups() {
  try {
    const data = await instance.get('/cognito/groups').then((res) => res.data);
    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function getAllResources(userType) {
  try {
    const data = await instance.get(`/cognito/resources/${userType}`).then((res) => res.data);
    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function updateUserGroups(username, role = 'no-role', affiliation = 'no-affiliation') {
  try {
    const data = await instance
      .post(`/cognito/users/${username}/groups`, { role, affiliation })
      .then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}
