import React, { useState, useEffect, useContext, useMemo, useRef, useLayoutEffect, createRef } from 'react';
import _ from 'lodash';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  TabPane,
  Row,
  Col,
  Spinner,
  Button,
  Modal,
  Progress,
} from 'reactstrap';
import Select from 'react-select';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import PieChart from 'components/PieChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { optionize, optionizeAll } from 'helpers/select';
import { getTightColIndexByKey } from 'helpers/chart';
import { toPerc, toDeciTable, toPercTable, formatDate, isEmptyTightData, removeEmptyRows } from 'helpers/formatter';

function CrossCorrelation({ data, notify, params, session }) {
  const [crossCorr, setCrossCorr] = useState();
  const [periodOptions, setPeriodOptions, selectedPeriodOption, setSelectedPeriodOption] = useArrayState();
  const [portfolioOptions, setPortfolioOptions, selectedPortfolioOption, setSelectedPortfolioOption] = useArrayState();
  const [holdingOptions, setHoldingOptions, selectedHoldingOption, setSelectedHoldingOption] = useArrayState();
  const [targetDataset, setTargetDataset] = useState();

  useEffect(() => {
    if (!data) return;

    const _crossCorr = _.reduce(
      data.cross_corr_dict,
      (ret, portData, portName) => {
        ret[portName] = _.reduce(
          portData,
          (ret2, yearData, year) => {
            if (!_.isEmpty(yearData[0].data)) ret2[year] = yearData;
            return ret2;
          },
          {},
        );

        return ret;
      },
      {},
    );

    const _portfolios = _.keys(_crossCorr);
    const _portfoliosOptions = optionizeAll(_portfolios);
    setPortfolioOptions(_portfoliosOptions);

    setCrossCorr(_crossCorr);
  }, [data]);

  useEffect(() => {
    if (!crossCorr || !selectedPortfolioOption) return;

    const _periods = _.keys(crossCorr[selectedPortfolioOption.value]);
    const _periodOptions = optionizeAll(_periods, { labelFormatter: _.upperFirst });
    setPeriodOptions(_periodOptions);
  }, [crossCorr, selectedPortfolioOption]);

  useEffect(() => {
    if (!data || !selectedPortfolioOption || !selectedPeriodOption) return;

    const _targetDataset = _.get(
      data,
      `cross_corr_dict.${selectedPortfolioOption.value}.${selectedPeriodOption.value}`,
      null,
    );
    setTargetDataset(_targetDataset);

    const _holdings = _targetDataset[0].index;
    const _holdingOptions = optionizeAll(_holdings);
    setHoldingOptions(_holdingOptions);
  }, [data, selectedPortfolioOption, selectedPeriodOption]);

  if (!targetDataset) {
    return null;
  }

  return (
    <>
      <div className="tw-text-left tw-font-bold">SELECT PORTFOLIO</div>
      <Select
        className="react-select tw-w-[380px]"
        name="portfolio"
        value={selectedPortfolioOption}
        onChange={setSelectedPortfolioOption}
        options={portfolioOptions}
      />
      <div className="tw-text-left tw-font-bold tw-mt-1">SELECT TIME PERIOD</div>
      <Select
        className="react-select tw-w-[230px]"
        name="mctrDurations"
        value={selectedPeriodOption}
        onChange={setSelectedPeriodOption}
        options={periodOptions}
      />

      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          <div>{selectedPortfolioOption.label}</div>
          <div className="small">Cross Correlations</div>
          <div className="small">Time Period: ({selectedPeriodOption.label})</div>
          <TimePeriod dates={targetDataset[2]} />
        </CardHeader>
        <CardBody>
          <TightTable
            data={targetDataset[0]}
            data2={targetDataset[1]}
            rowHeader2=" "
            indexName=""
            indexWidth="350px"
            cellFormatter={toDeciTable}
            suffix="correlation"
            heatmap
            title={[
              selectedPortfolioOption.label,
              'Cross Correlations',
              `Time Period: (${selectedPeriodOption.label})`,
              formatDates(targetDataset[2]),
            ]}
          />
        </CardBody>
      </Card>

      {selectedHoldingOption && (
        <>
          <AutoColumns>
            <>
              <div className="tw-text-left tw-font-bold">SELECT HOLDING</div>
              <Select
                className="react-select mw-400px"
                name="holding"
                value={selectedHoldingOption}
                onChange={setSelectedHoldingOption}
                options={holdingOptions}
              />
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  {selectedHoldingOption.label}
                </CardHeader>
                <CardBody>
                  <TightChart
                    title={selectedHoldingOption.label}
                    data={targetDataset[1]}
                    cols={[selectedHoldingOption.value]}
                    dataLabels={true}
                  />
                </CardBody>
              </Card>
            </>
          </AutoColumns>
          <AutoColumns>
            <>
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  {selectedHoldingOption.label}
                </CardHeader>
                <CardBody>
                  <TightChart
                    title={selectedHoldingOption.label}
                    data={targetDataset[0]}
                    rows={(rows, df) => {
                      const colInd = getTightColIndexByKey(df, selectedHoldingOption.value);
                      return rows.filter((row, i) => df.data[i][colInd] !== 1);
                    }}
                    cols={[selectedHoldingOption.value]}
                    dataLabels={true}
                    options={{
                      indexAxis: 'y',
                    }}
                  />
                </CardBody>
              </Card>
            </>
          </AutoColumns>
        </>
      )}
    </>
  );
}

export default CrossCorrelation;
