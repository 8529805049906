import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { Card, CardHeader, CardBody, Label, TabPane, Row, Col } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { getTightColIndexByKey } from 'helpers/chart';
import { toDeci, toDeciTable, toPerc, toPercTable, tablePresets } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';
import LineChart from 'components/LineChart';
import { colors } from 'helpers/chart';
import { defaultMeasureOptions } from 'views/MultipleAssetSelect';

function RiskReturn({ data: { chart, table }, params, notify, session }) {
  const measureLabel = defaultMeasureOptions.find((opt) => opt.value === params.measure).label;
  const lineChart = useMemo(() => {
    return {
      data: {
        labels: chart.index.map((v) => {
          const date = new Date(v);
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const day = date.getDate();

          return `${_.padStart(month, 2, '0')}/${year}`;
        }),
        datasets: [
          {
            label: measureLabel,
            borderColor: colors[0],
            backgroundColor: colors[5],
            pointRadius: 3,
            pointHoverRadius: 4,
            fill: false,
            borderWidth: 3,
            barPercentage: 1.6,
            tension: 0.4,
            data: chart.data.map((v) => v[0]),
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: (item, data) => `${item.dataset.label}: ${toPerc(item.raw)}`,
            },
          },
        },
        scales: {
          x: {
            title: {
              display: false,
            },
            ticks: {
              maxRotation: 0,
              autoskip: true,
              autoSkipPadding: 20,
              color: 'black',
            },
            beginAtZero: false,
          },
          y: {
            title: {
              display: true,
            },
            // min: -1,
            // max: 1,
            ticks: {
              callback: (val) => {
                if (val < 0) {
                  const pos = Math.abs(val);
                  return `(${toPerc(pos)})`;
                }

                return toPerc(val);
              },
              color: ({ tick }) => (tick.value < 0 ? 'red' : 'black'),
            },
            beginAtZero: false,
          },
        },
      },
    };
  }, [chart]);

  const tableDf = useMemo(() => {
    if (!table) return null;

    return {
      columns: ['Current', 'Average', 'Min', 'Max'],
      index: [0],
      data: [[table.Current, table.Average, table.Min, table.Max]],
    };
  }, [table]);

  if (!tableDf || !lineChart) return null;

  return (
    <>
      <Card className="tw-max-w-3xl tw-mx-auto">
        <CardHeader tag="h4" className="mt-0 text-center">
          <div className="tw-text-lg">{params.product}</div>
          <div className="tw-text-lg">{measureLabel}</div>
          <div className="tw-text-lg">Rolling Period: {params.window} Months</div>
        </CardHeader>
        <CardBody>
          <LineChart
            data={lineChart.data}
            options={lineChart.options}
            height={400}
            width={826}
            title={[`Rolling ${params.window} Month Correlation`]}
          />
        </CardBody>
      </Card>

      <Row>
        <Col xl="8" className="offset-xl-2">
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              <div className="tw-text-lg">{params.product}</div>
              <div className="tw-text-lg">{measureLabel}</div>
              <div className="tw-text-lg">Rolling Period: {params.window} Months</div>
            </CardHeader>
            <CardBody>
              <TightTable suffix="rolling-correlation" data={tableDf} dataType="percentage" hideIndexTable />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default RiskReturn;
