import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody, TabPane, Row, Col, Table } from 'reactstrap';
import PieChart from 'components/PieChart';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';

function RiskContributionHoldings({ data: { data, table_dates }, params, notify, session }) {
  const [holdingOptions, setHoldingOptions] = useState([]);
  const [selectedHoldingOption, setSelectedHoldingOption] = useState(null);

  useEffect(() => {
    if (!data) return;

    const _holdings = data.index.filter((val) => !['Portfolio'].includes(val));
    const _holdingOptions = optionizeAll(_holdings);
    setHoldingOptions(_holdingOptions);

    if (_holdingOptions.length > 0) {
      setSelectedHoldingOption(_holdingOptions[0]);
    }
  }, [data]);

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Holdings Contribution
          <TimePeriod dates={table_dates} />
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="risk-performance-risk-contribution"
            data={data}
            indexName=""
            indexWidth="250px"
            dataType="percentage"
            useSort={true}
            rowDecorator={(rowTitle, rowInd, rowTitles) =>
              rowTitle === 'Portfolio' ? { style: { backgroundColor: '#C6E0B4' } } : undefined
            }
            title={['Holdings Contribution', formatDates(table_dates)]}
          />
        </CardBody>
      </Card>

      <AutoColumns>
        <Card>
          <CardHeader tag="h4" className="mt-0 text-center">
            Contribution to Portfolio Risk
            <div className="tw-text-lg">{params.port1Name}</div>
          </CardHeader>
          <CardBody>
            <PieChart
              title="Contribution to Portfolio Risk"
              data={data}
              base="rows"
              field="Contribution to Portfolio Risk"
              dataType="percentage"
              containerStyle={{ maxWidth: '500px', maxHeight: '500px' }}
              filter={(labels) => labels.filter((label) => !['Portfolio'].includes(label))}
              options={{
                plugins: {
                  legend: {
                    display: data.index.length <= 9, // 8 plus 'Portfolio'
                    position: 'bottom',
                  },
                },
              }}
            />
          </CardBody>
        </Card>
        <>
          <div className="tw-text-left">
            {holdingOptions.length > 1 && (
              <>
                <div className="tw-text-left tw-font-bold">SELECT HOLDING</div>
                <Select
                  className="react-select tw-w-[400px] tw-inline-block"
                  value={selectedHoldingOption}
                  onChange={setSelectedHoldingOption}
                  options={holdingOptions}
                />
              </>
            )}
          </div>
          {selectedHoldingOption && (
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                Portfolio Risk and Return
              </CardHeader>
              <CardBody>
                <TightChart
                  title="Portfolio Risk and Return"
                  data={data}
                  options={{ indexAxis: 'y' }}
                  base="cols"
                  rows={['Portfolio', selectedHoldingOption.value]}
                  cols={['Contribution to Portfolio Risk', 'Contribution to Portfolio Return']}
                  dataType="percentage"
                />
              </CardBody>
            </Card>
          )}
        </>
      </AutoColumns>
    </>
  );
}

export default RiskContributionHoldings;
