import styled from 'styled-components';
import { Spinner } from 'reactstrap';

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export function FixedCenterLoader() {
  return (
    <SpinnerWrapper>
      <Spinner>Loading...</Spinner>
    </SpinnerWrapper>
  );
}
