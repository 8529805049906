import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { Card, CardHeader, CardBody, Label, TabPane, Row, Col } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { getTightColIndexByKey } from 'helpers/chart';
import { removeEmptyCols } from 'helpers/data-frame';
import { toDeci, toPerc, toPercTable, confidenceLevelToInterval } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';

function ValueAtRiskDynamic({ data, params, notify, session }) {
  const [durationOptions, setDurationOptions, selectedDurationOption, setSelectedDurationOption] = useArrayState();
  const [varOptions, setVarOptions, selectedVarOption, setSelectedVarOption] = useArrayState();

  useEffect(() => {
    if (!data) return;

    const compactAsset = removeEmptyCols(data.asset['0.95']);
    const _durations = compactAsset.columns;
    setDurationOptions(optionizeAll(_durations));

    const _vars = data.asset['0.95'].index;
    setVarOptions(optionizeAll(_vars, { useIndex: true }));
  }, [data]);

  const advancedTightDicts = useMemo(() => {
    if (!data || !selectedVarOption) return null;

    const percs = ['0.9', '0.95', '0.99'];

    const index = percs.map((v) => toPerc(v, { precision: 0 })).map((v) => `${selectedVarOption.label} ${v}`);
    const columns = data.asset['0.95'].columns;

    const dataset = [percs.map((perc) => data.asset[perc].data[selectedVarOption.value])];

    if (data.benchmark) {
      dataset.push(percs.map((perc) => data.benchmark[perc].data[selectedVarOption.value]));
    }

    return dataset.map((data) => ({ index, columns, data }));
  }, [params, data, selectedVarOption]);

  const advancedDurationTightDict = useMemo(() => {
    if (!advancedTightDicts) return null;

    const index = [params.product];
    if (data.benchmark) index.push(params.benchmark);

    const columns = advancedTightDicts[0].index;

    const _data = index.map((row, rowInd) => {
      const advDf = advancedTightDicts[rowInd];
      const duration = getTightColIndexByKey(advDf, selectedDurationOption.value);
      return columns.map((col, colInd) => advDf.data[colInd][duration]);
    });

    return { index, columns, data: _data };
  }, [data, advancedTightDicts, selectedDurationOption]);

  if (!selectedDurationOption || !selectedVarOption || !advancedDurationTightDict) return;

  return (
    <>
      <div className="tw-text-left tw-font-bold">SELECT VAR METRIC</div>
      <Select
        className="react-select mw-200px"
        name="var"
        value={selectedVarOption}
        onChange={setSelectedVarOption}
        options={varOptions}
      />

      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Value at Risk ({selectedVarOption.label})
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="benchmark-value-at-risk-var"
            data={advancedTightDicts[0]}
            data2={advancedTightDicts[1]}
            rowHeader={{ text: params.product, style: { backgroundColor: '#C6E0B4' } }}
            rowHeader2={{ text: params.benchmark, style: { backgroundColor: 'rgb(251,198,88,0.5)' } }}
            indexName="Time Interval"
            cellFormatter={toPercTable}
            title={`Value at Risk (${selectedVarOption.label})`}
          />
        </CardBody>
      </Card>

      <Row>
        <Col xl="8" className="offset-xl-2">
          <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
          <Select
            className="react-select mw-200px"
            name="duration"
            value={selectedDurationOption}
            onChange={setSelectedDurationOption}
            options={durationOptions}
          />

          <Card>
            <CardHeader>&nbsp;</CardHeader>
            <CardBody>
              <TightChart
                data={advancedDurationTightDict}
                base="rows"
                valueFormatter={(v) => parseFloat(v) * 100}
                options={{
                  plugins: {
                    tooltip: {
                      callbacks: {
                        label: (item, data) => `${item.dataset.label}: ${toDeci(item.raw)}%`,
                      },
                    },
                  },
                  scales: { y: { ticks: { callback: (val) => `${toDeci(val)}%` } } },
                }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default ValueAtRiskDynamic;
