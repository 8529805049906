import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import _ from 'lodash';
import { Card, CardHeader, CardBody, TabPane, Row, Col } from 'reactstrap';
import Select from 'react-select';
import PageTabs from 'components/PageTabs';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import AssetInfo from './AssetInfo';
import { optionize, optionizeAll } from 'helpers/select';
import { removeEmptyCols } from 'helpers/data-frame';
import { toDeci, toPerc, toPercTable, confidenceLevelToInterval } from 'helpers/formatter';
import { getTightColIndexByKey } from 'helpers/chart';
import { parseSearchParams } from 'helpers/location';
import { getAdvancedAnalyticsVar } from 'services/analysis';

const SpinnerWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

function SingleAssetVar({ params, notify, session }) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [durations, setDurations] = useState();
  const [selectedDuration, setSelectedDuration] = useState();
  const [vars, setVars] = useState();
  const [selectedVar, setSelectedVar] = useState();

  const fetchResult = async () => {
    if (!params) return;

    setLoading(true);
    const [data, err] = await getAdvancedAnalyticsVar(params);
    if (err) {
      notify.danger({ message: <div>{err}</div> });
    } else {
      setResult(data);
      const compactAsset = removeEmptyCols(data.asset['0.95']);
      const _durations = compactAsset.columns;
      setDurations(optionizeAll(_durations));
      if (_durations.length > 0) setSelectedDuration(optionize(_durations[0], _durations[0]));
      const _vars = data.asset['0.95'].index;
      setVars(_vars);
      if (_vars.length > 0) setSelectedVar(optionize(0, _vars[0]));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (params) fetchResult();
  }, [params]);

  const benchmarkTightDict = useMemo(() => {
    if (!result) return null;

    const target = result.asset['0.95'];
    const benchm = result.benchmark['0.95'];
    const duration = getTightColIndexByKey(target, selectedDuration.value);

    const index = [params.product, params.benchmark];
    const columns = target.index;
    const data = index.map((row, rowInd) => {
      if (rowInd === 0) return columns.map((col, colInd) => target.data[colInd][duration]);
      else return columns.map((col, colInd) => benchm.data[colInd][duration]);
    });

    return { index, columns, data };
  }, [params, result, selectedDuration]);

  const advancedTightDicts = useMemo(() => {
    if (!result) return null;

    const percs = ['0.9', '0.95', '0.99'];

    const index = percs.map((v) => toPerc(v, { precision: 0 })).map((v) => `${selectedVar.label} ${v}`);
    const columns = result.asset['0.95'].columns;

    const dataset = [
      percs.map((perc) => result.asset[perc].data[selectedVar.value]),
      percs.map((perc) => result.benchmark[perc].data[selectedVar.value]),
    ];

    return dataset.map((data) => ({ index, columns, data }));
  }, [params, result, selectedVar]);

  const advancedDurationTightDict = useMemo(() => {
    if (!advancedTightDicts) return null;

    const index = [params.product, params.benchmark];
    const columns = advancedTightDicts[0].index;

    const data = index.map((row, rowInd) => {
      const advDf = advancedTightDicts[rowInd];
      const duration = getTightColIndexByKey(advDf, selectedDuration.value);
      return columns.map((col, colInd) => advDf.data[colInd][duration]);
    });

    return { index, columns, data };
  }, [advancedTightDicts, selectedDuration]);

  if (loading || !result) {
    return (
      <SpinnerWrapper>
        <Spinner>Loading...</Spinner>
      </SpinnerWrapper>
    );
  }

  return (
    <>
      <AssetInfo asset={params.product} database={params.database} data={result} />

      <PageTabs
        options={[
          { value: 'summary', label: 'Summary', permission: 'aa-var-summary' },
          {
            value: 'benchmark',
            label: 'Benchmark Analysis',
            permission: 'aa-var-benchmark',
          },
          { value: 'advanced', label: 'Advanced', permission: 'aa-var-advanced' },
        ]}
        session={session}
      >
        <TabPane tabId="summary" role="tabpanel">
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Value at Risk (VaR)
              <div className="small">Confidence Interval: {confidenceLevelToInterval(params.significance)}</div>
            </CardHeader>
            <CardBody>
              <TightTable
                suffix="summary-value-at-risk"
                data={result.asset['0.95']}
                rowHeader={{ text: params.product, style: { backgroundColor: '#C6E0B4' } }}
                indexName="Time Interval"
                cellFormatter={toPercTable}
                title={[
                  'Value at Risk (VaR)',
                  `Confidence Interval: ${confidenceLevelToInterval(params.significance)}`,
                ]}
              />
            </CardBody>
          </Card>

          <Row>
            <Col xl="8" className="offset-xl-2">
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  Value at Risk
                </CardHeader>
                <CardBody>
                  <TightChart
                    title="Value at Risk"
                    data={result.asset['0.95']}
                    base="cols"
                    omitEmpty={true}
                    dataType="percentage"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="benchmark" role="tabpanel">
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Value at Risk (VaR)
              <div className="small">Confidence Interval: {confidenceLevelToInterval(params.significance)}</div>
            </CardHeader>
            <CardBody>
              <TightTable
                suffix="benchmark-value-at-risk"
                data={result.asset['0.95']}
                data2={result.benchmark['0.95']}
                rowHeader={{ text: params.product, style: { backgroundColor: '#C6E0B4' } }}
                rowHeader2={{ text: params.benchmark, style: { backgroundColor: 'rgb(251,198,88,0.5)' } }}
                indexName="Time Interval"
                cellFormatter={toPercTable}
                title={[
                  'Value at Risk (VaR)',
                  `Confidence Interval: ${confidenceLevelToInterval(params.significance)}`,
                ]}
              />
            </CardBody>
          </Card>

          {durations && durations.length > 0 && (
            <>
              <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
              <Select
                className="react-select mw-200px"
                name="duration"
                value={selectedDuration}
                onChange={setSelectedDuration}
                options={durations}
              />

              <Row>
                <Col xl="6">
                  <Card>
                    <CardHeader tag="h4" className="mt-0 text-center">
                      Value at Risk (VaR)
                      <div className="small">Confidence Interval: {confidenceLevelToInterval(params.significance)}</div>
                    </CardHeader>
                    <CardBody>
                      <TightTable
                        suffix="benchmark-value-at-risk-period"
                        data={benchmarkTightDict}
                        cellFormatter={toPercTable}
                        title={[
                          'Value at Risk (VaR)',
                          `Confidence Interval: ${confidenceLevelToInterval(params.significance)}`,
                        ]}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col xl="6">
                  <Card>
                    <CardBody>
                      <TightChart
                        title="Value at Risk (VaR)"
                        data={benchmarkTightDict}
                        base="rows"
                        valueFormatter={(v) => parseFloat(v) * 100}
                        options={{
                          plugins: {
                            tooltip: {
                              callbacks: {
                                label: (item, data) => `${item.dataset.label}: ${toDeci(item.raw)}%`,
                              },
                            },
                          },
                          scales: { y: { ticks: { callback: (val) => `${toDeci(val)}%` } } },
                        }}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </TabPane>

        <TabPane tabId="advanced" role="tabpanel">
          {vars && vars.length > 0 && (
            <>
              <div className="tw-text-left tw-font-bold">SELECT VAR METRIC</div>
              <Select
                className="react-select mw-200px"
                name="var"
                value={selectedVar}
                onChange={setSelectedVar}
                options={optionizeAll(vars, { useIndex: true })}
              />

              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  Value at Risk ({selectedVar.label})
                </CardHeader>
                <CardBody>
                  <TightTable
                    suffix="benchmark-value-at-risk-var"
                    data={advancedTightDicts[0]}
                    data2={advancedTightDicts[1]}
                    rowHeader={{ text: params.product, style: { backgroundColor: '#C6E0B4' } }}
                    rowHeader2={{ text: params.benchmark, style: { backgroundColor: 'rgb(251,198,88,0.5)' } }}
                    indexName="Time Interval"
                    cellFormatter={toPercTable}
                    title={`Value at Risk (${selectedVar.label})`}
                  />
                </CardBody>
              </Card>

              {durations && durations.length > 0 && (
                <Row>
                  <Col xl="8" className="offset-xl-2">
                    <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
                    <Select
                      className="react-select mw-200px"
                      name="duration"
                      value={selectedDuration}
                      onChange={setSelectedDuration}
                      options={durations}
                    />

                    <Card>
                      <CardBody>
                        <TightChart
                          data={advancedDurationTightDict}
                          base="rows"
                          valueFormatter={(v) => parseFloat(v) * 100}
                          options={{
                            plugins: {
                              tooltip: {
                                callbacks: {
                                  label: (item, data) => `${item.dataset.label}: ${toDeci(item.raw)}%`,
                                },
                              },
                            },
                            scales: { y: { ticks: { callback: (val) => `${toDeci(val)}%` } } },
                          }}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
            </>
          )}
        </TabPane>
      </PageTabs>
    </>
  );
}

export default SingleAssetVar;
