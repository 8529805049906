import React, { useState, useEffect, useContext, useMemo, useRef, useLayoutEffect, createRef } from 'react';
import _ from 'lodash';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  TabPane,
  Row,
  Col,
  Spinner,
  Button,
  Modal,
  Progress,
} from 'reactstrap';
import Select from 'react-select';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import PieChart from 'components/PieChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { optionize, optionizeAll } from 'helpers/select';
import { toPerc, toDeciTable, toPercTable, formatDate, isEmptyTightData, removeEmptyRows } from 'helpers/formatter';

const chartTypeOptions1 = optionizeAll(['Marginal Risk', 'Risk Reward']);
const chartTypeOptions2 = optionizeAll(['% Total Contribution to Portfolio Risk', 'Contribution to Portfolio Risk']);

function RiskContribution({ data, notify, params, session }) {
  const [periodOptions, setPeriodOptions, selectedPeriodOption, setSelectedPeriodOption] = useArrayState();
  const [portfolioOptions, setPortfolioOptions, selectedPortfolioOption, setSelectedPortfolioOption] = useArrayState();
  const [chartType1, setChartType1] = useState(chartTypeOptions1[0]);
  const [chartType2, setChartType2] = useState(chartTypeOptions2[0]);

  useEffect(() => {
    if (!data) return;

    const _portfolios = _.keys(data.mctr_dict);
    const _portfoliosOptions = optionizeAll(_portfolios);
    setPortfolioOptions(_portfoliosOptions);
    if (_portfoliosOptions.length > 0) setSelectedPortfolioOption(_portfoliosOptions[0]);
  }, [data]);

  useEffect(() => {
    if (!data || !selectedPortfolioOption) return;

    const _periods = _.keys(data.mctr_dict[selectedPortfolioOption.value]);
    const _periodOptions = optionizeAll(_periods, { labelFormatter: _.upperFirst });
    setPeriodOptions(_periodOptions);
    if (_periodOptions.length > 0) setSelectedPeriodOption(_periodOptions[0]);
  }, [data, selectedPortfolioOption]);

  const targetDataset = useMemo(() => {
    if (!data || !selectedPortfolioOption || !selectedPeriodOption) return;

    return _.get(data, `mctr_dict.${selectedPortfolioOption.value}.${selectedPeriodOption.value}`, null);
  }, [data, selectedPortfolioOption, selectedPeriodOption]);

  if (!targetDataset) {
    return null;
  }

  return (
    <>
      <>
        <div className="tw-text-left tw-font-bold">SELECT PORTFOLIO</div>
        <Select
          className="react-select tw-w-[380px]"
          name="portfolio"
          value={selectedPortfolioOption}
          onChange={setSelectedPortfolioOption}
          options={portfolioOptions}
        />
      </>

      <>
        <div className="tw-text-left tw-font-bold tw-mt-1">SELECT TIME PERIOD</div>
        <Select
          className="react-select tw-w-[230px]"
          name="mctrDurations"
          value={selectedPeriodOption}
          onChange={setSelectedPeriodOption}
          options={periodOptions}
        />
      </>

      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          <div>{selectedPortfolioOption.label}</div>
          <div className="small">Holdings Contribution</div>
          <div className="small">Time Period: ({selectedPeriodOption.label})</div>
          <TimePeriod dates={targetDataset[1]} />
        </CardHeader>
        <CardBody>
          <TightTable
            data={targetDataset[0]}
            indexName=""
            cellFormatter={(value, row, col) => (['Category (Group)'].includes(col) ? value : toPercTable(value))}
            rowDecorator={(rowTitle, rowInd, rowTitles) =>
              ['Total', 'Portfolio'].includes(rowTitle) ? { style: { backgroundColor: '#C6E0B4' } } : undefined
            }
            suffix="portfolio-risk-decomposition"
            compact
            useSort
            title={[
              selectedPortfolioOption.label,
              'Holdings Contribution',
              `Time Period: (${selectedPeriodOption.label})`,
              formatDates(targetDataset[1]),
            ]}
          />
        </CardBody>
      </Card>

      <AutoColumns>
        <>
          <div className="tw-text-left tw-font-bold">SELECT</div>
          <Select
            className="react-select mw-400px"
            name="chartType2"
            value={chartType2}
            onChange={setChartType2}
            options={chartTypeOptions2}
          />
          {chartType2.value === chartTypeOptions2[0].value ? (
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                <div>{selectedPortfolioOption.label}</div>
                <div className="small">% Total Contribution to Portfolio Risk</div>
              </CardHeader>
              <CardBody>
                <PieChart
                  title={selectedPortfolioOption.label}
                  data={targetDataset[0]}
                  field="% Total Contribution to Portfolio Risk"
                  filter={(values) => values.filter((row) => !['Total', 'Portfolio'].includes(row))}
                  dataType="percentage"
                  options={{
                    plugins: {
                      legend: {
                        display: targetDataset[0].index.length < 10,
                        position: 'bottom',
                      },
                    },
                  }}
                  containerStyle={{ maxWidth: '600px', maxHeight: '600px' }}
                />
              </CardBody>
            </Card>
          ) : (
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                <div>{selectedPortfolioOption.label}</div>
                <div className="small">Contribution to Portfolio Risk</div>
              </CardHeader>
              <CardBody>
                <TightChart
                  title={selectedPortfolioOption.label}
                  data={targetDataset[0]}
                  rows={(rows) =>
                    ['Total', 'Portfolio'].concat(rows.filter((row) => !['Total', 'Portfolio'].includes(row)))
                  }
                  cols={['Contribution to Portfolio Risk']}
                  dataType="percentage"
                  dataLabels={true}
                  options={{
                    indexAxis: 'y',
                  }}
                />
              </CardBody>
            </Card>
          )}
        </>
      </AutoColumns>
      <AutoColumns>
        <>
          <div className="tw-text-left tw-font-bold">SELECT</div>
          <Select
            className="react-select mw-300px"
            name="chartType1"
            value={chartType1}
            onChange={setChartType1}
            options={chartTypeOptions1}
          />
          {chartType1.value === chartTypeOptions1[0].value ? (
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                <div>{selectedPortfolioOption.label}</div>
                <div className="small">Marginal Risk Contribution</div>
              </CardHeader>
              <CardBody>
                <TightChart
                  title={selectedPortfolioOption.label}
                  data={targetDataset[0]}
                  rows={(rows) => rows.filter((row) => !['Total', 'Portfolio'].includes(row))}
                  cols={['Marginal Risk Contribution']}
                  dataType="percentage"
                  dataLabels={true}
                  options={{
                    indexAxis: 'y',
                  }}
                />
              </CardBody>
            </Card>
          ) : (
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                <div>{selectedPortfolioOption.label}</div>
                <div className="small">Risk Reward</div>
              </CardHeader>
              <CardBody>
                <TightChart
                  title={selectedPortfolioOption.label}
                  data={targetDataset[0]}
                  rows={(rows) => rows.filter((row) => !['Total', 'Portfolio'].includes(row))}
                  cols={['% Total Contribution to Portfolio Return', '% Total Contribution to Portfolio Risk']}
                  dataType="percentage"
                  dataLabels={true}
                  options={{
                    indexAxis: 'y',
                  }}
                />
              </CardBody>
            </Card>
          )}
        </>
      </AutoColumns>
    </>
  );
}

export default RiskContribution;
