import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import _ from 'lodash';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Table,
  Row,
  Col,
  Spinner,
  UncontrolledTooltip,
  TabPane,
  CardFooter,
} from 'reactstrap';
import PageTabs from 'components/PageTabs';
import { AutoColumns } from 'components/Columns';
import { FixedCenterLoader } from 'components/Loaders';
import LineChart from 'components/LineChart';
import ReactDatetime from 'react-datetime';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { listProducts, getDiscovery, toolboxCorrelation, getQuickViewData } from 'services/analysis';
import { optionizeValue, optionizeAll, getValue, getDate, getYearMonth, monthDiff } from 'helpers/select';
import { confidenceLevelToInterval, tablePresets, toDeciTable, formatCurrency } from 'helpers/formatter';
import { formatDateToMMYYYY } from 'helpers/date';
import TightTable from 'components/TightTable';
import { colors } from 'helpers/chart';
import { Descriptions, Notes } from '../others';

function extractYearAndPercentage(input) {
  const regex = /(\d{4}): \((\d+\.\d+)%\)/;
  const match = input.match(regex);

  if (match) {
    const year = match[1];
    const percentage = match[2];
    return { year, percentage };
  } else {
    return null;
  }
}

function Summary({ database, product, converter, setName }) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);

  const fetchData = async () => {
    setLoading(true);

    const [data, err] = await getQuickViewData({
      database,
      product,
      converter,
      page_choice: 'summary',
    });

    if (data) {
      setResult(data);
      setName(data.formal_name);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!database || !product) return;

    fetchData();
  }, [database, product, converter]);

  const lineChart = useMemo(() => {
    if (!result) return null;
    const gdata = result['chart_data'];

    const dataLabel1 = gdata.index[0];

    const tdata = {
      labels: gdata.columns.map(formatDateToMMYYYY),
      datasets: [
        {
          label: dataLabel1,
          data: gdata.data[0],
          fill: true,
          borderColor: colors[0],
          backgroundColor: colors[5],
          borderWidth: 2,
          tension: 0,
          pointRadius: 3,
          pointHoverRadius: 4,
        },
      ],
    };

    const options = {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          callbacks: {
            label: (item) => {
              return `${item.label}: ${formatCurrency(item.raw, { precision: 0 })}`;
            },
          },
        },
      },
      scales: {
        x: {
          title: {
            display: false,
          },
          ticks: {
            maxRotation: 0,
            autoskip: true,
            autoSkipPadding: 20,
            color: 'black',
          },
          beginAtZero: false,
        },
        y: {
          title: {
            display: true,
          },
          ticks: {
            callback: (val) => formatCurrency(val, { precision: 0 }),
            color: ({ tick }) => (tick.value < 0 ? 'red' : 'black'),
          },
          beginAtZero: false,
        },
      },
    };

    return { data: tdata, options };
  }, [result]);

  if (loading) {
    return <FixedCenterLoader />;
  }

  if (!result) {
    return null;
  }

  console.log('Summary', result);
  return (
    <>
      <Descriptions content={result.descriptions} />
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          <div>{product}</div>
          <div className="tw-text-lg">Currency: {result.currency}</div>
          <TimePeriod dates={result.table_dates} />
        </CardHeader>
        <CardBody>
          <TightTable
            data={result.summary}
            indexWidth="130px"
            suffix="quickview-summary"
            hideIndexTable
            cellFormatter={(value, row, col, rowInd, colInd, flip) => {
              const formatter = tablePresets[col];
              if (formatter) return formatter(value);

              const worstYearMatch = extractYearAndPercentage(value);
              if (worstYearMatch) {
                const { year, percentage } = worstYearMatch;
                return {
                  component: (
                    <div>
                      {year}: <span className="tw-text-red-500">({percentage}%)</span>
                    </div>
                  ),
                };
              }

              return value;
            }}
          />
        </CardBody>
      </Card>

      <AutoColumns>
        <Card>
          <CardHeader tag="h4" className="mt-0 text-center">
            <div>Value Add Monthly Index - VAMI</div>
            <div className="tw-text-lg">(Growth of $1,000)</div>
            <div className="tw-text-lg">{result.chart_data.index[0]}</div>
          </CardHeader>
          <CardBody>
            <LineChart
              title="Value Add Monthly Index - VAMI"
              data={lineChart.data}
              options={lineChart.options}
              plugins={[]}
            />
          </CardBody>
        </Card>
      </AutoColumns>
      <Notes content={result.notes} />
    </>
  );
}

export default Summary;
