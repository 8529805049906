import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import _ from 'lodash';
import {
  toDeci,
  toPerc,
  toDeciTable,
  toPercTable,
  formatDate,
  isEmptyTightData,
  removeEmptyRows,
} from 'helpers/formatter';
import { riskMeasuresRatios, riskMeasuresPerc, riskMeasuresDeci, riskMeasuresAll } from 'helpers/meta';
import { getHeatmap, distinctColor } from 'helpers/color';
import {
  jsPdfTable,
  jsPdfImage,
  jsPdfText,
  getChartDataUri,
  h1,
  paragraph,
  header,
  footer,
  boundary,
  setFont,
} from './helpers';
import getPieChartProps from 'components/_pie-chart';
import getBarChartProps from 'components/_bar-chart';

const TRUNCATE_MAX = 10;
const PAGE_PADDING = 10;

const coverPage = async (doc, data, params, context) => {
  let { session, selectedYear } = context;
  session = session || {};
  const now = new Date().toDateString();
  const dates = _.get(data, `risk_summary.1.${selectedYear}`, []).map(formatDate).join(' - ');
  header(doc);
  footer(doc);

  let result = { finalY: 10 };

  result = paragraph(doc, 'PORTFOLIO COMPARISON AND RISK ANALYSIS', {
    top: result.finalY + 30,
    fontStyle: 'bold',
    fontSize: 1.8,
  });

  result = paragraph(doc, `Prepared for: ${session.name}`, {
    top: result.finalY + 12,
    fontStyle: 'bold',
    fontSize: 1.2,
  });

  result = paragraph(doc, now, { top: result.finalY + 2, fontStyle: 'bold', fontSize: 1.2 });

  result = paragraph(doc, `Benchmark: ${params.benchmark}`, {
    top: result.finalY + 10,
    fontStyle: 'bold',
    fontSize: 1.2,
  });

  result = paragraph(doc, `Charts Time Period: ${dates}`, {
    top: result.finalY + 10,
    fontStyle: 'bold',
    fontSize: 1.2,
  });

  result = paragraph(doc, `Advisor Fees: ${toPerc(params.advisorFees, { precision: 1 })}`, {
    top: result.finalY + 3,
    fontStyle: 'bold',
    fontSize: 1.2,
  });

  result = paragraph(doc, `Rebalance Frequency: ${params.rebalanceFreq}`, {
    top: result.finalY + 3,
    fontStyle: 'bold',
    fontSize: 1.2,
  });

  result = paragraph(doc, 'FOR FINANCIAL ADVISORS AND PROFESSIONAL INVESTORS ONLY.', {
    top: result.finalY + 25,
    fontStyle: 'bold',
    fontSize: 1.3,
  });
};

const disclaimerPage = async (doc, data, params, context) => {
  doc.addPage([297, 210], 'l');

  header(doc);
  footer(doc);

  let result = { finalY: 10 };

  result = paragraph(doc, 'DISCLAIMER', {
    top: result.finalY + 23,
    fontStyle: 'bold',
    fontSize: 1.6,
  });

  result = paragraph(
    doc,
    `The analysis and results presented in this report are intended solely for the licensor (the “user” or the “recipient”) of Anchor Pacific Financial Risk Labs FLARE™ platform, and is subject to the terms and conditions of service in the user’s licensing agreement. Its use is for sophisticated financial professionals and is neither intended as an offer or a solicitation of an offer to buy or sell any investment or other specific product, nor is it intended to be tax or legal advice. Please note that this analysis has no regard to the specific investment objectives, financial situation, or particular needs of any recipient and that Anchor Pacific Financial Risk Labs Ltd. (“AP Fin Labs”) is not acting as an advisor or fiduciary to or for any recipient of these results. The results presented in this analysis are subject to certain inherent limitations and application of these results is subject to the judgement of the user. Past performance is no guarantee of future results. Recipients should not regard the results in this analysis as a substitute for the exercise of their own judgement and recipients are solely responsible for their own investment and trading decisions. Although all information within this analysis was obtained from sources believed to be reliable and in good faith, no representation or warranty, express or implied, is made as to its accuracy or completeness. This analysis is not intended for redistribution and may not be reproduced or copies circulated without prior written permission of AP Fin Labs. AP Fin Labs expressly prohibits the distribution and transfer of this analysis to third parties for any reason and AP Fin Labs will not be liable for any claims or lawsuits from any third parties arising from the use or distribution of this analysis. Please refer to your licensing agreement for further conditions.`,
    {
      top: result.finalY + 8,
      maxWidth: 250,
      fontStyle: 'normal',
      fontSize: 0.9,
    },
  );

  result = paragraph(doc, 'DISCLOSURE REGARDING THE USE OF BENCHMARKS', {
    top: result.finalY + 110,
    fontStyle: 'bold',
    fontSize: 1.6,
  });
};

const definitionPage1 = async (doc, data, params, context) => {
  doc.addPage([297, 210], 'l');

  header(doc);
  footer(doc);

  const pageWidth = doc.internal.pageSize.getWidth();
  const pageInnerWidth = pageWidth - PAGE_PADDING * 2;
  const halfWidth = pageInnerWidth / 2;
  const margin = 3;

  let result = { finalY: 10 };

  result = h1(doc, 'DEFINITIONS AND METHODOLOGY', {
    top: result.finalY + 10,
    fontSize: 1.2,
    underline: false,
  });

  const h1finalY = result.finalY;

  doc.rect(PAGE_PADDING + margin, result.finalY, halfWidth - margin * 2, 170);
  doc.rect(pageWidth / 2 + margin, result.finalY, halfWidth - margin * 2, 170);

  result = paragraph(doc, 'CUMULATIVE RETURN', {
    top: result.finalY + 8,
    left: PAGE_PADDING + margin + 3,
    fontStyle: 'bold',
    fontSize: 1,
  });

  result = paragraph(
    doc,
    `Cumulative return on an investment is the aggregate amount that the investment has gained or lost over time, independent of the time involved. Cumulative returns are not annualized.`,
    {
      top: result.finalY + 2,
      left: PAGE_PADDING + margin + 3,
      maxWidth: halfWidth - 10,
      fontStyle: 'normal',
      fontSize: 0.8,
    },
  );

  result = paragraph(doc, 'COMPOUND ANNUAL GROWTH RATE (CAGR)', {
    top: result.finalY + 17,
    left: PAGE_PADDING + margin + 3,
    fontStyle: 'bold',
    fontSize: 1,
  });

  result = paragraph(
    doc,
    `The rate of return that would be required for an investment to grow from its beginning balance to its ending balance, assuming the profits were invested at the end of each period of the investment’s life span.`,
    {
      top: result.finalY + 2,
      left: PAGE_PADDING + margin + 3,
      maxWidth: halfWidth - 10,
      fontStyle: 'normal',
      fontSize: 0.8,
    },
  );

  result = paragraph(doc, 'STANDARD DEVIATION', {
    top: result.finalY + 17,
    left: PAGE_PADDING + margin + 3,
    fontStyle: 'bold',
    fontSize: 1,
  });

  result = paragraph(
    doc,
    `Standard deviation is a statistic that measures the dispersion of returns relative to their mean. Standard deviation is often used as a measure of the relative riskiness of an asset.`,
    {
      top: result.finalY + 2,
      left: PAGE_PADDING + margin + 3,
      maxWidth: halfWidth - 10,
      fontStyle: 'normal',
      fontSize: 0.8,
    },
  );

  result = paragraph(doc, 'BETA', {
    top: result.finalY + 17,
    left: PAGE_PADDING + margin + 3,
    fontStyle: 'bold',
    fontSize: 1,
  });

  result = paragraph(
    doc,
    `Beta is a measure of volatility or systematic risk of an investment compared to the market as a whole.  The market is defined as the S&P 500 Index.  Assets with beta > (<) 1 are more (less) volatile than the S&P 500.`,
    {
      top: result.finalY + 2,
      left: PAGE_PADDING + margin + 3,
      maxWidth: halfWidth - 10,
      fontStyle: 'normal',
      fontSize: 0.8,
    },
  );

  result = paragraph(doc, 'CORRELATION', {
    top: result.finalY + 21,
    left: PAGE_PADDING + margin + 3,
    fontStyle: 'bold',
    fontSize: 1,
  });

  result = paragraph(
    doc,
    `Correlation coefficients measure the degree  to which two securities move in relation to one another.  Correlation of the portfolios and benchmark to the S&P 500 Index are provided in the summary and individual portfolio report sections.`,
    {
      top: result.finalY + 2,
      left: PAGE_PADDING + margin + 3,
      maxWidth: halfWidth - 10,
      fontStyle: 'normal',
      fontSize: 0.8,
    },
  );

  result = paragraph(doc, 'CONDITIONAL VALUE AT RISK (CVAR 95%)', {
    top: h1finalY + 8,
    left: pageWidth / 2 + margin + 3,
    fontStyle: 'bold',
    fontSize: 1,
  });

  result = paragraph(
    doc,
    `CVaR is known as the expected shortfall and is derived by taking the weighted average of the “extreme” losses in the tail of the distribution of possible returns.  CVaR is calculated with a 95% confidence interval which refers to the probability that the calculation will fall between a pair of values around the mean.`,
    {
      top: result.finalY + 2,
      left: pageWidth / 2 + margin + 3,
      maxWidth: halfWidth - 10,
      fontStyle: 'normal',
      fontSize: 0.8,
    },
  );

  result = paragraph(doc, 'MAXIMUM DRAWDOWN', {
    top: result.finalY + 27,
    left: pageWidth / 2 + margin + 3,
    fontStyle: 'bold',
    fontSize: 1,
  });

  result = paragraph(
    doc,
    `Maximum drawdown is the maximum observed loss from a peak to a trough of a portfolio before a new peak is attained.`,
    {
      top: result.finalY + 2,
      left: pageWidth / 2 + margin + 3,
      maxWidth: halfWidth - 10,
      fontStyle: 'normal',
      fontSize: 0.8,
    },
  );

  result = paragraph(doc, 'SKEW', {
    top: result.finalY + 14,
    left: pageWidth / 2 + margin + 3,
    fontStyle: 'bold',
    fontSize: 1,
  });

  result = paragraph(
    doc,
    `Skew or skewness refers to the asymmetry that deviates from the bell curve or normal distribution in a set of returns data.  Distributions can exhibit right (positive) or left (negative) skewness to varying degrees while a normal distribution exhibits zero skew.  Skewness informs of the direction of outliers although it does not tell the number of outliers.`,
    {
      top: result.finalY + 2,
      left: pageWidth / 2 + margin + 3,
      maxWidth: halfWidth - 10,
      fontStyle: 'normal',
      fontSize: 0.8,
    },
  );

  result = paragraph(doc, 'KURTOSIS', {
    top: result.finalY + 26,
    left: pageWidth / 2 + margin + 3,
    fontStyle: 'bold',
    fontSize: 1,
  });

  result = paragraph(
    doc,
    `Kurtosis measures the extreme values in the tails of a distribution of returns.  Distributions with large (low) kurtosis exhibit tails data exceeding (less than) the tails of the normal distribution.  Excess kurtosis compares the kurtosis coefficient with that of a normal distribution and signals the probability of an investment obtaining an extreme outcome.`,
    {
      top: result.finalY + 2,
      left: pageWidth / 2 + margin + 3,
      maxWidth: halfWidth - 10,
      fontStyle: 'normal',
      fontSize: 0.8,
    },
  );
};

const definitionPage2 = async (doc, data, params, context) => {
  doc.addPage([297, 210], 'l');

  header(doc);
  footer(doc);

  const pageWidth = doc.internal.pageSize.getWidth();
  const pageInnerWidth = pageWidth - PAGE_PADDING * 2;
  const halfWidth = pageInnerWidth / 2;
  const margin = 3;

  let result = { finalY: 10 };

  result = h1(doc, 'DEFINITIONS AND METHODOLOGY', {
    top: result.finalY + 10,
    fontSize: 1.2,
    underline: false,
  });

  const h1finalY = result.finalY;

  doc.rect(PAGE_PADDING + margin, result.finalY, halfWidth - margin * 2, 120);
  doc.rect(pageWidth / 2 + margin, result.finalY, halfWidth - margin * 2, 120);

  result = paragraph(doc, 'SHARPE RATIO', {
    top: result.finalY + 8,
    left: PAGE_PADDING + margin + 3,
    fontStyle: 'bold',
    fontSize: 1,
  });

  result = paragraph(
    doc,
    `Measures the return per unit of risk and is calculating by dividing the excess return (asset return – risk free rate) by the standard deviation of returns.`,
    {
      top: result.finalY + 2,
      left: PAGE_PADDING + margin + 3,
      maxWidth: halfWidth - 10,
      fontStyle: 'normal',
      fontSize: 0.8,
    },
  );

  result = paragraph(doc, 'SORTINO RATIO', {
    top: result.finalY + 17,
    left: PAGE_PADDING + margin + 3,
    fontStyle: 'bold',
    fontSize: 1,
  });

  result = paragraph(
    doc,
    `Measures the return per unit of risk and is calculating by dividing the excess return (asset return – risk free rate) by the standard deviation of NEGATIVE returns only.  The sortino ratio defines risk as downside volatility only and is a variation of the sharpe ratio which equally accounts for upside and downside volatility.`,
    {
      top: result.finalY + 2,
      left: PAGE_PADDING + margin + 3,
      maxWidth: halfWidth - 10,
      fontStyle: 'normal',
      fontSize: 0.8,
    },
  );

  result = paragraph(doc, 'OMEGA RATIO', {
    top: result.finalY + 26,
    left: PAGE_PADDING + margin + 3,
    fontStyle: 'bold',
    fontSize: 1,
  });

  result = paragraph(
    doc,
    `Measures the return per unit of risk after adjusting for skewness and kurtosis and is defined as the probability-weighted ratio of gains versus losses for a given minimum acceptable return. The omega ratio is  particularly relevant for non-normal distribution as it captures all of the higher statistical moments of the distribution.`,
    {
      top: result.finalY + 2,
      left: PAGE_PADDING + margin + 3,
      maxWidth: halfWidth - 10,
      fontStyle: 'normal',
      fontSize: 0.8,
    },
  );

  result = paragraph(doc, 'MARGINAL CONTRIBUTION TO RISK', {
    top: h1finalY + 8,
    left: pageWidth / 2 + margin + 3,
    fontStyle: 'bold',
    fontSize: 1,
  });

  result = paragraph(
    doc,
    `The marginal contribution to risk measures how much an individual security contributes to the overall risk of a portfolio.  It is calculated based on the covariance matrix and weights of all securities in the portfolio.`,
    {
      top: result.finalY + 2,
      left: pageWidth / 2 + margin + 3,
      maxWidth: halfWidth - 10,
      fontStyle: 'normal',
      fontSize: 0.8,
    },
  );

  result = paragraph(doc, '% CONTRIBUTION TO RETURN', {
    top: result.finalY + 18,
    left: pageWidth / 2 + margin + 3,
    fontStyle: 'bold',
    fontSize: 1,
  });

  result = paragraph(
    doc,
    `Measure of the contribution of an investment on the portfolio’s annualized total return.  Return contribution is a linear calculation of monthly performance relative to the portfolio using a weighted average of the underlying investment returns.`,
    {
      top: result.finalY + 2,
      left: pageWidth / 2 + margin + 3,
      maxWidth: halfWidth - 10,
      fontStyle: 'normal',
      fontSize: 0.8,
    },
  );

  result = paragraph(doc, '% CONTRIBUTION TO RISK', {
    top: result.finalY + 24,
    left: pageWidth / 2 + margin + 3,
    fontStyle: 'bold',
    fontSize: 1,
  });

  result = paragraph(
    doc,
    `Measure of the impact of an investment on the portfolio’s volatility .  Risk contribution is calculated by summing the weighted average standard deviation of each investment and adjusting by its correlation in the portfolio.`,
    {
      top: result.finalY + 2,
      left: pageWidth / 2 + margin + 3,
      maxWidth: halfWidth - 10,
      fontStyle: 'normal',
      fontSize: 0.8,
    },
  );
};

const portfolioComparisonSummary = async (doc, data, params, selectedYear = '1Y', portfolioKeys) => {
  const { cross_corr_dict, mctr_dict, return_summary, risk_summary } = data;
  const returnSummaryData = return_summary[0];
  const returnSummaryDate = return_summary[1];
  const riskSummaryData = risk_summary[0];

  doc.addPage([297, 210], 'portrait');
  header(doc);
  footer(doc);

  let result = { finalY: 10 };
  result = h1(doc, 'Portfolio Comparison', { top: result.finalY + 10 });
  result = h1(doc, 'Summary', { top: result.finalY + 4 });

  const pageWidth = doc.internal.pageSize.getWidth();
  const pageInnerWidth = pageWidth - PAGE_PADDING * 2;
  const chartWidth = pageInnerWidth / 3;
  const chartLefts = [PAGE_PADDING, PAGE_PADDING + chartWidth, PAGE_PADDING + chartWidth * 2];
  const finalY = result.finalY;

  await Promise.all(
    _.map(['1', '2', '3'], async (num, ind) => {
      const chart = getPieChartProps({ labels: params['portFunds' + num], values: params['portValues' + num] });
      const aa = await getChartDataUri(chart, { width: chartWidth - 1, height: chartWidth });

      result = jsPdfImage(doc, aa, {
        title: portfolioKeys[ind],
        left: chartLefts[ind],
        top: finalY + 8,
        width: chartWidth - 1,
        height: chartWidth,
      });
    }),
  );

  result = jsPdfTable(
    doc,
    {
      df: returnSummaryData,
      indexName: 'Fund Name',
      title: 'Returns',
      subTitle: `As of: ${formatDate(returnSummaryDate)}`,
      cellFormatter: (value, row, col) => (['Inception Date'].includes(col) ? value : toPercTable(value)),
    },
    {
      startY: result.finalY + 18,
    },
  );

  result = jsPdfTable(
    doc,
    {
      df: riskSummaryData[selectedYear],
      indexName: 'Portfolio',
      title: 'Risk Summary',
      subTitle: `Time Period: ${selectedYear}`,
      cellFormatter: (value, row, col) => (riskMeasuresPerc.includes(col) ? toPercTable(value) : toDeciTable(value)),
    },
    {
      startY: result.finalY + 16,
      margin: { left: 10, right: 10, top: 20 },
    },
  );
};

const portfolioComparisonRiskCharts = async (doc, data, params, selectedYear = '1Y') => {
  const { cross_corr_dict, mctr_dict, return_summary, risk_summary } = data;
  const returnSummaryData = return_summary[0];
  const returnSummaryDate = return_summary[1];
  const riskSummaryData = risk_summary[0];

  doc.addPage([297, 210], 'portrait');
  header(doc);
  footer(doc);

  let result = { finalY: 10 };
  result = h1(doc, 'Portfolio Comparison', { top: result.finalY + 10 });
  result = h1(doc, 'Risk Charts', { top: result.finalY + 4 });

  const pageWidth = doc.internal.pageSize.getWidth();
  const chartLefts = [PAGE_PADDING, pageWidth / 2];
  const chartWidth = (pageWidth - PAGE_PADDING * 2) / 2;

  const revert = setFont(doc, { fontSize: 0.8 }, { relative: true });
  doc.text(`Time Period: ${selectedYear}`, pageWidth / 2, result.finalY + 2, { align: 'center' });
  doc.text(`As of: ${formatDate(returnSummaryDate)}`, pageWidth / 2, result.finalY + 8, { align: 'center' });
  revert();
  result.finalY = result.finalY + 9;

  await Promise.all(
    _.map(
      ['Standard Deviation', 'Beta', 'CVaR 95%', 'Maximum Drawdown', 'Sharpe Ratio', 'Sortino Ratio'],
      async (type, ind) => {
        const isEven = ind % 2;
        const chart = getBarChartProps({
          data: riskSummaryData[selectedYear],
          cols: [type],
          dataType: riskMeasuresPerc.includes(type) ? 'percentage' : 'decimal',
          wraplabelX: true,
          dataLabels: true,
        });

        const dataUri = await getChartDataUri(chart, { width: 100, height: 50 });
        const tmp = jsPdfImage(doc, dataUri, {
          title: type,
          left: chartLefts[ind % chartLefts.length],
          top: result.finalY + 15,
          width: chartWidth - 2,
          height: 60,
        });

        if (isEven) result = tmp;
      },
    ),
  );
};

const portfolioRiskSummary = async (doc, data, params, selectedYear = '1Y', targetPortfolio) => {
  const { cross_corr_dict, mctr_dict, return_summary, risk_summary } = data;
  const returnSummaryData = return_summary[0];
  const returnSummaryDate = return_summary[1];
  const riskSummaryData = risk_summary[0];

  doc.addPage([297, 210], 'p');
  header(doc);
  footer(doc);

  let result = { finalY: 10 };
  result = h1(doc, 'Portfolio Risk Summary', { top: result.finalY + 10 });
  result = h1(doc, targetPortfolio, { top: result.finalY + 4 });

  const pageWidth = doc.internal.pageSize.getWidth();

  const rowFilter = (rows) =>
    rows.filter((row) => {
      if (!row.startsWith('Portfolio')) return true;
      if (row === targetPortfolio) return true;
      return false;
    });

  result = jsPdfTable(
    doc,
    {
      df: returnSummaryData,
      indexName: 'Fund Name',
      title: 'Returns',
      subTitle: `As of: ${formatDate(returnSummaryDate)}`,
      rows: rowFilter,
      cellFormatter: (value, row, col) => (['Inception Date'].includes(col) ? value : toPercTable(value)),
    },
    {
      startY: result.finalY + 12,
    },
  );

  result = jsPdfTable(
    doc,
    {
      df: riskSummaryData[selectedYear],
      indexName: 'Portfolio',
      title: 'Risk Summary',
      subTitle: `Time Period: ${selectedYear}`,
      rows: rowFilter,
      cellFormatter: (value, row, col) => (riskMeasuresPerc.includes(col) ? toPercTable(value) : toDeciTable(value)),
    },
    {
      startY: result.finalY + 11,
      margin: { left: 10, right: 10, top: 20 },
    },
  );

  const chart = getBarChartProps({
    data: riskSummaryData[selectedYear],
    rows: riskMeasuresRatios,
    cols: [targetPortfolio, params.benchmark],
    wraplabelX: true,
    dataLabels: true,
    flip: true,
  });

  const chartPadding = PAGE_PADDING + 30;
  const chartWidth = pageWidth - chartPadding * 2;

  const dataUri = await getChartDataUri(chart, { width: chartWidth, height: 60 });
  result = jsPdfImage(doc, dataUri, {
    title: 'Risk Chart',
    left: chartPadding,
    top: result.finalY + 15,
    width: chartWidth,
    height: 60,
  });
};

const portfolioHoldingsRiskContribution = async (
  doc,
  data,
  params,
  selectedYear = '1Y',
  portfolio,
  targetPortfolio,
  advisor = '',
) => {
  const { cross_corr_dict, mctr_dict, return_summary, risk_summary } = data;
  const returnSummaryDate = return_summary[1];

  const mctrData = mctr_dict[targetPortfolio][selectedYear][0];

  doc.addPage([297, 210], 'p');
  header(doc);
  footer(doc);

  let result = { finalY: 10 };
  result = h1(doc, 'Holdings and Risk Contribution', { top: result.finalY + 10 });
  result = h1(doc, targetPortfolio, { top: result.finalY + 4 });

  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const pageInnerWidth = pageWidth - PAGE_PADDING * 2;
  const pageInnerHeight = pageHeight - PAGE_PADDING * 2;
  const twoColChartWidth = pageInnerWidth / 2;

  const revert = setFont(doc, { fontSize: 0.8 }, { relative: true });
  doc.text(`Time Period: ${selectedYear}`, pageWidth / 2, result.finalY + 2, { align: 'center' });
  doc.text(`As of: ${formatDate(returnSummaryDate)}`, pageWidth / 2, result.finalY + 8, { align: 'center' });
  revert();
  result.finalY = result.finalY + 9;

  const finalY = result.finalY;

  const chartLeft1 = getPieChartProps({
    labels: params['portFunds' + portfolio],
    values: params['portValues' + portfolio],
  });

  const chartLeftDataUri1 = await getChartDataUri(chartLeft1, {
    width: twoColChartWidth - 1,
    height: twoColChartWidth,
  });

  result = jsPdfImage(doc, chartLeftDataUri1, {
    title: 'Holdings',
    left: PAGE_PADDING,
    top: finalY + 8,
    width: twoColChartWidth - 1,
    height: twoColChartWidth - 20,
    leftPadding: 10,
    rightPadding: 10,
  });

  const chartRight1 = getPieChartProps({
    data: mctrData,
    field: '% Total Contribution to Portfolio Risk',
    filter: (values) => values.filter((row) => row !== 'Total'),
    dataType: 'percentage',
    options: {
      plugins: {
        legend: {
          position: 'bottom',
        },
      },
    },
  });

  const chartRightDataUri1 = await getChartDataUri(chartRight1, {
    width: twoColChartWidth - 1,
    height: twoColChartWidth,
  });

  result = jsPdfImage(doc, chartRightDataUri1, {
    title: 'Contribution to Portfolio Risk',
    left: PAGE_PADDING + twoColChartWidth + 1,
    top: finalY + 8,
    width: twoColChartWidth - 1,
    height: twoColChartWidth - 20,
    leftPadding: 10,
    rightPadding: 10,
  });

  result = jsPdfTable(
    doc,
    {
      df: mctrData,
      indexName: 'Holdings',
      title: 'Portfolio Risk Decomposition',
      subTitle: `Time Period: ${selectedYear}`,
      cellFormatter: (value, row, col) => {
        if (row === 'Total' && col === 'Category (Group)') return _.truncate(value, { length: TRUNCATE_MAX });
        return ['Category (Group)'].includes(col) ? value : toPercTable(value);
      },
    },
    {
      startY: result.finalY + 11,
    },
  );
};

const portfolioHoldingsRiskContribution2 = async (
  doc,
  data,
  params,
  selectedYear = '1Y',
  portfolio,
  targetPortfolio,
  advisor = '',
) => {
  const { cross_corr_dict, mctr_dict, return_summary, risk_summary } = data;

  const mctrData = mctr_dict[targetPortfolio][selectedYear][0];

  const returnSummaryDate = return_summary[1];

  doc.addPage([297, 210], 'p');
  header(doc);
  footer(doc);

  let result = { finalY: 10 };
  result = h1(doc, 'Holdings and Risk Contribution', { top: result.finalY + 10 });
  result = h1(doc, targetPortfolio, { top: result.finalY + 4 });

  const pageWidth = doc.internal.pageSize.getWidth();
  const chartPadding = PAGE_PADDING + 30;
  const chartWidth = pageWidth - chartPadding * 2;
  const chartHeight = 80;

  const revert = setFont(doc, { fontSize: 0.8 }, { relative: true });
  doc.text(`Time Period: ${selectedYear}`, pageWidth / 2, result.finalY + 2, { align: 'center' });
  doc.text(`As of: ${formatDate(returnSummaryDate)}`, pageWidth / 2, result.finalY + 8, { align: 'center' });
  revert();
  result.finalY = result.finalY + 9;

  const chartLeft2 = getBarChartProps({
    data: mctrData,
    rows: (rows) => rows.filter((row) => row !== 'Total'),
    cols: ['Contribution to Portfolio Risk'],
    dataType: 'percentage',
    wraplabelX: false,
    dataLabels: true,
    options: { indexAxis: 'y' },
  });

  const chartLeftDataUri2 = await getChartDataUri(chartLeft2, { width: chartWidth, height: chartHeight });

  result = jsPdfImage(doc, chartLeftDataUri2, {
    title: 'Contribution to Portfolio Risk',
    left: chartPadding,
    top: result.finalY + 7,
    width: chartWidth,
    height: chartHeight,
  });

  const chartRight2 = getBarChartProps({
    data: mctrData,
    rows: (rows) => rows.filter((row) => row !== 'Total'),
    cols: ['% Total Contribution to Portfolio Return', '% Total Contribution to Portfolio Risk'],
    dataType: 'percentage',
    wraplabelX: true,
    dataLabels: true,
  });

  const chartRightDataUri2 = await getChartDataUri(chartRight2, { width: chartWidth, height: chartHeight });

  result = jsPdfImage(doc, chartRightDataUri2, {
    title: 'Risk Reward',
    left: chartPadding,
    top: result.finalY + 20,
    width: chartWidth,
    height: chartHeight,
  });
};

const portfolioHoldingsCrossCorrelation = async (doc, data, params, selectedYear = '1Y', targetPortfolio) => {
  const { cross_corr_dict, mctr_dict, return_summary, risk_summary } = data;

  const corrData1 = cross_corr_dict[targetPortfolio][selectedYear][0];
  const corrData2 = cross_corr_dict[targetPortfolio][selectedYear][1];

  const returnSummaryDate = return_summary[1];

  const pageWidth = doc.internal.pageSize.getHeight();
  const pageInnerWidth = pageWidth - PAGE_PADDING * 2;

  doc.addPage([297, 210], 'l');
  header(doc);
  footer(doc);

  let result = { finalY: 10 };
  result = h1(doc, 'Holdings Cross Correlation', { top: result.finalY + 10 });
  result = h1(doc, targetPortfolio, { top: result.finalY + 4 });

  const revert = setFont(doc, { fontSize: 0.8 }, { relative: true });
  doc.text(`Time Period: ${selectedYear}`, pageWidth / 2, result.finalY + 2, { align: 'center' });
  doc.text(`As of: ${formatDate(returnSummaryDate)}`, pageWidth / 2, result.finalY + 8, { align: 'center' });
  revert();
  result.finalY = result.finalY + 9;

  const indexWidth = 55;
  const cellWidth = 20;
  let columns = corrData1.columns;
  let cellWidths = cellWidth * columns.length;
  let maxWidth = indexWidth + cellWidths;
  if (maxWidth > pageInnerWidth) {
    maxWidth = null;
  }

  const heatmap = getHeatmap(_.flattenDeep([corrData1.data, corrData2.data]));

  result = jsPdfTable(
    doc,
    {
      df: corrData1,
      indexName: 'Holdings',
      cellFormatter: toDeciTable,
      equalWidth: true,
      heatmap,
      indexWidth,
      maxWidth,
    },
    {
      startY: result.finalY + 11,
    },
  );

  result = jsPdfTable(
    doc,
    {
      df: corrData2,
      cellFormatter: toDeciTable,
      equalWidth: true,
      heatmap,
      showHeader: false,
      indexWidth,
      maxWidth,
    },
    {
      startY: result.finalY + 3,
    },
  );
};

export const exportPDF = async (data, params, context = {}) => {
  if (!data) return;
  const doc = new jsPDF('l', 'mm', [297, 210]);

  const { session, selectedYear = '1Y', selectedAdvisor, setReportProgress, open = false } = context;
  const portfolioKeys = _.keys(data.mctr_dict);

  doc.setFontSize(14);
  header(doc);
  footer(doc);

  const max = 18;
  let value = 0;

  setReportProgress({ max, value: value++, message: 'generating cover page...' });
  await coverPage(doc, data, params, context);

  setReportProgress({ max, value: value++, message: 'generating disclaimer page...' });
  await disclaimerPage(doc, data, params, context);

  setReportProgress({ max, value: value++, message: 'generating comparison summary...' });
  await portfolioComparisonSummary(doc, data, params, selectedYear, portfolioKeys);

  setReportProgress({ max, value: value++, message: 'generating comparison risk charts...' });
  await portfolioComparisonRiskCharts(doc, data, params, selectedYear);

  for (let x = 0; x < portfolioKeys.length; x++) {
    const portfolioKey = portfolioKeys[x];
    setReportProgress({ max, value: value++, message: `generating portfolio "${portfolioKey}" risk summary...` });
    await portfolioRiskSummary(doc, data, params, selectedYear, portfolioKey);

    setReportProgress({ max, value: value++, message: `generating portfolio "${portfolioKey}" risk contribution...` });
    await portfolioHoldingsRiskContribution(doc, data, params, selectedYear, x + 1, portfolioKey);

    setReportProgress({ max, value: value++, message: `generating portfolio "${portfolioKey}" risk contribution...` });
    await portfolioHoldingsRiskContribution2(doc, data, params, selectedYear, x + 1, portfolioKey);

    setReportProgress({ max, value: value++, message: `generating portfolio "${portfolioKey}" cross correlation...` });
    await portfolioHoldingsCrossCorrelation(doc, data, params, selectedYear, portfolioKey);
  }

  setReportProgress({ max, value: value++, message: 'generating definition page...' });
  await definitionPage1(doc, data, params, context);

  setReportProgress({ max, value: value++, message: 'generating definition page...' });
  await definitionPage2(doc, data, params, context);

  const title = `portfolio-analysis-${new Date().toLocaleString()}.pdf`;

  doc.setProperties({
    title,
    subject: 'Portfolio Analysis Report',
    author: 'Anchor Pacific Financial Risk Labs Ltd.',
    keywords: 'Portfolio, Analysis',
    creator: 'Anchor Pacific Financial Risk Labs Ltd.',
  });

  if (open) {
    const blob = doc.output('blob', { filename: title });
    window.open(blob);
  } else {
    doc.save(title);
  }

  return true;
};
