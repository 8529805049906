import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody, TabPane, Row, Col, Table } from 'reactstrap';
import PageTabs from 'components/PageTabs';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { toDeci, toDeciTable, toPercTable, formatDate } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';

function Correlation({ data, params, options = {}, notify, session, header, footer }) {
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [selectedSubjectOption, setSelectedSubjectOption] = useState(null);
  const [assetOptions, setAssetOptions] = useState([]);
  const [selectedAssetOption, setSelectedAssetOption] = useState(null);

  useEffect(() => {
    if (!data) return;

    const _assetOptions = optionizeAll(data['asset correlation'].data.index);
    setAssetOptions(_assetOptions);

    const _subjectOptions = optionizeAll(data['cross correlation'].data.index);
    setSubjectOptions(_subjectOptions);

    if (_assetOptions.length > 0) {
      setSelectedAssetOption(_assetOptions[0]);
    }

    if (_subjectOptions.length > 0) {
      setSelectedSubjectOption(_subjectOptions[0]);
    }
  }, [data]);

  return (
    <>
      <PageTabs
        options={[
          { value: 'asset-correlations', label: 'Asset Correlations' },
          { value: 'cross-correlations', label: 'Cross Correlations' },
        ]}
        colorIndex={1}
      >
        <TabPane tabId="asset-correlations" role="tabpanel">
          {header}
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Asset Correlations
              <TimePeriod dates={data['asset correlation'].table_dates} />
            </CardHeader>
            <CardBody>
              <TightTable
                data={data['asset correlation'].data}
                indexWidth="350px"
                cellFormatter={toDeciTable}
                suffix="risk-performance-asset-correlation"
                heatmap
                useSort
                title={['Asset Correlations', formatDates(data['asset correlation'].table_dates)]}
                {...options.assetCorrelationTable}
              />
            </CardBody>
          </Card>
          <div className="tw-grid tw-grid-cols-6 tw-gap-4">
            <div className="tw-col-start-2 tw-col-span-4">
              <div className="tw-text-left tw-font-bold">SELECT ASSET CLASS</div>
              <Select
                className="react-select mw-400px"
                value={selectedAssetOption}
                onChange={setSelectedAssetOption}
                options={assetOptions}
              />
              {selectedAssetOption && (
                <Card>
                  <CardHeader tag="h4" className="mt-0 text-center">
                    <div>Asset Class: {selectedAssetOption.label}</div>
                    <TimePeriod dates={data['asset correlation'].table_dates} />
                  </CardHeader>
                  <CardBody>
                    <TightChart
                      title={`Asset Class: ${selectedAssetOption.label}`}
                      data={data['asset correlation'].data}
                      cols={[selectedAssetOption.value]}
                      rows={(rows) => rows.filter((row) => row !== selectedAssetOption.value)}
                      flip={true}
                      wraplabelX={true}
                    />
                  </CardBody>
                </Card>
              )}
            </div>
          </div>
          {footer}
        </TabPane>
        <TabPane tabId="cross-correlations" role="tabpanel">
          {header}
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Cross Correlations
              <TimePeriod dates={data['cross correlation'].table_dates} />
            </CardHeader>
            <CardBody>
              <TightTable
                data={data['cross correlation'].data}
                indexWidth="350px"
                cellFormatter={toDeciTable}
                suffix="risk-performance-cross-correlation"
                heatmap
                title={['Cross Correlations', formatDates(data['cross correlation'].table_dates)]}
              />
            </CardBody>
          </Card>
          <div className="tw-grid tw-grid-cols-6 tw-gap-4">
            <div className="tw-col-start-2 tw-col-span-4">
              <div className="tw-text-left tw-font-bold">SELECT</div>
              <Select
                className="react-select mw-400px"
                value={selectedSubjectOption}
                onChange={setSelectedSubjectOption}
                options={subjectOptions}
              />
              {selectedSubjectOption && (
                <Card>
                  <CardHeader tag="h4" className="mt-0 text-center">
                    {selectedSubjectOption.label}
                    <TimePeriod dates={data['cross correlation'].table_dates} />
                  </CardHeader>
                  <CardBody>
                    <TightChart
                      title={selectedSubjectOption.label}
                      data={data['cross correlation'].data}
                      cols={[selectedSubjectOption.value]}
                      rows={(rows) => rows.filter((row) => row !== selectedSubjectOption.value)}
                      flip={true}
                      wraplabelX={true}
                    />
                  </CardBody>
                </Card>
              )}
            </div>
          </div>
          {footer}
        </TabPane>
      </PageTabs>
    </>
  );
}

export default Correlation;
