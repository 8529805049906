import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { Card, CardHeader, CardBody, TabPane, Row, Col, Table } from 'reactstrap';
import PieChart from 'components/PieChart';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';

function RiskContribution({ data: { data, table_dates }, params, notify, session }) {
  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Risk Contribution
          <div className="tw-text-lg">{params.product}</div>
          <TimePeriod dates={table_dates} />
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="risk-performance-risk-contribution"
            data={data}
            indexName=""
            indexWidth="250px"
            dataType="percentage"
            useSort={true}
            hideIndexTable={true}
            title={['Risk Contribution', params.product, formatDates(table_dates)]}
          />
        </CardBody>
      </Card>

      <AutoColumns>
        <>
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Risk Contribution
            </CardHeader>
            <CardBody>
              <TightChart
                title="Risk Contribution"
                data={data}
                options={{ indexAxis: 'y' }}
                base="cols"
                cols={(cols) => cols.filter((cols) => !['R2'].includes(cols))}
                dataType="percentage"
              />
            </CardBody>
          </Card>
        </>
        <>
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              {params.product}
            </CardHeader>
            <CardBody>
              <PieChart
                title={params.product}
                data={data}
                base="cols"
                field={data.index[0]}
                dataType="percentage"
                containerStyle={{ maxWidth: '500px', maxHeight: '500px' }}
                filter={(labels) => labels.filter((label) => !['Total Risk', 'R2'].includes(label))}
              />
            </CardBody>
          </Card>
        </>
      </AutoColumns>
    </>
  );
}

export default RiskContribution;
