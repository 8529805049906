import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { Card, CardHeader, CardBody, Label, TabPane, Row, Col } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { getTightColIndexByKey } from 'helpers/chart';
import { removeEmptyCols } from 'helpers/data-frame';
import { toDeci, toDeciTable, toPercTable, tablePresets } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';

function ValueAtRiskAnalysis({ data, params, notify, session }) {
  const [durationOptions, setDurationOptions, selectedDurationOption, setSelectedDurationOption] = useArrayState();

  useEffect(() => {
    if (!data) return;

    const compactAsset = removeEmptyCols(data.asset['0.95']);
    const _durations = compactAsset.columns;
    setDurationOptions(optionizeAll(_durations));
  }, [data]);

  const benchmarkTightDict = useMemo(() => {
    if (!data || !selectedDurationOption) return null;

    const target = data.asset['0.95'];
    const duration = getTightColIndexByKey(target, selectedDurationOption.value);

    const index = [params.product];
    if (data.benchmark) index.push(params.benchmark);

    const columns = target.index;
    const _data = index.map((row, rowInd) => {
      if (rowInd === 0) return columns.map((col, colInd) => target.data[colInd][duration]);
      else if (data.benchmark) {
        const benchm = data.benchmark['0.95'];
        return columns.map((col, colInd) => benchm.data[colInd][duration]);
      }
    });

    return { index, columns, data: _data };
  }, [params, data, selectedDurationOption]);

  if (!selectedDurationOption || !benchmarkTightDict) return;

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Value at Risk (VaR)
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="benchmark-value-at-risk"
            data={data.asset['0.95']}
            data2={data.benchmark && data.benchmark['0.95']}
            rowHeader={{ text: params.product, style: { backgroundColor: '#C6E0B4' } }}
            rowHeader2={{ text: params.benchmark, style: { backgroundColor: 'rgb(251,198,88,0.5)' } }}
            indexName="Time Interval"
            cellFormatter={toPercTable}
            title={['Value at Risk (VaR)']}
          />
        </CardBody>
      </Card>

      <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
      <Select
        className="react-select mw-200px"
        name="duration"
        value={selectedDurationOption}
        onChange={setSelectedDurationOption}
        options={durationOptions}
      />

      <Row>
        <Col xl="6">
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Value at Risk (VaR)
            </CardHeader>
            <CardBody>
              <TightTable
                suffix="benchmark-value-at-risk-period"
                data={benchmarkTightDict}
                cellFormatter={toPercTable}
                title={['Value at Risk (VaR)']}
              />
            </CardBody>
          </Card>
        </Col>
        <Col xl="6">
          <Card>
            <CardHeader>&nbsp;</CardHeader>
            <CardBody>
              <TightChart
                title="Value at Risk (VaR)"
                data={benchmarkTightDict}
                base="rows"
                valueFormatter={(v) => parseFloat(v) * 100}
                options={{
                  plugins: {
                    tooltip: {
                      callbacks: {
                        label: (item, data) => `${item.dataset.label}: ${toDeci(item.raw)}%`,
                      },
                    },
                  },
                  scales: { y: { ticks: { callback: (val) => `${toDeci(val)}%` } } },
                }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default ValueAtRiskAnalysis;
