import { parseSearchParams } from 'helpers/location';

const pToD = (p) => (!p ? 0 : parseFloat(p) / 100);

export const prepareData = (data) => {
  const {
    assets,
    databases,
    weights1,
    weights2,
    weights3,
    benchmark,
    geography,
    riskbucket,
    correlation,
    significance,
    sdate,
    edate,
    advisorFees,
    rebalanceFreq,
    accrualFreq,
    deductionFreq,
    port1Name,
    port2Name,
    port3Name,
    holdings,
    converter,
  } = data;
  let product = [];
  const addValidAssets = (ratios) => {
    _.each(ratios, (val, i) => {
      const num = Number(val);
      if (isNaN(num)) return;

      if (num > 0) product.push(assets[i]);
    });
  };

  addValidAssets(weights1);
  addValidAssets(weights2);
  addValidAssets(weights3);
  product = _.uniq(product);

  const createPortfolio = (ratios) => {
    return _.reduce(
      product,
      (ret, fund, i) => {
        const ind = assets.indexOf(fund);
        const num = Number(ratios[ind]);
        if (isNaN(num) || num === 0) return ret;

        ret[fund] = pToD(num);
        return ret;
      },
      {},
    );
  };

  const portfolio1 = createPortfolio(weights1);
  const portfolio2 = createPortfolio(weights2);
  const portfolio3 = createPortfolio(weights3);

  const portfolios = {};
  if (!_.isEmpty(portfolio1)) portfolios[port1Name] = portfolio1;
  if (!_.isEmpty(portfolio2)) portfolios[port2Name] = portfolio2;
  if (!_.isEmpty(portfolio3)) portfolios[port3Name] = portfolio3;

  const portfolio_params = {
    fee: pToD(advisorFees) || 0,
    rebalance: rebalanceFreq || '',
    accrual: accrualFreq || '',
    deduction: deductionFreq || '',
    structure: 'General',
  };

  return {
    product,
    benchmark,
    geography,
    riskbucket,
    correlation,
    significance,
    sdate,
    edate,
    portfolios: JSON.stringify(portfolios),
    portfolio_params: JSON.stringify(portfolio_params),
    database: product.map((asset) => databases[assets.findIndex((v) => v === asset)]),
    with_holdings: holdings,
    converter,
  };
};

export const parseParams = (urlSearch) => {
  const params = parseSearchParams(urlSearch, [
    { key: 'assets', multi: true },
    { key: 'databases', multi: true },
    { key: 'weights1', multi: true },
    { key: 'weights2', multi: true },
    { key: 'weights3', multi: true },
    'benchmark',
    'geography',
    'riskbucket',
    'correlation',
    'significance',
    'sdate',
    'edate',
    'rebalanceFreq',
    'accrualFreq',
    'deductionFreq',
    'advisorFees',
    'port1Name',
    'port2Name',
    'port3Name',
    'holdings',
    'converter',
  ]);

  const portFunds1 = [];
  const portValues1 = [];
  const portFunds2 = [];
  const portValues2 = [];
  const portFunds3 = [];
  const portValues3 = [];

  _.each(params.weights1, (val, i) => {
    const v = Number(val);
    if (!isNaN(v) && v > 0) {
      portFunds1.push(params.assets[i]);
      portValues1.push(v);
    }
  });

  _.each(params.weights2, (val, i) => {
    const v = Number(val);
    if (!isNaN(v) && v > 0) {
      portFunds2.push(params.assets[i]);
      portValues2.push(v);
    }
  });

  _.each(params.weights3, (val, i) => {
    const v = Number(val);
    if (!isNaN(v) && v > 0) {
      portFunds3.push(params.assets[i]);
      portValues3.push(v);
    }
  });

  params.portFunds1 = portFunds1;
  params.portValues1 = portValues1;
  params.portFunds2 = portFunds2;
  params.portValues2 = portValues2;
  params.portFunds3 = portFunds3;
  params.portValues3 = portValues3;
  params.holdings = params.holdings === 'true';

  return params;
};
