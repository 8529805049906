import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { Card, CardHeader, CardBody, Label, TabPane, Row, Col } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { getTightRowByKey, getTightColIndexByKey, colors } from 'helpers/chart';
import { riskMeasuresDeci2 } from 'helpers/meta';
import { toDeci, toDeciTable, toPercTable, tablePresets } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';

export const riskRatioGroups = [
  {
    label: 'CAGR and Std Dev',
    value: { keys: ['CAGR', 'Standard Deviation'], type: 'percentage' },
  },
  {
    label: 'Beta and Correlation',
    value: { keys: ['Beta', 'Correlation'], type: 'decimal' },
  },
  {
    label: 'CVaR and Max DD',
    value: { keys: ['CVaR 95%', 'Maximum Drawdown'], type: 'percentage' },
  },
  {
    label: 'Std Dev and Downside Dev',
    value: { keys: ['Standard Deviation', 'Downside Deviation'], type: 'percentage' },
  },
  {
    label: 'Skew and Kurtosis',
    value: { keys: ['Skew', 'Kurtosis'], type: 'decimal' },
  },
  {
    label: 'Sharpe and Sortino',
    value: { keys: ['Sharpe Ratio', 'Sortino Ratio'], type: 'decimal' },
  },
  {
    label: 'MAR and Omega',
    value: { keys: ['MAR Ratio (RoMaD)', 'Omega Ratio'], type: 'decimal' },
  },
  {
    label: 'Ratios (Sharpe, Sortino, Omega)',
    value: { keys: ['Sharpe Ratio', 'Sortino Ratio', 'Omega Ratio'], type: 'decimal' },
  },
];

function PortfolioImpact({ data, params, notify, session }) {
  const [periodOptions, setPeriodOptions, selectedPeriodOption, setSelectedPeriodOption] = useArrayState();
  const [ratioOptions, setRatioOptions, selectedRatioOption, setSelectedRatioOption] = useArrayState({
    initialItems: riskRatioGroups,
  });

  useEffect(() => {
    if (!data) return;

    const _periods = _.keys(data);
    const _periodOptions = optionizeAll(_periods, { labelFormatter: _.upperFirst });
    setPeriodOptions(_periodOptions);
  }, [data]);

  if (!selectedPeriodOption || !selectedRatioOption) return null;

  const periodData = data[selectedPeriodOption.value];

  return (
    <>
      <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
      <Select
        className="react-select mw-200px"
        name="duration"
        value={selectedPeriodOption}
        onChange={setSelectedPeriodOption}
        options={periodOptions}
      />

      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Risk Metrics
          <TimePeriod dates={periodData.table_dates} prefix="Time Period:" />
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="risk-performance-impact"
            data={periodData.data}
            indexName=""
            indexWidth="150px"
            useSort
            rowDecorator={(rowTitle, rowInd, rowTitles) =>
              rowTitle === 'Original Portfolio' ? { style: { backgroundColor: 'rgb(251,198,88,0.5)' } } : undefined
            }
            cellFormatter={(value, row, col) =>
              riskMeasuresDeci2.includes(col) ? toDeciTable(value) : toPercTable(value)
            }
            title={['Risk Metrics', formatDates(periodData.table_dates)]}
          />
        </CardBody>
      </Card>

      <div className="tw-grid tw-grid-cols-6 tw-gap-4">
        <div className="tw-col-start-2 tw-col-span-4">
          <div style={{ width: '290px' }}>
            <div className="tw-text-left tw-font-bold">SELECT RISK METRICS</div>
            <Select
              className="react-select w-full"
              name="selectedRatioOption"
              value={selectedRatioOption}
              onChange={setSelectedRatioOption}
              options={ratioOptions}
            />
          </div>
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Risk Charts
            </CardHeader>
            <CardBody>
              <TightChart
                title="Risk Charts"
                data={periodData.data}
                cols={selectedRatioOption.value.keys}
                base="cols"
                dataType={selectedRatioOption.value.type}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default PortfolioImpact;
