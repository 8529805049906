import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import _ from 'lodash';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import Select from 'react-select';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import AssetInfo from './AssetInfo';
import { optionize, optionizeAll } from 'helpers/select';
import { getTightRowByKey } from 'helpers/chart';
import { toPercTable, formatDate } from 'helpers/formatter';
import { parseSearchParams } from 'helpers/location';
import { getAdvancedAnalyticsRollingReturns } from 'services/analysis';

const SpinnerWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const formatReturnPeriod = (value) => {
  const dates = value.split(' - ');
  if (dates.length < 2) return value;
  return dates.map((date) => date.substring(0, 7)).join(' - ');
};

function SingleAssetRollingReturn({ params, notify, session }) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [rowNames, setRowNames] = useState();
  const [selectedRowName, setSelectedRowName] = useState();
  const [colNames, setColNames] = useState();
  const [selectedColName, setSelectedColName] = useState();

  const fetchResult = async () => {
    if (!params) return;

    setLoading(true);
    const [data, err] = await getAdvancedAnalyticsRollingReturns(params);
    if (err) {
      notify.danger({ message: <div>{err}</div> });
    } else {
      data.rolling_analysis.index = data.rolling_analysis.index.filter(
        (row) =>
          ![
            'Correlation',
            'Beta',
            'Beta T-Stat',
            'Beta Significance',
            'Alpha',
            'Alpha T-Stat',
            'Alpha Significance',
            'R2',
          ].includes(row),
      );

      setResult(data);
      const analysisData = data.rolling_analysis.data;
      const index = data.rolling_analysis.index.filter((row) => !row.endsWith('Period'));
      const columns = data.rolling_analysis.columns.filter((col, i) =>
        index.some((row, j) => analysisData[j][i] && analysisData[j][i] !== '-'),
      );
      setRowNames(optionizeAll(index));
      setColNames(optionizeAll(columns));
      setSelectedRowName(optionize(index[0]));
      setSelectedColName(optionize(columns[0]));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (params) fetchResult();
  }, [params]);

  const lowHighReturnDF = useMemo(() => {
    if (!result) return null;

    const lows = getTightRowByKey(result.rolling_analysis, 'Lowest Return');
    const highs = getTightRowByKey(result.rolling_analysis, 'Highest Return');
    const lowest = _.min(lows);
    const highest = _.max(highs);

    const lowIndex = lows.findIndex((v) => v === lowest);
    const highIndex = highs.findIndex((v) => v === highest);

    const lowPeriods = getTightRowByKey(result.rolling_analysis, 'Lowest Return Time Period');
    const highPeriods = getTightRowByKey(result.rolling_analysis, 'Highest Return Time Period');
    const lowestPeriod = lowPeriods[lowIndex];
    const highestPeriod = highPeriods[highIndex];

    return {
      index: ['Lowest Return', 'Highest Return'],
      columns: ['value', 'period'],
      data: [
        [lowest, lowestPeriod],
        [highest, highestPeriod],
      ],
    };
  }, [result]);

  if (loading || !result) {
    return (
      <SpinnerWrapper>
        <Spinner>Loading...</Spinner>
      </SpinnerWrapper>
    );
  }

  return (
    <>
      <AssetInfo
        asset={params.product}
        database={params.database}
        data={result}
        valueMap={{
          name: 'info.Fund Name',
          description: 'info.Fund Description',
          benchmark: 'info.Benchmark Name',
          geography: 'info.geography',
          startDate: 'info.Dates.0',
          endDate: 'info.Dates.1',
          cumulative: 'info.cumul_return',
          cagr: 'info.cagr',
          deviation: 'info.std',
        }}
      />
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Rolling Returns Analysis
          <div className="small">Inception: {formatDate(result.info.Dates[0])}</div>
          <div className="small">As of: {formatDate(result.info.Dates[1])}</div>
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="rolling-returns-analysis"
            data={result.rolling_analysis}
            indexName="Rolling Period"
            indexWidth="250px"
            cellFormatter={(value, row, col) =>
              [
                '% Positive',
                '% Negative',
                'Average Return',
                'Median Return',
                'Standard Deviation',
                'Average Positive Return',
                'Average Negative Return',
                'Highest Return',
                'Lowest Return',
              ].includes(row)
                ? toPercTable(value)
                : ['Highest Return Time Period', 'Lowest Return Time Period'].includes(row)
                ? formatReturnPeriod(value)
                : value
            }
            title={[
              'Rolling Returns Analysis',
              `Inception: ${formatDate(result.info.Dates[0])}`,
              `As of: ${formatDate(result.info.Dates[1])}`,
            ]}
          />
        </CardBody>
      </Card>
      {rowNames && rowNames.length > 0 && (
        <Row>
          <Col xl="8" className="offset-xl-2">
            <div className="tw-text-left tw-font-bold">SELECT</div>
            <Select
              className="react-select mw-300px"
              name="rowName"
              value={selectedRowName}
              onChange={setSelectedRowName}
              options={rowNames}
            />
            <Card>
              <CardBody>
                <TightChart
                  data={result.rolling_analysis}
                  rows={[selectedRowName.value]}
                  base="cols"
                  dataType={
                    [
                      '% Positive',
                      '% Negative',
                      'Average Return',
                      'Median Return',
                      'Standard Deviation',
                      'Average Positive Return',
                      'Average Negative Return',
                      'Highest Return',
                      'Lowest Return',
                    ].includes(selectedRowName.value)
                      ? 'percentage'
                      : 'text'
                  }
                  omitEmpty={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {colNames && colNames.length > 0 && (
        <>
          <div className="tw-text-left tw-font-bold">SELECT ROLLING PERIOD</div>
          <Select
            className="react-select mw-200px"
            name="colName"
            value={selectedColName}
            onChange={setSelectedColName}
            options={colNames}
          />
          <Row>
            <Col xl="6">
              <Card>
                <CardBody>
                  <TightChart
                    data={result.rolling_analysis}
                    rows={['Average Return', 'Average Positive Return', 'Average Negative Return']}
                    cols={[selectedColName.value]}
                    omitEmpty={true}
                    dataType="percentage"
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xl="6">
              <Card>
                <CardBody>
                  {/* <TightChart
                    data={lowHighReturnDF}
                    dataType="percentage"
                    cols={['value']}
                    omitEmpty={true}
                    options={{
                      plugins: {
                        datalabels: {
                          formatter: (value, context) => {
                            const row = lowHighReturnDF.data.find((v) => v[0] === value);
                            return [row[1], toPerc(value, { suffix: '%' })];
                          },
                        },
                      },
                    }}
                  /> */}
                  <TightChart
                    data={result.rolling_analysis}
                    rows={['Lowest Return', 'Highest Return']}
                    cols={[selectedColName.value]}
                    omitEmpty={true}
                    dataType="percentage"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default SingleAssetRollingReturn;
