import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import _ from 'lodash';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Table,
  Row,
  Col,
  Spinner,
  UncontrolledTooltip,
  TabPane,
  CardFooter,
} from 'reactstrap';
import PageTabs from 'components/PageTabs';
import { FixedCenterLoader } from 'components/Loaders';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import DownloadSimpleTable from 'components/DownloadSimpleTable';
import ReactDatetime from 'react-datetime';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { getChartBetaCorrel } from 'services/analysis';
import { optionizeValue, optionizeAll, getValue, getDate, getYearMonth, monthDiff } from 'helpers/select';
import { confidenceLevelToInterval } from 'helpers/formatter';
import { toDeci, toPerc, toPercTable, toDeciTable, tablePresets, getDataType, formatCurrency } from 'helpers/formatter';
import PieChart from 'components/PieChart';
import TightTable from 'components/TightTable';
import { Descriptions, Notes } from '../others';
import MultipleAssetSelect from '../MultipleAssetSelect';
import BetaCorrelationBase from './beta-correlation/BetaCorrelation';
import SingleAssetHeader from 'views/SingleAssetHeader';

function BetaCorrelation({ session }) {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({
    product: '',
    database: '',
    factor: '',
    benchmark: '',
    chart: '',
    window: '12',
    converter: '',
  });
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);

    const [data, err] = await getChartBetaCorrel(options);

    setResult(data);
    setError(err);

    setLoading(false);
  };

  useEffect(() => {
    if (!options.product) return;
    fetchData();
  }, [options]);

  console.log('result', result);

  let main = null;
  if (loading) {
    main = <FixedCenterLoader />;
  } else if (result) {
    const chartData = result.chart[options.chart];
    const tableData = result.table[options.chart];

    main = (
      <>
        <SingleAssetHeader data={result.header} />
        <Descriptions content={result.description} />
        <BetaCorrelationBase data={{ chart: chartData, table: tableData }} params={options} />
        <Notes content={result.notes} />
      </>
    );
  } else if (error) {
    main = (
      <>
        <div className="tw-p-4 tw-mb-4 tw-text-sm tw-text-blue-800 tw-rounded-lg tw-bg-blue-100" role="alert">
          <span className="tw-font-bold">{error}</span>
        </div>
      </>
    );
  }

  return (
    <div className="tw-grid tw-grid-cols-12 tw-gap-4">
      <div className="tw-col-span-12 xl:tw-col-span-3">
        <MultipleAssetSelect
          session={session}
          onSelect={({ assets, databases, factor, benchmark, chart, period, converter }) => {
            setOptions({
              product: assets[0],
              database: databases[0],
              factor,
              benchmark,
              chart,
              window: period,
              converter,
            });
          }}
          title="SELECT ASSET"
          minItems={1}
          maxItems={1}
          includePortfolio
          enableChart
          enablePeriod
          enableBenchmark
          enableFactor
          factorOrBenchmark
        />
      </div>
      <div className="tw-col-span-12 xl:tw-col-span-9">{main}</div>
    </div>
  );
}

export default BetaCorrelation;
