import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import _ from 'lodash';
import { Card, CardHeader, CardBody, TabPane, Row, Col } from 'reactstrap';
import { Chart } from 'react-chartjs-2';
import Select from 'react-select';
import PageTabs from 'components/PageTabs';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import LineChart from 'components/LineChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import AssetInfo from './AssetInfo';
import { optionize, optionizeAll } from 'helpers/select';
import { getTightColIndexByKey } from 'helpers/chart';
import { toDeci, toDeciTable, toPercTable, formatDate } from 'helpers/formatter';
import { removeEmptyCols } from 'helpers/data-frame';
import { parseSearchParams } from 'helpers/location';
import { drawdownMetrics } from 'helpers/meta';
import { getAdvancedAnalyticsDrawdown } from 'services/analysis';

const SpinnerWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

function SingleAssetDrawdownAnalysis({ params, notify, session }) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [selectedMetric, setSelectedMetric] = useState(drawdownMetrics[0]);
  const [durations, setDurations] = useState();
  const [selectedDuration, setSelectedDuration] = useState();
  const [factors, setFactors] = useState([]);
  const [selectedFactor, setSelectedFactor] = useState(null);
  const [currentTab, setCurrentTab] = useState({ value: 'summary' });

  const fetchResult = async () => {
    if (!params) return;

    setLoading(true);
    const [data, err] = await getAdvancedAnalyticsDrawdown(params);

    if (err) {
      notify.danger({ message: <div>{err}</div> });
    } else {
      setResult(data);

      const _compactSummary = removeEmptyCols(data.summary_tab[0]);
      const durs = _compactSummary.columns;
      setDurations(optionizeAll(durs));
      if (durs.length > 0) setSelectedDuration(optionize(durs[0]));

      const assetIndex = getTightColIndexByKey(data.market_stress_tab, params.product);
      const benchIndex = getTightColIndexByKey(data.market_stress_tab, 'Benchmark');

      const facts = data.market_stress_tab.index.filter((v, i) => {
        const assetValue = data.market_stress_tab.data[i][assetIndex];
        const benchValue = data.market_stress_tab.data[i][benchIndex];

        return assetValue || benchValue;
      });

      setFactors(optionizeAll(facts));
      if (facts.length > 0) setSelectedFactor(optionize(facts[0]));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchResult();
  }, [params]);

  const drawdownChart = useMemo(() => {
    if (!result) return null;

    const data1 = _.get(result, 'dd_series.0');
    const data2 = _.get(result, 'dd_series.1');

    const firstAssetStartDate = new Date(_.sortBy(_.keys(data1))[0]).getTime();

    // remove all benchmark values that starts before the asset start date
    _.each(_.sortBy(_.keys(data2)), (date) => {
      const d = new Date(date).getTime();
      if (d < firstAssetStartDate) delete data2[date];
    });

    _.each(data1, (val, key) => {
      if (_.isNil(data2[key])) data2[key] = -1;
    });

    _.each(data2, (val, key) => {
      if (_.isNil(data1[key])) data1[key] = -1;
    });

    const allDates = _.sortBy(_.keys(data1));

    const dataLabel1 = params.product;
    const dataLabel2 = _.get(result, 'info.Benchmark Name');

    const getValues = (dataMap) =>
      allDates.map((date) => {
        return dataMap[date] === -1 ? undefined : dataMap[date] * 100;
      });

    const data = {
      labels: allDates.map((v) => v.substring(0, 7)),
      datasets: [
        {
          label: dataLabel1,
          data: getValues(data1),
          fill: false,
          backgroundColor: 'rgba(51,102,204,0.2)',
          borderColor: 'rgb(51,102,204)',
          borderWidth: 2,
          tension: 0,
          pointRadius: 3,
          pointHoverRadius: 4,
        },
        {
          label: dataLabel2,
          data: getValues(data2),
          fill: false,
          backgroundColor: 'rgba(220,57,18,0.2)',
          borderColor: 'rgb(220,57,18)',
          borderWidth: 2,
          tension: 0,
          pointRadius: 3,
          pointHoverRadius: 4,
        },
      ],
    };

    const options = {
      plugins: {
        legend: { display: true },
        tooltip: {
          enabled: true,
          callbacks: {
            label: (item) => {
              return `${item.label}: ${toDeci(item.raw)}%`;
            },
          },
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        x: {
          title: {
            display: false,
          },
          ticks: {
            autoSkip: true,
            maxRotation: 0,
            minRotation: 0,
            color: 'black',
          },
          beginAtZero: false,
        },
        y: {
          title: {
            display: true,
          },
          ticks: {
            callback: (val) => toDeci(val, { precision: 1, absolute: true, suffix: '%' }),
            color: ({ tick }) => (tick.value < 0 ? 'red' : 'black'),
          },
          beginAtZero: false,
        },
      },
    };

    return { data, options };
  }, [result]);

  const comparisonDf1 = useMemo(() => {
    if (!result) return null;

    const data1 = _.get(result, 'summary_tab.0');
    const data2 = _.get(result, 'summary_tab.1');

    const colIndex = getTightColIndexByKey(data1, selectedDuration.value);

    return {
      index: data1.index,
      columns: [params.product, _.get(result, 'info.Benchmark Name')],
      data: data1.index.map((rowName, i) => {
        return [data1.data[i][colIndex], data2.data[i][colIndex]];
      }),
    };
  }, [result, selectedDuration?.value]);

  if (loading || !result) {
    return (
      <SpinnerWrapper>
        <Spinner>Loading...</Spinner>
      </SpinnerWrapper>
    );
  }

  return (
    <>
      <AssetInfo
        asset={params.product}
        database={params.database}
        data={result}
        valueMap={{
          name: 'info.Fund Name',
          description: 'info.Fund Description',
          benchmark: 'info.Benchmark Name',
          startDate: 'info.Dates.0',
          endDate: 'info.Dates.1',
          cumulative: 'info.cumul_return',
          cagr: 'info.cagr',
          deviation: 'info.std',
        }}
      />
      <PageTabs
        options={[
          {
            value: 'summary',
            label: 'Summary',
            default: currentTab.value === 'summary',
            permission: 'aa-drawdown-summary',
          },
          {
            value: 'drawdown-details',
            label: 'Drawdown Detail',
            default: currentTab.value === 'drawdown-details',
            permission: 'aa-drawdown-details',
          },
          {
            value: 'market-stress',
            label: 'Market Stress',
            default: currentTab.value === 'market-stress',
            permission: 'aa-drawdown-market-stress',
          },
        ]}
        onTabSelect={setCurrentTab}
        session={session}
      >
        <TabPane tabId="summary" role="tabpanel">
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Drawdown Analysis
              <TimePeriod dates={[_.get(result, 'summary_tab.2.0'), _.get(result, 'summary_tab.2.1')]} />
            </CardHeader>
            <CardBody>
              <TightTable
                suffix="drawdown-analysis"
                data={result.summary_tab[0]}
                rowHeader={{ text: params.product, style: { color: 'black', backgroundColor: '#C6E0B4' } }}
                indexName="Time Interval"
                cellFormatter={(value, row, col) =>
                  ['CAGR', 'Standard Deviation', 'Maximum Drawdown'].includes(row)
                    ? toPercTable(value)
                    : ['Calmar Ratio', 'MAR Ratio (RoMaD)', 'Sterling Ratio'].includes(row)
                    ? toDeciTable(value)
                    : value
                }
                compact
                title={[
                  'Drawdown Analysis',
                  formatDates([_.get(result, 'summary_tab.2.0'), _.get(result, 'summary_tab.2.1')]),
                ]}
              />
            </CardBody>
          </Card>

          <Row>
            <Col xl="8" className="offset-xl-2">
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  Drawdowns History
                </CardHeader>
                <CardBody>
                  <LineChart
                    title="Drawdowns History"
                    data={drawdownChart.data}
                    options={drawdownChart.options}
                    plugins={[]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl="6">
              <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
              <Select
                className="react-select mw-200px"
                name="durations"
                value={selectedDuration}
                onChange={setSelectedDuration}
                options={durations}
              />
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  Drawdown Analysis
                  <TimePeriod
                    dates={[
                      _.get(result, `summary_tab.3.${selectedDuration.value}.0`, _.get(result, 'summary_tab.2.0')),
                      _.get(result, `summary_tab.3.${selectedDuration.value}.1`, _.get(result, 'summary_tab.2.1')),
                    ]}
                  />
                </CardHeader>
                <CardBody>
                  <TightTable
                    suffix="drawdowns-analysis"
                    data={comparisonDf1}
                    indexName=""
                    cellFormatter={(value, row, col) =>
                      ['CAGR', 'Standard Deviation', 'Maximum Drawdown'].includes(row)
                        ? toPercTable(value)
                        : ['Calmar Ratio', 'MAR Ratio (RoMaD)', 'Sterling Ratio'].includes(row)
                        ? toDeciTable(value)
                        : value
                    }
                    compact
                    title={[
                      'Drawdown Analysis',
                      formatDates([
                        _.get(result, `summary_tab.3.${selectedDuration.value}.0`, _.get(result, 'summary_tab.2.0')),
                        _.get(result, `summary_tab.3.${selectedDuration.value}.1`, _.get(result, 'summary_tab.2.1')),
                      ]),
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>

            <Col xl="6">
              <div className="tw-text-left tw-font-bold">SELECT RISK METRICS</div>
              <Select
                className="react-select mw-300px"
                name="drawdownMetrics"
                value={selectedMetric}
                onChange={setSelectedMetric}
                options={drawdownMetrics}
              />
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  Drawdown Analysis
                  <TimePeriod
                    dates={[
                      _.get(result, `summary_tab.3.${selectedDuration.value}.0`, _.get(result, 'summary_tab.2.0')),
                      _.get(result, `summary_tab.3.${selectedDuration.value}.1`, _.get(result, 'summary_tab.2.1')),
                    ]}
                  />
                </CardHeader>
                <CardBody>
                  <TightChart
                    title="Drawdown Analysis"
                    data={comparisonDf1}
                    rows={selectedMetric.value.keys}
                    dataType={selectedMetric.value.type}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="drawdown-details" role="tabpanel">
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Drawdown Detail
            </CardHeader>
            <CardBody>
              <TightTable
                suffix="drawdown-detail"
                data={result.detail_tab}
                rowHeader={{ text: params.product, style: { color: 'black', backgroundColor: '#C6E0B4' } }}
                indexName=""
                cellFormatter={(value, row, col) => (['Drawdown'].includes(col) ? toPercTable(value) : value)}
                compact
                title="Drawdown Detail"
              />
            </CardBody>
          </Card>
        </TabPane>

        <TabPane tabId="market-stress" role="tabpanel">
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Market Stress
              <div className="small">Total Returns</div>
            </CardHeader>
            <CardBody>
              <TightTable
                suffix="market-stress"
                data={result.market_stress_tab}
                indexName="Event"
                cellFormatter={(value, row, col) => (isNaN(Number(value)) ? value : toPercTable(value))}
                compact
                title={['Market Stress', 'Total Returns']}
              />
            </CardBody>
          </Card>

          <Row>
            <Col xl="8" className="offset-xl-2">
              <div className="tw-text-left tw-font-bold">SELECT</div>
              <Select
                className="react-select mw-400px"
                name="factors"
                value={selectedFactor}
                onChange={setSelectedFactor}
                options={factors}
              />
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  {selectedFactor.label}
                </CardHeader>
                <CardBody>
                  <TightChart
                    data={result.market_stress_tab}
                    cols={[params.product, params.benchmark]}
                    rows={[selectedFactor.value]}
                    dataType="percentage"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </PageTabs>
    </>
  );
}

export default SingleAssetDrawdownAnalysis;
