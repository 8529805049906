import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import _ from 'lodash';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Table,
  Row,
  Col,
  Spinner,
  UncontrolledTooltip,
  TabPane,
  CardFooter,
} from 'reactstrap';
import PageTabs from 'components/PageTabs';
import { FixedCenterLoader } from 'components/Loaders';
import ReactDatetime from 'react-datetime';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useArrayState } from 'components/CustomHooks';
import { faClose, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { listProducts, getDiscovery, getQuickViewDiscovery, getQuickViewData } from 'services/analysis';
import { optionizeValue, optionizeAll, getValue, getDate, getYearMonth, monthDiff } from 'helpers/select';
import { confidenceLevelToInterval } from 'helpers/formatter';
import Details from './tabs/Details';
import Summary from './tabs/Summary';
import ShortTermReturns from './tabs/ShortTermReturns';
import LongTermReturns from './tabs/LongTermReturns';
import CalendarYearReturns from './tabs/CalendarYearReturns';
import RiskMetrics from './tabs/RiskMetrics';
import { SAVED_PORTFOLIOS } from './constants';

const defaultCurrencyOptions = [
  { value: 'Default', label: 'No' },
  { value: 'CAD', label: 'CAD (Convert USD)' },
  { value: 'USD', label: 'USD (Convert CAD)' },
];

function QuickView({ session }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [metaLoading, setMetaLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [params, setParams] = useState(null);
  const [allAssetOptions, setAllAssetOptions] = useState(null);
  const [databaseOptions, setDatabaseOptions, selectedDatabaseOption, setSelectedDatabaseOption] = useArrayState([]);
  const [assetOptions, setAssetOptions, selectedAssetOption, setSelectedAssetOption] = useArrayState([]);
  const [currencyOptions, setCurrencyOptions, selectedCurrencyOption, setSelectedCurrencyOption] = useArrayState({
    initialItems: defaultCurrencyOptions,
  });
  const [name, setName] = useState('');

  const fetchMeta = async () => {
    setMetaLoading(true);
    const [meta, err] = await getQuickViewDiscovery();
    if (!meta) {
      setMeta({});
    } else {
      setMeta(meta);
      setDatabaseOptions(optionizeAll(_.keys(meta)));

      const _allAssetOptions = {};
      _.each(meta, (val, key) => {
        if (val) _allAssetOptions[key] = optionizeAll(val);
      });

      setAllAssetOptions(_allAssetOptions);
    }

    setMetaLoading(false);
  };

  useEffect(() => {
    if (!meta || !selectedDatabaseOption || !allAssetOptions) return;

    const _assetOptions = allAssetOptions[selectedDatabaseOption.value];
    setAssetOptions(_assetOptions);
    if (_assetOptions.length > 0) setSelectedAssetOption(_assetOptions[0]);
  }, [meta, selectedDatabaseOption]);

  useEffect(() => {
    fetchMeta();
  }, []);

  const handleAnalyze = useCallback(async () => {
    const _params = {
      database: selectedDatabaseOption.value,
      product: selectedAssetOption.value,
      converter:
        selectedDatabaseOption.value === SAVED_PORTFOLIOS
          ? selectedCurrencyOption.value
          : defaultCurrencyOptions[0].value,
      setName,
    };

    setParams(_params);
  }, [selectedDatabaseOption, selectedAssetOption, selectedCurrencyOption]);

  if (metaLoading || !selectedDatabaseOption || !selectedAssetOption) {
    return <FixedCenterLoader />;
  }

  return (
    <div className="tw-grid tw-grid-cols-12 tw-gap-4">
      <div className="tw-col-span-12 xl:tw-col-span-3">
        <Card className="tw-max-w-3xl tw-mx-auto">
          <CardBody>
            <div className="tw-text-left tw-font-bold">SELECT</div>
            <Select
              className="react-select tw-mb-2"
              name="selectedDatabaseOption"
              value={selectedDatabaseOption}
              onChange={setSelectedDatabaseOption}
              options={databaseOptions}
            />

            {meta[selectedDatabaseOption.value].includes(selectedAssetOption.value) && (
              <>
                <div className="tw-text-left tw-font-bold">SELECT</div>
                <Select
                  className="react-select tw-mb-2"
                  name="selectedAssetOption"
                  value={selectedAssetOption}
                  onChange={setSelectedAssetOption}
                  options={assetOptions}
                />
              </>
            )}
            {selectedDatabaseOption?.value === SAVED_PORTFOLIOS && (
              <>
                <div className="tw-text-left tw-font-bold">Convert Currency</div>
                <Select
                  className="react-select tw-mb-2"
                  name="selectedCurrencyOption"
                  value={selectedCurrencyOption}
                  onChange={setSelectedCurrencyOption}
                  options={currencyOptions}
                />
              </>
            )}

            <Button className="btn-round" color="info" onClick={handleAnalyze}>
              Analyze
            </Button>
          </CardBody>
        </Card>
      </div>
      <div className="tw-col-span-12 xl:tw-col-span-9">
        {name && <div className="tw-text-xl tw-font-bold tw-text-center tw-mb-2">{name}</div>}
        {params && (
          <PageTabs
            options={[
              { value: 'details', label: 'Details' },
              { value: 'summary', label: 'Summary' },
              { value: 'short_term_returns', label: 'Short-Term Returns' },
              { value: 'long_term_returns', label: 'Long-Term Returns' },
              { value: 'calendar_year_returns', label: 'Annual Returns' },
              { value: 'risk_metrics', label: 'Risk Metrics' },
            ]}
            style={{ width: '160px' }}
            session={session}
          >
            <TabPane tabId="details" role="tabpanel">
              <Details {...params} />
            </TabPane>
            <TabPane tabId="summary" role="tabpanel">
              <Summary {...params} />
            </TabPane>
            <TabPane tabId="short_term_returns" role="tabpanel">
              <ShortTermReturns {...params} />
            </TabPane>
            <TabPane tabId="long_term_returns" role="tabpanel">
              <LongTermReturns {...params} />
            </TabPane>
            <TabPane tabId="calendar_year_returns" role="tabpanel">
              <CalendarYearReturns {...params} />
            </TabPane>
            <TabPane tabId="risk_metrics" role="tabpanel">
              <RiskMetrics {...params} />
            </TabPane>
          </PageTabs>
        )}
      </div>
    </div>
  );
}

export default QuickView;
