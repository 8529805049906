import React, { memo } from 'react';
import _ from 'lodash';
import { Chart } from 'react-chartjs-2';
import getBarChartProps from './_bar-chart';
import ChartDownload from './ChartDownload';

function TightChart(props) {
  const { title } = props;
  const chart = getBarChartProps(props);

  return (
    <>
      <ChartDownload chartFn={() => getBarChartProps(props)} filename="download.png" title={title} />
      <Chart {...chart} />
    </>
  );
}

export default memo(TightChart);
