import React, { memo } from 'react';
import _ from 'lodash';
import { Chart } from 'react-chartjs-2';
import ChartDownload from './ChartDownload';
import { customCanvasBackgroundColor } from './_chart';

function ScatterChart(props) {
  const { title } = props;

  return (
    <>
      <ChartDownload
        chartFn={() => ({
          ..._.cloneDeep(props),
          type: 'scatter',
          plugins: [...(props.plugins || []), customCanvasBackgroundColor],
        })}
        filename="download.png"
        title={title}
      />
      <Chart {...props} type="scatter" />
    </>
  );
}

export default memo(ScatterChart);
