import React from 'react';
import _ from 'lodash';
import { Card, CardHeader, CardBody, CardFooter, CardTitle, Row, Col } from 'reactstrap';
import riskGuide from 'assets/files/risk-guide.pdf';
import allocator from 'assets/files/allocator.pdf';
import factorList from 'assets/files/factor-list.xlsx';

function RiskGuide() {
  return (
    <>
      <Row>
        <Col className="col-lg-6 col-md-7 ml-auto mr-auto">
          <Card className="card-stats">
            <CardHeader>
              <CardTitle tag="h4">Risk Guide</CardTitle>
            </CardHeader>
            <CardBody>
              <p>Please see the following guide summarizing relevant risk measures and methodologies.</p>
            </CardBody>
            <CardFooter className="text-center">
              <hr />
              <a className="btn btn-royal-blue" target="_blank" href={riskGuide}>
                <i className="fa fa-download" aria-hidden="true"></i>
                Download
              </a>
            </CardFooter>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col className="col-lg-6 col-md-7 ml-auto mr-auto">
          <Card className="card-stats">
            <CardHeader>
              <CardTitle tag="h4">Use Cases</CardTitle>
            </CardHeader>
            <CardBody>
              <p>Please see the following presentation summarizing and outlining FLARE use cases and examples.</p>
            </CardBody>
            <CardFooter className="text-center">
              <hr />
              <a className="btn btn-steel-blue" target="_blank" href={allocator}>
                <i className="fa fa-download" aria-hidden="true"></i>
                Download
              </a>
            </CardFooter>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col className="col-lg-6 col-md-7 ml-auto mr-auto">
          <Card className="card-stats">
            <CardHeader>
              <CardTitle tag="h4">Factors</CardTitle>
            </CardHeader>
            <CardBody>
              <p>Please see the following list of the major factor exposures that FLARE is presently calculating.</p>
            </CardBody>
            <CardFooter className="text-center">
              <hr />
              <a className="btn btn-light-sky-blue" target="_blank" href={factorList}>
                <i className="fa fa-download" aria-hidden="true"></i>
                Download
              </a>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default RiskGuide;
