import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { toDeci, toDeciTable, toPercTable, formatDate } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';

export const metricOptions = [
  {
    label: 'Up Market Capture and Down Market Capture',
    value: { keys: ['Up Market Capture Ratio', 'Down Market Capture Ratio'], type: 'decimal' },
  },
  {
    label: 'Beta and Correlation',
    value: { keys: ['Beta', 'Correlation'], type: 'decimal' },
  },
  {
    label: 'R2',
    value: { keys: ['R2'], type: 'percentage' },
  },
];

function RiskExposure({ data: { data, table_dates }, params, options = {}, notify, session }) {
  const [exposureOptions, setExposureOptions] = useState([]);
  const [selectedExposureOption, setSelectedExposureOption] = useState(null);
  const [holdingOptions, setHoldingOptions] = useState([]);
  const [selectedHoldingOption, setSelectedHoldingOption] = useState(null);
  const [selectedMetricOption, setSelectedMetricOption] = useState(metricOptions[0]);

  useEffect(() => {
    if (!data) return;

    const _exposureOptions = optionizeAll(_.keys(data));
    setExposureOptions(_exposureOptions);

    if (_exposureOptions.length > 0) {
      setSelectedExposureOption(_exposureOptions[0]);

      const _holdings = data[_exposureOptions[0].value].columns.filter(
        (val) => ![params.port1Name, params.benchmark].includes(val),
      );
      const _holdingOptions = optionizeAll(_holdings);
      setHoldingOptions(_holdingOptions);

      if (_holdingOptions.length > 0) {
        setSelectedHoldingOption(_holdingOptions[0]);
      }
    }
  }, [data]);

  return (
    <>
      {selectedExposureOption && (
        <>
          <div className="tw-col-span-6">
            <div className="tw-text-left tw-font-bold">SELECT RISK EXPOSURE</div>
            <Select
              className="react-select mw-400px"
              value={selectedExposureOption}
              onChange={setSelectedExposureOption}
              options={exposureOptions}
            />
          </div>
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Exposure: {selectedExposureOption.label}
              <TimePeriod dates={table_dates[selectedExposureOption.value]} />
            </CardHeader>
            <CardBody>
              <TightTable
                suffix="risk-performance-risk-exposure"
                data={data[selectedExposureOption.value]}
                indexName=""
                indexWidth="250px"
                useSort={true}
                cellFormatter={(value, row, col, rowInd, colInd, flip) => {
                  const label = flip ? col : row;
                  return ['R2'].includes(label) ? toPercTable(value, { precision: 0 }) : toDeciTable(value);
                }}
                title={[
                  `Exposure: ${selectedExposureOption.label}`,
                  formatDates(table_dates[selectedExposureOption.value]),
                ]}
                {...options.riskExposureTable}
              />
            </CardBody>
          </Card>
          <div className="tw-grid tw-grid-cols-6 tw-gap-4">
            <div className="tw-col-start-2 tw-col-span-4">
              <div className="tw-flex">
                <div>
                  <div className="tw-text-left tw-font-bold">SELECT RISK EXPOSURE</div>
                  <Select
                    className="react-select tw-w-[310px]"
                    value={selectedExposureOption}
                    onChange={setSelectedExposureOption}
                    options={exposureOptions}
                  />
                </div>
                <div className="tw-mr-1">
                  <div className="tw-text-left tw-font-bold">SELECT METRIC</div>
                  <Select
                    className="react-select tw-w-[310px] tw-inline-block"
                    value={selectedMetricOption}
                    onChange={setSelectedMetricOption}
                    options={metricOptions}
                  />
                </div>
                {holdingOptions.length > 1 && (
                  <div className="tw-ml-1">
                    <div className="tw-text-left tw-font-bold">SELECT HOLDING</div>
                    <Select
                      className="react-select tw-w-[310px] tw-inline-block"
                      value={selectedHoldingOption}
                      onChange={setSelectedHoldingOption}
                      options={holdingOptions}
                    />
                  </div>
                )}
              </div>
              {selectedMetricOption && (
                <>
                  <Card>
                    <CardHeader tag="h4" className="mt-0 text-center">
                      Exposure: {selectedExposureOption.label}
                    </CardHeader>
                    <CardBody>
                      {selectedHoldingOption ? (
                        <TightChart
                          title={`Exposure: ${selectedExposureOption.label}`}
                          data={data[selectedExposureOption.value]}
                          rows={[params.port1Name, params.benchmark, selectedHoldingOption.value]}
                          cols={selectedMetricOption.value.keys}
                          flip={true}
                          wraplabelX={true}
                          dataType={selectedMetricOption.value.type}
                        />
                      ) : (
                        <TightChart
                          title={`Exposure: ${selectedExposureOption.label}`}
                          data={data[selectedExposureOption.value]}
                          rows={[params.port1Name, params.benchmark]}
                          cols={selectedMetricOption.value.keys}
                          flip={true}
                          wraplabelX={true}
                          dataType={selectedMetricOption.value.type}
                        />
                      )}
                    </CardBody>
                  </Card>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default RiskExposure;
