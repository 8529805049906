import { instance } from './axios';
import _ from 'lodash';

const getErrorMessage = (error) => _.get(error, 'response.data.message');

export async function listClientPortfolios() {
  try {
    const result = await instance.get('/client-portfolios').then((res) => res.data);

    return [result, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function createClientPortfolio(name, data) {
  try {
    const result = await instance
      .post('/client-portfolios', { name, data: JSON.stringify(data) })
      .then((res) => res.data);

    return [result, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function deleteClientPortfolio(id) {
  try {
    const result = await instance.delete(`/client-portfolios/${id}`).then((res) => res.data);

    return [result, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}
