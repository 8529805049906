import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody, TabPane } from 'reactstrap';
import PageTabs from 'components/PageTabs';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { toDeci, toPerc } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';

function Returns({ data, params, options = {}, notify, session }) {
  const [yearOptions, setYearOptions] = useState([]);
  const [selectedYearOption, setselectedYearOption] = useState(null);
  const [monthOptions, setMonthOptions] = useState([]);
  const [selectedMonthOption, setselectedMonthOption] = useState(null);
  const [assetOptions, setAssetOptions] = useState([]);
  const [selectedAssetOption, setSelectedAssetOption] = useState(null);

  useEffect(() => {
    if (!data) return;

    const years = data.calendar_year_return.index;
    const yearOptions = optionizeAll(years);
    setYearOptions(yearOptions);

    if (years.length > 0) {
      setselectedYearOption(yearOptions[0]);
      const months = data.monthly_returns[years[0]].index;
      const monthOptions = optionizeAll(months);

      setMonthOptions(monthOptions);
      setselectedMonthOption(monthOptions[0]);
    }
  }, [data]);

  useEffect(() => {
    if (!selectedYearOption) return;

    const assets = data.monthly_returns[selectedYearOption.value].columns;
    const _assetOptions = optionizeAll(assets.filter((v, i) => v !== params.benchmark));
    setAssetOptions(_assetOptions);

    if (_assetOptions.length > 0) {
      setSelectedAssetOption(_assetOptions[0]);
    }
  }, [selectedYearOption]);

  return (
    <>
      <TabPane tabId="detail" role="tabpanel">
        <PageTabs
          options={[
            { value: 'calendar-year', label: 'Calendar Year' },
            { value: 'monthly', label: 'Monthly' },
          ]}
          colorIndex={0}
        >
          <TabPane tabId="calendar-year" role="tabpanel">
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                Calendar Year Returns
              </CardHeader>
              <CardBody>
                <TightTable
                  suffix="risk-performance-returns"
                  data={data.calendar_year_return}
                  indexName="Year"
                  useSort={true}
                  initialSortBy={0}
                  initialSortOrder="asc"
                  cellFormatter={(value, row, col) => toPerc(value, { decorate: true, absolute: true })}
                  title="Calendar Year Returns"
                  {...options.calendarYearTable}
                />
              </CardBody>
            </Card>

            <div className="tw-grid tw-grid-cols-6 tw-gap-4">
              <div className="tw-col-start-2 tw-col-span-4">
                <div className="tw-text-left tw-font-bold">SELECT YEAR</div>
                <Select
                  className="react-select mw-400px"
                  value={selectedYearOption}
                  onChange={setselectedYearOption}
                  options={yearOptions}
                />
                {selectedYearOption && (
                  <Card>
                    <CardHeader tag="h4" className="mt-0 text-center">
                      Calendar Year Returns
                      <div className="tw-text-lg">{selectedYearOption.label}</div>
                    </CardHeader>
                    <CardBody>
                      <TightChart
                        title="Calendar Year Returns"
                        data={data.calendar_year_return}
                        cols={[selectedYearOption.value]}
                        flip={true}
                        wraplabelX={true}
                        dataType="percentage"
                      />
                    </CardBody>
                  </Card>
                )}
              </div>
            </div>
          </TabPane>
          <TabPane tabId="monthly" role="tabpanel">
            <div className="tw-grid tw-grid-cols-6 tw-gap-4">
              <div className="tw-col-span-6">
                <div className="tw-text-left tw-font-bold">SELECT YEAR</div>
                <Select
                  className="react-select mw-400px"
                  value={selectedYearOption}
                  onChange={setselectedYearOption}
                  options={yearOptions}
                />
                {selectedYearOption && (
                  <Card>
                    <CardHeader tag="h4" className="mt-0 text-center">
                      Monthly Returns
                      <div className="tw-text-lg">{selectedYearOption.label}</div>
                    </CardHeader>
                    <CardBody>
                      <TightTable
                        suffix="risk-performance-returns-year-month"
                        data={data.monthly_returns[selectedYearOption.value]}
                        flip={true}
                        indexName=""
                        useSort={true}
                        rowDecorator={(rowTitle, rowInd, rowTitles) =>
                          rowTitle === params.benchmark
                            ? { style: { backgroundColor: 'rgb(251,198,88,0.5)' } }
                            : undefined
                        }
                        cellFormatter={(value, row, col) => toPerc(value, { decorate: true, absolute: true })}
                        title={['Monthly Returns', selectedYearOption.label]}
                        {...options.monthlyReturnTable}
                      />
                    </CardBody>
                  </Card>
                )}
              </div>
            </div>

            <div className="tw-grid tw-grid-cols-6 tw-gap-4">
              <div className="tw-col-start-2 tw-col-span-4">
                <div className="tw-text-left tw-font-bold">
                  {params.tags && params.tags.includes('portfolio-compare') ? 'SELECT PORTFOLIO' : 'SELECT ASSET'}
                </div>
                <Select
                  className="react-select mw-400px"
                  value={selectedAssetOption}
                  onChange={setSelectedAssetOption}
                  options={assetOptions}
                />
                {selectedYearOption && selectedAssetOption && (
                  <Card>
                    <CardHeader tag="h4" className="mt-0 text-center">
                      Monthly Returns
                      <div className="tw-text-lg">{selectedYearOption.label}</div>
                    </CardHeader>
                    <CardBody>
                      <TightChart
                        title="Monthly Returns"
                        cols={[selectedAssetOption.value, params.benchmark]}
                        data={data.monthly_returns[selectedYearOption.value]}
                        dataType="percentage"
                      />
                    </CardBody>
                  </Card>
                )}
              </div>
            </div>
          </TabPane>
        </PageTabs>
      </TabPane>
    </>
  );
}

export default Returns;
