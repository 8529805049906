import React, { useEffect, useState, useRef, useCallback } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Chart as ChartJS, registerables } from 'chart.js';
import store from 'store2';
import _ from 'lodash';
import ChartDataLabels from 'chartjs-plugin-datalabels';
// import autocolors from 'chartjs-plugin-autocolors';
import NotificationAlert from 'react-notification-alert';

import AuthLayout from 'layouts/Auth.js';
import AdminLayout from 'layouts/Admin.js';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/paper-dashboard.scss?v=1.3.0';
// import 'assets/css/paper-dashboard.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'assets/css/styles.css';

import { getSession } from 'services/auth';
import { getCacheUpdateTime } from 'services/analysis';
import GeneralConfirmationModal from 'components/GeneralConfirmationModal';

ChartJS.register(...registerables);
// ChartJS.register(ChartDataLabels);
// ChartJS.register(autocolors);

const defaultNotiOptions = {
  place: 'tr',
  message: '',
  type: 'info',
  icon: 'now-ui-icons ui-1_bell-53',
  autoDismiss: 4,
  closeButton: true,
  zIndex: 1000,
};

const DROPBOX_KEY = 'dropbox-key';

function App() {
  const notifRef = useRef();
  const modalRef = useRef();
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState(null);

  useEffect(() => {
    const sess = async () => {
      setLoading(true);
      const data = await getSession();
      setSession(data);
      setLoading(false);
    };

    sess();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const val = await getCacheUpdateTime();
      if (_.isString(val) && val.length < 100) {
        const curr = store.get(DROPBOX_KEY);
        if (curr !== val) {
          store.clearAll();
          store.set(DROPBOX_KEY, val);
        }
      }
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const notify = useCallback(
    (options) => {
      notifRef.current && notifRef.current.notificationAlert({ ...defaultNotiOptions, ...options });
    },
    [notifRef.current],
  );

  notify.primary = (opts) => notify({ ...opts, type: 'primary' });
  notify.secondary = (opts) => notify({ ...opts, type: 'secondary' });
  notify.success = (opts) => notify({ ...opts, type: 'success' });
  notify.danger = (opts) => notify({ ...opts, type: 'danger' });
  notify.warning = (opts) => notify({ ...opts, type: 'warning' });
  notify.info = (opts) => notify({ ...opts, type: 'info' });
  notify.light = (opts) => notify({ ...opts, type: 'light' });
  notify.dark = (opts) => notify({ ...opts, type: 'dark' });

  const confirm = useCallback(
    (options) => {
      modalRef.current && modalRef.current.open({ ...options });
    },
    [modalRef.current],
  );

  if (loading) return null;

  return (
    <BrowserRouter>
      <ToastContainer />
      <NotificationAlert ref={notifRef} />
      <GeneralConfirmationModal ref={modalRef} />
      <Routes>
        {/* <Route path="/auth/*" element={<AuthLayout />} /> */}
        <Route path="/*" element={<AdminLayout session={session} notify={notify} confirm={confirm} />} />
        {/* <Route path="*" element={<Navigate to="/dashboard" />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
