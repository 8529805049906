import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import _ from 'lodash';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Table,
  Row,
  Col,
  Spinner,
  UncontrolledTooltip,
  TabPane,
  CardFooter,
} from 'reactstrap';
import PageTabs from 'components/PageTabs';
import { AutoColumns } from 'components/Columns';
import { FixedCenterLoader } from 'components/Loaders';
import TightChart from 'components/TightChart';
import LineChart from 'components/LineChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import ReactDatetime from 'react-datetime';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { listProducts, getDiscovery, toolboxCorrelation, getQuickViewData } from 'services/analysis';
import { optionizeValue, optionizeAll, getValue, getDate, getYearMonth, monthDiff } from 'helpers/select';
import { confidenceLevelToInterval, tablePresets, toDeciTable, formatCurrency } from 'helpers/formatter';
import TightTable from 'components/TightTable';
import { useArrayState } from 'components/CustomHooks';
import { colors } from 'helpers/chart';
import { Descriptions, Notes } from '../others';

export const metricOptions = [
  {
    label: 'CAGR and Std Dev',
    value: { keys: ['CAGR', 'Standard Deviation'], type: 'percentage' },
  },
  {
    label: 'Beta and Correlation',
    value: { keys: ['Beta', 'Correlation'], type: 'decimal' },
  },
  {
    label: 'CVaR and Max DD',
    value: { keys: ['CVaR 95%', 'Maximum Drawdown'], type: 'percentage' },
  },
  {
    label: 'Std Dev and Downside Dev',
    value: { keys: ['Standard Deviation', 'Downside Deviation'], type: 'percentage' },
  },
  {
    label: 'Skew and Kurtosis',
    value: { keys: ['Skew', 'Kurtosis'], type: 'decimal' },
  },
  {
    label: 'Sharpe and Sortino',
    value: { keys: ['Sharpe Ratio', 'Sortino Ratio'], type: 'decimal' },
  },
  {
    label: 'MAR and Omega',
    value: { keys: ['MAR Ratio', 'Omega Ratio'], type: 'decimal' },
  },
  {
    label: 'Ratios (Sharpe, Sortino, Omega)',
    value: { keys: ['Sharpe Ratio', 'Sortino Ratio', 'Omega Ratio'], type: 'decimal' },
  },
];

function RiskMetrics({ database, product, converter, setName }) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [selectedMetricOption, setSelectedMetricOption] = useState(metricOptions[0]);
  const [durationOptions, setDurationOptions, selectedDurationOption, setSelectedDurationOption] = useArrayState();

  const fetchData = async () => {
    setLoading(true);

    const [data, err] = await getQuickViewData({
      database,
      product,
      converter,
      page_choice: 'risk_metrics',
    });

    if (data) {
      setResult(data);
      setDurationOptions(optionizeAll(data.risk_metrics.columns));
      setName(data.formal_name);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!database || !product) return;

    fetchData();
  }, [database, product, converter]);

  if (loading) {
    return <FixedCenterLoader />;
  }

  if (!result) {
    return null;
  }

  return (
    <>
      <Descriptions content={result.descriptions} />
      <div className="tw-text-left tw-font-bold tw-mt-1">SELECT TIME PERIOD</div>
      <div className="tw-text-left">
        <Select
          className="react-select tw-text-sm tw-inline-block tw-w-[300px]"
          name="selectedDurationOption"
          value={selectedDurationOption}
          onChange={setSelectedDurationOption}
          options={durationOptions}
        />
      </div>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          {product}
          <div className="tw-text-lg">Risk Metrics</div>
          <div className="tw-text-lg">Currency: {result.currency}</div>
          <TimePeriod dates={result.date_range_map[selectedDurationOption.value]} prefix="Time Period:" />
        </CardHeader>

        {selectedDurationOption && (
          <CardBody>
            <TightTable
              suffix="quick-view-risk-metrics"
              data={result.risk_metrics}
              cols={(cols) =>
                cols.filter((col, ind) => {
                  return selectedDurationOption.value === col;
                })
              }
              flip
              indexName=""
              indexWidth="120px"
              hideIndexTable
              cellFormatter={(value, row, col) => {
                const formatter = tablePresets[col];
                if (formatter) return formatter(value);
                return value;
              }}
              title={['Risk Metrics', product, formatDates(result.table_dates)]}
            />
          </CardBody>
        )}
      </Card>
      <div className="tw-mx-auto tw-max-w-xl">
        <div className="tw-flex">
          <div className="">
            <div className="tw-text-left tw-font-bold">SELECT RISK METRICS</div>
            <Select
              className="react-select tw-w-[400px] tw-inline-block"
              value={selectedMetricOption}
              onChange={setSelectedMetricOption}
              options={metricOptions}
            />
          </div>
        </div>

        <Card>
          <CardHeader tag="h4" className="mt-0 text-center">
            {product}
            <div className="tw-text-lg">Risk Metrics</div>
            <div className="tw-text-lg">Currency: {result.currency}</div>
            <TimePeriod dates={result.date_range_map[selectedDurationOption.value]} prefix="Time Period:" />
          </CardHeader>
          <CardBody>
            <TightChart
              title={product}
              data={result.risk_metrics}
              base="cols"
              cols={selectedMetricOption.value.keys}
              rows={(rows) =>
                rows.filter((row, ind) => {
                  if (selectedDurationOption.value === row) {
                    return true;
                  }

                  return false;
                  // if (['15Y', '20Y'].includes(row)) {
                  //   return selectedMetricOption.value.keys.every((key) => {
                  //     const col = result.risk_metrics.index.findIndex((col) => col === key);
                  //     return !!result.risk_metrics.data[col][ind];
                  //   });
                  // }
                })
              }
              dataType={selectedMetricOption.value.type}
              flip
            />
          </CardBody>
        </Card>
      </div>
      <Notes content={result.notes} />
    </>
  );
}

export default RiskMetrics;
