import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody, TabPane, Row, Col, Table } from 'reactstrap';
import PieChart from 'components/PieChart';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';
import { toDeci, toDeciTable, toPercTable, tablePresets } from 'helpers/formatter';

function RiskBudget({ data, params, options = {}, notify, session }) {
  const [holdingOptions, setHoldingOptions, selectedHoldingOption, setSelectedHoldingOption] = useArrayState();
  const [periodOptions, setPeriodOptions, selectedPeriodOption, setSelectedPeriodOption] = useArrayState();

  useEffect(() => {
    if (!data) return;

    const _periods = _.keys(data);
    const _periodOptions = optionizeAll(_periods, { labelFormatter: _.upperFirst });
    setPeriodOptions(_periodOptions);
    if (_periodOptions.length > 0) setSelectedPeriodOption(_periodOptions[0]);
  }, [data]);

  useEffect(() => {
    if (!selectedPeriodOption) return;
    const periodData = data[selectedPeriodOption.value];

    const _holdings = periodData.data.index.filter((val) => !['Portfolio'].includes(val));
    const _holdingOptions = optionizeAll(_holdings);
    setHoldingOptions(_holdingOptions);

    if (_holdingOptions.length > 0) {
      setSelectedHoldingOption(_holdingOptions[0]);
    }
  }, [selectedPeriodOption]);

  if (!selectedPeriodOption || !selectedHoldingOption) return null;

  const periodData = data[selectedPeriodOption.value];

  return (
    <>
      <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
      <Select
        className="react-select mw-200px"
        name="duration"
        value={selectedPeriodOption}
        onChange={setSelectedPeriodOption}
        options={periodOptions}
      />

      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Risk Contribution
          <div className="tw-text-lg">{params.portfolio}</div>
          <div className="tw-text-lg">Model: {params.budget_model}</div>
          <TimePeriod dates={periodData.table_dates} prefix="Time Period:" />
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="risk-performance-risk-contribution"
            data={periodData.data}
            indexName=""
            indexWidth="320px"
            useSort={true}
            cellFormatter={(value, row, col) => {
              const formatter = tablePresets[col];
              if (formatter) return formatter(value);

              return toPercTable(value);
            }}
            title={['Risk Contribution', formatDates(periodData.table_dates)]}
            {...options.riskContributionTable}
          />
        </CardBody>
      </Card>

      <div className="tw-col-span-12 md:tw-col-span-6">
        <div className="tw-text-left">
          <div className="tw-text-left tw-font-bold">SELECT</div>
          <Select
            className="react-select tw-w-[400px] tw-inline-block"
            value={selectedHoldingOption}
            onChange={setSelectedHoldingOption}
            options={holdingOptions}
          />
        </div>
      </div>

      {selectedHoldingOption && (
        <div className="tw-grid tw-grid-cols-12 tw-gap-4">
          <div className="tw-col-span-12 md:tw-col-span-6">
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                Risk Contribution
                <TimePeriod dates={periodData.table_dates} prefix="Time Period:" />
              </CardHeader>
              <CardBody>
                <TightChart
                  title="Risk Contribution"
                  data={periodData.data}
                  options={{ indexAxis: 'y' }}
                  base="cols"
                  rows={[selectedHoldingOption.value]}
                  cols={(cols) => cols.filter((col) => !['R2'].includes(col))}
                  dataType="percentage"
                />
              </CardBody>
            </Card>
          </div>
          <div className="tw-col-span-12 md:tw-col-span-6">
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                {selectedHoldingOption.label}
                <TimePeriod dates={periodData.table_dates} prefix="Time Period:" />
              </CardHeader>
              <CardBody>
                <PieChart
                  title={selectedHoldingOption.label}
                  data={periodData.data}
                  filter={(labels) => labels.filter((label) => !['R2', 'Total Risk'].includes(label))}
                  base="cols"
                  field={selectedHoldingOption.value}
                  dataType="percentage"
                  containerStyle={{ maxWidth: '500px', maxHeight: '500px' }}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      )}
    </>
  );
}

export default RiskBudget;
