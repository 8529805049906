import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import _ from 'lodash';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Table,
  Row,
  Col,
  Spinner,
  UncontrolledTooltip,
  TabPane,
  CardFooter,
} from 'reactstrap';
import PageTabs from 'components/PageTabs';
import { FixedCenterLoader } from 'components/Loaders';
import ReactDatetime from 'react-datetime';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useArrayState } from 'components/CustomHooks';
import { faClose, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { listProducts, getDiscovery } from 'services/analysis';
import { optionizeValue, optionizeAll, getValue, getDate, getYearMonth, monthDiff } from 'helpers/select';
import { confidenceLevelToInterval } from 'helpers/formatter';
import SavedPopup from './SavedPopup';
import RiskReturn from './charts/RiskReturn';

const selectOptions = {
  valueContainer: () => ({
    paddingLeft: 10,
    display: 'flex',
    alignItems: 'center',
  }),
  menu: () => ({
    zIndex: 200,
    position: 'absolute',
    backgroundColor: '#fff',
  }),
};

const frequencies = optionizeAll(['Annual', 'Semi', 'Quarterly', 'Monthly'], {
  labelFormatter: _.startCase,
});

const trimData = (assets, databases) => {
  const trimmedAssets = [];
  const trimmedDatabases = [];

  _.each(assets, (asset, i) => {
    if (!asset) return;
  });

  return {
    assets: trimmedAssets,
    databases: trimmedDatabases,
  };
};

const tabOptions = [
  { value: 'asset', label: 'Assets' },
  // { value: 'saved', label: 'Saved Portfolios' },
];

const defaultNulls = [null, null, null, null, null];

function ToolboxCorrelation({ confirm, session }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [benchmarks, setBenchmarks, benchmark, setBenchmark] = useArrayState([]);
  const [geographys, setGeographys, geography, setGeography] = useArrayState([]);
  const [riskbuckets, setRiskbuckets, riskbucket, setRiskbucket] = useArrayState([]);
  const [correlations, setCorrelations, correlation, setCorrelation] = useArrayState([]);
  const [significances, setSignificances, significance, setSignificance] = useArrayState([]);
  const [options, setOptions] = useState(null);
  const [assetsMeta, setAssetsMeta] = useState({});
  const [databaseOptions, setDatabaseOptions] = useState([]);
  const [assets, setAssets] = useState(defaultNulls.concat());
  const [databases, setDatabases] = useState(defaultNulls.concat());
  const [dataLoading, setDataLoading] = useState(false);
  const [metaLoading, setMetaLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [sdate, setSdate] = useState(null);
  const [edate, setEdate] = useState(null);
  const [rebalanceFreq, setRebalanceFreq] = useState(frequencies[0]);
  const [accrualFreq, setAccrualFreq] = useState(frequencies[0]);
  const [deductionFreq, setDeductionFreq] = useState(frequencies[0]);
  const [advisorFees, setAdvisorFees] = useState(0);
  const [selectedTab, setSelectedTab] = useState(tabOptions[0]);
  const [holdings, setHoldings] = useState(false);
  const [error, setError] = useState('');
  const maxItems = session?.permissions['pb-max-items'] ?? 1;

  const fetchData = async () => {
    setDataLoading(true);
    const [data, err] = await listProducts(false);
    if (!data) {
      setAssetsMeta({});
      setDatabaseOptions([]);
    } else {
      const keys = _.keys(data);
      setAssetsMeta(data);
      setDatabaseOptions(optionizeAll(keys));
    }

    setDataLoading(false);
  };

  const fetchMeta = async () => {
    setMetaLoading(true);
    const [meta, err] = await getDiscovery();
    if (!meta) {
      setMeta({});
      setBenchmarks([]);
      setBenchmark(null);
      setGeographys([]);
      setGeography(null);
      setRiskbuckets([]);
      setRiskbucket(null);
      setCorrelations([]);
      setCorrelation(null);
      setSignificances([]);
      setSignificance(null);
    } else {
      setMeta(meta);
      const benchmarks = meta.benchmark_choice.map(optionizeValue);
      const geographys = meta.geography_choice.map(optionizeValue);
      const riskbuckets = meta.risk_budget_choice.map(optionizeValue);
      const correlations = meta.correlation_choice.map(optionizeValue);
      const significances = meta.significance_choice.map((level) => ({
        value: level,
        label: confidenceLevelToInterval(level),
      }));

      setBenchmarks(benchmarks);
      setBenchmark(benchmarks[0]);
      setGeographys(geographys);
      setGeography(geographys[0]);
      setRiskbuckets(riskbuckets);
      setRiskbucket(riskbuckets[0]);
      setCorrelations(correlations);
      setCorrelation(correlations[0]);
      setSignificances(significances);
      setSignificance(significances[0]);
    }

    setMetaLoading(false);
  };

  useEffect(() => {
    fetchData();
    fetchMeta();
  }, []);

  const allowed = useMemo(() => {
    if (_.compact(assets).length === 0) return false;

    return true;
  }, [assets]);

  const handleAnalyze = () => {
    if (!allowed) return;

    const mdiff = monthDiff(sdate, edate);
    if (mdiff !== Number.MIN_VALUE) {
      if (mdiff < 0) {
        setError('Start date cannot be after end date.');
        return;
      }

      if (mdiff < 12) {
        setError('The analysis time interval should include at least 12 months.');
        return;
      }
    }

    // if chart_choice == 'Alpha & Beta':
    // elif chart_choice == 'Risk & Return':
    // elif chart_choice == 'Risk Ratios':
    // elif chart_choice == 'Value at Risk':
    // elif chart_choice == 'Probability Density Function':

    setOptions({
      product: assets,
      database: databases,
      benchmark: getValue(benchmark),
      geography: getValue(geography),
      riskbucket: getValue(riskbucket),
      correlation: getValue(correlation),
      significance: getValue(significance),
      sdate: getYearMonth(sdate),
      edate: '',
      rolling_window: 12,
    });
  };

  const handlePortfolioLoad = (portfolio) => {
    const newAssets = [];
    const newDatabases = [];
    _.each(portfolio.data, (row) => {
      newAssets.push(row.asset);
      newDatabases.push(row.database);
    });

    setAssets(newAssets);
    setDatabases(newDatabases);
  };

  if (metaLoading || _.isEmpty(assetsMeta)) {
    return <FixedCenterLoader />;
  }

  return (
    <>
      <PageTabs options={tabOptions} session={session} onTabSelect={setSelectedTab}>
        <TabPane tabId="asset" role="tabpanel"></TabPane>
        <TabPane tabId="saved" role="tabpanel"></TabPane>
      </PageTabs>

      <div className="tw-grid tw-grid-cols-12 tw-gap-4">
        <div className="tw-col-span-12 xl:tw-col-span-3">
          <Card className="tw-max-w-3xl tw-mx-auto">
            <CardHeader>
              <CardTitle tag="h4" className="mb-0 text-center">
                Select Assets
                <div className="tw-float-right">
                  <SavedPopup onLoadSelected={handlePortfolioLoad} />
                </div>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Table hover striped bordered className="tw-table-auto">
                <tbody>
                  {assets.map((asset, i) => {
                    const databaseAssetOptions = optionizeAll((databases[i] && assetsMeta[databases[i]]?.index) || []);

                    return (
                      <tr key={`${asset}-${i}`}>
                        <td width={'40%'}>
                          <Select
                            className="react-select tw-w-full"
                            name="databases"
                            placeholder="Select Database..."
                            value={databaseOptions.find((option) => option.value === databases[i])}
                            onChange={(option) => {
                              databases[i] = option.value;
                              assets[i] = null;
                              setDatabases(databases.concat());
                              setAssets(assets.concat());
                            }}
                            options={databaseOptions}
                            styles={selectOptions}
                          />
                          <Select
                            className="react-select tw-w-full"
                            name="assets"
                            placeholder="Select Asset..."
                            value={databaseAssetOptions.find((option) => option.value === asset)}
                            onChange={(option) => {
                              assets[i] = option.value;
                              setAssets(assets.concat());
                            }}
                            // exclude the asset already taken
                            options={databaseAssetOptions.filter((option) => {
                              if (option.value === option) return true;
                              if (assets.includes(option.value)) return false;
                              return true;
                            })}
                            styles={selectOptions}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>

              {assets.length < maxItems && (
                <>
                  <Button
                    className="btn-round"
                    color="info"
                    onClick={() => {
                      setDatabases(databases.concat(...defaultNulls.concat()));
                      setAssets(assets.concat(...defaultNulls.concat()));
                    }}
                  >
                    Add Assets
                  </Button>
                  <Button
                    className="btn-round"
                    color="danger"
                    disabled={databases.length <= 5}
                    onClick={() => {
                      let num = 5;
                      if (databases.length < 10) {
                        num = databases.length - 5;
                      }

                      setDatabases(databases.slice(0, databases.length - num));
                      setAssets(assets.slice(0, assets.length - num));
                    }}
                  >
                    Remove Assets
                  </Button>
                </>
              )}
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle tag="h4" className="mb-0 text-center">
                Modeling Assumptions
              </CardTitle>
            </CardHeader>
            <CardBody className="">
              {benchmark && (
                <>
                  <Label className="tw-w-full tw-mb-0">Benchmark Selection</Label>
                  <Select
                    className="react-select"
                    name="benchmark"
                    value={benchmark}
                    onChange={setBenchmark}
                    options={benchmarks}
                  />
                </>
              )}

              <Label className="tw-w-full tw-mt-2 tw-mb-0">Start Date (Optional)</Label>
              <FormGroup className="tw-w-full">
                <ReactDatetime
                  initialValue={sdate}
                  dateFormat="YYYY-MM"
                  onChange={setSdate}
                  inputProps={{
                    className: 'form-control',
                    placeholder: 'Start Date',
                  }}
                  closeOnSelect={true}
                  timeFormat={false}
                />
              </FormGroup>
              {error && (
                <Row>
                  <Col sm="3"></Col>
                  <Col sm="7">
                    <div className="tw-text-[#ef8157]">{error}</div>
                  </Col>
                </Row>
              )}
              <div className="tw-text-right">
                <Button className="btn-round" color="info" disabled={!allowed} onClick={handleAnalyze}>
                  Analyze
                </Button>
              </div>
            </CardBody>
          </Card>

          {selectedTab.value === 'advanced' && (
            <Card>
              <CardHeader>
                <CardTitle tag="h4" className="mb-0 text-center">
                  Advisor Fees
                </CardTitle>
              </CardHeader>
              <CardBody className="mt-3">
                <Form className="form-horizontal">
                  <Row>
                    <Col sm="3" className="text-right">
                      <Label>Advisor Fees (Optional)</Label>
                    </Col>
                    <Col sm="7">
                      <FormGroup>
                        <Input
                          type="number"
                          min={0}
                          max={100000}
                          value={advisorFees}
                          onChange={(event) => {
                            setAdvisorFees(parseFloat(event.target.value));
                          }}
                          onWheel={(event) => {
                            event.target?.blur();
                            event.currentTarget?.blur();
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="3" className="text-right">
                      <Label>Accrual Freq</Label>
                    </Col>
                    <Col sm="7">
                      <FormGroup>
                        {accrualFreq && (
                          <Select
                            className="react-select"
                            name="accrualFreq"
                            value={accrualFreq}
                            onChange={setAccrualFreq}
                            options={frequencies}
                          />
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="3" className="text-right">
                      <Label>Deduction Freq</Label>
                    </Col>
                    <Col sm="7">
                      <FormGroup>
                        {deductionFreq && (
                          <Select
                            className="react-select"
                            name="deductionFreq"
                            value={deductionFreq}
                            onChange={setDeductionFreq}
                            options={frequencies}
                          />
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          )}
        </div>
        {options && (
          <div className="tw-col-span-12 xl:tw-col-span-9">
            <PageTabs
              options={[
                { value: 'risk-return', label: 'Risk & Return' },
                { value: 'risk-ratios', label: 'Risk Ratios' },
                { value: 'probability-df', label: 'Probability DF' },
                { value: 'value-at-risk', label: 'Value at Risk' },
                { value: 'beta', label: 'Beta' },
                { value: 'correlation', label: 'Correlation' },
              ]}
              style={{ width: '160px' }}
              session={session}
            >
              <TabPane tabId="risk-return" role="tabpanel">
                <RiskReturn options={options} />
              </TabPane>
              <TabPane tabId="risk-ratios" role="tabpanel"></TabPane>
              <TabPane tabId="probability-df" role="tabpanel"></TabPane>
              <TabPane tabId="value-at-risk" role="tabpanel"></TabPane>
              <TabPane tabId="beta" role="tabpanel"></TabPane>
              <TabPane tabId="correlation" role="tabpanel"></TabPane>
            </PageTabs>
          </div>
        )}
      </div>
    </>
  );
}

export default ToolboxCorrelation;
