import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { Card, CardHeader, CardBody, Label, TabPane, Row, Col } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { getTightColIndexByKey } from 'helpers/chart';
import { removeEmptyCols } from 'helpers/data-frame';
import { toDeci, toPerc, toPercTable, confidenceLevelToInterval } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';

function DrawdownDetail({ data, params, notify, session }) {
  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Drawdown Detail
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="drawdown-detail"
            data={data}
            rowHeader={{ text: params.product, style: { color: 'black', backgroundColor: '#C6E0B4' } }}
            indexName=""
            cellFormatter={(value, row, col) => (['Drawdown'].includes(col) ? toPercTable(value) : value)}
            compact
            title="Drawdown Detail"
          />
        </CardBody>
      </Card>
    </>
  );
}

export default DrawdownDetail;
