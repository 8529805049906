import { useState } from 'react';

export function useArrayState({ initialItems = [], initialItem = null } = {}) {
  const [items, setItems] = useState(initialItems);
  const [selectedItem, setSelectedItem] = useState(initialItem ?? (initialItems.length > 0 ? initialItems[0] : null));

  const _setItems = (items) => {
    setItems(items);
    if (items.length > 0) setSelectedItem(items[0]);
    else setSelectedItem(null);
  };

  return [items, _setItems, selectedItem, setSelectedItem];
}
