import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import store from 'store2';
import _ from 'lodash';
import { Card, CardHeader, CardBody, TabPane, Row, Col } from 'reactstrap';
import PageTabs from 'components/PageTabs';
import {
  getInvestmentComparisonHeader,
  getInvestmentComparison,
  getInvestmentComparisonWithCustomData,
} from 'services/analysis';
import TightTable from 'components/TightTable';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { FixedCenterLoader } from 'components/Loaders';
import { parseSearchParams } from 'helpers/location';
import DetailSummary from './Detail/Summary';
import RiskPerformanceReturns from './RiskPerformance/Returns';
import RiskPerformanceRiskMetrics from './RiskPerformance/RiskMetrics';
import RiskPerformanceValueAtRisk from './RiskPerformance/ValueAtRisk';
import RiskPerformanceDrawdown from './RiskPerformance/Drawdown';
import RiskPerformanceMarketRisk from './RiskPerformance/MarketRisk';
import RiskPerformanceRiskExposure from './RiskPerformance/RiskExposure';
import RiskPerformanceFamaFrench from './RiskPerformance/FamaFrench';
import RiskPerformanceCorrelation from './RiskPerformance/Correlation';
import RiskPerformanceInterestRateRisk from './RiskPerformance/InterestRateRisk';
import RiskPerformanceCreditRisk from './RiskPerformance/CreditRisk';
import RiskPerformanceRiskContribution from './RiskPerformance/RiskContribution';
import RiskPerformancePortfolioImpact from './RiskPerformance/PortfolioImpact';
import { fetchData } from './data';

const CUSTOM_DATA = 'custom-data';

function HeaderComponent({ content }) {
  if (!content) return;

  return (
    <Card>
      <CardHeader tag="h4" className="mt-0 tw-text-center">
        Description
      </CardHeader>
      <CardBody>
        <div className="tw-italic tw-text-sm tw-text-center">{content}</div>
      </CardBody>
    </Card>
  );
}

function FooterComponent({ content }) {
  if (!content) return;

  return (
    <Card>
      <CardHeader tag="h4" className="mt-0 tw-text-center">
        Notes
      </CardHeader>
      <CardBody>
        <div className="tw-italic tw-text-sm tw-text-center">{content}</div>
      </CardBody>
    </Card>
  );
}

function InvestmentComparison({ notify, session }) {
  const location = useLocation();
  const [params, setParams] = useState(null);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(null);
  const [detail, setDetail] = useState(null);
  const [riskPerf, setRiskPerf] = useState(null);

  const loadData = async () => {
    setLoading(true);
    const { success, error, info, riskPerf, detail } = await fetchData(params);

    if (success) {
      setInfo(info);
      setDetail(detail);
      setRiskPerf(riskPerf);
    } else {
      notify.danger({ message: <div>{error}</div> });
    }

    setLoading(false);
  };

  const loadDataFromState = async () => {
    const { info, riskPerf, detail } = location.state;
    setInfo(info);
    setDetail(detail);
    setRiskPerf(riskPerf);
  };

  useEffect(() => {
    const params = parseSearchParams(location.search, [
      { key: 'product', multi: true },
      { key: 'database', multi: true },
      'benchmark',
      'geography',
      'riskbucket',
      'correlation',
      'significance',
      'sdate',
      'edate',
      'custom',
    ]);

    if (params.custom === 'true') {
      params.product = [];
      params.database = [];
    }

    setParams(params);
  }, [location]);

  useEffect(() => {
    if (location.state.success) {
      loadDataFromState();
    } else if (params) {
      loadData();
    }
  }, [params]);

  if (loading) {
    return <FixedCenterLoader />;
  }

  if (!info) return null;

  const detailKeys = _.keys(detail.data);
  const riskKeys = _.keys(riskPerf.data);

  const topTabOptions = [];
  if (detailKeys.length > 0) {
    topTabOptions.push({ value: 'detail', label: 'Detail' });
  }

  if (riskKeys.length > 0) {
    topTabOptions.push({ value: 'riskperf', label: 'Risk & Performance' });
  }

  const isCustom = params.custom === 'true';

  return (
    <>
      <div className="tw-text-center">
        <h3 className="title tw-mb-2">Investment Comparison</h3>
      </div>

      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          <div className="tw-text-sm">
            Benchmark: <span className="tw-font-semibold">{info.benchmark_name}</span>
          </div>
          <TimePeriod dates={[info.start_date, info.end_date]} prefix="Time Period:" />
        </CardHeader>
        <CardBody>
          <TightTable
            title={[`Benchmark: ${info.benchmark_name}`, formatDates([info.start_date, info.end_date])]}
            suffix="info"
            data={info.asset_info_header}
            indexName=""
          />
        </CardBody>
      </Card>

      <PageTabs options={topTabOptions} colorIndex={0}>
        <TabPane tabId="detail" role="tabpanel">
          <PageTabs
            options={_.compact([detail.data.summary && { value: 'summary', label: detail.titles.summary }])}
            colorIndex={1}
          >
            <TabPane tabId="summary" role="tabpanel">
              <HeaderComponent content={detail.descriptions.summary} />
              <DetailSummary data={detail.data.summary} params={params} notify={notify} session={session} />
              <FooterComponent content={detail.notes.summary} />
            </TabPane>
          </PageTabs>
        </TabPane>

        <TabPane tabId="riskperf" role="tabpanel">
          <PageTabs
            options={_.compact([
              riskPerf.data.calendar_return_output && {
                value: 'returns',
                label: riskPerf.titles.calendar_return_output,
              },
              riskPerf.data.risk_metrics_output && {
                value: 'risk-metrics',
                label: riskPerf.titles.risk_metrics_output,
              },
              riskPerf.data.var_output && { value: 'value-at-risk', label: riskPerf.titles.var_output },
              riskPerf.data.drawdown_output && { value: 'drawdown', label: riskPerf.titles.drawdown_output },
              riskPerf.data.market_risk_output && { value: 'market-risk', label: riskPerf.titles.market_risk_output },
              riskPerf.data.ir_output && { value: 'interest-rate-risk', label: riskPerf.titles.ir_output },
              riskPerf.data.cr_output && { value: 'credit-risk', label: riskPerf.titles.cr_output },
              riskPerf.data.exposure_output && { value: 'risk-exposure', label: riskPerf.titles.exposure_output },
              riskPerf.data.risk_contribution_output && {
                value: 'risk-contribution',
                label: riskPerf.titles.risk_contribution_output,
              },
              riskPerf.data.ffm_output && { value: 'fama-french', label: riskPerf.titles.ffm_output },
              riskPerf.data.corr_output && { value: 'correlation', label: riskPerf.titles.corr_output },
              riskPerf.data.impact_output && { value: 'portfolio-impact', label: riskPerf.titles.impact_output },
            ])}
            colorIndex={1}
            round={false}
          >
            <TabPane tabId="returns" role="tabpanel">
              <HeaderComponent content={riskPerf.descriptions.calendar_return_output} />
              <RiskPerformanceReturns
                data={riskPerf.data.calendar_return_output}
                params={params}
                notify={notify}
                session={session}
              />
              <FooterComponent content={riskPerf.notes.calendar_return_output} />
            </TabPane>
            <TabPane tabId="risk-metrics" role="tabpanel">
              <HeaderComponent content={riskPerf.descriptions.risk_metrics_output} />
              <RiskPerformanceRiskMetrics
                data={riskPerf.data.risk_metrics_output}
                params={params}
                notify={notify}
                session={session}
              />
              <FooterComponent content={riskPerf.notes.risk_metrics_output} />
            </TabPane>
            <TabPane tabId="value-at-risk" role="tabpanel">
              <HeaderComponent content={riskPerf.descriptions.var_output} />
              <RiskPerformanceValueAtRisk data={riskPerf.data.var_output} notify={notify} session={session} />
              <FooterComponent content={riskPerf.notes.var_output} />
            </TabPane>
            <TabPane tabId="drawdown" role="tabpanel">
              <HeaderComponent content={riskPerf.descriptions.drawdown_output} />
              <RiskPerformanceDrawdown
                data={riskPerf.data.drawdown_output}
                params={params}
                notify={notify}
                session={session}
              />
              <FooterComponent content={riskPerf.notes.drawdown_output} />
            </TabPane>
            <TabPane tabId="market-risk" role="tabpanel">
              <HeaderComponent content={riskPerf.descriptions.market_risk_output} />
              <RiskPerformanceMarketRisk
                data={riskPerf.data.market_risk_output}
                params={params}
                notify={notify}
                session={session}
              />
              <FooterComponent content={riskPerf.notes.market_risk_output} />
            </TabPane>
            <TabPane tabId="risk-exposure" role="tabpanel">
              <HeaderComponent content={riskPerf.descriptions.exposure_output} />
              <RiskPerformanceRiskExposure
                data={riskPerf.data.exposure_output}
                params={params}
                notify={notify}
                session={session}
              />
              <FooterComponent content={riskPerf.notes.exposure_output} />
            </TabPane>
            <TabPane tabId="risk-contribution" role="tabpanel">
              <HeaderComponent content={riskPerf.descriptions.risk_contribution_output} />
              <RiskPerformanceRiskContribution
                data={riskPerf.data.risk_contribution_output}
                params={params}
                notify={notify}
                session={session}
              />
              <FooterComponent content={riskPerf.notes.risk_contribution_output} />
            </TabPane>
            <TabPane tabId="fama-french" role="tabpanel">
              <HeaderComponent content={riskPerf.descriptions.ffm_output} />
              <RiskPerformanceFamaFrench
                data={riskPerf.data.ffm_output}
                params={params}
                notify={notify}
                session={session}
              />
              <FooterComponent content={riskPerf.notes.ffm_output} />
            </TabPane>
            <TabPane tabId="correlation" role="tabpanel">
              <RiskPerformanceCorrelation
                data={riskPerf.data.corr_output}
                params={params}
                notify={notify}
                session={session}
                header={<HeaderComponent content={riskPerf.descriptions.corr_output} />}
                footer={<FooterComponent content={riskPerf.notes.corr_output} />}
              />
            </TabPane>
            <TabPane tabId="interest-rate-risk" role="tabpanel">
              <HeaderComponent content={riskPerf.descriptions.ir_output} />
              <RiskPerformanceInterestRateRisk
                data={riskPerf.data.ir_output}
                params={params}
                notify={notify}
                session={session}
              />
              <FooterComponent content={riskPerf.notes.ir_output} />
            </TabPane>
            <TabPane tabId="credit-risk" role="tabpanel">
              <HeaderComponent content={riskPerf.descriptions.cr_output} />
              <RiskPerformanceCreditRisk
                data={riskPerf.data.cr_output}
                params={params}
                notify={notify}
                session={session}
              />
              <FooterComponent content={riskPerf.notes.cr_output} />
            </TabPane>
            <TabPane tabId="portfolio-impact" role="tabpanel">
              <HeaderComponent content={riskPerf.descriptions.impact_output} />
              <RiskPerformancePortfolioImpact
                options={riskPerf.data.impact_output}
                params={params}
                notify={notify}
                session={session}
                custom={isCustom}
                customData={store.get(CUSTOM_DATA)}
              />
              <FooterComponent content={riskPerf.notes.impact_output} />
            </TabPane>
          </PageTabs>
        </TabPane>
      </PageTabs>
    </>
  );
}

export default InvestmentComparison;
