import _ from 'lodash';
import { Chart } from 'chart.js';
import { flipDF, filterDF } from 'helpers/data-frame';
import { getHeatmap, distinctColor } from 'helpers/color';
import { logo, logo2 } from './meta';

const PAGE_PADDING = 10;
const CHART_WIDTH = 1280;

const chartTitleSizes = {
  bar: 26,
  line: 35,
  pie: 42,
  bubble: 35,
  scatter: 35,
};

export const getChartDataUri = (chart, { width = 1, height = 1, title, titles } = {}) => {
  const chartWidth = (height * CHART_WIDTH) / width;
  const wrapper = document.createElement('div');
  wrapper.style.position = 'relative';
  wrapper.style.width = `${CHART_WIDTH}px`;
  wrapper.style.height = `${chartWidth}px`;

  const canvas = document.createElement('canvas');
  canvas.style.width = '100%';
  canvas.style.height = '100%';

  const ctx = canvas.getContext('2d');
  wrapper.appendChild(canvas);
  document.body.appendChild(wrapper);

  return new Promise((resolve, reject) => {
    try {
      if (title || titles) {
        _.set(chart, 'options.plugins.title', {
          display: true,
          text: titles || title,
          font: {
            size: chartTitleSizes[chart.type] || 42,
            style: 'normal',
            weight: 'bold',
            family: 'Helvetica Neue',
          },
        });
      }

      _.set(chart, 'options.responsive', true);
      _.set(chart, 'options.maintainAspectRatio', true);
      _.set(chart, 'options.bezierCurve', false);
      _.set(chart, 'options.plugins.customCanvasBackgroundColor.color', '#fff');
      _.set(chart, 'options.animation.onComplete', function done() {
        const dataurl = this.toBase64Image();
        document.body.removeChild(wrapper);
        resolve(dataurl);
      });

      let fontSize = 0;
      if (chart.type === 'bar') {
        fontSize = 20;
        _.set(chart, 'options.scales.x.ticks.font.size', fontSize);
        _.set(chart, 'options.scales.y.ticks.font.size', fontSize);
        if (!_.has(chart, 'options.scales.x.ticks.color')) _.set(chart, 'options.scales.x.ticks.color', 'black');
        if (!_.has(chart, 'options.scales.y.ticks.color')) _.set(chart, 'options.scales.y.ticks.color', 'black');
        // _.set(chart, 'options.scales.y.afterSetDimensions', (x) => console.log(x));
      } else if (chart.type === 'pie') {
        fontSize = 34;
      } else if (chart.type === 'bubble') {
        fontSize = 16;
      } else if (chart.type === 'scatter') {
        fontSize = 16;
      }

      const displayLegend = _.get(chart, 'options.plugins.legend.display', false) === true;
      if (displayLegend) {
        _.set(chart, 'options.plugins.legend.labels.boxWidth', 100);
        _.set(chart, 'options.plugins.legend.labels.font.size', fontSize);
        _.set(chart, 'options.plugins.legend.labels.color', 'black');
      }

      _.set(chart, 'options.plugins.datalabels.font.size', fontSize);

      new Chart(ctx, chart);
    } catch (err) {
      reject(err);
    }
  });
};

const setFontSize = (doc, fontSize, { relative = false } = {}) => {
  const tmpFontSize = doc.internal.getFontSize();
  doc.setFontSize(relative ? tmpFontSize * fontSize : fontSize);
  return tmpFontSize;
};

export const setFont = (doc, { fontName, fontSize, fontStyle } = {}, { relative = false } = {}) => {
  const tmpFontSize = doc.internal.getFontSize();
  if (relative) fontSize = tmpFontSize * fontSize;
  const tmpFont = doc.internal.getFont();
  doc.setFontSize(fontSize || tmpFontSize);
  doc.setFont(fontName || tmpFont.fontName, fontStyle || tmpFont.fontStyle);

  return () => {
    doc.setFontSize(tmpFontSize);
    doc.setFont(tmpFont.fontName, tmpFont.fontStyle);
  };
};

const getPageWidth = (doc) => {
  return doc.internal.pageSize.getWidth();
};

const getPageHeight = (doc) => {
  return doc.internal.pageSize.getHeight();
};

const getInnerWidth = (doc) => {
  const pageWidth = doc.internal.pageSize.getWidth();
  return pageWidth - PAGE_PADDING * 2;
};

const tableDefaultStyle = {
  theme: 'grid',
  styles: {
    font: 'times',
    fontSize: 9,
    halign: 'center',
    cellPadding: 1,
    lineWidth: 0.1,
    lineColor: [0, 0, 0],
    textColor: [0, 0, 0],
  },
  headStyles: {
    textColor: [0, 0, 0],
    fontStyle: 'normal',
    fontSize: 8,
    lineWidth: 0.1,
    lineColor: [0, 0, 0],
    fillColor: [166, 204, 247],
  },
  bodyStyles: {},
  footStyles: {},
  rowStyles: {
    lineWidth: 0.1,
    lineColor: [0, 0, 0],
  },
  columnStyles: {},
  alternateRowStyles: {
    fillColor: [212, 212, 212],
    textColor: [0, 0, 0],
    lineWidth: 0.1,
    lineColor: [0, 0, 0],
  },
  tableLineColor: [0, 0, 0],
};

export const jsPdfTable = (
  doc,
  {
    df,
    rows,
    cols,
    indexName = '',
    cellFormatter = (v) => v,
    title = '',
    subTitle = '',
    heatmap = false,
    showHeader = true,
    equalWidth = false,
    indexWidth,
    maxWidth,
  },
  tableProps = {},
) => {
  df = filterDF(df, rows, cols);

  const { index, columns, data } = df;

  let heat = {};
  if (heatmap) {
    heat = heatmap === true ? getHeatmap(_.flatten(data)) : heatmap;
  }

  const pageWidth = doc.internal.pageSize.getWidth();
  let padding = PAGE_PADDING;
  if (maxWidth) {
    padding = (pageWidth - maxWidth) / 2;
  }

  const pageInnerWidth = pageWidth - padding * 2;

  let cellWidth;
  if (equalWidth) {
    cellWidth = indexWidth ? (pageInnerWidth - indexWidth) / columns.length : pageInnerWidth / (columns.length + 1);
  }

  const body = [];
  _.each(index, (rowTitle, rowInd) => {
    const row = [];
    const indexData = { content: rowTitle, styles: { halign: 'left' } };
    if (indexWidth) indexData.styles.cellWidth = indexWidth;
    row.push(indexData);

    _.each(columns, (colTitle, colInd) => {
      const cellValue = data[rowInd][colInd];
      const processed = cellFormatter(cellValue, rowTitle, colTitle, colInd);
      const cell = { content: cellValue, styles: {} };

      const fillColor = heat[cellValue];
      if (fillColor) {
        cell.content = processed.value || processed;
        cell.styles.fillColor = fillColor;
        cell.styles.textColor = distinctColor(fillColor);
      } else if (_.isObject(processed)) {
        const { style = {}, value = '' } = processed;
        cell.content = value;
        cell.styles.textColor = style.color;
      } else {
        cell.content = processed;
      }

      if (cellWidth) cell.styles.cellWidth = cellWidth;

      row.push(cell);
    });

    body.push(row);
  });

  let { startY = 0 } = tableProps;

  if (title) {
    const pageWidth = doc.internal.pageSize.getWidth();
    doc.text(title, pageWidth / 2, startY, { align: 'center' });
    startY = startY + 3;

    if (subTitle) {
      const revert = setFont(doc, { fontSize: 0.8 }, { relative: true });
      const pageWidth = doc.internal.pageSize.getWidth();
      doc.text(subTitle, pageWidth / 2, startY + 2, { align: 'center' });
      revert();
      startY = startY + 5;
    }
  }

  const margin = { left: padding, right: padding, top: 20 };
  const config = {
    ..._.merge({}, tableDefaultStyle, tableProps),
    margin,
    startY,
    body,
    columns: [indexName, ...columns].map((col) => ({ header: col, dataKey: col })),
  };

  if (!showHeader) delete config.columns;

  doc.autoTable(config);
  return doc.autoTable.previous;
};

export const h1 = (doc, text, { top = 0, fontSize = 1.5, align = 'center', underline = false } = {}) => {
  const tmpFontSize = doc.internal.getFontSize();
  const tmpFont = doc.internal.getFont();
  const tmpLineWidth = doc.internal.getLineWidth();
  doc.setFontSize(tmpFontSize * fontSize);
  doc.setFont(tmpFont.fontName, 'bold');

  const pageWidth = doc.internal.pageSize.getWidth();
  const textWidth = doc.getTextWidth(text);

  if (align === 'center') {
    const halfPageWidth = pageWidth / 2;
    const halfTextWidth = textWidth / 2;

    doc.text(text, halfPageWidth, top, { align });
    doc.setLineWidth(1);
    if (underline) doc.line(halfPageWidth - halfTextWidth, top + 2, halfPageWidth - halfTextWidth + textWidth, top + 2);
  } else {
    doc.text(text, PAGE_PADDING, top, { align });
    doc.setLineWidth(1);
    if (underline) doc.line(PAGE_PADDING, top + 2, PAGE_PADDING + textWidth, top + 2);
  }

  doc.setFontSize(tmpFontSize);
  doc.setFont(tmpFont.fontName, tmpFont.fontStyle);
  doc.setLineWidth(tmpLineWidth);

  return { finalY: top + 5 };
};

export const paragraph = (
  doc,
  text,
  { top, left = PAGE_PADDING, maxWidth, fontName, fontSize, fontStyle } = {},
  { relative = true } = {},
) => {
  const revert = setFont(doc, { fontName, fontSize, fontStyle }, { relative });
  doc.text(text, left, top, { maxWidth });
  const dim = doc.getTextDimensions(text);
  revert();

  return { finalY: top + dim.h };
};

export const jsPdfText = (doc, text, { left = 0, top = 0, width = 100, height = 100 } = {}) => {
  doc.text(text, left + width / 2, top, { align: 'center' });
};

export const boundary = (doc, { left = 0, top = 0, width = 100, height = 100 } = {}) => {
  doc.setDrawColor(0);
  doc.setFillColor(255, 255, 255);
  doc.roundedRect(left, top, width, height, 1, 1, 'FD');
};

const rightHeaderText = 'PORTFOLIO ANALYSIS';
const leftHeaderText = 'Powered by Anchor Pacific Financial Risk Labs Ltd.';
export const header = (doc) => {
  let revert = setFont(doc, { fontStyle: 'bold' });
  doc.text(rightHeaderText, PAGE_PADDING, 6);
  revert();

  revert = setFont(doc, { fontSize: 0.7 }, { relative: true });
  const pageWidth = getPageWidth(doc);
  const textWidth = doc.getTextWidth(leftHeaderText);
  doc.text(leftHeaderText, pageWidth - textWidth - PAGE_PADDING, 6);
  revert();
};

export const footer = (doc) => {
  const pageWidth = getPageWidth(doc);
  const pageHeight = getPageHeight(doc);
  const bottom = pageHeight - 10;

  doc.addImage(logo, 'PNG', PAGE_PADDING, bottom, 30, 8);
  doc.addImage(logo2, 'PNG', pageWidth - PAGE_PADDING - 25, bottom, 25, 8);
};

const BOUNDARY_PADDING = 1;
export const jsPdfImage = (
  doc,
  image,
  {
    title = '',
    left = 0,
    top = 0,
    width = 100,
    height = 100,
    leftPadding = 0,
    rightPadding = 0,
    leftMargin = 0,
    rightMargin = 0,
  } = {},
) => {
  let boundaryTop = title ? top - 5 : top;
  let boundaryHeight = title ? height + 1 : height;
  boundaryHeight = boundaryHeight + 9;
  boundary(doc, { left, top: boundaryTop, width, height: boundaryHeight });

  if (title) {
    doc.text(title, left + width / 2, top + 1, { align: 'center' });
    top = top + 1;
  }

  doc.addImage(
    image,
    'PNG',
    left + leftPadding + BOUNDARY_PADDING,
    top + 3,
    width - leftPadding - rightPadding,
    height - BOUNDARY_PADDING,
  );

  return { finalY: top + height };
};
