import React, { memo, useState, cloneElement, Children } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import classnames from 'classnames';
import { NavItem, NavLink, Nav, TabContent, UncontrolledTooltip } from 'reactstrap';
import _ from 'lodash';
import { colors } from 'helpers/chart';

const ColorNav = styled(Nav)`
  ${(props) =>
    `
    & .nav-link {
      border: 1px solid ${props.color} !important;
      color: ${props.color} !important;
      border-radius: 0px !important;

      &.active {
        border: 1px solid ${props.color} !important;
        background: ${props.color} !important;
        color: #ffffff !important;
      }
    }

    ${
      props.round &&
      `
    & .nav-item:first-child .nav-link {
      border-radius: 30px 0 0 30px !important;
    }

    & .nav-item:last-child .nav-link {
      border-radius: 0 30px 30px 0 !important;
    }
    `
    }
`}
`;

function PageTabs({
  options = [],
  defaultToFirst = true,
  children,
  colorIndex = 0,
  onTabSelect = _.noop,
  HeaderComponent = null,
  session = { permissions: {} },
  round = true,
  fixedWidth = true,
  style = {},
}) {
  const navigate = useNavigate();

  options = options.filter((option) => {
    if (option.permission) {
      return session.permissions[option.permission];
    }
    return true;
  });

  let defaultOption = options.find((opt) => opt.default);
  if (defaultToFirst && !defaultOption && options.length > 0) defaultOption = options[0];
  const [pageTab, setPageTab] = useState(defaultOption && defaultOption.value);

  let _children = [];
  if (typeof children === 'object') {
    if (Array.isArray(children)) {
      _children = children;
    } else {
      _children.push(children);
    }
  }

  _children = Children.map(_children, (child, index) => {
    if (child?.props?.tabId === pageTab) return cloneElement(child);
    return null;
  });

  return (
    <>
      <ColorNav
        className="nav-pills-primary nav-pills-icons justify-content-center clickable"
        pills
        round={round}
        role="tablist"
        color={colors[colorIndex]}
      >
        {options.length > 1 &&
          options.map((option) => {
            const id = _.snakeCase(option.label);
            const trunc = fixedWidth && option.label.length > 21;

            return (
              <NavItem key={option.value}>
                <NavLink
                  data-toggle="tab"
                  role="tablist"
                  className={classnames('tw-text-center tw-px-0', {
                    'tw-w-[200px]': fixedWidth,
                    active: pageTab === option.value,
                  })}
                  onClick={() => {
                    if (option.navigate) {
                      navigate(option.navigate, { replace: false });
                      setPageTab(undefined);
                      onTabSelect(undefined);
                    } else {
                      setPageTab(option.value);
                      onTabSelect(option);
                    }
                  }}
                  style={style}
                >
                  {option.labels ? (
                    option.labels.map((label) => {
                      return <div>{label}</div>;
                    })
                  ) : (
                    <span id={id}>{trunc ? _.truncate(option.label, { length: 22 }) : option.label}</span>
                  )}

                  {trunc && (
                    <UncontrolledTooltip delay={0} target={id}>
                      {option.label}
                    </UncontrolledTooltip>
                  )}
                </NavLink>
              </NavItem>
            );
          })}
      </ColorNav>
      <TabContent className="text-center mt-2" activeTab={pageTab}>
        {HeaderComponent}
        {_children}
      </TabContent>
    </>
  );
}

export default memo(PageTabs);
