import React, { useState, useEffect, useContext, useMemo, useRef, useLayoutEffect, createRef } from 'react';
import _ from 'lodash';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  TabPane,
  Row,
  Col,
  Spinner,
  Button,
  Modal,
  Progress,
} from 'reactstrap';
import Select from 'react-select';
import PageTabs from 'components/PageTabs';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import PieChart from 'components/PieChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { optionize, optionizeAll } from 'helpers/select';
import { toPerc, toDeciTable, toPercTable, formatDate, isEmptyTightData, removeEmptyRows } from 'helpers/formatter';
import { riskMeasuresRatios, riskMeasuresPerc, riskMeasuresAll } from 'helpers/meta';
import TopPieCharts from 'views/portfolio-analysis/TopPieCharts';

function Summary({ data, notify, params, session }) {
  const [periodOptions, setPeriodOptions, selectedPeriodOption, setSelectedPeriodOption] = useArrayState();
  const [portfolioOptions, setPortfolioOptions, selectedPortfolioOption, setSelectedPortfolioOption] = useArrayState();
  const [metricOptions, setMetricOptions, selectedMetricOption, setSelectedMetricOption] = useArrayState({
    initialItems: optionizeAll(riskMeasuresAll),
  });

  useEffect(() => {
    if (!data) return;

    const _periods = _.keys(data.risk_summary[0]);
    const _periodOptions = optionizeAll(_periods, { labelFormatter: _.upperFirst });
    setPeriodOptions(_periodOptions);
    if (_periodOptions.length > 0) setSelectedPeriodOption(_periodOptions[0]);
  }, [data]);

  useEffect(() => {
    if (!data || !selectedPeriodOption) return;

    const _portfolios = data.risk_summary[0][selectedPeriodOption.value].index.filter((v) => v !== params.benchmark);
    const _portfolioOptions = optionizeAll(_portfolios);
    setPortfolioOptions(_portfolioOptions);
    if (_portfolioOptions.length > 0) setSelectedPortfolioOption(_portfolioOptions[0]);
  }, [data, selectedPeriodOption]);

  if (!selectedPeriodOption || !selectedMetricOption) {
    return null;
  }

  return (
    <>
      <TopPieCharts params={params} />

      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Returns
          <div className="small">As of: {formatDate(data.return_summary[1])}</div>
        </CardHeader>
        <CardBody>
          <TightTable
            data={data.return_summary[0]}
            indexName=""
            cellFormatter={(value, row, col) => (['Inception Date'].includes(col) ? value : toPercTable(value))}
            rowDecorator={(rowTitle, rowInd, rowTitles) =>
              rowTitle === params.port1Name
                ? { style: { backgroundColor: '#C6E0B4' } }
                : rowTitle === params.benchmark
                ? { style: { backgroundColor: 'rgb(251,198,88,0.5)' } }
                : undefined
            }
            suffix="returns"
            useSort
            title={['Returns', `As of: ${formatDate(data.return_summary[1])}`]}
          />
        </CardBody>
      </Card>

      {periodOptions && periodOptions.length > 0 && (
        <>
          <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
          <Select
            className="react-select mw-200px"
            name="duration"
            value={selectedPeriodOption}
            onChange={setSelectedPeriodOption}
            options={periodOptions}
          />

          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Risk Metrics
              <div className="small">Time Period: ({selectedPeriodOption.label})</div>
              <TimePeriod dates={data.risk_summary[1][selectedPeriodOption.value]} />
            </CardHeader>
            <CardBody>
              <TightTable
                data={data.risk_summary[0][selectedPeriodOption.value]}
                indexName=""
                cellFormatter={(value, row, col) =>
                  riskMeasuresPerc.includes(col) ? toPercTable(value) : toDeciTable(value)
                }
                rowDecorator={(rowTitle, rowInd, rowTitles) =>
                  rowTitle === params.port1Name
                    ? { style: { backgroundColor: '#C6E0B4' } }
                    : rowTitle === params.benchmark
                    ? { style: { backgroundColor: 'rgb(251,198,88,0.5)' } }
                    : undefined
                }
                suffix="risk-summary"
                useSort
                title={[
                  'Risk Metrics',
                  `Time Period: (${selectedPeriodOption.label})`,
                  formatDates(data.risk_summary[1][selectedPeriodOption.value]),
                ]}
              />
            </CardBody>
          </Card>
        </>
      )}

      {portfolioOptions && portfolioOptions.length > 0 && (
        <AutoColumns>
          <>
            <div className="tw-text-left tw-font-bold">SELECT METRIC</div>
            <Select
              className="react-select mw-300px"
              name="measure"
              value={selectedMetricOption}
              onChange={setSelectedMetricOption}
              options={metricOptions}
            />
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                {selectedMetricOption.label}
              </CardHeader>
              <CardBody>
                <TightChart
                  title={selectedMetricOption.label}
                  data={data.risk_summary[0][selectedPeriodOption.value]}
                  cols={[selectedMetricOption.value]}
                  dataType={riskMeasuresPerc.includes(selectedMetricOption.value) ? 'percentage' : 'decimal'}
                  wraplabelX={true}
                  dataLabels={true}
                />
              </CardBody>
            </Card>
          </>
          <>
            <>
              <div className="tw-text-left tw-font-bold">SELECT PORTFOLIO</div>
              <Select
                className="react-select mw-400px"
                name="portfolio"
                value={selectedPortfolioOption}
                onChange={setSelectedPortfolioOption}
                options={portfolioOptions}
              />
            </>

            {selectedPortfolioOption && (
              <Card>
                <CardBody>
                  <TightChart
                    data={data.risk_summary[0][selectedPeriodOption.value]}
                    rows={[selectedPortfolioOption.value, params.benchmark]}
                    cols={riskMeasuresRatios}
                    base="cols"
                    dataLabels={true}
                  />
                </CardBody>
              </Card>
            )}
          </>
        </AutoColumns>
      )}
    </>
  );
}

export default Summary;
