import React, { useState, useEffect, useMemo, useCallback } from 'react';
import _ from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { toDeci, toPerc, toPercTable, toDeciTable, tablePresets, getDataType, formatCurrency } from 'helpers/formatter';
import TightTable from 'components/TightTable';
import AssetHeader from 'views/AssetHeader';

function SingleAssetHeader({ data }) {
  const infoHeaderData = {
    Type: data.info_header['Type'],
    Symbol: data.info_header['Symbol'],
    Currency: data.info_header['Currency'],
    'Factor Exposure': data.info_header['Factor Exposure'],
    'Confidence Interval': data.info_header['Confidence Level']
      ? toPerc(1 - data.info_header['Confidence Level'], { precision: 0 })
      : '',
    'Risk Model': data.info_header['Risk Model'],
    Benchmark: data.info_header['Benchmark'],
    'Time Period': data.info_header['time_period'],
  };

  return (
    <>
      <AssetHeader
        asset={data.info_header['Title'] || data.info_header['Fund Name'] || data.info_header['Portfolio Name']}
      />

      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          {_.map(infoHeaderData, (val, key) => {
            if (!val) return null;

            return (
              <div className="tw-text-base">
                {key}: <span className="tw-font-semibold">{val}</span>
              </div>
            );
          })}
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="info"
            indexWidth="230px"
            data={data.performance_header}
            hideIndexTable
            cellFormatter={(value, row, col) => {
              const formatter = tablePresets[col];
              if (formatter) return formatter(value);

              return toDeciTable(value);
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}

export default SingleAssetHeader;
