import { instance } from './axios';
import _ from 'lodash';

const getErrorMessage = (error) => _.get(error, 'response.data.message');

export async function listWatchlists() {
  try {
    const result = await instance.get('/watchlists').then((res) => res.data);

    return [result, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function getWatchlist(asset, database) {
  try {
    const result = await instance.get(`/watchlists/${asset}/${database}`).then((res) => res.data);

    return [result, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function createWatchlist(asset, database) {
  try {
    const result = await instance.post('/watchlists', { asset, database }).then((res) => res.data);

    return [result, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function deleteWatchlist(asset, database) {
  try {
    const result = await instance.delete(`/watchlists/${asset}/${database}`).then((res) => res.data);

    return [result, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}
