export const optionize = (value, label) => ({ value, label: label || value });
export const optionizeValue = (value) => ({ value, label: value });
export const optionizeAll = (values, { useIndex = false, labelFormatter = (v) => v } = {}) =>
  values.map((val, ind) => (useIndex ? optionize(ind, labelFormatter(val)) : optionize(val, labelFormatter(val))));

export const getValue = (option) => option?.value;

const getDateFromMoment = (momentval) => {
  if (!momentval?.toDate) return null;
  return momentval.toDate();
};

export const getDate = (momentval) => {
  const date = getDateFromMoment(momentval);
  if (!date) return '';

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${_.padStart(month, 2, '0')}-${_.padStart(day, 2, '0')}`;
};

export const getYearMonth = (momentval) => {
  const date = getDateFromMoment(momentval);
  if (!date) return '';

  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  return `${_.padStart(month, 2, '0')}/${year}`;
};

export function monthDiff(momentval1, momentval2, maxDate = new Date()) {
  let date1 = getDateFromMoment(momentval1);
  let date2 = getDateFromMoment(momentval2);
  if (!date1 && !date2) return Number.MIN_VALUE;

  if (!date2) date2 = maxDate;

  let months;
  months = (date2.getFullYear() - date1.getFullYear()) * 12;
  months -= date1.getMonth();
  months += date2.getMonth();
  return months;
}
