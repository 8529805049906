import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import _ from 'lodash';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Table,
  Row,
  Col,
  Spinner,
  UncontrolledTooltip,
  TabPane,
  CardFooter,
} from 'reactstrap';
import PageTabs from 'components/PageTabs';
import { FixedCenterLoader } from 'components/Loaders';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import DownloadSimpleTable from 'components/DownloadSimpleTable';
import ReactDatetime from 'react-datetime';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { listProducts, getDiscovery, toolboxCorrelation, getQuickViewData } from 'services/analysis';
import { optionizeValue, optionizeAll, getValue, getDate, getYearMonth, monthDiff } from 'helpers/select';
import { confidenceLevelToInterval } from 'helpers/formatter';
import { toDeci, toPerc, toPercTable, toDeciTable, tablePresets, getDataType, formatCurrency } from 'helpers/formatter';
import PieChart from 'components/PieChart';
import TightTable from 'components/TightTable';
import { Descriptions, Notes } from '../others';

function DatabaseDetails({ database, product, data }) {
  const tableKeys = Object.keys(data.Summary);

  return (
    <>
      <AutoColumns>
        {tableKeys.map((key, ind) => {
          const tableData = data.Summary[key];
          return (
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                {key}
              </CardHeader>
              <CardBody>
                <DownloadSimpleTable data={tableData} suffix={`quick-view-details-${key}`}></DownloadSimpleTable>
                <Table bordered striped>
                  <tbody>
                    {tableData.index.map((row, i) => {
                      const value = tableData.data[i][0];
                      if (!value) return null;

                      return (
                        <tr>
                          <td>{row}</td>
                          <td>{value}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          );
        })}
      </AutoColumns>
    </>
  );
}

function UserSavedDetails({ database, product, data }) {
  const [typeOptions, setTypeOptions, selectedTypeOption, setSelectedTypeOption] = useArrayState({
    initialItems: optionizeAll(['Holdings', 'Category']),
  });

  const holdingsDf = data.portfolio_comp;
  const categoryDf = {
    index: [],
    columns: ['Weight'],
    data: [],
    index_names: [],
    column_names: [],
  };

  const categoryMap = _.reduce(
    holdingsDf.data,
    (ret, val) => {
      const [num, db, category] = val;
      const cat = _.trim(category);

      if (!ret[cat]) {
        ret[cat] = num;
      } else {
        ret[cat] += num;
      }

      return ret;
    },
    {},
  );

  categoryDf.index = _.keys(categoryMap);
  categoryDf.data = categoryDf.index.map((row) => [categoryMap[row]]);

  const chartDf = selectedTypeOption.value === 'Holdings' ? holdingsDf : categoryDf;

  const tableData = { ...chartDf };
  tableData.index = tableData.index.concat('Total');

  const weightColIndex = tableData.columns.findIndex((col) => col === 'Weight');
  const totalRow = ['', '', ''];
  totalRow[weightColIndex] = 1;

  tableData.data = tableData.data.concat([totalRow]);

  return (
    <>
      <div className="tw-max-w-xl tw-mx-auto">
        <div className="tw-text-left tw-font-bold">SELECT</div>
        <Select
          className="react-select tw-max-w-xs"
          name="selectedTypeOption"
          value={selectedTypeOption}
          onChange={setSelectedTypeOption}
          options={typeOptions}
        />

        <Card>
          <CardHeader tag="h4" className="tw-mt-0 tw-text-center tw-text-lg">
            <div>{product}</div>
          </CardHeader>
          <CardBody>
            <PieChart
              title={product}
              data={chartDf}
              field="Weight"
              dataType="percentage"
              containerStyle={{ maxWidth: '500px', maxHeight: '500px' }}
            />
          </CardBody>
        </Card>
      </div>

      <div className={classnames('tw-mx-auto', selectedTypeOption.value === 'Holdings' ? '' : 'tw-max-w-xl')}>
        <Card>
          <CardHeader tag="h4" className="mt-0 text-center">
            <div>{product}</div>
          </CardHeader>
          <CardBody>
            <TightTable
              suffix="quickview-details"
              data={tableData}
              cols={['Category', 'Weight']}
              indexName=""
              cellFormatter={(value, row, col) => {
                if (col === 'Weight') return toPercTable(value);
                return value;
              }}
              indexWidth="350px"
              compact
            />
          </CardBody>
        </Card>
      </div>
    </>
  );
}

function Details({ database, product, converter, setName }) {
  const [loading, setLoading] = useState(false);
  const [isUserSaved, setIsUserSaved] = useState(database === 'Saved Portfolios');
  const [result, setResult] = useState(null);

  const fetchData = async () => {
    setLoading(true);

    const [data, err] = await getQuickViewData({
      database,
      product,
      converter,
      page_choice: 'details',
    });

    if (data) {
      setResult(data);
      setName(data.formal_name);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!database || !product) return;

    setIsUserSaved(database === 'Saved Portfolios');
    fetchData();
  }, [database, product, converter]);

  if (loading) {
    return <FixedCenterLoader />;
  }

  if (!result) {
    return null;
  }

  let main = null;

  if (isUserSaved) {
    main = <UserSavedDetails database={database} product={product} data={result} />;
  } else {
    main = <DatabaseDetails database={database} product={product} data={result} />;
  }

  return (
    <>
      <Descriptions content={result.descriptions} />
      {main}
      <Notes content={result.notes} />
    </>
  );
}

export default Details;
