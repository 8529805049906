import _ from 'lodash';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getTightRowByKey, getTightColIndexByKey, colors } from 'helpers/chart';
import { toDeci } from 'helpers/formatter';
import { customCanvasBackgroundColor } from './_chart';

const defaultOptions = {
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
    },
    datalabels: {
      backgroundColor: function (context) {
        return context.dataset.backgroundColor;
      },
      // borderColor: 'white',
      // borderRadius: 25,
      // borderWidth: 2,
      color: 'white',
      // display: function (context) {
      //   var dataset = context.dataset;
      //   var count = dataset.data.length;
      //   var value = dataset.data[context.dataIndex];
      //   return value > count * 1.5;
      // },
      font: {
        weight: 'bold',
      },
      padding: 6,
    },
  },
};

function getPieChartProps({
  data = {},
  base = 'rows',
  field,
  labels,
  values,
  options = {},
  dataType = 'decimal',
  filter,
  width,
  height,
}) {
  const _options = _.cloneDeep(defaultOptions);

  const isPercentage = dataType === 'percentage';

  if (!labels) {
    labels = base === 'rows' ? data.index : data.columns;
    if (filter) labels = filter(labels);
  }

  if (!values) {
    if (base === 'rows') {
      const colIndex = getTightColIndexByKey(data, field);
      values = labels.map((label) => {
        const ind = data.index.findIndex((v) => v === label);
        return data.data[ind][colIndex];
      });
    } else {
      const row = getTightRowByKey(data, field);
      values = labels.map((label) => {
        const ind = data.columns.findIndex((v) => v === label);
        return row[ind];
      });
    }
  }

  if (isPercentage) values = values.map(Number).map((v) => v * 100);

  _.set(
    _options,
    `plugins.tooltip.callbacks.label`,
    isPercentage
      ? (item) => `${item.label}: ${toDeci(item.raw, { suffix: '%' })}`
      : (item) => `${item.label}: ${toDeci(item.raw)}`,
  );

  _.set(
    _options,
    `plugins.datalabels.formatter`,
    isPercentage ? (value, context) => `${toDeci(value, { suffix: '%' })}` : (value, context) => `${toDeci(value)}`,
  );

  return {
    type: 'pie',
    data: {
      labels,
      datasets: [
        {
          data: values,
          backgroundColor: colors,
          hoverOffset: 4,
        },
      ],
    },
    plugins: [ChartDataLabels, customCanvasBackgroundColor],
    options: _.merge({}, _options, options),
    width,
    height,
  };
}

export default getPieChartProps;
