import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import bg from 'assets/img/analyzers-bg.png';
import ia from 'assets/img/analyzers-ia.png';
import ic from 'assets/img/analyzers-ic.png';
import pa from 'assets/img/analyzers-pa.png';

const Background = styled.div`
  text-align: center;
  color: #fff;
  width: 100%;
  height: 100%;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  border-radius: 0.25rem;
`;

function Charts({ session }) {
  const navigate = useNavigate();
  const { permissions } = session ?? {};

  return (
    <div className="tw-max-w-7xl tw-m-auto tw-mt-10">
      <Background>
        <div className="tw-container tw-mx-auto tw-flex tw-flex-col tw-flex-wrap tw-px-3 tw-py-12 lg:tw-flex-row tw-cursor-pointer">
          {permissions['chart-risk-return'] && (
            <div
              className="tw-mb-5 tw-w-full tw-px-3 xl:tw-w-1/2 hover:tw-underline"
              onClick={() => navigate({ pathname: '/charts/risk-return' }, { replace: false })}
            >
              <h3 className="tw-w-full tw-text-center tw-text-3xl tw-font-bold tw-leading-tight">Risk & Return</h3>
              <img
                className="tw-mx-auto tw-w-full tw-max-w-[220px] tw-max-h-[195px] tw-mb-2"
                src={ia}
                alt="Risk & Return"
              />
              <p className="tw-mt-3 tw-text-2xl tw-mx-auto tw-max-w-[300px]"></p>
            </div>
          )}

          {permissions['chart-risk-ratios'] && (
            <div
              className="tw-mb-5 tw-w-full tw-px-3 xl:tw-w-1/2 hover:tw-underline"
              onClick={() => navigate({ pathname: '/charts/risk-ratios' }, { replace: false })}
            >
              <h3 className="tw-w-full tw-text-center tw-text-3xl tw-font-bold tw-leading-tight">Risk Ratios</h3>
              <img
                className="tw-mx-auto tw-w-full tw-max-w-[220px] tw-max-h-[195px] tw-mb-2"
                src={ic}
                alt="Risk Ratios"
              />
              <p className="tw-mt-3 tw-text-2xl tw-mx-auto tw-max-w-[300px]"></p>
            </div>
          )}

          {permissions['chart-var'] && (
            <div
              className="tw-mb-5 tw-w-full tw-px-3 xl:tw-w-1/2 hover:tw-underline"
              onClick={() => navigate({ pathname: '/charts/var' })}
            >
              <h3 className="tw-w-full tw-text-center tw-text-3xl tw-font-bold tw-leading-tight">Value at Risk</h3>
              <img
                className="tw-mx-auto tw-w-full tw-max-w-[220px] tw-max-h-[195px] tw-mb-2"
                src={pa}
                alt="Value at Risk"
              />
              <p className="tw-mt-3 tw-text-2xl tw-mx-auto tw-max-w-[300px]"></p>
            </div>
          )}

          {permissions['chart-beta-correl'] && (
            <div
              className="tw-mb-5 tw-w-full tw-px-3 xl:tw-w-1/2 hover:tw-underline"
              onClick={() => navigate({ pathname: '/charts/beta-correl' })}
            >
              <h3 className="tw-w-full tw-text-center tw-text-3xl tw-font-bold tw-leading-tight">Beta & Correlation</h3>
              <img
                className="tw-mx-auto tw-w-full tw-max-w-[220px] tw-max-h-[195px] tw-mb-2"
                src={pa}
                alt="Beta & Correlation"
              />
              <p className="tw-mt-3 tw-text-2xl tw-mx-auto tw-max-w-[300px]"></p>
            </div>
          )}
        </div>
      </Background>
    </div>
  );
}

export default Charts;
