import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import _ from 'lodash';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Table,
  Row,
  Col,
  Spinner,
  UncontrolledTooltip,
  TabPane,
  CardFooter,
} from 'reactstrap';
import PageTabs from 'components/PageTabs';
import { FixedCenterLoader } from 'components/Loaders';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import DownloadSimpleTable from 'components/DownloadSimpleTable';
import ReactDatetime from 'react-datetime';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { getToolboxMarketStress } from 'services/analysis';
import { optionizeValue, optionizeAll, getValue, getDate, getYearMonth, monthDiff } from 'helpers/select';
import { confidenceLevelToInterval } from 'helpers/formatter';
import { toDeci, toPerc, toPercTable, toDeciTable, tablePresets, getDataType, formatCurrency } from 'helpers/formatter';
import PieChart from 'components/PieChart';
import TightTable from 'components/TightTable';
import { Descriptions, Notes } from '../others';
import AssetSelect from '../AssetSelect';
import MarketStressBase from './market-stress/MarketStress';
import SingleAssetHeader from 'views/SingleAssetHeader';

function MarketStress({ session }) {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({ product: '', database: '', benchmark: '', converter: '' });
  const [result, setResult] = useState(null);

  const fetchData = async () => {
    setLoading(true);

    const [data, err] = await getToolboxMarketStress(options);

    setResult(data);
    setLoading(false);
  };

  useEffect(() => {
    if (!options.product) return;
    fetchData();
  }, [options]);

  console.log('result', result);

  let main = null;
  if (loading) {
    main = <FixedCenterLoader />;
  } else if (result) {
    main = (
      <>
        <SingleAssetHeader data={result.header} />
        <Descriptions content={result.description} />
        <MarketStressBase data={result.data} params={options} />
        <Notes content={result.notes} />
      </>
    );
  }

  return (
    <div className="tw-grid tw-grid-cols-12 tw-gap-4">
      <div className="tw-col-span-12 xl:tw-col-span-3">
        <AssetSelect
          session={session}
          onSelect={({ product, database, benchmark, converter }) => {
            setOptions({ product, database, benchmark, converter });
          }}
          enableBenchmark
        />
      </div>
      <div className="tw-col-span-12 xl:tw-col-span-9">{main}</div>
    </div>
  );
}

export default MarketStress;
