import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { toDeci, toPerc, tablePresets } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';
import { riskMeasuresRatios, riskMeasuresPerc, riskMeasuresDeci2 } from 'helpers/meta';
import { removeEmptyCols } from 'helpers/data-frame';

export const metricOptions = [
  {
    label: 'CAGR and Std Dev',
    value: { keys: ['CAGR', 'Standard Deviation'], type: 'percentage' },
  },
  {
    label: 'Beta and Correlation',
    value: { keys: ['Beta', 'Correlation'], type: 'decimal' },
  },
  {
    label: 'CVaR and Max DD',
    value: { keys: ['CVaR 95%', 'Maximum Drawdown'], type: 'percentage' },
  },
  {
    label: 'Std Dev and Downside Dev',
    value: { keys: ['Standard Deviation', 'Downside Deviation'], type: 'percentage' },
  },
  {
    label: 'Skew and Kurtosis',
    value: { keys: ['Skew', 'Kurtosis'], type: 'decimal' },
  },
  {
    label: 'Sharpe and Sortino',
    value: { keys: ['Sharpe Ratio', 'Sortino Ratio'], type: 'decimal' },
  },
  {
    label: 'MAR and Omega',
    value: { keys: ['MAR Ratio (RoMaD)', 'Omega Ratio'], type: 'decimal' },
  },
  {
    label: 'Ratios (Sharpe, Sortino, Omega)',
    value: { keys: ['Sharpe Ratio', 'Sortino Ratio', 'Omega Ratio'], type: 'decimal' },
  },
];

function RiskMetrics({ data: { data, table_dates }, params, notify, session }) {
  const [filteredDF, setFilteredDF] = useState(null);
  const [measureOptions, setMeasureOptions] = useState([]);
  const [selectedMeasureOption, setSelectedMeasureOption] = useState(null);
  const [assetOptions, setAssetOptions] = useState([]);
  const [selectedAssetOption, setSelectedAssetOption] = useState(null);
  const [selectedMetricOption, setSelectedMetricOption] = useState(metricOptions[0]);

  useEffect(() => {
    if (!data) return;
    const _filteredDF = removeEmptyCols(data, ['15Y', '20Y']);

    const _measureOptions = optionizeAll(_filteredDF.columns);
    setMeasureOptions(_measureOptions);

    if (_measureOptions.length > 0) {
      setSelectedMeasureOption(_measureOptions[0]);
    }

    const _assetOptions = optionizeAll(_filteredDF.index.filter((v, i) => i < _filteredDF.index.length - 1));
    setAssetOptions(_assetOptions);

    if (_assetOptions.length > 0) {
      setSelectedAssetOption(_assetOptions[0]);
    }

    setFilteredDF(_filteredDF);
  }, [data]);

  if (!filteredDF) return null;

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Risk Metrics
          <div className="tw-text-lg">{params.product}</div>
          <TimePeriod dates={table_dates} />
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="risk-performance-risk-metrics"
            data={filteredDF}
            indexName=""
            indexWidth="250px"
            cellFormatter={(value, row, col) => {
              const formatter = tablePresets[row];
              if (formatter) return formatter(value);

              return riskMeasuresDeci2.includes(row)
                ? toDeci(value, { decorate: true, absolute: true })
                : toPerc(value, { decorate: true, absolute: true });
            }}
            title={['Risk Metrics', params.product, formatDates(table_dates)]}
          />
        </CardBody>
      </Card>

      <div className="tw-grid tw-grid-cols-6 tw-gap-4">
        <div className="tw-col-start-2 tw-col-span-4">
          <div className="tw-flex">
            <div className="">
              <div className="tw-text-left tw-font-bold">SELECT RISK METRICS</div>
              <Select
                className="react-select tw-w-[400px] tw-inline-block"
                value={selectedMetricOption}
                onChange={setSelectedMetricOption}
                options={metricOptions}
              />
            </div>
          </div>

          {selectedAssetOption && selectedMetricOption && (
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                {params.product}
              </CardHeader>
              <CardBody>
                <TightChart
                  title={params.product}
                  data={filteredDF}
                  cols={selectedMetricOption.value.keys}
                  rows={(rows) =>
                    rows.filter((row, ind) => {
                      if (['15Y', '20Y'].includes(row)) {
                        return selectedMetricOption.value.keys.every((key) => {
                          const col = filteredDF.index.findIndex((col) => col === key);
                          return !!filteredDF.data[col][ind];
                        });
                      }

                      return true;
                    })
                  }
                  dataType={selectedMetricOption.value.type}
                  flip
                />
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </>
  );
}

export default RiskMetrics;
