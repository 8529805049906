import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { toDeci, toDeciTable, toPercTable, formatDate } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';

function RiskExposure({ data, params, notify, session }) {
  const [exposureOptions, setExposureOptions] = useState([]);
  const [selectedExposureOption, setSelectedExposureOption] = useState(null);
  const [ratioOptions, setRatioOptions] = useState([]);
  const [selectedRatioOption, setSelectedRatioOption] = useState(null);

  useEffect(() => {
    if (!data) return;

    const _exposureOptions = optionizeAll(data.columns);
    setExposureOptions(_exposureOptions);

    if (_exposureOptions.length > 0) {
      setSelectedExposureOption(_exposureOptions[0]);
    }

    const _ratioOptions = optionizeAll(data.index.filter((ind) => ind !== 'Time Period'));
    setRatioOptions(_ratioOptions);

    if (_ratioOptions.length > 0) {
      setSelectedRatioOption(_ratioOptions[0]);
    }
  }, [data]);

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Risk Exposures
          <div className="tw-text-lg">{params.product}</div>
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="risk-performance-risk-exposure"
            data={data}
            indexName=""
            indexWidth="250px"
            cellFormatter={(value, row, col) =>
              ['R2'].includes(row)
                ? toPercTable(value, { precision: 0 })
                : row === 'Time Period'
                ? value
                : toDeciTable(value)
            }
            title={['Risk Exposures', params.product]}
          />
        </CardBody>
      </Card>
      <div className="tw-grid tw-grid-cols-6 tw-gap-4">
        <div className="tw-col-span-3">
          <div className="tw-text-left tw-font-bold">SELECT METRIC</div>
          <Select
            className="react-select mw-400px"
            value={selectedRatioOption}
            onChange={setSelectedRatioOption}
            options={ratioOptions}
          />
          {selectedRatioOption && (
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                <div>{selectedRatioOption.label}</div>
                <div className="tw-text-lg">{params.product}</div>
              </CardHeader>
              <CardBody>
                <TightChart
                  title={selectedRatioOption.label}
                  data={data}
                  cols={[selectedRatioOption.value]}
                  flip={true}
                  wraplabelX={true}
                  dataType={selectedRatioOption.value === 'R2' ? 'percentage' : 'decimal'}
                />
              </CardBody>
            </Card>
          )}
        </div>
        <div className="tw-col-span-3">
          <div className="tw-text-left tw-font-bold">SELECT RISK EXPOSURE</div>
          <Select
            className="react-select mw-400px"
            value={selectedExposureOption}
            onChange={setSelectedExposureOption}
            options={exposureOptions}
          />
          {selectedExposureOption && (
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                <div>Exposure: {selectedExposureOption.label}</div>
                <div className="tw-text-lg">{params.product}</div>
              </CardHeader>
              <CardBody>
                <TightChart
                  title={`Exposure: ${selectedExposureOption.label}`}
                  data={data}
                  rows={[selectedExposureOption.value]}
                  cols={['Up Market Capture Ratio', 'Down Market Capture Ratio']}
                  flip={true}
                  wraplabelX={true}
                />
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </>
  );
}

export default RiskExposure;
