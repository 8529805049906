import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import { Chart } from 'react-chartjs-2';
import _ from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { toDeci, toDeciTable, toPercTable, tablePresets } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';
import { filterDF } from 'helpers/data-frame';

function InterestRateRisk({ data, params, notify, session }) {
  const [periodOptions, setPeriodOptions, selectedPeriodOption, setSelectedPeriodOption] = useArrayState();

  useEffect(() => {
    if (!data) return;

    const _periods = _.keys(data);
    const _periodOptions = optionizeAll(_periods, { labelFormatter: _.upperFirst });
    setPeriodOptions(_periodOptions);
    if (_periodOptions.length > 0) setSelectedPeriodOption(_periodOptions[0]);
  }, [data]);

  if (!selectedPeriodOption) return null;

  const periodData = data[selectedPeriodOption.value];

  return (
    <>
      <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
      <Select
        className="react-select mw-200px"
        name="duration"
        value={selectedPeriodOption}
        onChange={setSelectedPeriodOption}
        options={periodOptions}
      />

      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Exposure: Interest Rates
          <div className="tw-text-lg">{params.product}</div>
          <TimePeriod dates={periodData.table_dates} prefix="Time Period:" />
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="risk-performance-interest-rate-risk"
            data={periodData.data}
            indexName=""
            indexWidth="250px"
            flip={false}
            cellFormatter={(value, row, col) => {
              const formatter = tablePresets[row];
              if (formatter) return formatter(value);

              return _.isBoolean(value) ? value : toDeciTable(value);
            }}
            title={['Exposure: Interest Rates', params.product, formatDates(periodData.table_dates)]}
          />
        </CardBody>
      </Card>

      <div className="tw-grid tw-grid-cols-6 tw-gap-4">
        <div className="tw-col-start-2 tw-col-span-4">
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Exposure: Interest Rates
              <div className="tw-text-lg">{params.product}</div>
            </CardHeader>
            <CardBody>
              <TightChart
                title="Exposure: Interest Rates"
                data={periodData.data}
                cols={['Beta', 'R2']}
                flip={true}
                wraplabelX={true}
                dataType="decimal"
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default InterestRateRisk;
