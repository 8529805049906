import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import _ from 'lodash';
import { Card, CardHeader, CardBody, TabPane, Row, Col } from 'reactstrap';
import Select from 'react-select';
import { FixedCenterLoader } from 'components/Loaders';
import PageTabs from 'components/PageTabs';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { optionize, optionizeAll } from 'helpers/select';
import { toDeciTable, toPercTable, formatDate } from 'helpers/formatter';
import { removeEmptyCols, flipDF } from 'helpers/data-frame';
import { parseSearchParams } from 'helpers/location';
import { riskMeasuresPerc, riskRatioGroups } from 'helpers/meta';

function RiskMetrics({ data, params, notify, session }) {
  const [flippedAsset, setFlippedAsset] = useState(null);
  const [flippedBench, setFlippedBench] = useState(null);
  const [selectedRiskGroup, setSelectedRiskGroup] = useState(riskRatioGroups[0]);
  const [durationOptions, setDurationOptions, selectedDurationOption, setSelectedDurationOption] = useArrayState({
    initialItems: [],
  });

  useEffect(() => {
    const _compactAsset = removeEmptyCols(data.asset_risk_metrics);
    const _durations = _compactAsset.columns;
    setDurationOptions(optionizeAll(_durations, { labelFormatter: _.upperFirst }));
    setFlippedAsset(flipDF(data.asset_risk_metrics));
    setFlippedBench(flipDF(data.bench_risk_metrics));
  }, [data]);

  const joinTightDict = useMemo(() => {
    if (!data || !selectedDurationOption) return null;

    const ind = data.asset_risk_metrics.columns.findIndex((v) => v === selectedDurationOption.value);

    const index = data.asset_risk_metrics.index.concat();
    const columns = [params.product, params.benchmark];
    const _data = index.map((row, i) => [data.asset_risk_metrics.data[i][ind], data.bench_risk_metrics.data[i][ind]]);

    return { index, columns, data: _data };
  }, [data, selectedDurationOption]);

  if (!flippedAsset || !flippedBench || !selectedDurationOption || !joinTightDict) return null;

  return (
    <>
      <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
      <Select
        className="react-select mw-200px"
        name="selectedDurationOption"
        value={selectedDurationOption}
        onChange={setSelectedDurationOption}
        options={durationOptions}
      />
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Benchmark Comparison
          <div className="tw-text-lg">Time Period: {data.risk_metrics_date[selectedDurationOption.value]}</div>
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="comparison-benchmark-comparison"
            data={flippedAsset}
            data2={flippedBench}
            indexName="Year"
            dataType="percentage"
            rows={[selectedDurationOption.value]}
            rows2={[selectedDurationOption.value]}
            rowHeader={{
              text: params.product,
              style: { color: 'black', backgroundColor: '#C6E0B4' },
            }}
            rowHeader2={{
              text: params.benchmark,
              style: { color: 'black', backgroundColor: 'rgb(251,198,88,0.5)' },
            }}
            cellFormatter={(value, row, col) =>
              riskMeasuresPerc.includes(col) ? toPercTable(value) : toDeciTable(value)
            }
            hideIndexTable
            compact
          />
        </CardBody>
      </Card>
      <Row>
        <Col xl="8" className="offset-xl-2">
          <div className="tw-text-left tw-font-bold">SELECT RISK METRICS</div>
          <Select
            className="react-select mw-300px"
            name="selectedRiskGroup"
            placeholder="Please select an risk ratio group..."
            value={selectedRiskGroup}
            onChange={setSelectedRiskGroup}
            options={riskRatioGroups}
          />

          <Card>
            <CardHeader>&nbsp;</CardHeader>
            <CardBody>
              <TightChart
                data={joinTightDict}
                // base="cols"
                rows={selectedRiskGroup.value.keys.map((key) => (key === 'Sortino Ratio' ? 'Sortino (MAR RF)' : key))}
                dataType={selectedRiskGroup.value.type}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default RiskMetrics;
