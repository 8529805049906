import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { toDeci, toDeciTable, toPercTable, isEmptyTightData, removeEmptyRows, formatDate } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';

function ShortTermReturns({ data, asof, notify, session }) {
  const [assetOptions, setAssetOptions, selectedAssetOption, setSelectedAssetOption] = useArrayState();

  useEffect(() => {
    if (!data) return;

    const _assets = data.index;
    const _assetsOptions = optionizeAll(_assets);
    setAssetOptions(_assetsOptions);
  }, [data]);

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Returns
          <div className="small">As of: {formatDate(asof)}</div>
        </CardHeader>
        <CardBody>
          <TightTable
            data={data}
            indexName="Asset Class"
            useSort
            cellFormatter={(value, row, col) => (['Currency'].includes(col) ? value : toPercTable(value))}
            suffix="short-term-returns"
            title="Returns"
          />
        </CardBody>
      </Card>

      <div className="tw-grid tw-grid-cols-6 tw-gap-4">
        <div className="tw-col-start-2 tw-col-span-4">
          {assetOptions && assetOptions.length > 0 && (
            <>
              <div className="tw-text-left tw-font-bold">SELECT FUND</div>
              <Select
                className="react-select mw-400px"
                name="asset"
                placeholder="Please select an asset..."
                value={selectedAssetOption}
                onChange={setSelectedAssetOption}
                options={assetOptions}
              />
            </>
          )}

          {selectedAssetOption && (
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                {selectedAssetOption.label}
              </CardHeader>
              <CardBody>
                <TightChart
                  title={selectedAssetOption.label}
                  data={data}
                  rows={[selectedAssetOption.label]}
                  base="cols"
                  cols={(cols) => cols.filter((col) => col !== 'Currency')}
                  dataType="percentage"
                />
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </>
  );
}

export default ShortTermReturns;
