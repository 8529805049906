import React, { useState, useEffect, useRef, createContext } from 'react';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Modal,
} from 'reactstrap';
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Footer from 'components/Footer/Footer.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import ConsentModal from './ConsentModal';
import routes from 'routes.js';

var ps;

export const AdminContext = createContext({});

function Admin(props) {
  const { session = {} } = props || {};
  const { permissions = {}, consent_verified: consentVerified = false, id } = session || {};
  const reportRef = useRef(null);
  const location = useLocation();
  const [backgroundColor, setBackgroundColor] = useState('black');
  const [activeColor, setActiveColor] = useState('info');
  const [sidebarMini, setSidebarMini] = useState(false);
  const [showConsentModal, setShowConsentModal] = useState(!consentVerified);
  const [currentRoute, setCurrentRoute] = useState(null);
  const mainPanel = useRef();

  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      document.documentElement.className += ' perfect-scrollbar-on';
      document.documentElement.classList.remove('perfect-scrollbar-off');
      ps = new PerfectScrollbar(mainPanel.current);
    }
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.documentElement.className += ' perfect-scrollbar-off';
        document.documentElement.classList.remove('perfect-scrollbar-on');
      }
    };
  }, []);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;

    const pathname = location.pathname;
    let current = null;
    _.each(routes, (route) => {
      if (route.views) {
        _.each(route.views, (view) => {
          if (pathname === view.path) {
            current = view;
            return false;
          }
        });
      } else {
        if (pathname === `${route.layout}${route.path}`) {
          current = route;
          return false;
        }
      }
    });

    setCurrentRoute(current);
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.permission) {
        if (!permissions[prop.permission]) return null;
      }

      if (prop.collapse) {
        return getRoutes(prop.views);
      }

      if (prop.layout === '') {
        if (prop.path !== '/landing' && !session) return;

        return (
          <Route
            path={prop.path}
            element={
              <prop.component
                session={props.session || { permissions: {} }}
                notify={props.notify}
                confirm={props.confirm}
                state={prop.state}
              />
            }
            key={key}
          />
        );
      }

      return null;
    });
  };

  const handleMiniClick = () => {
    if (document.body.classList.contains('sidebar-mini')) {
      setSidebarMini(false);
    } else {
      setSidebarMini(true);
    }
    document.body.classList.toggle('sidebar-mini');
  };

  return (
    <AdminContext.Provider value={{ session, reportRef }}>
      <div className="wrapper">
        <Sidebar {...props} routes={routes} bgColor={backgroundColor} activeColor={activeColor} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar {...props} currentRoute={currentRoute} handleMiniClick={handleMiniClick}>
            {permissions['pa-advanced-pdf'] &&
              currentRoute &&
              ['/portfolio-analysis-single-advanced', '/portfolio-analysis-multiple-advanced'].includes(
                currentRoute.path,
              ) && (
                <NavItem>
                  <button ref={reportRef} className="btn btn-secondary" style={{ fontSize: '14px' }}>
                    <i className="fa fa-file" aria-hidden="true"></i>
                    Portfolio Report
                  </button>
                </NavItem>
              )}
          </AdminNavbar>
          <div className="content mt-2">
            <Routes>
              {getRoutes(routes)}
              <Route path="*" element={<Navigate to="/landing" />} />
            </Routes>
          </div>
          <Footer fluid />
          {id && <ConsentModal isOpen={showConsentModal} onClose={() => setShowConsentModal(false)} />}
        </div>
      </div>
    </AdminContext.Provider>
  );
}

export default Admin;
