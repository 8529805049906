import React, { useState, useEffect, useMemo } from 'react';
import { Chart } from 'react-chartjs-2';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import _ from 'lodash';
import { Card, CardHeader, CardBody, TabPane, Row, Col } from 'reactstrap';
import Select from 'react-select';
import { getModelPortfoliosOptions, getModelPortfolios } from 'services/analysis';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import BubbleChart from 'components/BubbleChart';
import PageTabs from 'components/PageTabs';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { toDeci, toDeciTable, toPercTable, isEmptyTightData, removeEmptyRows, formatDate } from 'helpers/formatter';
import { optionize, optionizeAll } from 'helpers/select';
import { colors } from 'helpers/chart';
import { riskMeasuresPerc, riskRatioGroups } from 'helpers/meta';
import CalendarYearReturns from './model-portfolios/CalendarYearReturns';

const SpinnerWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

function ModelPortfolios({ notify }) {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [option, setOption] = useState(null);
  const [result, setResult] = useState(null);
  const [assets, setAssets] = useState();
  const [selectedAsset, setSelectedAsset] = useState([]);
  const [selectedRiskGroup, setSelectedRiskGroup] = useState(riskRatioGroups[0]);
  const [periodOptions, setPeriodOptions, selectedPeriodOption, setSelectedPeriodOption] = useArrayState();

  useEffect(() => {
    const fetchOptions = async () => {
      const [data, err] = await getModelPortfoliosOptions();
      if (err) {
        notify.danger({ message: <div>{err}</div> });
      } else {
        setOptions(optionizeAll(data));
        if (!option) setOption(optionize(data[0]));
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    const fetchResult = async () => {
      if (!option) return;

      setLoading(true);
      const [data, err] = await getModelPortfolios({ group: option.value });
      if (err) {
        notify.danger({ message: <div>{err}</div> });
      } else {
        data.risk_summary[0] = _.omitBy(data.risk_summary[0], isEmptyTightData);
        data.risk_summary[0] = _.mapValues(data.risk_summary[0], removeEmptyRows);
        setResult(data);

        const _periods = _.keys(data.risk_summary[0]);
        const _periodOptions = optionizeAll(_periods, { labelFormatter: _.upperFirst });
        setPeriodOptions(_periodOptions);
        if (_periodOptions.length > 0) setSelectedPeriodOption(_periodOptions[0]);

        const assetList = data.return_summary_st.index;
        setAssets(optionizeAll(assetList, { useIndex: true }));
        if (assetList.length > 0) setSelectedAsset(optionize(0, assetList[0]));
      }
      setLoading(false);
    };

    fetchResult();
  }, [option]);

  const riskReturnChart = useMemo(() => {
    if (!result || !selectedPeriodOption) return {};

    const target = result.risk_summary[0][selectedPeriodOption.value];
    const rowTitles = target.index;
    const colTitles = target.columns;

    const indCG = colTitles.indexOf('CAGR');
    const indSD = colTitles.indexOf('Standard Deviation');

    return {
      data: {
        datasets: rowTitles.map((title, ind) => {
          return {
            label: title,
            data: [
              {
                x: target.data[ind][indSD] * 100,
                y: target.data[ind][indCG] * 100,
              },
            ],
            pointRadius: 13,
            pointHoverRadius: 5,
            backgroundColor: colors[ind % colors.length],
          };
        }),
      },
      options: {
        plugins: {
          legend: { display: rowTitles.length < 5 },
          tooltip: {
            enabled: true,
            callbacks: {
              label: (item) => `${item.dataset.label}: ${toDeci(item.raw.y)}%, ${toDeci(item.raw.x)}%`,
            },
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Standard Deviation of Returns',
            },
            ticks: { callback: (val) => `${val}%` },
            color: 'black',
            beginAtZero: true,
          },
          y: {
            title: {
              display: true,
              text: 'Annualized Return',
            },
            ticks: {
              callback: (val) => toDeci(val, { precision: 2, suffix: '%', absolute: true }),
              color: ({ tick }) => (tick.value < 0 ? 'red' : 'black'),
            },
            beginAtZero: true,
          },
        },
      },
    };
  }, [result, selectedPeriodOption]);

  if (loading || !result) {
    return (
      <SpinnerWrapper>
        <Spinner>Loading...</Spinner>
      </SpinnerWrapper>
    );
  }

  return (
    <PageTabs
      options={[
        { value: 'description', label: 'Description' },
        { value: 'risk', label: 'Risk & Performance' },
      ]}
      colorIndex={0}
    >
      <TabPane tabId="description" role="tabpanel">
        <div className="tw-text-left tw-font-bold">SELECT</div>
        <Select
          className="react-select mw-400px tw-mb-2"
          name="group"
          value={option}
          onChange={setOption}
          options={options}
        />
        <Card>
          <CardHeader tag="h4" className="mt-0 text-center">
            Detail
          </CardHeader>
          <CardBody>
            <TightTable
              data={result.model_portfolios_detail_df}
              indexName="Portfolio"
              useSort={true}
              suffix="description-detail"
              title="Detail"
            />
          </CardBody>
        </Card>
      </TabPane>

      <TabPane tabId="risk" role="tabpanel">
        <PageTabs
          options={[
            { value: 'short-term', label: 'Short-Term Returns' },
            { value: 'long-term', label: 'Long-Term Returns' },
            { value: 'calendar-year-returns', label: 'Calendar Year Returns' },
            { value: 'risk-metrics', label: 'Risk Metrics' },
          ]}
          colorIndex={1}
          HeaderComponent={
            <>
              <div className="tw-text-left tw-font-bold">SELECT</div>
              <Select
                className="react-select mw-400px tw-mb-2"
                name="group"
                value={option}
                onChange={setOption}
                options={options}
              />
            </>
          }
        >
          <TabPane tabId="short-term" role="tabpanel">
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                Returns
                <div className="small">As of: {formatDate(result.as_of_date)}</div>
              </CardHeader>
              <CardBody>
                <TightTable
                  data={result.return_summary_st}
                  indexName="Portfolio"
                  useSort={true}
                  cellFormatter={(value, row, col) => (['Currency'].includes(col) ? value : toPercTable(value))}
                  suffix="description-returns"
                  title={['Returns', `As of: ${formatDate(result.as_of_date)}`]}
                />
              </CardBody>
            </Card>

            <Row>
              <Col xl="8" className="offset-xl-2">
                {assets && assets.length > 0 && (
                  <>
                    <div className="tw-text-left tw-font-bold">SELECT PORTFOLIO</div>
                    <Select
                      className="react-select mw-400px"
                      name="asset"
                      placeholder="Please select an asset..."
                      value={selectedAsset}
                      onChange={setSelectedAsset}
                      options={assets}
                    />
                  </>
                )}

                <Card>
                  <CardHeader tag="h4" className="mt-0 text-center">
                    {selectedAsset.label}
                  </CardHeader>
                  <CardBody>
                    <TightChart
                      title={selectedAsset.label}
                      data={result.return_summary_st}
                      rows={[selectedAsset.label]}
                      base="cols"
                      cols={(cols) => cols.filter((col) => col !== 'Currency')}
                      dataType="percentage"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="long-term" role="tabpanel">
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                Returns
                <div className="small">As of: {formatDate(result.as_of_date)}</div>
              </CardHeader>
              <CardBody>
                <TightTable
                  data={result.return_summary_lt}
                  indexName="Portfolio"
                  useSort={true}
                  cellFormatter={(value, row, col) =>
                    ['Currency', 'Inception Date'].includes(col) ? value : toPercTable(value)
                  }
                  suffix="risk-returns"
                  indexWidth="200px"
                  title={['Returns', `As of: ${formatDate(result.as_of_date)}`]}
                />
              </CardBody>
            </Card>

            <Row>
              <Col xl="8" className="offset-xl-2">
                {assets && assets.length > 0 && (
                  <>
                    <div className="tw-text-left tw-font-bold">SELECT PORTFOLIO</div>
                    <Select
                      className="react-select mw-400px"
                      name="asset"
                      placeholder="Please select an asset..."
                      value={selectedAsset}
                      onChange={setSelectedAsset}
                      options={assets}
                    />
                  </>
                )}

                <Card>
                  <CardHeader tag="h4" className="mt-0 text-center">
                    {selectedAsset.label}
                  </CardHeader>
                  <CardBody>
                    <TightChart
                      title={selectedAsset.label}
                      data={result.return_summary_lt}
                      rows={[selectedAsset.label]}
                      cols={(cols) => cols.filter((col) => !['Currency', 'Inception Date'].includes(col))}
                      base="cols"
                      dataType="percentage"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="calendar-year-returns" role="tabpanel">
            <CalendarYearReturns data={result.calendar_year_returns} asof={result.as_of_date} />
          </TabPane>
          <TabPane tabId="risk-metrics" role="tabpanel">
            {periodOptions && periodOptions.length > 0 && (
              <>
                <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
                <Select
                  className="react-select mw-200px"
                  name="duration"
                  value={selectedPeriodOption}
                  onChange={setSelectedPeriodOption}
                  options={periodOptions}
                />
                <Card>
                  <CardHeader tag="h4" className="mt-0 text-center">
                    Risk Metrics
                    <div className="small">Time Period ({selectedPeriodOption.label.replace('yr', ' Year')})</div>
                    <TimePeriod dates={result.risk_summary[1][selectedPeriodOption.value]} />
                  </CardHeader>
                  <CardBody>
                    <TightTable
                      data={result.risk_summary[0][selectedPeriodOption.value]}
                      indexName="Portfolio"
                      useSort={true}
                      omitEmptyRow={true}
                      cellFormatter={(value, row, col) =>
                        riskMeasuresPerc.includes(col) ? toPercTable(value) : toDeciTable(value)
                      }
                      suffix="risk-summary"
                      indexWidth="200px"
                      title={[
                        'Risk Metrics',
                        `Time Period (${selectedPeriodOption.label.replace('yr', ' Year')})`,
                        formatDates(result.risk_summary[1][selectedPeriodOption.value]),
                      ]}
                    />
                  </CardBody>
                </Card>

                <Row>
                  <Col xl="8" className="offset-xl-2">
                    <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
                    <Select
                      className="react-select mw-200px"
                      name="duration"
                      value={selectedPeriodOption}
                      onChange={setSelectedPeriodOption}
                      options={periodOptions}
                    />
                    <Card>
                      <CardHeader tag="h4" className="mt-0 text-center">
                        Risk and Return
                      </CardHeader>
                      <CardBody>
                        <BubbleChart
                          data={riskReturnChart.data}
                          options={riskReturnChart.options}
                          height={400}
                          width={826}
                          title="Risk and Return"
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col xl="8" className="offset-xl-2">
                    {assets && assets.length > 0 && (
                      <div className="tw-flex">
                        <div className="tw-min-w-[400px] tw-mr-1">
                          <div className="tw-text-left tw-font-bold">SELECT PORTFOLIO</div>
                          <Select
                            className="react-select w-full"
                            name="asset"
                            placeholder="Please select an asset..."
                            value={selectedAsset}
                            onChange={setSelectedAsset}
                            options={assets}
                          />
                        </div>
                        <div className="tw-min-w-[400px]">
                          <div className="tw-text-left tw-font-bold">SELECT RISK METRICS</div>
                          <Select
                            className="react-select w-full"
                            name="asset"
                            placeholder="Please select an risk ratio group..."
                            value={selectedRiskGroup}
                            onChange={setSelectedRiskGroup}
                            options={riskRatioGroups}
                          />
                        </div>
                      </div>
                    )}

                    <Card>
                      <CardHeader tag="h4" className="mt-0 text-center">
                        {selectedAsset.label}
                      </CardHeader>
                      <CardBody>
                        <TightChart
                          title={selectedAsset.label}
                          data={result.risk_summary[0][selectedPeriodOption.value]}
                          rows={[selectedAsset.label]}
                          cols={selectedRiskGroup.value.keys}
                          base="cols"
                          dataType={selectedRiskGroup.value.type}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            )}
          </TabPane>
        </PageTabs>
      </TabPane>
    </PageTabs>
  );
}

export default ModelPortfolios;
