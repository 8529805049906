import Landing from 'views/Landing';
import Users from 'views/Users';
import Analyzers from 'views/Analyzers';
import AnalyzersPA from 'views/AnalyzersPA';
import AssetDiscovery from 'views/AssetDiscovery';
import RiskGuide from 'views/RiskGuide';
import AdvancedAnalytics from 'views/advanced-analytics/Base';
import InvestmentAnalysis from 'views/investment-analysis/Base';
import InvestmentComparison from 'views/investment-comparison/Base';
import ClientPortfolio from 'views/my-flare/ClientPortfolio';
import ModelPortfolios from 'views/my-flare/ModelPortfolios';
import AlternativeDashboard from 'views/my-flare/AlternativeDashboard';
import AssetClasses from 'views/my-flare/AssetClasses';
import BuildPortfolio from 'views/portfolio-analysis/BuildPortfolio';
import PortfolioAnalysisSingleAdvanced from 'views/portfolio-analysis/single/advanced/Base';
import PortfolioAnalysisSingleAnalyze from 'views/portfolio-analysis/single/analyze/Base';
import PortfolioAnalysisMultipleAdvanced from 'views/portfolio-analysis/multiple/advanced/Base';
import PortfolioAnalysisMultipleAnalyze from 'views/portfolio-analysis/multiple/analyze/Base';
import ToolboxCorrelation from 'views/toolbox/Correlation';
import QuickView from 'views/quickview/Base';

import RiskLabRiskCompare from 'views/risk-lab/RiskCompare';
import RiskLabMarketRisk from 'views/risk-lab/MarketRisk';
import RiskLabInterestRates from 'views/risk-lab/InterestRates';
import RiskLabCreditSpreads from 'views/risk-lab/CreditSpreads';
import RiskLabFactorLens from 'views/risk-lab/FactorLens';
import RiskLabRiskBudget from 'views/risk-lab/RiskBudget';
import RiskLabPortfolioRisk from 'views/risk-lab/PortfolioRisk';

import InsightsToolboxMarketStress from 'views/insights-toolbox/MarketStress';
import InsightsToolboxRollingReturns from 'views/insights-toolbox/RollingReturns';
import InsightsToolboxValueAtRisk from 'views/insights-toolbox/ValueAtRisk';
import InsightsToolboxDrawdown from 'views/insights-toolbox/Drawdown';

import Correlation from 'views/correlation/Landing';
import CorrelationRollingCorrelation from 'views/correlation/RollingCorrelation';
import CorrelationCorrelationMatrix from 'views/correlation/CorrelationMatrix';
import CorrelationAssetCorrelations from 'views/correlation/AssetCorrelations';

import Charts from 'views/charts/Landing';
import ChartsRiskReturn from 'views/charts/RiskReturn';
import ChartsRiskRatios from 'views/charts/RiskRatios';
import ChartsValueAtRisk from 'views/charts/ValueAtRisk';
import ChartsBetaCorrelation from 'views/charts/BetaCorrelation';

import PerformanceEngineBenchmarkAnalysis from 'views/performance-engine/BenchmarkAnalysis';
import PerformanceEnginePortfolioImpact from 'views/performance-engine/PortfolioImpact';

const routes = [
  {
    path: '/landing',
    name: 'Introducing FLARE™',
    icon: 'nc-icon nc-bank',
    component: Landing,
    layout: '',
    redirect: true,
    titleBold: true,
  },
  {
    path: '/analyzers-pa',
    name: 'Portfolio Analysis',
    component: AnalyzersPA,
    layout: '',
    permission: 'analyzers',
    redirect: true,
  },
  // {
  //   path: '/toolbox/correlation',
  //   name: 'Toolbox Correlation',
  //   icon: 'nc-icon nc-bank',
  //   component: ToolboxCorrelation,
  //   layout: '',
  //   permission: 'pa-build',
  // },
  {
    path: '/quickview',
    name: 'Quick View',
    icon: 'nc-icon nc-bank',
    component: QuickView,
    layout: '',
    permission: 'pa-build',
  },
  {
    collapse: true,
    name: 'My Flare',
    icon: 'nc-icon nc-book-bookmark',
    state: 'pagesCollapse',
    permission: 'myflare',
    views: [
      // {
      //   path: '/portfolio',
      //   name: 'Client Portfolios',
      //   mini: 'CP',
      //   component: ClientPortfolio,
      //   layout: '',
      //   permission: 'my-flare-client-portfolios',
      // },
      // {
      //   path: '/advisor-holdings',
      //   name: 'Advisor Holdings',
      //   mini: 'AH',
      //   component: ClientPortfolio,
      //   layout: '',
      // },
      // {
      //   path: '/advisor-watchlist',
      //   name: 'Advisor Watchlist',
      //   mini: 'AW',
      //   component: ClientPortfolio,
      //   layout: '',
      // },
      {
        path: '/model-portfolio',
        name: 'Model Portfolios',
        mini: 'MP',
        component: ModelPortfolios,
        layout: '',
        permission: 'myflare-model-portfolios',
      },
      {
        path: '/asset-classes',
        name: 'Asset Classes',
        mini: 'AC',
        component: AssetClasses,
        layout: '',
        permission: 'myflare-asset-classes',
      },
      {
        path: '/alternatives',
        name: 'Alternative Funds',
        mini: 'AF',
        component: AlternativeDashboard,
        layout: '',
        permission: 'myflare-alternative-funds',
      },
    ],
  },
  {
    path: '/analyzers',
    name: 'Analyzers',
    icon: 'nc-icon nc-bank',
    component: Analyzers,
    layout: '',
    permission: 'analyzers',
  },
  {
    path: '/charts',
    name: 'Charts',
    icon: 'nc-icon nc-bank',
    component: Charts,
    layout: '',
    permissions: ['chart-risk-return', 'chart-risk-ratios', 'chart-var', 'chart-beta-correl'],
  },
  {
    path: '/charts/risk-return',
    name: 'Risk Return',
    icon: 'nc-icon nc-bank',
    component: ChartsRiskReturn,
    layout: '',
    redirect: true,
  },
  {
    path: '/charts/risk-ratios',
    name: 'Risk Ratios',
    icon: 'nc-icon nc-bank',
    component: ChartsRiskRatios,
    layout: '',
    redirect: true,
  },
  {
    path: '/charts/var',
    name: 'Value at Risk',
    icon: 'nc-icon nc-bank',
    component: ChartsValueAtRisk,
    layout: '',
    redirect: true,
  },
  {
    path: '/charts/beta-correl',
    name: 'Beta & Correlation',
    icon: 'nc-icon nc-bank',
    component: ChartsBetaCorrelation,
    layout: '',
    redirect: true,
  },
  {
    path: '/co',
    name: 'Correlation',
    icon: 'nc-icon nc-bank',
    component: Correlation,
    layout: '',
    permissions: ['correlation-assets', 'correlation-matrix', 'correlation-rolling'],
  },
  {
    path: '/co/rolling-correlation',
    name: 'Rolling Correlation',
    icon: 'nc-icon nc-bank',
    component: CorrelationRollingCorrelation,
    layout: '',
    redirect: true,
  },
  {
    path: '/co/correlation-matrix',
    name: 'Correlation Matrix',
    icon: 'nc-icon nc-bank',
    component: CorrelationCorrelationMatrix,
    layout: '',
    redirect: true,
  },
  {
    path: '/co/asset-correlations',
    name: 'Asset Correlations',
    icon: 'nc-icon nc-bank',
    component: CorrelationAssetCorrelations,
    layout: '',
    redirect: true,
  },
  {
    collapse: true,
    name: 'Risk Lab',
    icon: 'nc-icon nc-book-bookmark',
    state: 'risk-lab',
    views: [
      {
        path: '/rl/risk-compare',
        name: 'Risk Compare',
        mini: 'RC',
        component: RiskLabRiskCompare,
        layout: '',
        permission: 'risk-lab-compare',
      },
      {
        path: '/rl/market-risk',
        name: 'Market Risk',
        mini: 'MR',
        component: RiskLabMarketRisk,
        layout: '',
        permission: 'risk-lab-market',
      },
      {
        path: '/rl/interest-rates',
        name: 'Interest Rates',
        mini: 'IR',
        component: RiskLabInterestRates,
        layout: '',
        permission: 'risk-lab-ir',
      },
      {
        path: '/rl/credit-spreads',
        name: 'Credit Spreads',
        mini: 'CS',
        component: RiskLabCreditSpreads,
        layout: '',
        permission: 'risk-lab-cr',
      },
      {
        path: '/rl/factor-lens',
        name: 'Factor Lens',
        mini: 'FL',
        component: RiskLabFactorLens,
        layout: '',
        permission: 'risk-lab-factors',
      },
      {
        path: '/rl/risk-budget',
        name: 'Risk Budget',
        mini: 'RB',
        component: RiskLabRiskBudget,
        layout: '',
        permission: 'risk-lab-rb',
      },
      {
        path: '/rl/portfolio-risk',
        name: 'Portfolio Risk',
        mini: 'PR',
        component: RiskLabPortfolioRisk,
        layout: '',
        permission: 'risk-lab-port',
      },
    ],
  },
  {
    collapse: true,
    name: 'Performance Engine',
    icon: 'nc-icon nc-book-bookmark',
    state: 'performance-engine',
    views: [
      {
        path: '/pe/benchmark-analysis',
        name: 'Benchmark Analysis',
        mini: 'BA',
        component: PerformanceEngineBenchmarkAnalysis,
        layout: '',
        permission: 'performance-engine-benchmark',
      },
      {
        path: '/pe/portfolio-impact',
        name: 'Portfolio Impact',
        mini: 'PI',
        component: PerformanceEnginePortfolioImpact,
        layout: '',
        permission: 'performance-engine-portfolio-impact',
      },
    ],
  },
  {
    collapse: true,
    name: 'Insights Toolbox',
    icon: 'nc-icon nc-book-bookmark',
    state: 'insights-toolbox',
    views: [
      {
        path: '/it/market-stress',
        name: 'Market Stress',
        mini: 'MS',
        component: InsightsToolboxMarketStress,
        layout: '',
        permission: 'toolbox-market-stress',
      },
      {
        path: '/it/rolling-returns',
        name: 'Rolling Returns',
        mini: 'RR',
        component: InsightsToolboxRollingReturns,
        layout: '',
        permission: 'toolbox-rolling-returns',
      },
      {
        path: '/it/drawdown',
        name: 'Drawdown',
        mini: 'DD',
        component: InsightsToolboxDrawdown,
        layout: '',
        permission: 'toolbox-drawdown',
      },
      {
        path: '/it/value-at-risk',
        name: 'Value At Risk',
        mini: 'RR',
        component: InsightsToolboxValueAtRisk,
        layout: '',
        permission: 'toolbox-var',
      },
    ],
  },
  {
    path: '/analyzers/asset-discovery-aa',
    name: 'Advanced Analytics',
    icon: 'nc-icon nc-bank',
    component: AssetDiscovery,
    layout: '',
    permission: 'aa',
  },
  {
    path: '/analyzers/asset-discovery-ia',
    name: 'Investment Analysis',
    icon: 'nc-icon nc-bank',
    component: AssetDiscovery,
    layout: '',
    redirect: true,
  },
  {
    path: '/analyzers/asset-discovery-ic',
    name: 'Investment Comparison',
    icon: 'nc-icon nc-bank',
    component: AssetDiscovery,
    layout: '',
    redirect: true,
  },
  {
    path: '/advanced-analytics',
    name: 'Advanced Analytics',
    icon: 'nc-icon nc-bank',
    component: AdvancedAnalytics,
    layout: '',
    redirect: true,
  },
  {
    path: '/investment-analysis',
    name: 'Investment Analysis',
    icon: 'nc-icon nc-bank',
    component: InvestmentAnalysis,
    layout: '',
    redirect: true,
  },
  {
    path: '/investment-comparison',
    name: 'Investment Comparison',
    icon: 'nc-icon nc-bank',
    component: InvestmentComparison,
    layout: '',
    redirect: true,
    permission: 'ic',
  },
  {
    path: '/build-portfolio',
    name: 'Build Portfolio',
    icon: 'nc-icon nc-bank',
    component: BuildPortfolio,
    redirect: true,
    layout: '',
    permission: 'pa-build',
  },
  {
    path: '/compare-portfolios',
    name: 'Compare Portfolios',
    icon: 'nc-icon nc-bank',
    component: BuildPortfolio,
    redirect: true,
    layout: '',
    permission: 'pa-build',
  },
  {
    path: '/portfolio-analysis-multiple-advanced',
    name: 'Portfolio Analysis',
    icon: 'nc-icon nc-bank',
    component: PortfolioAnalysisMultipleAdvanced,
    redirect: true,
    layout: '',
  },
  {
    path: '/portfolio-analysis-multiple-analyze',
    name: 'Portfolio Analysis',
    icon: 'nc-icon nc-bank',
    component: PortfolioAnalysisMultipleAnalyze,
    redirect: true,
    layout: '',
  },
  {
    path: '/portfolio-analysis-single-advanced',
    name: 'Portfolio Analysis',
    icon: 'nc-icon nc-bank',
    component: PortfolioAnalysisSingleAdvanced,
    redirect: true,
    layout: '',
  },
  {
    path: '/portfolio-analysis-single-analyze',
    name: 'Portfolio Analysis',
    icon: 'nc-icon nc-bank',
    component: PortfolioAnalysisSingleAnalyze,
    redirect: true,
    layout: '',
  },
  {
    path: '/risk-guide',
    name: 'Risk Guide',
    icon: 'nc-icon nc-bank',
    component: RiskGuide,
    layout: '',
    permission: 'risk-guide',
  },
  {
    collapse: true,
    path: '/users',
    name: 'User Management',
    icon: 'nc-icon nc-user-run',
    state: 'userMenuCollapse',
    permission: 'user-management',
    views: [
      {
        path: '/assigned-users',
        name: 'Assigned Users',
        mini: 'CP',
        component: Users,
        layout: '',
        permission: 'user-management',
        state: 'assigned',
      },
      {
        path: '/pending-users',
        name: 'Pending Users',
        mini: 'CP',
        component: Users,
        layout: '',
        permission: 'user-management',
        state: 'pending',
      },
    ],
  },
];

export default routes;
