import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody, TabPane, Row, Col, Table } from 'reactstrap';
import PieChart from 'components/PieChart';
import TightTable from 'components/TightTable';
import LineChart from 'components/LineChart';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';
import { toDeci, toPerc, toPercTable, toDeciTable, tablePresets, getDataType, formatCurrency } from 'helpers/formatter';
import { formatDateToMMYYYY } from 'helpers/date';

const backgroundColors = ['rgba(51,102,204,0.2)', 'rgba(220,57,18,0.2)', 'rgba(18, 222, 59,0.2)'];
const borderColors = ['rgb(51,102,204)', 'rgb(220,57,18)', 'rgb(18, 222, 59)'];

function Summary({ data, params, notify, session }) {
  const lineChart = useMemo(() => {
    if (!data) return null;

    const tdata = {
      labels: data.index.map(formatDateToMMYYYY),
      datasets: data.columns.map((col, i) => ({
        label: col,
        data: data.data.map((row) => row[i]),
        fill: false,
        backgroundColor: backgroundColors[i],
        borderColor: borderColors[i],
        borderWidth: 2,
        tension: 0,
        pointRadius: 3,
        pointHoverRadius: 4,
      })),
    };

    const options = {
      plugins: {
        legend: { display: true },
        tooltip: {
          enabled: true,
          callbacks: {
            label: (item) => {
              return `${item.label}: ${toDeci(item.raw, { prefix: '$' })}`;
            },
          },
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        x: {
          title: {
            display: false,
          },
          ticks: {
            autoSkip: true,
            autoSkipPadding: 30,
            maxRotation: 0,
            minRotation: 0,
            color: 'black',
          },
          beginAtZero: false,
        },
        y: {
          title: {
            display: true,
          },
          ticks: {
            callback: (val) => toDeci(val, { precision: 1, absolute: true, prefix: '$' }),
            color: ({ tick }) => (tick.value < 0 ? 'red' : 'black'),
          },
          beginAtZero: false,
        },
      },
    };

    return { data: tdata, options };
  }, [data]);

  return (
    <>
      <Card className="tw-max-w-3xl tw-mx-auto">
        <CardHeader tag="h4" className="mt-0 text-center">
          <div className="">{params.product}</div>
          <div className="tw-text-lg">Value Add Monthly Index - VAMI</div>
          <div className="tw-text-lg">(Growth of $1,000)</div>
        </CardHeader>
        <CardBody>
          <LineChart
            title="Value Add Monthly Index - VAMI"
            data={lineChart.data}
            options={lineChart.options}
            plugins={[]}
          />
        </CardBody>
      </Card>
    </>
  );
}

export default Summary;
