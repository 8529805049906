import { HueCircle19 } from 'helpers/colorschemes/colorschemes.tableau';

export const getTightRowByKey = (data, key) => {
  const ind = data.index.indexOf(key);
  if (ind === -1) return [];

  return data.data[ind];
};

export const getTightColIndexByKey = (data, key) => data.columns.indexOf(key);
export const getTightRowIndexByKey = (data, key) => data.index.indexOf(key);

export const getDataByColName = (data, colName) => {
  const ind = data.columns.indexOf(colName);
  if (ind === -1) return [];
  return data.data.map((row) => row[ind]);
};

export const getDataByColIndex = (data, colIndex) => {
  return data.index.map((row) => row[colIndex]);
};

// export const colors = _.shuffle(HueCircle19);

export const colors = [
  '#191970',
  '#4682b4',
  '#c0c0c0',
  '#000080',
  '#87cefa',
  '#b0c4de',
  '#00008b',
  '#87ceeb',
  '#778899',
  '#0000cd',
  '#808080',
  '#d3d3d3',
  '#4169e1',
];
