import React, { useState, useEffect, useMemo } from 'react';
import { Chart } from 'react-chartjs-2';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';
import PieChart from 'components/PieChart';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import LineChart from 'components/LineChart';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { toDeci, toPerc, formatCurrency } from 'helpers/formatter';
import { colors } from 'helpers/chart';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';
import { removeEmptyCols, removeEmptyRows } from 'helpers/data-frame';

function Summary({ data, params, notify, session }) {
  const [returnDF, setReturnDF] = useState(null);
  const [assetOptions, setAssetOptions] = useState([]);
  const [selectedAssetOption, setSelectedAssetOption] = useState(null);

  useEffect(() => {
    if (!data) return;

    const _returnDF = removeEmptyRows(data.returns, ['15Y', '20Y']);
    const returnAssetKeys = _returnDF.columns;
    setReturnDF(_returnDF);

    const _assetOptions = optionizeAll(returnAssetKeys.filter((v, i) => v !== params.benchmark));
    setAssetOptions(_assetOptions);

    if (_assetOptions.length > 0) {
      setSelectedAssetOption(_assetOptions[0]);
    }
  }, [data]);

  const lineChart = useMemo(() => {
    if (!data) return null;
    const gdata = data['line_graph'];

    const dataVal1 = [];
    const dataVal2 = [];

    _.each(gdata.data, (row) => {
      dataVal1.push(row[0]);
      dataVal2.push(row[1]);
    });

    const dataLabel1 = gdata.columns[0];
    const dataLabel2 = gdata.columns[1];

    const tdata = {
      labels: gdata.index.map((v) => v.substring(0, 7)),
      datasets: [
        {
          label: dataLabel1,
          data: dataVal1,
          fill: false,
          backgroundColor: 'rgba(51,102,204,0.2)',
          borderColor: 'rgb(51,102,204)',
          borderWidth: 2,
          tension: 0,
          pointRadius: 3,
          pointHoverRadius: 4,
        },
        {
          label: dataLabel2,
          data: dataVal2,
          fill: false,
          backgroundColor: 'rgba(220,57,18,0.2)',
          borderColor: 'rgb(220,57,18)',
          borderWidth: 2,
          tension: 0,
          pointRadius: 3,
          pointHoverRadius: 4,
        },
      ],
    };

    const options = {
      plugins: {
        legend: { display: true },
        tooltip: {
          enabled: true,
          callbacks: {
            label: (item) => {
              return `${item.label}: ${formatCurrency(item.raw, { precision: 0 })}`;
            },
          },
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        x: {
          title: {
            display: false,
          },
          ticks: {
            autoSkip: true,
            autoSkipPadding: 30,
            maxRotation: 0,
            minRotation: 0,
            color: 'black',
          },
          beginAtZero: false,
        },
        y: {
          title: {
            display: true,
          },
          ticks: {
            callback: (val) => formatCurrency(val, { precision: 0 }),
            color: ({ tick }) => (tick.value < 0 ? 'red' : 'black'),
          },
          beginAtZero: false,
        },
      },
    };

    return { data: tdata, options };
  }, [data]);

  if (!returnDF) return null;

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Returns
          <TimePeriod dates={data.table_dates} />
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="summary_returns"
            data={returnDF}
            indexName=""
            flip={true}
            useSort={true}
            rowDecorator={(rowTitle, rowInd, rowTitles) =>
              rowTitle === data.portfolio_name
                ? { style: { backgroundColor: '#C6E0B4' } }
                : rowTitle === params.benchmark
                ? { style: { backgroundColor: 'rgb(251,198,88,0.5)' } }
                : undefined
            }
            cellFormatter={(value, row, col) => toPerc(value, { decorate: true, absolute: true })}
            title={['Returns', formatDates(data.table_dates)]}
          />
        </CardBody>
      </Card>

      <div className="tw-grid tw-grid-cols-6 tw-gap-4">
        <div className="tw-col-start-2 tw-col-span-4">
          {lineChart && (
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                <div>Value Add Monthly Index - VAMI</div>
                <div className="small">(Growth of $1,000)</div>
              </CardHeader>
              <CardBody>
                <LineChart
                  title="Value Add Monthly Index - VAMI"
                  data={lineChart.data}
                  options={lineChart.options}
                  plugins={[]}
                />
              </CardBody>
            </Card>
          )}
        </div>
      </div>

      <div className="tw-grid tw-grid-cols-6 tw-gap-4">
        <div className="tw-col-start-2 tw-col-span-4">
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              {data.portfolio_name}
              <div className="tw-text-sm">Holdings Contribution</div>
              <TimePeriod dates={data.table_dates} />
            </CardHeader>
            <CardBody>
              <TightTable
                suffix="holdings_contribution"
                data={data.contribution_table}
                rowDecorator={(rowTitle, rowInd, rowTitles) =>
                  rowTitle === 'Portfolio' ? { style: { backgroundColor: '#C6E0B4' } } : undefined
                }
                indexName=""
                dataType="percentage"
                useSort={true}
                title={[data.portfolio_name, 'Holdings Contribution', formatDates(data.table_dates)]}
              />
            </CardBody>
          </Card>
        </div>
      </div>
      <div className="tw-grid tw-grid-cols-12 tw-gap-4">
        <div className="tw-col-span-6">
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Contribution to Portfolio Return
            </CardHeader>
            <CardBody>
              <PieChart
                data={data.contribution_table}
                base="rows"
                field="% Total Contribution to Portfolio Return"
                filter={(labels) => labels.filter((label) => label !== 'Portfolio')}
                dataType="percentage"
                containerStyle={{ maxWidth: '500px', maxHeight: '500px' }}
                options={{
                  plugins: {
                    legend: {
                      display: data.contribution_table.index.length <= 8,
                      position: 'bottom',
                    },
                  },
                }}
              />
            </CardBody>
          </Card>
        </div>
        <div className="tw-col-span-6 ">
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Contribution to Portfolio Risk
            </CardHeader>
            <CardBody>
              <PieChart
                title="Contribution to Portfolio Risk"
                data={data.contribution_table}
                base="rows"
                field="% Total Contribution to Portfolio Risk"
                filter={(labels) => labels.filter((label) => label !== 'Portfolio')}
                dataType="percentage"
                containerStyle={{ maxWidth: '500px', maxHeight: '500px' }}
                options={{
                  plugins: {
                    legend: {
                      display: data.contribution_table.index.length <= 8,
                      position: 'bottom',
                    },
                  },
                }}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default Summary;
