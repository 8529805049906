import React, { useState, useEffect, useContext, useMemo, useRef, useLayoutEffect, createRef } from 'react';
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';
import _ from 'lodash';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  TabPane,
  Row,
  Col,
  Spinner,
  Button,
  Modal,
  Progress,
} from 'reactstrap';
import Select from 'react-select';
import PageTabs from 'components/PageTabs';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import PieChart from 'components/PieChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { FixedCenterLoader } from 'components/Loaders';
import { AutoColumns } from 'components/Columns';
import { optionize, optionizeAll } from 'helpers/select';
import {
  toPerc,
  toDeci,
  toDeciTable,
  toPercTable,
  formatDate,
  formatCurrency,
  isEmptyTightData,
  removeEmptyRows,
  tablePresets,
} from 'helpers/formatter';
import { exportPDF } from 'reports/portfolio-analysis';
import { getPortfolioAnalysisBuildAdvanced } from 'services/analysis';
import { AdminContext } from 'layouts/Admin';
import BackButton from 'views/portfolio-analysis/BackButton';
import { prepareData, parseParams } from 'views/portfolio-analysis/helpers';
import Summary from './Summary';
import RiskContribution from './RiskContribution';
import CrossCorrelation from './CrossCorrelation';

const reportAdvisors = optionizeAll(['Jon Eaton', 'Steve Adang']);

function SingleAdvanced({ notify, session }) {
  const context = useContext(AdminContext);

  const location = useLocation();
  const [params, setParams] = useState(null);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [mctrDurations, setMctrDurations] = useState();
  const [selectedMctrDurations, setSelectedMctrDurations] = useState();
  const [selectedAdvisor, setSelectedAdvisor] = useState(reportAdvisors[0]);
  const [pdfExporting, setPdfExporting] = useState(false);
  const [reportProgress, setReportProgress] = useState({ max: 0, value: 0, message: '' });
  const [modalReport, setModalReport] = useState(false);
  const toggleModalReport = () => {
    setModalReport(!modalReport);
  };

  useEffect(() => {
    const element = context.reportRef.current;
    if (!element) return;

    const openModalReport = () => setModalReport(true);

    element.addEventListener('click', openModalReport);

    return () => {
      element.removeEventListener('click', openModalReport);
    };
  }, [context, result, params]);

  const fetchResult = async () => {
    if (!params) return;

    setLoading(true);
    const [data, err] = await getPortfolioAnalysisBuildAdvanced(prepareData(params));
    if (err) {
      notify.danger({ message: <div>{err}</div> });
    } else {
      data.risk_summary[0] = _.omitBy(data.risk_summary[0], isEmptyTightData);
      data.risk_summary[0] = _.mapValues(data.risk_summary[0], removeEmptyRows);
      data.mctr_dict = _.mapValues(data.mctr_dict, (portData) => _.omitBy(portData, (val) => !val[0]));

      const mctrDurs = _.keys(data.mctr_dict[_.keys(data.mctr_dict)[0]]);
      setMctrDurations(optionizeAll(mctrDurs, { labelFormatter: _.upperFirst }));
      if (mctrDurs.length > 0) setSelectedMctrDurations(optionize(mctrDurs[0], _.upperFirst(mctrDurs[0])));

      setResult(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    const params = parseParams(location.search);
    setParams(params);
  }, [location]);

  useEffect(() => {
    if (params) fetchResult();
  }, [params]);

  if (loading || !result || !selectedMctrDurations) {
    return <FixedCenterLoader />;
  }

  return (
    <>
      <BackButton />

      <div className="tw-flex tw-justify-center tw-items-center">
        <div className="tw-mx-auto">
          <h3 className="title tw-mb-2">{params.port1Name}</h3>
        </div>
      </div>

      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          <div className="tw-text-sm">
            Benchmark: <span className="tw-font-semibold">{result.pba_advanced_header.benchmark_name}</span>
          </div>
          <TimePeriod dates={result.pba_advanced_header.table_dates} prefix="Time Period:" />
          <div className="tw-text-sm">
            Rebalance Frequency:{' '}
            <span className="tw-font-semibold">{result.pba_advanced_header.rebalance_frequency}</span>
          </div>
          <div className="tw-text-sm">
            Advisor Fee: <span className="tw-font-semibold">{toPerc(result.pba_advanced_header.advisor_fee)}</span>
          </div>
        </CardHeader>
        <CardBody>
          <TightTable
            data={result.pba_advanced_header.data}
            indexWidth="350px"
            flip={true}
            cellFormatter={(value, row, col, rowInd, colInd, flip) => {
              const label = flip ? col : row;

              const formatter = tablePresets[label];
              if (formatter) return formatter(value);

              return toDeciTable(value);
            }}
            rowDecorator={(rowTitle, rowInd, rowTitles) =>
              rowTitle === params.benchmark ? { style: { backgroundColor: 'rgb(251,198,88,0.5)' } } : undefined
            }
            suffix="portfolio-analysis-single-analyze-top"
            title={[
              `Benchmark: ${result.pba_advanced_header.benchmark_name}`,
              formatDates(result.pba_advanced_header.table_dates),
              `Rebalance Frequency: ${result.pba_advanced_header.rebalance_frequency}`,
              `Advisor Fee: ${toPerc(result.pba_advanced_header.advisor_fee)}`,
            ]}
          />
        </CardBody>
      </Card>

      <PageTabs
        options={[
          { value: 'summary', label: 'Summary' },
          { value: 'contribution', label: 'Portfolio Risk Contribution' },
          { value: 'correlation', label: 'Cross Correlation' },
        ]}
        session={session}
        colorIndex={0}
      >
        <TabPane tabId="summary" role="tabpanel">
          <Summary data={result} params={params} notify={notify} session={session} />
        </TabPane>

        <TabPane tabId="contribution" role="tabpanel">
          <RiskContribution data={result} params={params} notify={notify} session={session} />
        </TabPane>

        <TabPane tabId="correlation" role="tabpanel">
          <CrossCorrelation data={result} params={params} notify={notify} session={session} />
        </TabPane>
      </PageTabs>

      <Modal isOpen={modalReport} toggle={toggleModalReport}>
        <div className="modal-header">
          <button aria-hidden={true} className="close" data-dismiss="modal" type="button" onClick={toggleModalReport}>
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h5 className="modal-title" id="myModalLabel">
            Portfolio Analysis Report
          </h5>
        </div>
        <div className="modal-body">
          <strong>Choose time period for comparison</strong>
          <Select
            className="react-select mb-3"
            name="mctrDurations"
            value={selectedMctrDurations}
            onChange={setSelectedMctrDurations}
            options={mctrDurations}
            isDisabled={pdfExporting}
          />

          <strong>Choose Advisor</strong>
          <Select
            className="react-select"
            name="reportAdvisors"
            value={selectedAdvisor}
            onChange={setSelectedAdvisor}
            options={reportAdvisors}
            isDisabled={pdfExporting}
          />

          <br />
          <Progress max={reportProgress.max} value={reportProgress.value} />
          <div className="text-muted">{reportProgress.message}</div>
        </div>
        <div className="modal-footer justify-content-center">
          <Button
            className="btn-round"
            color="info"
            data-dismiss="modal"
            type="button"
            onClick={async () => {
              if (pdfExporting) return;

              setPdfExporting(true);
              await exportPDF(result, params, {
                session,
                selectedYear: selectedMctrDurations.value,
                selectedAdvisor: selectedAdvisor.value,
                setReportProgress,
              });
              setPdfExporting(false);
              setReportProgress({ max: 0, value: 0, message: '' });
              setModalReport(false);
            }}
          >
            Download
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default SingleAdvanced;
