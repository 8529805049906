import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import _ from 'lodash';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Table,
  Row,
  Col,
  Spinner,
  UncontrolledTooltip,
  TabPane,
  CardFooter,
} from 'reactstrap';
import PageTabs from 'components/PageTabs';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { FixedCenterLoader } from 'components/Loaders';
import TightChart from 'components/TightChart';
import LineChart from 'components/LineChart';
import ReactDatetime from 'react-datetime';
import Select from 'react-select';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { listProducts, getDiscovery, toolboxCorrelation, getQuickViewData } from 'services/analysis';
import { optionizeValue, optionizeAll, getValue, getDate, getYearMonth, monthDiff } from 'helpers/select';
import { confidenceLevelToInterval, tablePresets, toDeciTable, formatCurrency } from 'helpers/formatter';
import TightTable from 'components/TightTable';
import { colors } from 'helpers/chart';
import { Descriptions, Notes } from '../others';

function CalendarYearReturns({ database, product, converter, setName }) {
  const [yearOptions, setYearOptions, selectedYearOption, setSelectedYearOption] = useArrayState();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);

  const fetchData = async () => {
    setLoading(true);

    const [data, err] = await getQuickViewData({
      database,
      product,
      converter,
      page_choice: 'calendar_year_returns',
    });

    if (data) {
      setResult(data);
      setName(data.formal_name);

      const yearStrs = Object.keys(data.monthly_returns).map(Number);
      const years = yearStrs.map(Number);
      const yearsASC = _.orderBy(years, null, 'asc');
      const yearsDESC = _.orderBy(years, null, 'desc');
      const yearOptions = optionizeAll(yearsDESC);
      setYearOptions(yearOptions);
      setSelectedYearOption(yearOptions[0]);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!database || !product) return;

    fetchData();
  }, [database, product, converter]);

  if (loading) {
    return <FixedCenterLoader />;
  }

  if (!result) {
    return null;
  }

  console.log('CalendarYearReturns', result);

  const sortedYears = result.calendar_year_return.index
    .filter((row) => !['As of Date'].includes(row))
    .sort()
    .reverse();

  return (
    <>
      <Descriptions content={result.descriptions} />
      <div className="tw-text-left tw-font-bold">SELECT YEAR</div>
      <Select
        className="react-select tw-w-[200px]"
        value={selectedYearOption}
        onChange={setSelectedYearOption}
        options={yearOptions}
      />
      {selectedYearOption && (
        <Card>
          <CardHeader tag="h4" className="mt-0 text-center">
            Calendar Year Returns
            <div className="tw-text-lg">{selectedYearOption.label}</div>
          </CardHeader>
          <CardBody>
            <TightTable
              suffix="quick-view-risk-metrics"
              data={result.monthly_returns[selectedYearOption.value]}
              dataType="percentage"
              indexWidth="120px"
              hideIndexTable
            />
          </CardBody>
        </Card>
      )}

      <Card className="tw-max-w-3xl tw-mx-auto">
        <CardHeader tag="h4" className="mt-0 text-center">
          Calendar Year Returns
          <div className="tw-text-lg">{result.calendar_year_return.columns[0]}</div>
          <TimePeriod dates={result.table_dates} />
        </CardHeader>
        <CardBody>
          <TightChart
            title="Calendar Year Returns"
            data={result.calendar_year_return}
            rows={(rows) =>
              rows.filter((row) => {
                if (['As of Date'].includes(row)) return false;

                // min year range to 5 years
                const yearIndex = sortedYears.findIndex((v) => v === row);
                if (yearIndex < 5) return true;

                const rowInd = result.calendar_year_return.index.findIndex((v) => v === row);
                return result.calendar_year_return.data[rowInd][0];
              })
            }
            dataType="percentage"
          />
        </CardBody>
      </Card>
      <Notes content={result.notes} />
    </>
  );
}

export default CalendarYearReturns;
